import React from 'react';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { valids } from '@config/index';

interface Props {
  addr: string;
  setAddr: React.Dispatch<React.SetStateAction<string>>;
  zone: string;
  setZone: React.Dispatch<React.SetStateAction<string>>;
}

export default function SearchAddress({ addr, setAddr, zone, setZone }: Props) {
  const open = useDaumPostcodePopup();

  const handleComplete = (data: any) => {
    setAddr(data.address);
    setZone(data.zonecode ?? '');
  };

  return (
    <div className="flex gap-3">
      <input
        type="text"
        id="address"
        className="input line1_text flex-1"
        placeholder={valids.inputAddress}
        readOnly
        value={zone !== '' ? `[${zone}] ${addr}` : addr}
        onClick={() => open({ onComplete: handleComplete })}
      />
      <button
        type="button"
        className="btn btn_style01 h-9 rounded-sm px-3 text-xs font-medium"
        onClick={() => open({ onComplete: handleComplete })}
      >
        주소검색
      </button>
    </div>
  );
}
