import React from 'react';
import { Link } from 'react-router-dom';
import imgLogo from '@assets/images/shared/logo02.svg';
import HomeApi from '@api/home/home.query';

interface Props {
  btmNav?: boolean;
}

export default function Footer({ btmNav }: Props) {
  // api
  const resultData = HomeApi.useFooterInfoQuery()?.data?.data?.data;

  return (
    <footer id="footer" className={btmNav ? 'pb-24' : ''}>
      <div className="inner">
        <div className="ft_top">
          <p>
            고객센터{' '}
            <a href={`tel:${resultData?.customerServicePhone ?? ''}`}>
              {resultData?.customerServicePhone ?? ''}
            </a>
          </p>
          <span
            dangerouslySetInnerHTML={{
              __html: resultData?.businessHours
                ? resultData?.businessHours.replace(/\\n/g, '<br />')
                : '',
            }}
          />
        </div>
        <div className="ft_btm">
          <ul>
            <li>
              <a href="/">고객센터</a>
            </li>
            <li>
              <Link to="/policy">개인정보처리방침</Link>
            </li>
            <li>
              <Link to="/terms">이용약관</Link>
            </li>
            <li>
              <Link to="/service-info/rentique">회사소개</Link>
            </li>
            <li>
              <Link to="/luxshare">위탁신청</Link>
            </li>
          </ul>
          <Link to="/" className="ft_logo">
            <img src={imgLogo} alt="RENTIQUE LOGO" />
          </Link>
          <address>
            ㈜{resultData?.companyName ?? ''}
            <br />
            대표자명. {resultData?.representativeName ?? ''}
            <br />
            소재지. {resultData?.address ?? ''}
            <br />
            사업자등록번호. {resultData?.businessRegistrationNumber ?? ''}
            <br />
            통신판매업신고번호. {resultData?.mailOrderSalesReportNumber ?? ''}
          </address>
          <p>
            고객님께 안전한 서비스를 제공하기위해 결제 시 렌트백에서 가입한
            나이스페이와 계약하여 안전거래를 보장하고 있습니다.
          </p>
          <span>copyrights @Rental bag. All right reversed.</span>
        </div>
      </div>
    </footer>
  );
}
