import React, { useEffect, useState } from 'react';
import {
  format,
  differenceInDays,
  eachDayOfInterval,
  addDays,
  subDays,
  getDay,
} from 'date-fns';
import { ko } from 'date-fns/locale';
import { DayPicker, DateRange, DayProps, Matcher } from 'react-day-picker';
import CalendarDay from '@app/product/rent/calendarDay';
import UsePeriodModal from '@components/modal/usePeriod';
import ProductDto from '@api/product/product.dto';
import { holiday2024Data } from '@temp/rentData';

type CustomDaysTypes = {
  [key: string]: number;
};

interface Props {
  data: ProductDto.Products.Response;
  rentingData?: string[];
}

export default function RentCalendar({ data, rentingData }: Props) {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [priceDates, setPriceDates] = useState<CustomDaysTypes>({});
  const [disabledDates, setDisabledDates] = useState<string[]>([]);
  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>(
    undefined,
  );
  const [disabledOption, setDisabledOption] = useState<
    Matcher | Matcher[] | undefined
  >([]);
  const [periodOpen, setPeriodOpen] = useState(false);

  const handleSelect = (range: DateRange | undefined) => {
    setSelectedRange(range);

    // 시작일 선택한 경우
    if (range?.from) setStartDate(range.from);
    else setStartDate(undefined);

    // 종료일 선택한 경우
    if (range?.to) setEndDate(range.to);
    else setEndDate(undefined);
  };

  const onHolidayDisabled = (type?: string) => {
    let spaceTwoDays;
    if (type === 'init') {
      spaceTwoDays = holiday2024Data.map((item) => {
        return addDays(item, 1);
      });
    } else {
      spaceTwoDays = holiday2024Data.map((item) => {
        return subDays(item, 1);
      });
    }
    const days = spaceTwoDays?.map((item) => format(item, 'yyyy-MM-dd'));
    // console.log('???', holiday2024Data, days);
    return days;
  };

  const onResetDisabled = () => {
    const afterSixWeeksDay = addDays(new Date(), 42);
    let beforeDate;
    let afterDate;

    // 비활성화 옵션 데이터 표기
    if (
      differenceInDays(new Date(data?.salesStartDate ?? ''), new Date()) < 0
    ) {
      beforeDate = addDays(new Date(), 2);
    } else {
      beforeDate = new Date(data?.salesStartDate ?? '');
    }
    if (
      differenceInDays(new Date(data?.salesEndDate ?? ''), afterSixWeeksDay) < 0
    ) {
      if (
        differenceInDays(new Date(data?.salesEndDate ?? ''), new Date()) > 0
      ) {
        afterDate = new Date(data?.salesEndDate ?? '');
      } else {
        afterDate = new Date();
      }
    } else {
      afterDate = afterSixWeeksDay;
    }
    const opts = [{ before: beforeDate }, { after: afterDate }];

    // 이용기간 데이터 표기
    const allDates = eachDayOfInterval({ start: beforeDate, end: afterDate });
    const monTuesDays = allDates.filter((item) => {
      const day = getDay(item);
      return day === 1 || day === 2; // 월, 화
    });
    const days = monTuesDays.map((item) => format(item, 'yyyy-MM-dd'));

    setDisabledOption(opts);
    setDisabledDates([...days, ...onHolidayDisabled('init')]);
  };

  useEffect(() => {
    let rdays: string[] = [];
    if (rentingData !== undefined && rentingData?.length > 0)
      rdays = rentingData;

    if (startDate !== undefined) {
      if (endDate !== undefined) {
        /* === 시작일 & 종료일 선택한 경우 === */
        // 비활성화 옵션 데이터 표기
        const opts = [{ before: startDate }, { after: endDate }];

        // 이용기간 데이터 표기
        const allDates = eachDayOfInterval({
          start: addDays(startDate, 1),
          end: subDays(endDate, 1),
        });
        const days = allDates.map((item) => format(item, 'yyyy-MM-dd'));

        setDisabledOption(opts);
        setDisabledDates([...rdays, ...days]);
        setPeriodOpen(true);
      } else {
        /* === 시작일만 선택한 경우 === */
        // 비활성화 옵션 데이터 표기
        let afterDate = addDays(startDate, 13);
        if (
          differenceInDays(new Date(data?.salesEndDate ?? ''), afterDate) < 0
        ) {
          afterDate = new Date(data?.salesEndDate ?? '');
        }
        const opts = [{ before: startDate }, { after: afterDate }];

        // 가격 데이터 표기
        const defaultPrice =
          Number(data?.discountRate ?? 0) > 0
            ? Number(data?.discountPrice ?? 0)
            : Number(data?.dailyRentalPrice ?? 0);
        const prices: CustomDaysTypes = {};
        let date = startDate;
        let price = defaultPrice ?? 0;
        for (let i = 1; i <= 14; i += 1) {
          if (i > 3 && i <= 6) {
            price = Math.round((defaultPrice * 0.9) / 100) * 100;
          } else if (i > 6 && i <= 9) {
            price = Math.round((defaultPrice * 0.8) / 100) * 100;
          } else if (i > 9) {
            price = Math.round((defaultPrice * 0.7) / 100) * 100;
          }
          prices[format(date, 'yyyy-MM-dd')] = price;

          date = addDays(date, 1);
        }

        // 이용기간 데이터 표기
        const allDates = eachDayOfInterval({
          start: addDays(startDate, 1),
          end: afterDate,
        });
        const thurFriDays = allDates.filter((item) => {
          const day = getDay(item);
          return day === 5 || day === 6; // 금, 토
        });
        const days = thurFriDays.map((item) => format(item, 'yyyy-MM-dd'));

        setDisabledOption(opts);
        setPriceDates(prices);
        setDisabledDates([
          ...rdays,
          ...days,
          format(addDays(startDate, 1), 'yyyy-MM-dd'),
          ...onHolidayDisabled(),
        ]);
      }
    } else {
      // 날짜 선택하지 않은 경우
      onResetDisabled();
    }

    if (
      (startDate === undefined && endDate === undefined) ||
      (startDate !== undefined && endDate !== undefined)
    ) {
      setPriceDates({});
    }
  }, [rentingData, startDate, endDate]);

  useEffect(() => {
    onResetDisabled();
    // onHolidayDisabled();
  }, [data]);

  return (
    <>
      <div className="calendar_area">
        <div className="cal_info_area">
          <ul>
            <li>
              <i className="col01" />
              <span>{startDate ? '종료가능' : '시작가능'}</span>
            </li>
            <li>
              <i className="col02" />
              <span>이용가능</span>
            </li>
            <li>
              <i className="col03" />
              <span>이용불가</span>
            </li>
          </ul>
        </div>
        <DayPicker
          locale={ko}
          formatters={{
            formatCaption: (date, options) => format(date, 'yyyy. LL', options),
          }}
          mode="range"
          disabled={disabledOption}
          min={2}
          max={13}
          selected={selectedRange}
          onSelect={handleSelect}
          // modifiers={{
          //   specialDay: (date) => {
          //     const dateKey = date.toISOString().split('T')[0];
          //     return specialDays[dateKey];
          //   },
          // }}
          // modifiersClassNames={{
          //   specialDay: 'special-day', // 특정 날짜에 적용될 클래스
          // }}
          components={{
            Day: (props: DayProps) => (
              <CalendarDay
                {...props}
                startDate={startDate}
                endDate={endDate}
                priceDates={priceDates}
                disabledDates={disabledDates}
              />
            ),
          }}
          // onDayClick={(day) => {
          //   console.log('dayClick', day);
          // }}
          // footer={
          //   selected
          //     ? `Selected: ${selected.toLocaleDateString()}`
          //     : 'Pick a day.'
          // }
        />
      </div>

      <UsePeriodModal
        open={periodOpen}
        setOpen={setPeriodOpen}
        range={selectedRange}
        data={data}
      />
    </>
  );
}
