import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import Header from '@components/shared/header';
import BottomNav from '@components/shared/bottomNav';
import MsgModal from '@components/modal/msg';
import UpdateDeliveryModal from '@components/modal/updateDelivery';
import CheckIsLogin from '@components/checkIsLogin';
import { common, valids } from '@config/index';
import { formatPhone, sleep } from '@utils/common';
import useStore from '@store/index';
import DeliveryApi from '@api/delivery/delivery.query';
import { deliveryData } from '@temp/deliveryData';

function Page() {
  const { setLoading, setToastContainerStyle } = useStore((state) => state);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgContents, setMsgContents] = useState<
    JSX.Element | JSX.Element[] | null
  >(null);
  const [msgBtns, setMsgBtns] = useState<JSX.Element | JSX.Element[] | null>(
    null,
  );
  const [deliveryOpen, setDeliveryOpen] = useState(false);
  const [deliveryId, setDeliveryId] = useState<number | undefined>(undefined);

  // api
  const { data: callData, isLoading, refetch } = DeliveryApi.useDeliveryQuery();
  const listData = callData?.data?.data?.result;
  const useDeleteDeliveryMutation = DeliveryApi.useDeleteDeliveryMutation();

  const handleDeleteClick = (id?: number) => {
    if (!id) return;

    setLoading(true);
    useDeleteDeliveryMutation.mutate(id);
  };

  const handleModalOpen = (btn: string, id?: number) => {
    switch (btn) {
      case '삭제':
        setMsgContents(
          <>
            <p className="tit">배송지 삭제</p>
            <span className="desc">배송지 정보를 삭제하시겠습니까?</span>
          </>,
        );
        setMsgBtns(
          <>
            <button
              type="button"
              className="btn btn_style03 h-10 w-full rounded-sm text-sm font-bold"
              onClick={() => setMsgOpen(false)}
            >
              취소
            </button>
            <button
              type="button"
              className="btn btn_style02 h-10 w-full rounded-sm text-sm font-bold"
              onClick={() => handleDeleteClick(id)}
            >
              확인
            </button>
          </>,
        );
        break;
      case '기본배송지 삭제':
        setMsgContents(
          <>
            <p className="tit">배송지 삭제 불가 안내</p>
            <span className="desc">
              기본 배송지로 등록되어 배송지 삭제가 불가능합니다. <br /> 기본
              배송지를 변경 후 삭제해 주세요.
            </span>
          </>,
        );
        setMsgBtns(null);
        break;
      default:
        break;
    }
    setMsgOpen(true);
  };

  // 배송지 삭제 SUCCESS !!!
  useEffect(() => {
    if (!useDeleteDeliveryMutation.isSuccess) return;
    setMsgOpen(false);
    setToastContainerStyle({ bottom: 78 });
    toast(valids.deleteDelivery, { style: common.toastStyle });
    refetch();
    if (deliveryOpen) setDeliveryOpen(false);
    setLoading(false);
  }, [useDeleteDeliveryMutation.isSuccess]);

  useEffect(() => {
    if (!deliveryOpen) refetch();
  }, [deliveryOpen]);

  useEffect(() => {
    if (listData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [listData, isLoading]);

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="배송지 관리" back="/mypage" searchCart />
          </div>

          <div className="pb-24 pt-4">
            <div className="inner">
              {(listData ?? [])?.length > 0 ? (
                <>
                  <h3 className="text-xs font-medium text-gray-9">
                    배송지 목록
                  </h3>
                  <div className="mb-4">
                    {listData?.map((item) => {
                      return (
                        <div
                          key={item.id}
                          className="flex flex-col items-start gap-2 border-b border-b-gray-c py-4"
                        >
                          {item?.isDefault && (
                            <p className="inline-flex h-5 items-center rounded-sm bg-main-02 px-2 text-2xs font-medium text-main">
                              기본배송지
                            </p>
                          )}
                          <div className="flex w-full items-center justify-between gap-2">
                            <p className="line1_text flex-1 text-base font-bold text-black">
                              {item?.recipientName ?? ''}
                              {item?.addressName
                                ? `(${item?.addressName})`
                                : ''}
                            </p>
                            <div className="flex items-center gap-1">
                              <button
                                type="button"
                                className="btn btn_style04 h-7.5 w-11 rounded-sm text-xs font-medium"
                                onClick={() => {
                                  if (item?.isDefault)
                                    handleModalOpen('기본배송지 삭제');
                                  else handleModalOpen('삭제', item?.id);
                                }}
                              >
                                삭제
                              </button>
                              <button
                                type="button"
                                className="btn btn_style01 h-7.5 w-11 rounded-sm text-xs font-medium"
                                onClick={() => {
                                  setDeliveryId(item?.id);
                                  setDeliveryOpen(true);
                                }}
                              >
                                수정
                              </button>
                            </div>
                          </div>
                          <p className="text-xs font-medium text-gray-9">
                            {formatPhone(item?.phoneNumber_1 ?? '')}
                          </p>
                          <p className="text-xs font-medium text-black">
                            {item?.postCode ? `[${item.postCode}] ` : ''}
                            {item?.address1 ?? ''}
                            {item?.address2 && <br />}
                            {item?.address2 ?? ''}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : null}
              {deliveryData?.length < 20 && (
                <button
                  type="button"
                  className="btn btn_style01 h-10 w-full rounded-sm text-sm font-medium"
                  onClick={() => {
                    setDeliveryId(undefined);
                    setDeliveryOpen(true);
                  }}
                >
                  배송지 추가
                </button>
              )}
            </div>
          </div>

          <MsgModal open={msgOpen} setOpen={setMsgOpen} btns={msgBtns}>
            {msgContents}
          </MsgModal>
          <UpdateDeliveryModal
            open={deliveryOpen}
            setOpen={setDeliveryOpen}
            id={deliveryId}
            isFirst={(listData ?? [])?.length <= 0}
            onDelete={handleModalOpen}
          />

          <BottomNav active="MYPAGE" />
        </div>
      </div>
    </>
  );
}
export default Page;
