import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { differenceInDays, format } from 'date-fns';
import Modal from '@components/modal/modal';
import { common, valids } from '@config/index';
import useStore from '@store/index';
import ProductDto from '@api/product/product.dto';
import CartApi from '@api/cart/cart.query';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: ProductDto.Products.Response;
  range?: {
    from?: Date;
    to?: Date;
  };
}

export default function UsePeriodModal({ open, setOpen, data, range }: Props) {
  const navigate = useNavigate();
  const { setLoading, setAlert, setToastContainerStyle } = useStore(
    (state) => state,
  );
  const [periodDates, setPeriodDates] = useState('');
  const [periodNumber, setPeriodNumber] = useState(0);
  const [priceData, setPriceData] = useState({
    total: '0',
    period: '0',
    coupon: '0',
    totalOrder: '0',
    percent: '0',
  });

  // api
  const useCreateCartMutation = CartApi.useCreateCartMutation();

  const handleCreateCart = () => {
    if (!data?.id) return;

    setLoading(true);
    const formData = {
      productId: data?.id,
      rentalStartDate: format(range?.from ?? '', 'yyyy-MM-dd'),
      rentalEndDate: format(range?.to ?? '', 'yyyy-MM-dd'),
    };
    // console.log(formData);
    useCreateCartMutation.mutate(formData);
  };

  // ERROR !!!
  useEffect(() => {
    if (!useCreateCartMutation.isError) return;
    setAlert(
      useCreateCartMutation.error?.response?.data?.message ?? valids.errored,
    );
    setLoading(false);
  }, [useCreateCartMutation.isError]);

  // 장바구니 SUCCESS !!!
  useEffect(() => {
    if (!useCreateCartMutation.isSuccess) return;
    setLoading(false);
    setToastContainerStyle({ bottom: 72 });
    toast(valids.goCart, { style: common.toastStyle });
  }, [useCreateCartMutation.isSuccess]);

  useEffect(() => {
    if (data) {
      const defaultPrice =
        Number(data?.discountRate ?? 0) > 0
          ? Number(data?.discountPrice ?? 0) / 100
          : Number(data?.dailyRentalPrice ?? 0) / 100;
      const defaultPeriodPrice = defaultPrice * periodNumber; // 총 금액

      // 기간할인 적용 금액
      let totalRentPrice = 0;
      for (let i = 1; i <= periodNumber; i += 1) {
        if (i >= 1 && i <= 3) {
          totalRentPrice += defaultPrice;
        } else if (i > 3 && i <= 6) {
          totalRentPrice += Math.round((defaultPrice * 0.9) / 100) * 100;
        } else if (i > 6 && i <= 9) {
          totalRentPrice += Math.round((defaultPrice * 0.8) / 100) * 100;
        } else if (i > 9) {
          totalRentPrice += Math.round((defaultPrice * 0.7) / 100) * 100;
        }
      }

      // 기간할인 + 쿠폰할인 적용 금액
      let couponDiscountPrice = totalRentPrice;
      if (data?.coupon?.discountType === 'percentage') {
        couponDiscountPrice =
          Math.round(
            (totalRentPrice *
              (100 - Number(data?.coupon?.discountValue ?? 0))) /
              100 /
              100,
          ) * 100;
      } else if (data?.coupon?.discountType === 'amount') {
        couponDiscountPrice =
          totalRentPrice - Number(data?.coupon?.discountValue ?? 0);
      }

      const periodDiscount = totalRentPrice - defaultPeriodPrice;
      const couponDiscount = couponDiscountPrice - totalRentPrice;
      const discountPercent =
        100 - Math.round((couponDiscountPrice * 100) / defaultPeriodPrice);

      setPriceData({
        total: defaultPeriodPrice.toLocaleString(),
        period: periodDiscount.toLocaleString(),
        coupon: couponDiscount.toLocaleString(),
        totalOrder: couponDiscountPrice.toLocaleString(),
        percent: discountPercent.toString(),
      });
    }
  }, [data, periodNumber]);

  useEffect(() => {
    if (open && range?.from && range?.to) {
      const startDate = format(range.from, 'yy. MM. dd');
      const startDay = format(range.from, 'c');
      const endDate = format(range.to, 'yy. MM. dd');
      const endDay = format(range.to, 'c');
      setPeriodDates(
        `${startDate}(${common.weeks.filter((item) => item.id === startDay)?.[0]
          ?.text}) ~ ${endDate}(${common.weeks.filter(
          (item) => item.id === endDay,
        )?.[0]?.text})` ?? '',
      );
      setPeriodNumber(differenceInDays(range.to, range.from) + 1);
    }
  }, [open, range]);

  return open ? (
    <Modal className="md_use_period" type="BOTTOM" setOpen={setOpen}>
      <div className="modal_mid">
        <p className="text-center text-sm font-bold text-black">이용기간</p>
        <div className="period_area mt-4">
          <p>{periodDates}</p>
          <i />
          <p>총 {periodNumber}일</p>
        </div>
        <div className="mt-4">
          <dl>
            <dt>총 금액</dt>
            <dd>{priceData.total ?? 0}</dd>
          </dl>
          <dl className="mt-3">
            <dt>기간할인</dt>
            <dd>
              <span className="text-main">{priceData.period ?? 0}</span>
            </dd>
          </dl>
          {priceData.coupon !== '0' && (
            <dl className="mt-3">
              <dt>쿠폰할인</dt>
              <dd>
                <span className="text-main">{priceData.coupon ?? 0}</span>
              </dd>
            </dl>
          )}
          <dl className="total">
            <dt>주문 금액</dt>
            <dd>
              <div className="flex items-center gap-1">
                <p className="percent">{priceData.percent ?? 0}%</p>
                <p className="price">{priceData.totalOrder ?? 0}</p>
              </div>
              <p className="desc">무료배송</p>
            </dd>
          </dl>
        </div>
      </div>
      <div className="modal_btm mt-4">
        <button
          type="button"
          className="btn btn_style04 h-12 rounded-sm text-sm"
          onClick={handleCreateCart}
        >
          장바구니
        </button>
        <button
          type="button"
          className="btn btn_style02 h-12 rounded-sm text-sm"
          onClick={() =>
            navigate(
              `/order?id=${data?.id}&start=${format(
                range?.from ?? '',
                'yyyy-MM-dd',
              )}&end=${format(range?.to ?? '', 'yyyy-MM-dd')}`,
            )
          }
        >
          바로 렌트
        </button>
      </div>
    </Modal>
  ) : null;
}
