import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import icArrow from '@assets/images/icon_arrow_down.svg';
import Header from '@components/shared/header';
import BottomNav from '@components/shared/bottomNav';
import { valids } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import CategoryDto from '@api/category/category.dto';
import CategoryApi from '@api/category/category.query';
// import { categoryData } from '@temp/categoryData';

interface ViewDepth02Props {
  item: CategoryDto.Category;
  item2: CategoryDto.Category;
  handleCategoryClick: (tab: number, id: number) => void;
}

// Depth02 Component
function ViewDepth02({ item, item2, handleCategoryClick }: ViewDepth02Props) {
  const navigate = useNavigate();
  const categoryData3 =
    CategoryApi.useTertiaryCategoriesQuery(item2.id)?.data?.data?.data
      ?.result ?? [];

  useEffect(() => {
    // depth02 클릭 이벤트
    $('.category_page_wrap ul.depth02 > li > button')
      .off('click')
      .on('click', function () {
        $(this).parent().siblings().find('.depth03').slideUp();
        $(this).next('.depth03').stop(false, false).slideToggle();
      });
  }, []);

  return (
    <>
      <button
        type="button"
        onClick={() => {
          if (categoryData3?.length > 0) {
            handleCategoryClick(2, item2.id);
          } else {
            navigate(
              `/product?c1=${item.id}&c1Name=${item.name}&c2=${item2.id}&c2Name=${item2.name}`,
            );
          }
        }}
      >
        {item2.name}
        {categoryData3?.length > 0 && (
          <i>
            <img src={icArrow} alt="arrow" />
          </i>
        )}
      </button>
      {categoryData3?.length > 0 && (
        <div className="depth03">
          <ul>
            <li>
              <Link
                to={`/product?c1=${item.id}&c1Name=${item.name}&c2=${item2.id}&c2Name=${item2.name}`}
              >
                전체
              </Link>
            </li>
            {categoryData3.map((item3, index3) => {
              return (
                <li key={index3}>
                  <Link
                    to={`/product?c1=${item.id}&c1Name=${item.name}&c2=${item2.id}&c2Name=${item2.name}&c3=${item3.id}`}
                  >
                    {item3.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}

function Page() {
  const navigate = useNavigate();
  const { setLoading } = useStore((state) => state);
  const [active, setActive] = useState<{
    tab1?: number;
    tab2?: number;
  }>({
    tab1: undefined,
    tab2: undefined,
  });

  // api
  const { data: callData, isLoading } = CategoryApi.useCategoriesQuery();
  const categoryData = callData?.data?.data?.result;
  const categoryData2 = CategoryApi.useSecondaryCategoriesQuery(active.tab1)
    ?.data?.data?.data?.result;

  const handleCategoryClick = (tab: number, id: number) => {
    let tmpActive = active;
    if (tab === 1) {
      tmpActive = { tab1: id, tab2: undefined };
    } else if (tab === 2) {
      if (tmpActive.tab2 === id) {
        tmpActive = { ...tmpActive, tab2: undefined };
      } else {
        tmpActive = { ...tmpActive, tab2: id };
      }
    }
    setActive(tmpActive);
  };

  useEffect(() => {
    if (categoryData !== undefined && !isLoading) {
      // depth01 클릭 이벤트
      $('.category_page_wrap > ul > li > button')
        .off('click')
        .on('click', function () {
          $('.category_page_wrap').find('.depth03').slideUp();
        });

      setActive({
        tab1: categoryData?.[0]?.id ?? undefined,
        tab2: categoryData2?.[0]?.id ?? undefined,
      });

      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [categoryData, isLoading]);

  return (
    <div id="root_layout">
      <div className="root_container">
        <div className="sticky_menu">
          <Header title="카테고리" back />
        </div>

        <div className="category_page_wrap">
          {(categoryData ?? [])?.length > 0 ? (
            <ul>
              {categoryData?.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={
                      active?.tab1 && active?.tab1 === item.id ? 'on' : ''
                    }
                  >
                    <button
                      type="button"
                      onClick={() => handleCategoryClick(1, item.id)}
                    >
                      {item?.name ?? ''}
                    </button>
                    <ul className="depth02">
                      <li>
                        <button
                          type="button"
                          onClick={() =>
                            navigate(
                              `/product?c1=${item.id}&c1Name=${item.name}`,
                            )
                          }
                        >
                          전체
                        </button>
                      </li>
                      {categoryData2?.map((item2, index2) => {
                        return (
                          <li
                            key={index2}
                            className={
                              active?.tab2 && active?.tab2 === item2.id
                                ? 'on'
                                : ''
                            }
                          >
                            <ViewDepth02
                              item={item}
                              item2={item2}
                              handleCategoryClick={handleCategoryClick}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div className="no_data_text">
              <p>{valids.noDataCategory}</p>
            </div>
          )}
        </div>

        <BottomNav active="CATEGORY" />
      </div>
    </div>
  );
}
export default Page;
