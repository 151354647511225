import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { common, valids } from '@config/index';
import useStore from '@store/index';
import AuthApi from '@api/auth/auth.query';
import FavoriteApi from '@api/favorite/favorite.query';

interface Props {
  id: number;
  likeYn?: boolean | null;
  refetch?: () => void;
  toastBottom?: number;
  styleType?: number;
}

export default function LikeBtn({
  id,
  likeYn = false,
  refetch,
  toastBottom = 16,
  styleType = 1,
}: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const beforeUrl = location.pathname + location.search;
  const { setLoading, setToastContainerStyle } = useStore((state) => state);

  // api
  const isLogin = AuthApi.useIsLogin();
  const useFavoriteMutation = FavoriteApi.useFavoriteMutation();
  const useDeleteFavoriteMutation = FavoriteApi.useDeleteFavoriteMutation();

  const onClick = () => {
    /** TODO :
     * 비회원
     * - 로그인 페이지로 이동
     * 회원
     * - 찜한 상품 클릭 시,
     *   찜 삭제 api
     * - 찜 안한 상품 클릭 시,
     *   찜하기 api 및 '해당상품이 찜한상품으로 등록되었습니다.' 토스트 메시지 노출
     */

    if (isLogin && id) {
      setLoading(true);
      if (!likeYn) useFavoriteMutation.mutate({ productId: id });
      else useDeleteFavoriteMutation.mutate(id);
    } else {
      navigate(`/login?returnUrl=${encodeURIComponent(beforeUrl)}`, {
        replace: true,
      });
    }
  };

  // 찜하기 SUCCESS !!!
  useEffect(() => {
    if (!useFavoriteMutation.isSuccess) return;
    setToastContainerStyle({ bottom: toastBottom });
    toast(valids.addInterest, { style: common.toastStyle });
    setLoading(false);
    refetch?.();
  }, [useFavoriteMutation.isSuccess]);

  // 찜 삭제 SUCCESS !!!
  useEffect(() => {
    if (!useDeleteFavoriteMutation.isSuccess) return;
    setLoading(false);
    refetch?.();
  }, [useDeleteFavoriteMutation.isSuccess]);

  return (
    <button
      type="button"
      className={`heart ${likeYn ? 'on' : ''}`}
      onClick={onClick}
    >
      {styleType === 2 ? (
        <>
          <i />
          <span className="hidden_text">관심상품</span>
        </>
      ) : (
        <i>찜하기</i>
      )}
    </button>
  );
}
