import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useForm, SubmitHandler } from 'react-hook-form';
import Header from '@components/shared/header';
import CustomStar from '@components/forms/customStar';
import MultiImgUpload from '@components/forms/multiImgUpload';
import { common, valids } from '@config/index';
import { convertURLtoFile, formatMmddd } from '@utils/common';
import useStore from '@store/index';
import MypageDto from '@api/mypage/mypage.dto';
import MypageApi from '@api/mypage/mypage.query';
import { ReviewProductType } from '@app/mypage/review/page';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  productData?: ReviewProductType;
  refetch?: () => void;
  refetch2?: () => void;
}

export default function UpdateReviewModal({
  open,
  setOpen,
  productData,
  refetch,
  refetch2,
}: Props) {
  const { setLoading, setToastContainerStyle } = useStore((state) => state);
  const { register, handleSubmit, reset, setValue } =
    useForm<MypageDto.MyReviews.CreateRequest>();
  const [rating, setRating] = useState('0');
  const [files, setFiles] = useState<(File | null)[]>([null]);

  // api
  const useCreateReviewMutation = MypageApi.useCreateReviewMutation();
  const useUpdateReviewMutation = MypageApi.useUpdateReviewMutation();

  const onFormSubmit: SubmitHandler<MypageDto.MyReviews.CreateRequest> = (
    data,
  ) => {
    setLoading(true);
    const filteredFiles = files?.filter((item) => item !== null);
    const imageMap: { [key: string]: File } = {};
    filteredFiles.forEach((item, index) => {
      imageMap[`reviewsFile${index + 1}`] = item as File;
    });

    if (!productData?.id) {
      // 작성
      if (
        !(productData?.order_item?.product?.id && productData?.order_item?.id)
      )
        return;

      const formData = {
        ...data,
        ...imageMap,
        rating,
        productId: productData?.order_item?.product?.id,
        orderItemId: productData?.order_item?.id,
      };

      // console.log(formData);
      useCreateReviewMutation.mutate(formData);
    } else {
      // 수정
      if (!productData?.id) return;

      const formData = {
        ...data,
        ...imageMap,
        rating,
        reviewId: productData?.id,
      };

      // console.log(formData);
      useUpdateReviewMutation.mutate(formData);
    }
  };

  // ERROR !!!
  useEffect(() => {
    if (!(useCreateReviewMutation.isError && useUpdateReviewMutation.isError))
      return;
    setLoading(false);
    setToastContainerStyle({ bottom: 84 });
    toast(valids.errored, { style: common.toastStyle });
  }, [useCreateReviewMutation.isError, useUpdateReviewMutation.isError]);

  // 리뷰 작성 SUCCESS !!!
  useEffect(() => {
    if (!useCreateReviewMutation.isSuccess) return;
    setOpen(false);
    setToastContainerStyle({ bottom: 78 });
    toast(valids.reviewSaved, { style: common.toastStyle });
    setLoading(false);
    refetch?.();
    refetch2?.();
  }, [useCreateReviewMutation.isSuccess]);

  // 리뷰 수정 SUCCESS !!!
  useEffect(() => {
    if (!useUpdateReviewMutation.isSuccess) return;
    setOpen(false);
    setToastContainerStyle({ bottom: 78 });
    toast(valids.reviewUpdated, { style: common.toastStyle });
    setLoading(false);
    refetch?.();
    refetch2?.();
  }, [useUpdateReviewMutation.isSuccess]);

  useEffect(() => {
    if (open && productData?.id) {
      setRating(productData?.rating ?? '0');
      setValue('content', productData?.content ?? '');

      (async () => {
        let file: (File | null)[] = [];
        const file1 = productData?.image_file_id_1_file?.fileUrl
          ? await convertURLtoFile(productData?.image_file_id_1_file?.fileUrl)
          : null;
        const file2 = productData?.image_file_id_2_file?.fileUrl
          ? await convertURLtoFile(productData?.image_file_id_2_file?.fileUrl)
          : null;
        const file3 = productData?.image_file_id_3_file?.fileUrl
          ? await convertURLtoFile(productData?.image_file_id_3_file?.fileUrl)
          : null;
        // console.log('file1', file1, file2, file3);

        if (file1) file = [...file, file1];
        if (file2) file = [...file, file2];
        if (file3) file = [...file, file3];
        if (file.length < 3) file = [...file, null];

        setFiles(file);
      })();
    } else {
      reset();
      setRating('0');
      setFiles([null]);
    }
  }, [open]);

  return open ? (
    <div className="modal_wrap full">
      <div className="modal_area">
        <Header
          title={`리뷰 ${productData?.id ? '수정' : '작성'}`}
          closeFunc={() => setOpen(false)}
        />

        <div className="mypage_delivery_wrap">
          <div className="inner">
            <form onSubmit={handleSubmit(onFormSubmit)}>
              {productData && (
                <div className="item_area mb-4">
                  <div className="img_wrap w-25">
                    <div className="img_area">
                      {productData?.order_item?.product?.product_images?.filter(
                        (item) => item.type !== 'info',
                      )?.length > 0 ? (
                        <img
                          src={`${
                            common.imageUrl
                          }/${productData?.order_item?.product?.product_images?.filter(
                            (item) => item.type !== 'info',
                          )?.[0]?.file?.fileUrl}`}
                          alt="상품 이미지"
                        />
                      ) : (
                        <i className="no_productData_img" />
                      )}
                    </div>
                  </div>
                  <div className="text_area">
                    <p className="brand">
                      {productData?.order_item?.product?.brand?.nameEnglish.toUpperCase() ??
                        ''}
                    </p>
                    <p className="product">
                      {productData?.order_item?.product?.productName ?? ''}
                    </p>
                    <p className="price_total mt-3">
                      {Number(
                        productData?.order_item?.finalAmount ?? 0,
                      ).toLocaleString()}
                    </p>
                    <span className="desc mt-3">
                      이용기간 :{' '}
                      {formatMmddd(
                        productData?.order_item?.order?.rentalStartDate ?? '',
                      )}
                      {' - '}
                      {formatMmddd(
                        productData?.order_item?.order?.rentalEndDate ?? '',
                      )}
                    </span>
                  </div>
                </div>
              )}
              <div className="label_input_area">
                <label htmlFor="star">별점 등록</label>
                <CustomStar value={rating} setValue={setRating} />
              </div>
              <div className="label_input_area mt-4">
                <label htmlFor="content">리뷰 작성</label>
                <textarea
                  id="content"
                  className="textarea"
                  cols={30}
                  rows={9}
                  placeholder={valids.inputReview}
                  {...register('content', { required: true })}
                />
              </div>
              <div className="mt-4">
                <MultiImgUpload files={files} setFiles={setFiles} />
              </div>

              <div className="fixed_btm_btn">
                <div className="fixed_btn bg_style01">
                  <button
                    type="submit"
                    className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                  >
                    {productData?.id ? '수정' : '등록'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
