import React from 'react';
import { useNavigate } from 'react-router-dom';
import icCheck from '@assets/images/deco/icon_check.svg';
import imgProduct01 from '@assets/images/temp/product_img08.jpg';
import Header from '@components/shared/header';
import CheckIsLogin from '@components/checkIsLogin';

function Page() {
  const navigate = useNavigate();

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="주문 완료" />
          </div>

          <div className="order_wrap pb-36 pt-4">
            <div className="inner">
              <div className="text-center">
                <img src={icCheck} alt="check" className="mx-auto" />
                <h3 className="mt-8 text-base font-bold text-black">
                  렌트 예약이 정상적으로 완료되었습니다!
                </h3>
              </div>
              <div className="item_area mt-8 items-center border border-gray-c p-2">
                <div className="img_wrap w-25">
                  <div className="img_area">
                    <img src={imgProduct01} alt="상품 이미지" />
                  </div>
                </div>
                <div className="text_area">
                  <p className="brand">PRADA</p>
                  <p className="product">캐러멜 가죽 핸드백</p>
                </div>
              </div>
              <div className="mt-8">
                <dl className="text_list_area">
                  <dt>주문번호</dt>
                  <dd>20240707c12345</dd>
                </dl>
                <dl className="text_list_area mt-2">
                  <dt>이용기간</dt>
                  <dd>24.07.20(토) ~ 24.07.22(월)</dd>
                </dl>
                <dl className="text_list_area mt-2">
                  <dt>회수일</dt>
                  <dd>24. 07. 23(화)</dd>
                </dl>
                <dl className="text_list_area mt-2">
                  <dt>배송정보</dt>
                  <dd>
                    김렌탈 <br />
                    01012341234 <br />
                    [12345] 서울특별시 구로구 디지털로 32길 30 <br />
                    코오롱디지털타워빌란트 1510호
                  </dd>
                </dl>
                <dl className="text_list_area mt-2">
                  <dt>배송메모</dt>
                  <dd>부재 시 문 앞에 놓아주세요.</dd>
                </dl>
                <dl className="text_list_area mt-2">
                  <dt>결제정보</dt>
                  <dd>삼성(1234-56**-***-025)</dd>
                </dl>
                <dl className="text_list_area mt-2">
                  <dt>총 렌트 금액</dt>
                  <dd>147,400원</dd>
                </dl>
                <dl className="text_list_area mt-2">
                  <dt>쿠폰</dt>
                  <dd>-44,220원</dd>
                </dl>
                <dl className="text_list_area mt-2">
                  <dt>
                    <p className="text-main">결제금액</p>
                  </dt>
                  <dd>
                    <b>103,180원</b>
                  </dd>
                </dl>
              </div>
              <div className="fixed_btm_btn">
                <div className="fixed_btn bg_style01">
                  <button
                    type="button"
                    className="btn btn_style03 h-12 w-full rounded-sm text-sm font-bold text-gray-3"
                    onClick={() => navigate(`/order/detail/${1}`)}
                  >
                    렌트 주문 상세보기
                  </button>
                  <button
                    type="button"
                    className="btn btn_style02 mt-2 h-12 w-full rounded-sm text-sm font-bold text-gray-3"
                    onClick={() => navigate('/')}
                  >
                    메인으로 가기
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Page;
