import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import Header from '@components/shared/header';
import BottomNav from '@components/shared/bottomNav';
import ToggleItem from '@components/toggleItem2';
import LargeImage from '@components/largeImage';
import InquiryModal from '@components/modal/inquiry';
import CheckIsLogin from '@components/checkIsLogin';
import { common, valids } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import MypageApi from '@api/mypage/mypage.query';
// import { inqiuryData } from '@temp/inqiuryData';

function Page() {
  const { setLoading } = useStore((state) => state);
  const [tab, setTab] = useState(0);
  const [inquiryOpen, setInquiryOpen] = useState(false);

  // api
  const { data: callData, isLoading } = MypageApi.useProductInquiriesQuery();
  const listData = callData?.data?.data?.result;
  const { data: callData2, refetch: refetch2 } =
    MypageApi.useOnetooneInquiriesQuery();
  const listData2 = callData2?.data?.data?.result;

  useEffect(() => {
    if (listData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [listData, isLoading]);

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="문의내역" back="/mypage" searchCart />
            <div className="tab_area03 bg-white">
              <ul>
                <li className={tab === 0 ? 'on' : ''}>
                  <button type="button" onClick={() => setTab(0)}>
                    <b>상품 문의</b> {listData?.length ?? 0}
                  </button>
                </li>
                <li className={tab === 1 ? 'on' : ''}>
                  <button type="button" onClick={() => setTab(1)}>
                    <b>1:1 문의</b> {listData2?.length ?? 0}
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div className="mypage_inquiry_wrap pb-24 pt-4">
            <div className="inner">
              {tab === 0 ? (
                (listData ?? [])?.length > 0 ? (
                  <div className="flex flex-col gap-4">
                    {listData?.map((item) => {
                      return (
                        <ToggleItem
                          key={item.id}
                          initOpen={false}
                          title={
                            <>
                              <div className="mb-2">
                                {item?.status === 'answered' ? (
                                  <p className="status text-main">답변완료</p>
                                ) : item?.status === 'pending' ? (
                                  <p className="status text-gray-9">답변대기</p>
                                ) : null}
                              </div>
                              <p className="inquiry_tit">{item?.title ?? ''}</p>
                              <div className="item_area mt-4 items-center">
                                <div className="img_wrap w-17.5">
                                  <div className="img_area">
                                    {item.product?.product_images?.length >
                                    0 ? (
                                      <img
                                        src={`${common.imageUrl}/${item.product?.product_images?.[0]?.file?.fileUrl}`}
                                        alt="상품 이미지"
                                      />
                                    ) : (
                                      <i className="no_data_img" />
                                    )}
                                  </div>
                                </div>
                                <div className="text_area">
                                  <p className="brand">
                                    {item?.product?.brand?.nameEnglish.toUpperCase() ??
                                      ''}
                                  </p>
                                  <p className="product">
                                    {item?.product?.productName ?? ''}
                                  </p>
                                </div>
                              </div>
                            </>
                          }
                        >
                          <div className="inquiry_content mt-4 bg-gray-f8">
                            <div className="tit">
                              <p className="text-black">문의내용</p>
                              <span>
                                {format(item?.createdAt ?? '', 'yyyy.MM.dd')}
                              </span>
                            </div>
                            <p className="desc">{item?.content ?? ''}</p>
                            {item?.image_file_id_1_file ||
                            item?.image_file_id_2_file ||
                            item?.image_file_id_3_file ? (
                              <div className="mt-2 flex items-center gap-1">
                                {item?.image_file_id_1_file && (
                                  <LargeImage
                                    imgSrc={`${common.imageUrl}/${item?.image_file_id_1_file?.fileUrl}`}
                                  >
                                    <div className="w-17.5">
                                      <div className="img_area">
                                        <img
                                          src={`${common.imageUrl}/${item?.image_file_id_1_file?.fileUrl}`}
                                          alt="상품 이미지"
                                        />
                                      </div>
                                    </div>
                                  </LargeImage>
                                )}
                                {item?.image_file_id_2_file && (
                                  <LargeImage
                                    imgSrc={`${common.imageUrl}/${item?.image_file_id_2_file?.fileUrl}`}
                                  >
                                    <div className="w-17.5">
                                      <div className="img_area">
                                        <img
                                          src={`${common.imageUrl}/${item?.image_file_id_2_file?.fileUrl}`}
                                          alt="상품 이미지"
                                        />
                                      </div>
                                    </div>
                                  </LargeImage>
                                )}
                                {item?.image_file_id_3_file && (
                                  <LargeImage
                                    imgSrc={`${common.imageUrl}/${item?.image_file_id_3_file?.fileUrl}`}
                                  >
                                    <div className="w-17.5">
                                      <div className="img_area">
                                        <img
                                          src={`${common.imageUrl}/${item?.image_file_id_3_file?.fileUrl}`}
                                          alt="상품 이미지"
                                        />
                                      </div>
                                    </div>
                                  </LargeImage>
                                )}
                              </div>
                            ) : null}
                          </div>
                          {item?.answer && (
                            <div className="inquiry_content mt-4 bg-main-02">
                              <div className="tit">
                                <p className="text-main">답변내용</p>
                                <span>
                                  {format(item?.answerDate ?? '', 'yyyy.MM.dd')}
                                </span>
                              </div>
                              <p className="desc">{item?.answer ?? ''}</p>
                            </div>
                          )}
                        </ToggleItem>
                      );
                    })}
                  </div>
                ) : (
                  <div className="no_data_text py-4 text-center">
                    <p>{valids.noDataProductInquiry}</p>
                  </div>
                )
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn_style01 h-10 w-full rounded-sm text-sm font-medium"
                    onClick={() => setInquiryOpen(true)}
                  >
                    문의하기
                  </button>
                  {(listData2 ?? [])?.length > 0 ? (
                    <div className="mt-4 flex flex-col gap-4">
                      {listData2?.map((item) => {
                        return (
                          <ToggleItem
                            key={item.id}
                            initOpen={false}
                            title={
                              <>
                                <div className="mb-2">
                                  {item?.status === 'answered' ? (
                                    <p className="status text-main">답변완료</p>
                                  ) : item?.status === 'pending' ? (
                                    <p className="status text-gray-9">
                                      답변대기
                                    </p>
                                  ) : null}
                                </div>
                                <p className="inquiry_tit">
                                  {item?.subject ?? ''}
                                </p>
                              </>
                            }
                          >
                            <div className="inquiry_content mt-4 bg-gray-f8">
                              <div className="tit">
                                <p className="text-black">문의내용</p>
                                <span>
                                  {format(item?.createdAt ?? '', 'yyyy.MM.dd')}
                                </span>
                              </div>
                              <p className="desc">{item?.content ?? ''}</p>
                              {item?.image_file_id_1_file ||
                              item?.image_file_id_2_file ||
                              item?.image_file_id_3_file ? (
                                <div className="mt-2 flex items-center gap-1">
                                  {item?.image_file_id_1_file && (
                                    <LargeImage
                                      imgSrc={`${common.imageUrl}/${item?.image_file_id_1_file?.fileUrl}`}
                                    >
                                      <div className="w-17.5">
                                        <div className="img_area">
                                          <img
                                            src={`${common.imageUrl}/${item?.image_file_id_1_file?.fileUrl}`}
                                            alt="상품 이미지"
                                          />
                                        </div>
                                      </div>
                                    </LargeImage>
                                  )}
                                  {item?.image_file_id_2_file && (
                                    <LargeImage
                                      imgSrc={`${common.imageUrl}/${item?.image_file_id_2_file?.fileUrl}`}
                                    >
                                      <div className="w-17.5">
                                        <div className="img_area">
                                          <img
                                            src={`${common.imageUrl}/${item?.image_file_id_2_file?.fileUrl}`}
                                            alt="상품 이미지"
                                          />
                                        </div>
                                      </div>
                                    </LargeImage>
                                  )}
                                  {item?.image_file_id_3_file && (
                                    <LargeImage
                                      imgSrc={`${common.imageUrl}/${item?.image_file_id_3_file?.fileUrl}`}
                                    >
                                      <div className="w-17.5">
                                        <div className="img_area">
                                          <img
                                            src={`${common.imageUrl}/${item?.image_file_id_3_file?.fileUrl}`}
                                            alt="상품 이미지"
                                          />
                                        </div>
                                      </div>
                                    </LargeImage>
                                  )}
                                </div>
                              ) : null}
                            </div>
                            {item.answer && (
                              <div className="inquiry_content mt-4 bg-main-02">
                                <div className="tit">
                                  <p className="text-main">답변내용</p>
                                  <span>
                                    {format(
                                      item?.answerDate ?? '',
                                      'yyyy.MM.dd',
                                    )}
                                  </span>
                                </div>
                                <p className="desc">{item?.answer ?? ''}</p>
                              </div>
                            )}
                          </ToggleItem>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="no_data_text py-4 text-center">
                      <p>{valids.noDataInquiry}</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          <InquiryModal
            open={inquiryOpen}
            setOpen={setInquiryOpen}
            refetch={refetch2}
          />

          <BottomNav active="MYPAGE" />
        </div>
      </div>
    </>
  );
}
export default Page;
