import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '@components/shared/header';
import HeaderNav from '@components/shared/headerNav';
import Footer from '@components/shared/footer';
import BottomNav from '@components/shared/bottomNav';
import CustomSelect from '@components/forms/customSelect';
import LikeBtn from '@components/likeBtn';
import { common, options, valids } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import CategoryApi from '@api/category/category.query';
import RankingApi from '@api/ranking/ranking.query';
// import { productData } from '@temp/productData';

function Page() {
  const { setLoading } = useStore((state) => state);
  const [tab, setTab] = useState(0);
  const [sorts, setSorts] = useState(options.rankSorts[0].id);

  // api
  const categoryData =
    CategoryApi.useCategoriesQuery()?.data?.data?.data?.result ?? [];
  const {
    data: callData,
    isLoading,
    refetch,
  } = RankingApi.useRankingQuery({
    categoryId: tab !== 0 ? Number(tab) : undefined,
    dateType: sorts,
  });
  const listData = callData?.data?.data?.result;

  useEffect(() => {
    if (listData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [listData, isLoading]);

  return (
    <div id="root_layout">
      <div className="root_container">
        <div className="sticky_menu">
          <Header logo searchCart />
          <HeaderNav active="RANKING" />
        </div>

        {/* 탭 */}
        <div className="tab_area02 grid04 pt-4">
          <div className="inner">
            <ul>
              {[{ id: 0, name: '전체' }, ...categoryData]?.map((item) => {
                return (
                  <li key={item.id} className={tab === item.id ? 'on' : ''}>
                    <button type="button" onClick={() => setTab(item.id)}>
                      {item.name}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        {/* 리스트 */}
        <div className="pb-11 pt-4">
          <div className="inner">
            <div className="flex items-center justify-end pb-4">
              <CustomSelect
                type={1}
                optionClassName="w-24 right-0"
                options={options.rankSorts}
                value={sorts}
                setValue={setSorts}
              />
            </div>
            <div className="list_area list_style01 grid02">
              <ul>
                {(listData ?? [])?.length > 0 ? (
                  listData?.map((item, index) => {
                    const rankNum =
                      index >= 0 && index <= 8 ? `0${index + 1}` : index + 1;
                    return (
                      <li key={item.id}>
                        <p className="rank_text">{rankNum}</p>
                        <div className="relative">
                          <LikeBtn
                            id={item?.id}
                            likeYn={item?.likeYn}
                            refetch={refetch}
                            toastBottom={78}
                          />
                          <Link to={`/product/detail/${item.id}`}>
                            <div className="img_wrap">
                              <div className="img_area rounded-lg">
                                {item?.product_images?.length > 0 ? (
                                  <img
                                    src={`${common.imageUrl}/${item?.product_images?.[0]?.file?.fileUrl}`}
                                    alt="상품 이미지"
                                  />
                                ) : (
                                  <i className="no_data_img" />
                                )}
                                <span className="tag">렌트</span>
                              </div>
                            </div>
                            <div className="text_area">
                              <span className="brand">
                                {item?.brand?.nameEnglish.toUpperCase() ?? ''}
                              </span>
                              <p className="product">
                                {item?.productName ?? ''}
                              </p>
                              <div className="flex items-center justify-start gap-1">
                                {Number(item?.discountRate ?? 0) > 0 && (
                                  <span className="price_discount">
                                    {`${Number(item?.discountRate ?? 0)}%`}
                                  </span>
                                )}
                                <em className="price_desc">일</em>
                                <p className="price_total">
                                  {Number(item?.discountRate ?? 0) > 0
                                    ? Number(
                                        item?.discountPrice ?? 0,
                                      ).toLocaleString()
                                    : Number(
                                        item?.dailyRentalPrice ?? 0,
                                      ).toLocaleString()}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <li className="no_data_text">
                    <p>{valids.noData}</p>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>

        <Footer btmNav />
        <BottomNav />
      </div>
    </div>
  );
}
export default Page;
