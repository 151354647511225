import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Header from '@components/shared/header';
import PassAuthWrap, { AuthType } from '@components/passAuthWrap';
import MsgModal from '@components/modal/msg';
import CheckIsLogin from '@components/checkIsLogin';
import { formatPhone, formatYyyyMmddBar, sleep } from '@utils/common';
import { common, valids } from '@config/index';
import useStore from '@store/index';
import MypageDto from '@api/mypage/mypage.dto';
import MypageApi from '@api/mypage/mypage.query';

function Page() {
  const navigate = useNavigate();
  const { setLoading, setAlert, setToastContainerStyle } = useStore(
    (state) => state,
  );
  const { register, setValue, watch } = useForm<MypageDto.MyInfo.Request>();
  const [authData, setAuthData] = useState<AuthType | null>(null);
  const [isAuthData, setIsAuthData] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);

  // api
  const { data: callData, isLoading, refetch } = MypageApi.useInfoQuery();
  const resultData = callData?.data?.data;
  const useUpdateInfoMutation = MypageApi.useUpdateInfoMutation();

  const onFormSubmit = () => {
    setLoading(true);
    console.log(watch());
    const formData = {
      ...watch(),
      gender:
        watch('gender') === '남성'
          ? true
          : watch('gender') === '여성'
            ? false
            : '',
    };
    console.log(formData);
    useUpdateInfoMutation.mutate(formData);
  };

  // ERROR !!!
  useEffect(() => {
    if (!useUpdateInfoMutation.isError) return;
    setAlert(
      useUpdateInfoMutation.error?.response?.data?.message ?? valids.errored,
    );
    setLoading(false);
  }, [useUpdateInfoMutation.isError]);

  // 회원정보 수정 SUCCESS !!!
  useEffect(() => {
    if (!useUpdateInfoMutation.isSuccess) return;
    refetch();
    setMsgOpen(false);
    setLoading(false);
    setToastContainerStyle({ bottom: 84 });
    toast(valids.updateUserInfo, { style: common.toastStyle });
  }, [useUpdateInfoMutation.isSuccess]);

  // 본인인증 DATA
  useEffect(() => {
    if (!authData) return;
    setValue('name', authData?.name ?? '');
    setValue(
      'birthDate',
      authData?.birthDate ? formatYyyyMmddBar(authData?.birthDate) : '',
    );
    setValue(
      'gender',
      authData?.gender === '1'
        ? '남성'
        : authData?.gender === '0'
          ? '여성'
          : '',
    );
    setValue(
      'phoneNumber',
      authData?.phoneNumber ? formatPhone(authData?.phoneNumber) : '',
    );
  }, [authData, isAuthData]);

  useEffect(() => {
    if (resultData !== undefined && !isLoading) {
      setValue('name', resultData?.name ?? '');
      setValue('birthDate', resultData?.birthDate ?? '');
      setValue(
        'gender',
        resultData?.gender === true
          ? '남성'
          : resultData?.gender === false
            ? '여성'
            : '',
      );
      setValue(
        'phoneNumber',
        resultData?.phoneNumber ? formatPhone(resultData?.phoneNumber) : '',
      );
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [resultData, isLoading]);

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="회원정보수정" back="/mypage" />
          </div>

          <section className="pb-24 pt-4">
            <div className="inner">
              <h3 className="text-xs font-medium text-gray-9">내정보</h3>
              <div className="label_input_area mt-4">
                <label htmlFor="email_id">이메일</label>
                <input
                  type="text"
                  id="email_id"
                  className="input"
                  value={resultData?.email ?? ''}
                  disabled
                />
              </div>
              {resultData?.providerType === null && (
                <button
                  type="button"
                  className="btn btn_style01 mt-4 h-10 w-full rounded-sm text-sm font-medium"
                  onClick={() => navigate('/mypage/change-password')}
                >
                  비밀번호 변경
                </button>
              )}
              <div className="label_input_area mt-4">
                <label htmlFor="name">이름</label>
                <input
                  type="text"
                  id="name"
                  className="input"
                  disabled
                  {...register('name')}
                />
              </div>
              <div className="label_input_area mt-4">
                <label htmlFor="birth">생년월일</label>
                <input
                  type="text"
                  id="birth"
                  className="input"
                  disabled
                  {...register('birthDate')}
                />
              </div>
              <div className="label_input_area mt-4">
                <label htmlFor="gender">성별</label>
                <input
                  type="text"
                  id="gender"
                  className="input"
                  disabled
                  {...register('gender')}
                />
              </div>
              <div className="label_input_area mt-4">
                <label htmlFor="phone">연락처</label>
                <input
                  type="text"
                  id="phone"
                  className="input"
                  disabled
                  {...register('phoneNumber')}
                />
              </div>

              <div className="mt-8 rounded-sm bg-main-02 p-4 text-center">
                <p className="break-keep text-xs text-gray-3">
                  회원정보 수정은 휴대폰 본인인증을 통해서만 수정가능합니다.{' '}
                  <br />
                  인증이 어렵거나 문의사항은 카카오 채널톡으로 문의해주세요.
                </p>
              </div>

              <PassAuthWrap setData={setAuthData} setIsData={setIsAuthData}>
                <button
                  type="button"
                  className="btn btn_style01 mt-8 h-10 w-full rounded-sm text-sm font-medium"
                >
                  회원 정보 수정(본인인증)
                </button>
              </PassAuthWrap>

              <div className="fixed_btm_btn">
                <div className="fixed_btn bg_style01">
                  <div className="flex gap-2">
                    <button
                      type="button"
                      className="btn btn_style04 h-12 w-full rounded-sm text-sm font-bold"
                      onClick={() => navigate(-1)}
                    >
                      취소
                    </button>
                    <button
                      type="button"
                      className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                      onClick={() => setMsgOpen(true)}
                      disabled={!isAuthData}
                    >
                      수정
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <MsgModal
            open={msgOpen}
            setOpen={setMsgOpen}
            btns={
              <>
                <button
                  type="button"
                  className="btn btn_style03 h-10 w-full rounded-sm text-sm font-bold"
                  onClick={() => setMsgOpen(false)}
                >
                  취소
                </button>
                <button
                  type="button"
                  className="btn btn_style02 h-10 w-full rounded-sm text-sm font-bold"
                  onClick={onFormSubmit}
                >
                  확인
                </button>
              </>
            }
          >
            <p className="tit">회원정보 수정</p>
            <span className="desc">회원정보를 수정하시겠습니까?</span>
          </MsgModal>
        </div>
      </div>
    </>
  );
}
export default Page;
