import React from 'react';
import Header from '@components/shared/header';
import { couponData } from '@temp/couponData';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function UseCouponModal({ open, setOpen }: Props) {
  return open ? (
    <div className="modal_wrap full">
      <div className="modal_area">
        <Header back backFunc={() => setOpen(false)} title="받은 쿠폰" />

        <section className="order_coupon_wrap">
          <div className="inner">
            <div className="list_area list_style07">
              <ul>
                {couponData.map((item) => {
                  return (
                    <li key={item.id}>
                      <input
                        type="radio"
                        name="coupon"
                        id={`coupon_${item.id}`}
                        disabled={item.disabled}
                      />
                      <label htmlFor={`coupon_${item.id}`}>
                        <div className="top_area">
                          <div>
                            <p>{item.percent}%</p>
                            <span>{item.name}</span>
                          </div>
                        </div>
                        <div className="btm_area">
                          <p>
                            <b>사용 조건</b>
                            <span>{item.condition}</span>
                          </p>
                          <p className="mt-1">
                            <b>유효 기간</b>
                            <span>{item.period}</span>
                          </p>
                        </div>
                      </label>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="fixed_btm_btn">
              <div className="fixed_btn bg_style02">
                <button
                  type="button"
                  className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                  onClick={() => setOpen(false)}
                >
                  사용하기
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  ) : null;
}
