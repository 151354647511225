import React from 'react';
import { format, isSameDay, differenceInDays } from 'date-fns';

type CustomDaysTypes = {
  [key: string]: number;
};

interface CustomDayProps {
  startDate?: Date;
  endDate?: Date;
  priceDates: CustomDaysTypes; // 날짜 및 가격 데이터
  disabledDates: string[]; // 선택 불가능 날짜
  [key: string]: any;
}

// 캘린더 날짜 영역 커스텀
export default function CalendarDay({
  startDate,
  endDate,
  priceDates,
  disabledDates,
  ...props
}: CustomDayProps) {
  const priceDay = priceDates[props['data-day']];
  const isDisabledDay = disabledDates.indexOf(props['data-day']);

  return (
    <td {...props}>
      <div
        className={`cal_date_area ${
          differenceInDays(props.day.date, startDate) === -2 ||
          (endDate && differenceInDays(props.day.date, endDate) === 1)
            ? 'btn_info_area'
            : ''
        }`}
      >
        {isDisabledDay > -1 ? (
          <button
            type="button"
            disabled
            tabIndex={-1}
            className="custom_disabled"
          >
            {Number(props['data-day'].split('-')[2])}
          </button>
        ) : props.className.includes('rdp-range_end') ? (
          <button
            type="button"
            className="rdp-day_button"
            tabIndex={-1}
            disabled
          >
            {Number(props['data-day'].split('-')[2])}
          </button>
        ) : (
          props.children
        )}
        {priceDay && <span className="btn_price">{priceDay}</span>}
        {startDate && (
          <>
            {differenceInDays(props.day.date, startDate) === -2 && (
              <div className="btn_info">
                <span>출고</span>
              </div>
            )}
            {isSameDay(props.day.date, startDate) && endDate === undefined && (
              <div className="select_info_area">
                <p>
                  <b>{format(startDate, 'M월 d일')}</b>
                  종료일을 <br />
                  선택해주세요.
                </p>
              </div>
            )}
          </>
        )}
        {endDate && differenceInDays(props.day.date, endDate) === 1 && (
          <div className="btn_info">
            <span>회수</span>
          </div>
        )}
      </div>
    </td>
  );
}
