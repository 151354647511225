import React from 'react';
import HomeApi from '@api/home/home.query';

export default function PrivacyPolicy() {
  // api
  const resultData = HomeApi.useFooterInfoQuery()?.data?.data?.data;

  return (
    <div
      dangerouslySetInnerHTML={{ __html: resultData?.privacyPolicy ?? '' }}
    />
  );
}
