import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useStore from '@store/index';
import AuthApi from '@api/auth/auth.query';

interface Props {
  loginCheck: boolean;
  once?: boolean;
}

export default function CheckIsLogin({ loginCheck, once = false }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const beforeUrl = location.pathname + location.search;
  const { setLoading } = useStore((state) => state);
  const hasMounted = useRef(false);

  // api
  const isLogin = AuthApi.useIsLogin();

  useEffect(() => {
    setLoading(false);
    if (!once || (once && !hasMounted.current)) {
      if (loginCheck && isLogin) {
        navigate('/', { replace: true });
      } else if (!loginCheck && !isLogin) {
        navigate(`/login?returnUrl=${encodeURIComponent(beforeUrl)}`, {
          replace: true,
        });
      }
      hasMounted.current = true;
    }
  }, [isLogin]);

  return null;
}
