import React from 'react';
import { Link } from 'react-router-dom';
import icArrow from '@assets/images/icon_arrow_right_gray.svg';
import ProductListSlider from '@components/slider/productList';
import CommonDto from '@api/common.dto';
// import { productData } from '@temp/productData';

interface Props {
  id: number;
  data: CommonDto.Product[];
}

export default function ProductSimilarItems({ id, data }: Props) {
  return (
    <>
      <div className="inner">
        <div className="flex items-center justify-between pb-4">
          <h3 className="prod_tit">유사한 상품</h3>
          <Link
            to={`/product/similar/${id}`}
            className="flex items-center gap-1 text-sm font-medium text-gray-9"
          >
            더보기
            <img src={icArrow} alt="arrow" />
          </Link>
        </div>
      </div>
      <ProductListSlider data={data} />
    </>
  );
}
