import React, { useEffect } from 'react';
import { format } from 'date-fns';
import Header from '@components/shared/header';
import BottomNav from '@components/shared/bottomNav';
import ToggleItem from '@components/toggleItem';
import CheckIsLogin from '@components/checkIsLogin';
import { valids } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import CustomerApi from '@api/customer/customer.query';
// import { noticeData } from '@temp/customerData';

function Page() {
  const { setLoading } = useStore((state) => state);

  // api
  const { data: callData, isLoading } = CustomerApi.useNoticesQuery();
  const listData = callData?.data?.data?.result;

  useEffect(() => {
    console.log(listData);
    if (listData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [listData, isLoading]);

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="공지사항" back />
          </div>

          <div className="mypage_customer_detail_wrap pb-24 pt-4">
            <div className="inner">
              {(listData ?? [])?.length > 0 ? (
                listData?.map((item, index) => {
                  return (
                    <ToggleItem
                      key={item.id}
                      initOpen={index === 0}
                      isArrow={false}
                      title={
                        <div>
                          <span className="mb-1 block text-xs font-normal text-gray-9">
                            {format(item?.createdAt ?? '', 'yyyy.MM.dd')}
                          </span>
                          <p className="text-xs font-medium text-black">
                            {item?.title ?? '-'}
                          </p>
                        </div>
                      }
                    >
                      <div className="p-4">
                        <p className="text-xs font-medium text-gray-9">
                          {item?.content ?? '-'}
                        </p>
                      </div>
                    </ToggleItem>
                  );
                })
              ) : (
                <div className="no_data_text text-center">
                  <p>{valids.noDataNotice}</p>
                </div>
              )}
            </div>
          </div>

          <BottomNav active="MYPAGE" />
        </div>
      </div>
    </>
  );
}
export default Page;
