import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import icPlus from '@assets/images/icon_plus.svg';
import icArrow from '@assets/images/icon_arrow_right_gray.svg';
import Header from '@components/shared/header';
import BottomNav from '@components/shared/bottomNav';
import Pagination from '@components/pagination';
import ToassAuthWrap from '@components/tossAuth';
import CheckIsLogin from '@components/checkIsLogin';
import { valids } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import PaymentApi from '@api/payment/payment.query';
// import { paymentData } from '@temp/paymentData';

function Page() {
  const { setLoading } = useStore((state) => state);
  const [page, setPage] = useState(1);

  // api
  const { data: callData, refetch } = PaymentApi.usePaymentCardQuery();
  const cardData = callData?.data?.data;
  const { data: callData2, isLoading } = PaymentApi.usePaymentHistoryQuery({
    curPage: page,
  });
  const resultData = callData2?.data?.data;
  const listData = resultData?.result ?? [];

  useEffect(() => {
    // console.log('card', cardData, listData);
    if (resultData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [resultData, isLoading]);

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="결제 관리" back="/mypage" searchCart />
          </div>

          <div className="pb-24 pt-4">
            <div className="inner">
              <div>
                <h3 className="text-xs font-medium text-black">
                  자동 결제 수단 등록
                </h3>
                <div className="card_area mt-6">
                  {cardData ? (
                    <div className="card_info">
                      <span>{cardData?.cardCompany ?? ''}</span>
                      <p>{cardData?.cardNumber ?? ''}</p>
                    </div>
                  ) : (
                    <ToassAuthWrap refetch={refetch}>
                      <button type="button" className="card_btn">
                        <img src={icPlus} alt="+" />
                        <span>카드 등록</span>
                      </button>
                    </ToassAuthWrap>
                  )}
                </div>
                {cardData && (
                  <ToassAuthWrap refetch={refetch}>
                    <button
                      type="button"
                      className="btn btn_style01 mt-6 h-10 w-full rounded-sm text-sm font-bold"
                    >
                      카드 변경
                    </button>
                  </ToassAuthWrap>
                )}
              </div>
              <div className="mt-8">
                <h3 className="text-xs font-medium text-gray-9">결제 내역</h3>
                {listData?.length > 0 ? (
                  listData?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="border-b border-b-gray-c py-4"
                      >
                        <div className="flex items-center justify-between">
                          {item?.status === 'done' ? (
                            <p className="text-xs font-medium text-black">
                              결제 완료
                            </p>
                          ) : item?.status === 'canceled' ? (
                            <p className="text-xs font-medium text-red">
                              결제 취소
                            </p>
                          ) : item?.status === 'partial_canceled' ? (
                            <p className="text-xs font-medium text-red">
                              부분 결제 취소
                            </p>
                          ) : null}
                          <Link
                            to={`/order/detail/${item?.orderId}`}
                            className="flex items-center gap-1 text-xs font-medium text-gray-9"
                          >
                            자세히 보기 <img src={icArrow} alt="arrow" />
                          </Link>
                        </div>
                        <p className="my-1 text-base font-extrabold text-black">
                          {Number(item?.amount ?? 0).toLocaleString()}
                        </p>
                        <div className="flex items-center justify-between">
                          <span className="text-xs font-medium text-gray-9">
                            {format(item?.createdAt ?? '', 'yyyy.MM.dd HH:mm')}
                          </span>
                          <span className="text-xs font-medium text-gray-9">
                            {item?.cardCompany ?? ''}
                            {item?.cardNumber ? ` (${item?.cardNumber})` : ''}
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="no_data_text py-4 text-center">
                    <p>{valids.noDataPayment}</p>
                  </div>
                )}
                {listData?.length > 0 && (
                  <div className="mt-6">
                    <Pagination
                      total={resultData?.cntAll ?? 0}
                      page={page}
                      setPage={setPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <BottomNav active="MYPAGE" />
        </div>
      </div>
    </>
  );
}
export default Page;
