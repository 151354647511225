import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import icArrow from '@assets/images/icon_arrow_right_gray.svg';
import Header from '@components/shared/header';
import BottomNav from '@components/shared/bottomNav';
import CancelReasonModal from '@components/modal/cancelReason';
import MsgModal from '@components/modal/msg';
import CheckIsLogin from '@components/checkIsLogin';
import { common, valids } from '@config/index';
import { formatMmddd, sleep } from '@utils/common';
import useStore from '@store/index';
import PaymentApi from '@api/payment/payment.query';
// import { rentData } from '@temp/rentData';

function Page() {
  const navigate = useNavigate();
  const { setLoading, setAlert, setToastContainerStyle } = useStore(
    (state) => state,
  );
  const [orderItemId, setOrderItemId] = useState<number | undefined>(undefined);
  const [reasonOpen, setReasonOpen] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);

  // api
  const {
    data: callData,
    isLoading,
    refetch,
  } = PaymentApi.useOrderHistoryQuery();
  const resultData = callData?.data?.data;
  const listData = resultData?.result ?? [];
  const useOrderItemReturnMutation = PaymentApi.useOrderItemReturnMutation();

  const onFormSubmit = () => {
    if (!orderItemId) return;

    setLoading(true);
    // console.log(orderItemId);
    useOrderItemReturnMutation.mutate({ orderItemId });
  };

  // ERROR !!!
  useEffect(() => {
    if (!useOrderItemReturnMutation.isError) return;
    setAlert(
      useOrderItemReturnMutation.error?.response?.data?.message ??
        valids.errored,
    );
    setLoading(false);
  }, [useOrderItemReturnMutation.isError]);

  // 주문 반품 SUCCESS !!!
  useEffect(() => {
    if (!useOrderItemReturnMutation.isSuccess) return;
    setMsgOpen(false);
    refetch();
    setToastContainerStyle({ bottom: 78 });
    toast(valids.requestedReturn, { style: common.toastStyle });
    setLoading(false);
  }, [useOrderItemReturnMutation.isSuccess]);

  useEffect(() => {
    // console.log(resultData);
    if (resultData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [resultData, isLoading]);

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="렌트 내역" back="/mypage" searchCart />
          </div>

          <div className="pb-24 pt-4">
            <div className="inner">
              <ul className="flex flex-col gap-8">
                {listData?.length > 0 ? (
                  listData?.map((item) => {
                    return (
                      <li key={item.id}>
                        <div className="flex items-center justify-between border-b border-b-gray-c pb-2">
                          <p className="text-xs font-medium text-gray-9">
                            주문번호 {item.orderNumbers ?? '-'}
                          </p>
                          <Link
                            to={`/order/detail/${item.id}`}
                            className="flex items-center gap-1 text-xs font-medium text-gray-9"
                          >
                            상세 정보 <img src={icArrow} alt="arrow" />
                          </Link>
                        </div>
                        {item?.order_items?.map((item2) => {
                          return (
                            <React.Fragment key={item2.id}>
                              <div className="item_area pt-4">
                                <div className="img_wrap w-25">
                                  <div className="img_area">
                                    {item2.product?.product_images?.length >
                                    0 ? (
                                      <img
                                        src={`${common.imageUrl}/${item2.product?.product_images?.[0]?.file?.fileUrl}`}
                                        alt="상품 이미지"
                                      />
                                    ) : (
                                      <i className="no_data_img" />
                                    )}
                                  </div>
                                </div>
                                <div className="text_area">
                                  <div className="mb-3">
                                    {item2?.status === 'new' ||
                                    item2?.status === 'ready_for_delivery' ? (
                                      <p className="status text-main">
                                        배송 준비중
                                      </p>
                                    ) : item2?.status === 'shipped' ? (
                                      <p className="status text-main">배송중</p>
                                    ) : item2?.status === 'delivered' ||
                                      item2?.status === 'end' ? (
                                      <p className="status text-black">
                                        배송 완료
                                      </p>
                                    ) : item2?.status === 'collected' ? (
                                      <p className="status text-black">
                                        반납 완료
                                      </p>
                                    ) : item2?.status === 'cancel' ? (
                                      <p className="status text-gray-9">
                                        예약 취소
                                      </p>
                                    ) : item2?.status === 'return_requested' ||
                                      item2?.status === 'return_collecting' ||
                                      item2?.status === 'return_collected' ? (
                                      <p className="status text-main">
                                        반품진행중
                                      </p>
                                    ) : item2?.status === 'return_completed' ? (
                                      <p className="status text-black">
                                        반품완료
                                      </p>
                                    ) : (
                                      <p className="status">-</p>
                                    )}
                                  </div>
                                  <p className="brand">
                                    {item2?.product?.brand?.nameEnglish.toUpperCase() ??
                                      ''}
                                  </p>
                                  <p className="product">
                                    {item2?.product?.productName ?? ''}
                                  </p>
                                  {item2.status !== 'cancel' && (
                                    <p className="price_total mt-3">
                                      {Number(
                                        item2?.finalAmount ?? 0,
                                      ).toLocaleString()}
                                    </p>
                                  )}
                                  {/* {item?.order_items?.length === index2 + 1 && ( */}
                                  <span className="desc mt-3">
                                    이용기간 :
                                    {formatMmddd(item?.rentalStartDate ?? '')} ~{' '}
                                    {formatMmddd(item?.rentalEndDate ?? '')}
                                  </span>
                                  {/* )} */}
                                  {(item2?.status === 'new' ||
                                    item2?.status === 'ready_for_delivery') && (
                                    <span className="desc_red mt-3">
                                      주문 후 2시간 이내 무료 취소 가능 <br />*
                                      상품 출고 이후에는 취소 불가
                                    </span>
                                  )}
                                </div>
                              </div>
                              {item2.status === 'new' ||
                              item2.status === 'ready_for_delivery' ? (
                                <button
                                  type="button"
                                  className="btn btn_style01 mt-4 h-10 w-full rounded-sm text-sm font-medium"
                                  onClick={() => {
                                    setOrderItemId(item2?.id ?? undefined);
                                    setReasonOpen(true);
                                  }}
                                >
                                  취소 신청
                                </button>
                              ) : item2.status === 'cancel' ||
                                item2.status === 'collected' ? (
                                <button
                                  type="button"
                                  className="btn btn_style01 mt-4 h-10 w-full rounded-sm text-sm font-medium"
                                  onClick={() =>
                                    navigate(
                                      `/product/detail/${item2?.product?.id}`,
                                    )
                                  }
                                >
                                  다시 예약
                                </button>
                              ) : item2.status === 'delivered' ||
                                item2.status === 'end' ? (
                                <button
                                  type="button"
                                  className="btn btn_style01 mt-4 h-10 w-full rounded-sm text-sm font-medium"
                                  onClick={() => {
                                    setOrderItemId(item2?.id ?? undefined);
                                    setMsgOpen(true);
                                  }}
                                >
                                  반품 신청
                                </button>
                              ) : null}
                            </React.Fragment>
                          );
                        })}
                      </li>
                    );
                  })
                ) : (
                  <li className="no_data_text py-1 text-center">
                    <p>{valids.noDataRent}</p>
                  </li>
                )}
              </ul>
            </div>
          </div>

          <CancelReasonModal
            open={reasonOpen}
            setOpen={setReasonOpen}
            id={orderItemId}
          />
          <MsgModal
            open={msgOpen}
            setOpen={setMsgOpen}
            btns={
              <>
                <button
                  type="button"
                  className="btn btn_style03 h-10 rounded-sm text-sm font-bold"
                  onClick={() => setMsgOpen(false)}
                >
                  아니요
                </button>
                <button
                  type="button"
                  className="btn btn_style02 h-10 rounded-sm text-sm font-bold"
                  onClick={onFormSubmit}
                >
                  확인
                </button>
              </>
            }
          >
            <p className="text-sm font-bold text-black">
              반품 신청을 하시겠습니까?
            </p>
            <span className="mt-2 block text-xs font-medium text-black">
              결제하신 금액의 환불 처리는 불가하며, <br />
              관리자 승인 후 상품 수거처리가 진행됩니다.
            </span>
          </MsgModal>

          <BottomNav active="MYPAGE" />
        </div>
      </div>
    </>
  );
}
export default Page;
