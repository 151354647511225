import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAxios, Utils } from '@api/index';
import PaymentQueryKey from '@api/payment/payment.key';
import PaymentDto from '@api/payment/payment.dto';

namespace PaymentApi {
  /**
   * 날짜선택 렌트중인 상품 조회
   * @param {PaymentDto.RentingProduct.Request} body - Body
   */
  export function useRentingProductQuery(
    params?: PaymentDto.RentingProduct.Request,
  ) {
    const axios = useAxios();

    return useQuery({
      queryKey: [PaymentQueryKey.RENTING_PRODUCT, params],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<PaymentDto.RentingProduct.Response[]>
        >(`/api/users/renting-products/${params?.productId}`, { params });
      },
      enabled: !!params,
    });
  }

  /**
   * 주문하기
   */

  /**
   * 렌트 내역
   */
  export function useOrderHistoryQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [PaymentQueryKey.ORDER_HISTORY],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<PaymentDto.Order.HistoryResponse[]>
        >('/api/users/order-history');
      },
    });
  }

  /**
   * 주문 상세
   * @param {number} orderId - 주문 ID
   */
  export function useOrderDetailQuery(orderId?: number) {
    const axios = useAxios();

    return useQuery({
      queryKey: [PaymentQueryKey.ORDER_DETAIL, orderId],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<PaymentDto.Order.DetailResponse>
        >(`/api/users/order-detail/${orderId}`);
      },
      enabled: !!orderId,
    });
  }

  /**
   * 주문 취소
   * @param {PaymentDto.Order.CancelRequest} body - Body
   */
  export function useOrderItemCancelMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<PaymentDto.Order.CancelResponse>>,
      AxiosError<{ message: string }>,
      PaymentDto.Order.CancelRequest
    >({
      mutationFn(body: PaymentDto.Order.CancelRequest) {
        return axios.post<
          Utils.View.Response<PaymentDto.Order.CancelResponse>,
          AxiosResponse<Utils.View.Response<PaymentDto.Order.CancelResponse>>,
          PaymentDto.Order.CancelRequest
        >('/api/users/order-items-cancel', body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [PaymentQueryKey.ORDER_CANCEL],
        });
      },
    });
  }

  /**
   * 주문 반품
   * @param {PaymentDto.Order.ReturnRequest} body - Body
   */
  export function useOrderItemReturnMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<null>>,
      AxiosError<{ message: string }>,
      PaymentDto.Order.ReturnRequest
    >({
      mutationFn(body: PaymentDto.Order.ReturnRequest) {
        return axios.post<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>,
          PaymentDto.Order.ReturnRequest
        >('/api/users/order-items-returns', body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [PaymentQueryKey.ORDER_RETURN],
        });
      },
    });
  }

  /**
   * 상품문의하기
   * @param {PaymentDto.Product.InquiryRequest} body - Body
   */
  export function useProductInquiriesMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation({
      mutationFn(body: PaymentDto.Product.InquiryRequest) {
        const formData = new FormData();
        for (const [key, value] of Object.entries(body)) {
          formData.append(key, value);
        }

        return axios.post<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>
        >('/api/users/products-inquiries', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [PaymentQueryKey.PRODUCT_INQUIRY],
        });
      },
    });
  }

  /**
   * 결제 카드 조회
   */
  export function usePaymentCardQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [PaymentQueryKey.PAYMENT_CARD],
      queryFn() {
        return axios.get<Utils.View.Response<PaymentDto.PaymentCard.Response>>(
          '/api/users/payments-card',
        );
      },
    });
  }

  /**
   * 결제 카드 등록 및 변경
   * @param {PaymentDto.PaymentCard.Request} body - Body
   */
  export function useUpdatePaymentCardMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<null>>,
      AxiosError<{ message: string }>,
      PaymentDto.PaymentCard.Request
    >({
      mutationFn(body: PaymentDto.PaymentCard.Request) {
        return axios.put<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>,
          PaymentDto.PaymentCard.Request
        >('/api/users/payments-card', body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [PaymentQueryKey.PAYMENT_CARD],
        });
      },
    });
  }

  /**
   * 결제 내역
   * @param {PaymentDto.PaymentHistory.Request} body - Body
   */
  export function usePaymentHistoryQuery(
    params: PaymentDto.PaymentHistory.Request,
  ) {
    const axios = useAxios();

    return useQuery({
      queryKey: [PaymentQueryKey.PAYMENT_HISTORY, params],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<PaymentDto.PaymentHistory.Response[]>
        >('/api/users/payments-history', { params });
      },
    });
  }

  /**
   * 보유중 적립금
   */
}

export default PaymentApi;
