import { ConfigIdType } from '@config/common';
import CustomerDto from '@api/customer/customer.dto';
import MypageDto from '@api/mypage/mypage.dto';

/* ===== TABS ===== */
export const brandSearchTabs = [
  { id: 'NAME', text: '가나다순' },
  { id: 'RECOMMEND', text: '인기' },
  { id: 'A', text: 'A' },
  { id: 'B', text: 'B' },
  { id: 'C', text: 'C' },
  { id: 'D', text: 'D' },
  { id: 'E', text: 'E' },
  { id: 'F', text: 'F' },
  { id: 'G', text: 'G' },
  { id: 'H', text: 'H' },
  { id: 'I', text: 'I' },
  { id: 'J', text: 'J' },
  { id: 'K', text: 'K' },
  { id: 'L', text: 'L' },
  { id: 'M', text: 'M' },
  { id: 'N', text: 'N' },
  { id: 'O', text: 'O' },
  { id: 'P', text: 'P' },
  { id: 'Q', text: 'Q' },
  { id: 'R', text: 'R' },
  { id: 'S', text: 'S' },
  { id: 'T', text: 'T' },
  { id: 'U', text: 'U' },
  { id: 'V', text: 'V' },
  { id: 'W', text: 'W' },
  { id: 'X', text: 'X' },
  { id: 'Y', text: 'Y' },
  { id: 'Z', text: 'Z' },
];

export const productDetailTabs = [
  { id: 0, text: '상품정보' },
  { id: 1, text: '리뷰' },
  { id: 2, text: '이용안내' },
];

export const customerFaqTabs: ConfigIdType<CustomerDto.FaqTypes>[] = [
  { id: 'total', text: '전체' },
  { id: 'member', text: '회원' },
  { id: 'product', text: '상품' },
  { id: 'order_payment', text: '주문/결제' },
  { id: 'delivery', text: '배송' },
  { id: 'cancel_exchange_return', text: '취소/교환/반품' },
  { id: 'service', text: '서비스' },
  { id: 'etc', text: '기타' },
];

export const savingTabs: ConfigIdType<MypageDto.PointTypes>[] = [
  { id: 'total', text: '전체' },
  { id: 'earn', text: '적립' },
  { id: 'usage', text: '사용' },
  { id: 'expiration', text: '소멸' },
];
