import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useForm, SubmitHandler } from 'react-hook-form';
import Header from '@components/shared/header';
import MultiImgUpload from '@components/forms/multiImgUpload';
import { common, valids } from '@config/index';
import useStore from '@store/index';
import MypageDto from '@api/mypage/mypage.dto';
import MypageApi from '@api/mypage/mypage.query';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetch?: () => void;
}

export default function InquiryModal({ open, setOpen, refetch }: Props) {
  const { setLoading, setToastContainerStyle } = useStore((state) => state);
  const { register, handleSubmit, reset } =
    useForm<MypageDto.MyInquiries.Request>();
  const [files, setFiles] = useState<(File | null)[]>([null]);

  // api
  const useCreateInquiryMutation = MypageApi.useCreateInquiryMutation();

  const onFormSubmit: SubmitHandler<MypageDto.MyInquiries.Request> = (data) => {
    setLoading(true);
    const filteredFiles = files?.filter((item) => item !== null);
    const imageMap: { [key: string]: File } = {};
    filteredFiles.forEach((item, index) => {
      imageMap[`inquiriesFile${index + 1}`] = item as File;
    });
    const formData = { ...data, ...imageMap };

    // console.log(formData);
    useCreateInquiryMutation.mutate(formData);
  };

  // ERROR !!!
  useEffect(() => {
    if (!useCreateInquiryMutation.isError) return;
    setLoading(false);
    setToastContainerStyle({ bottom: 84 });
    toast(valids.errored, { style: common.toastStyle });
  }, [useCreateInquiryMutation.isError]);

  // 1:1문의하기 SUCCESS !!!
  useEffect(() => {
    if (!useCreateInquiryMutation.isSuccess) return;
    setOpen(false);
    setToastContainerStyle({ bottom: 78 });
    toast(valids.inquirySaved, { style: common.toastStyle });
    setLoading(false);
    refetch?.();
  }, [useCreateInquiryMutation.isSuccess]);

  useEffect(() => {
    if (open) return;

    reset();
    setFiles([null]);
  }, [open]);

  return open ? (
    <div className="modal_wrap full">
      <div className="modal_area">
        <Header title="1:1 문의하기" closeFunc={() => setOpen(false)} />

        <div className="mypage_inquiry_post_wrap">
          <div className="inner">
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div>
                <div className="label_input_area">
                  <label htmlFor="subject">제목</label>
                  <input
                    type="text"
                    id="subject"
                    className="input"
                    placeholder={valids.inputTitle}
                    {...register('subject', { required: true })}
                  />
                </div>
                <div className="label_input_area mt-4">
                  <label htmlFor="content">내용</label>
                  <textarea
                    id="content"
                    className="textarea"
                    cols={30}
                    rows={9}
                    placeholder={valids.inputContent}
                    {...register('content', { required: true })}
                  />
                </div>
              </div>
              <div className="mt-4">
                <MultiImgUpload files={files} setFiles={setFiles} />
              </div>

              <div className="fixed_btm_btn">
                <div className="fixed_btn bg_style01">
                  <button
                    type="submit"
                    className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                  >
                    등록
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
