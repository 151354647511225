import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import { common } from '@config/index';
import CommonDto from '@api/common.dto';
// import { productData } from '@temp/productData';

interface Props {
  data: {
    type: string;
    file: CommonDto.File;
  }[];
}

export default function ProductImageSlider({ data }: Props) {
  return (
    <Swiper
      modules={[Scrollbar]}
      scrollbar={{
        hide: false,
      }}
      className="prod_detail_image_wrap"
    >
      {data?.length > 0 ? (
        data?.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="img_area">
                <img
                  src={`${common.imageUrl}/${item?.file?.fileUrl}`}
                  alt="상품 이미지"
                />
              </div>
            </SwiperSlide>
          );
        })
      ) : (
        <SwiperSlide>
          <div className="img_area">
            <i className="no_data_img" />
          </div>
        </SwiperSlide>
      )}
    </Swiper>
  );
}
