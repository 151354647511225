import { useQuery } from '@tanstack/react-query';
import { useAxios, Utils } from '@api/index';
import ProductQueryKey from '@api/product/product.key';
import ProductDto from '@api/product/product.dto';
import CommonDto from '@api/common.dto';

namespace ProductApi {
  /**
   * 추천 상품 목록
   * @param {ProductDto.Products.Request} body - Body
   */
  export function useRecommendProductsQuery(
    params: ProductDto.Products.Request,
  ) {
    const axios = useAxios();

    return useQuery({
      queryKey: [ProductQueryKey.PRODUCTS, params],
      queryFn() {
        return axios.get<Utils.View.ResponseResult<CommonDto.Product[]>>(
          '/api/users/recommend-products',
          { params },
        );
      },
    });
  }

  /**
   * 상품 상세
   * @param {number} productId - 상품 ID
   */
  export function useProductsDetailQuery(productId: number) {
    const axios = useAxios();

    return useQuery({
      queryKey: [ProductQueryKey.PRODUCT_DETAIL, productId],
      queryFn() {
        return axios.get<Utils.View.Response<ProductDto.Products.Response>>(
          `/api/users/products/${productId}`,
        );
      },
      enabled: !!productId,
    });
  }

  /**
   * 유사 상품 리스트
   * @param {number} productId - 상품 ID
   */
  export function useProductsSimilarQuery(productId: number) {
    const axios = useAxios();

    return useQuery({
      queryKey: [ProductQueryKey.PRODUCT_SIMILAR, productId],
      queryFn() {
        return axios.get<Utils.View.ResponseResult<CommonDto.Product[]>>(
          `/api/users/products-similar/${productId}`,
        );
      },
      enabled: !!productId,
    });
  }
}

export default ProductApi;
