import React, { useEffect, useState } from 'react';
import icDelete from '@assets/images/icon_x_white.svg';
import MsgModal from '@components/modal/msg';

interface Props {
  initFile: File | null;
  onAddFile: (file: File) => void;
  onDeleteFile: () => void;
}

export default function SingleImgUpload({
  initFile,
  onAddFile,
  onDeleteFile,
}: Props) {
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgContents, setMsgContents] = useState<
    JSX.Element | JSX.Element[] | null
  >(null);

  const handlePreviewShow = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImgSrc(reader.result as string | null);
    };
  };

  const handleValid = (file: File) => {
    const maxFileSize = 10 * 1024 * 1024; // bytes

    // Check file extension
    const allowedExtensions = ['.jpg', '.jpeg', '.png'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (!allowedExtensions.includes(`.${fileExtension}`)) {
      // console.log('Invalid file extension', fileExtension);
      setMsgContents(
        <>
          <p className="tit">확장자 제한</p>
          <span className="desc">
            확장자는 .jpg, .jpeg, .png 파일만 등록 가능합니다.
          </span>
        </>,
      );
      setMsgOpen(true);
      return;
    }

    // Check file size
    if (file.size > maxFileSize) {
      // console.log('File size exceeds the limit', file.size);
      setMsgContents(
        <>
          <p className="tit">파일 크기 제한</p>
          <span className="desc">파일 크기는 10MB 이하만 등록 가능합니다.</span>
        </>,
      );
      setMsgOpen(true);
      return;
    }

    handlePreviewShow(file);
    onAddFile(file);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0) {
      const file = e.target.files[0];
      handleValid(file);

      e.target.value = '';
    }
  };

  useEffect(() => {
    if (initFile) handlePreviewShow(initFile);
    else setImgSrc(null);
  }, [initFile]);

  return (
    <>
      <div className="img_input">
        {imgSrc ? (
          <>
            <img src={imgSrc} alt="이미지 등록" />
            <button type="button" className="x_btn" onClick={onDeleteFile}>
              <img src={icDelete} alt="x" />
            </button>
          </>
        ) : (
          <>
            <input
              type="file"
              id="img"
              accept=".jpg, .jpeg, .png"
              onChange={handleChange}
            />
            <label htmlFor="img">
              <i />
            </label>
          </>
        )}
      </div>
      <MsgModal open={msgOpen} setOpen={setMsgOpen}>
        {msgContents}
      </MsgModal>
    </>
  );
}
