import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Header from '@components/shared/header';
import MsgModal from '@components/modal/msg';
import AuthTimer from '@app/auth/find-password/email/timer';
import CheckIsLogin from '@components/checkIsLogin';
import { regex, valids, common } from '@config/index';
import useStore from '@store/index';
import AuthDto from '@api/auth/auth.dto';
import AuthApi from '@api/auth/auth.query';

const INIT_MINUTES = 5 * 60 - 1;

function Page() {
  const navigate = useNavigate();
  const { setLoading, setToastContainerStyle } = useStore((state) => state);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<AuthDto.VerificationCode>();
  const [step, setStep] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isTimeEnd, setIsTimeEnd] = useState(false);
  const [totalData, setTotalData] = useState<AuthDto.VerificationCode | null>(
    null,
  );
  const [emailError, setEmailError] = useState('');
  const [authNumberError, setAuthNumberError] = useState('');
  const [msgOpen, setMsgOpen] = useState(false);

  // api
  const useVerificationCodeSendMutation =
    AuthApi.useVerificationCodeSendMutation();
  const useVerificationCodeCheckMutation =
    AuthApi.useVerificationCodeCheckMutation();

  const onFormSubmit: SubmitHandler<AuthDto.VerificationCode> = (data) => {
    setLoading(true);
    if (step === 0 || !data?.authNumber) {
      useVerificationCodeSendMutation.mutate({ email: data.email });
    } else if (!isTimeEnd) {
      useVerificationCodeCheckMutation.mutate(data);
      setTotalData({ email: data.email });
    }
  };

  // ERROR !!!
  useEffect(() => {
    if (!useVerificationCodeSendMutation.isError) return;
    setEmailError(
      useVerificationCodeSendMutation.error?.response?.data?.message ??
        valids.errored,
    );
    setLoading(false);
  }, [useVerificationCodeSendMutation.isError]);

  useEffect(() => {
    if (!useVerificationCodeCheckMutation.isError) return;
    setAuthNumberError(
      useVerificationCodeCheckMutation.error?.response?.data?.message ??
        valids.errored,
    );
    setLoading(false);
  }, [useVerificationCodeCheckMutation.isError]);

  // 이메일 인증코드 발송 SUCCESS !!!
  useEffect(() => {
    if (!useVerificationCodeSendMutation.isSuccess) return;
    setTimeLeft(INIT_MINUTES);
    setIsTimeEnd(false);
    setAuthNumberError('');
    if (step === 0) {
      setMsgOpen(true);
      setStep(1);
    } else {
      setToastContainerStyle({ bottom: 128 });
      toast(valids.resendVertifyNumber, { style: common.toastStyle });
    }
    setLoading(false);
  }, [useVerificationCodeSendMutation.isSuccess]);

  // 이메일 인증코드 검증 SUCCESS !!!
  useEffect(() => {
    if (!useVerificationCodeCheckMutation.isSuccess) return;
    sessionStorage.setItem(
      common.VERIFICATION_DATA_KEY,
      JSON.stringify(totalData),
    );
    navigate('/reset-password');
    setLoading(false);
  }, [useVerificationCodeCheckMutation.isSuccess]);

  useEffect(() => {
    setEmailError('');
  }, [watch('email')]);

  useEffect(() => {
    if (!isTimeEnd) setAuthNumberError('');
  }, [watch('authNumber')]);

  return (
    <>
      <CheckIsLogin loginCheck />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="비밀번호 찾기" back />
          </div>

          <div className="auth_wrap type02">
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div className="inner">
                <div className="label_input_area">
                  <label htmlFor="email">이메일 정보</label>
                  <input
                    type="text"
                    id="email"
                    className={`input ${
                      errors.email || emailError ? 'error' : ''
                    }`}
                    placeholder={valids.inputEmail}
                    {...register('email', {
                      required: valids.inputEmail,
                      pattern: {
                        value: regex.regEmail,
                        message: valids.inputRightlyEmail,
                      },
                    })}
                  />
                  {errors.email && (
                    <span className="input_error_text">
                      {errors.email.message}
                    </span>
                  )}
                  {emailError && (
                    <span className="input_error_text">{emailError}</span>
                  )}
                </div>

                {step === 1 && (
                  <div className="label_input_area mt-4">
                    <label htmlFor="authNumber">인증번호 입력</label>
                    <input
                      type="text"
                      id="authNumber"
                      className={`input ${authNumberError ? 'error' : ''}`}
                      placeholder={valids.inputVertifyNumber}
                      {...register('authNumber')}
                    />
                    {authNumberError && (
                      <span className="input_error_text">
                        {authNumberError}
                      </span>
                    )}
                    <AuthTimer
                      timeLeft={timeLeft}
                      setTimeLeft={setTimeLeft}
                      setIsTimeEnd={setIsTimeEnd}
                      setError={setAuthNumberError}
                    />
                  </div>
                )}
              </div>

              <div className="fixed_btm_btn">
                <div className="fixed_btn bg_style01 flex flex-col gap-2">
                  {step === 0 ? (
                    <button
                      type="submit"
                      className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                      disabled={!watch('email')}
                    >
                      인증번호 전송
                    </button>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn_style03 h-12 w-full rounded-sm text-sm font-bold"
                        onClick={() => onFormSubmit({ email: watch('email') })}
                        disabled={!watch('email')}
                      >
                        인증번호 재발송
                      </button>
                      <button
                        type="submit"
                        className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                        disabled={
                          isTimeEnd || (!watch('authNumber') && !isTimeEnd)
                        }
                      >
                        인증하기
                      </button>
                    </>
                  )}
                </div>
              </div>
            </form>
          </div>

          <MsgModal open={msgOpen} setOpen={setMsgOpen}>
            <p className="tit">인증번호 전송</p>
            <span className="desc">
              인증번호가 전송되었습니다. <br />
              5분 이내에 입력해 주세요.
            </span>
          </MsgModal>
        </div>
      </div>
    </>
  );
}
export default Page;
