import React from 'react';
import { Link } from 'react-router-dom';
import icNotFound from '@assets/images/deco/icon_not_found.svg';
import Header from '@components/shared/header';

function Page() {
  return (
    <div id="root_layout">
      <div className="root_container">
        <Header title="페이지 오류" />

        <div className="error_page_wrap">
          <div className="text">
            <img src={icNotFound} alt="페이지 오류" />
            <p>요청하신 페이지를 찾을 수 없습니다.</p>
            <span>
              요청하신 페이지는 제거되었거나 <br />
              이름이 변경되어 사용할 수 없습니다.
              <br />
              페이지의 주소가 정확한 지 다시 한 번 확인해 주세요.
            </span>
          </div>
          <Link to="/" className="btn btn_style02 rounded-sm text-sm">
            렌티크 홈페이지
          </Link>
        </div>
      </div>
    </div>
  );
}
export default Page;
