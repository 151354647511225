import { useEffect } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { valids } from '@config/index';
import { postChildMessage } from '@utils/common';
import useStore from '@store/index';
import PaymentApi from '@api/payment/payment.query';

function Page() {
  const params = useParams();
  const paramsStatus = params.status;
  const [searchParams] = useSearchParams();
  const spMessage = searchParams.get('message');
  const spAuthKey = searchParams.get('authKey');
  const spCustomerKey = searchParams.get('customerKey');
  const { setLoading } = useStore((state) => state);

  // api
  const useUpdatePaymentCardMutation =
    PaymentApi.useUpdatePaymentCardMutation();

  // ERROR !!!
  useEffect(() => {
    if (!useUpdatePaymentCardMutation.isError) return;
    postChildMessage(
      'fail',
      useUpdatePaymentCardMutation.error?.response?.data?.message ??
        valids.errored,
    );
    setLoading(false);
  }, [useUpdatePaymentCardMutation.isError]);

  // 결제 카드 등록 및 변경 SUCCESS !!!
  useEffect(() => {
    if (!useUpdatePaymentCardMutation.isSuccess) return;
    postChildMessage('success', valids.cardSaved);
    setLoading(false);
  }, [useUpdatePaymentCardMutation.isSuccess]);

  useEffect(() => {
    if (paramsStatus === 'success') {
      setLoading(true);
      useUpdatePaymentCardMutation.mutate({
        customerKey: spCustomerKey ?? '',
        authKey: spAuthKey ?? '',
      });
    } else if (paramsStatus === 'fail') {
      postChildMessage(paramsStatus, spMessage ?? undefined);
    }
  }, [paramsStatus]);

  return null;
}
export default Page;
