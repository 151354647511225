import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  active?: 'HOME' | 'LUXURY' | 'PROMOTION' | 'BRAND' | 'RANKING';
}

export default function HeaderNav({ active }: Props) {
  return (
    <div className="header_nav">
      <div className="inner">
        <ul>
          <li className={active === 'HOME' ? 'on' : ''}>
            <Link to="/">추천</Link>
          </li>
          <li className={active === 'LUXURY' ? 'on' : ''}>
            <Link to="/luxury">럭셔리</Link>
          </li>
          <li className={active === 'PROMOTION' ? 'on' : ''}>
            <Link to="/promotion">기획전</Link>
          </li>
          <li className={active === 'BRAND' ? 'on' : ''}>
            <Link to="/brand">브랜드</Link>
          </li>
          <li className={active === 'RANKING' ? 'on' : ''}>
            <Link to="/ranking">랭킹</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
