import React from 'react';
import icClose from '@assets/images/icon_modal_x.svg';
import Modal from '@components/modal/modal';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CancelPenaltyModal({ open, setOpen }: Props) {
  return open ? (
    <Modal>
      <div className="modal_top">
        <p className="md_tit">수수료 및 취소 패널티</p>
        <button type="button" className="x_btn" onClick={() => setOpen(false)}>
          <img src={icClose} alt="모달 닫기" />
        </button>
      </div>
      <div className="modal_mid p-4">
        <div className="table_area table_style01">
          <table className="border-t-gray-c">
            <colgroup>
              <col style={{ width: '35%' }} />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th colSpan={2}>수수료 및 취소 패널티</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">위탈 신청 후</td>
                <td className="text-center">입고 거부 패널티 없음</td>
              </tr>
              <tr>
                <td className="text-center" rowSpan={2}>
                  상품 진단 후
                </td>
                <td className="text-center">합격 후 판매포기 패널티 없음</td>
              </tr>
              <tr>
                <td className="text-center">불합격(가품) 반품비+검수비 청구</td>
              </tr>
              <tr>
                <td className="text-center">판매 중 포기</td>
                <td className="text-center">
                  계약 이후 거래액의 30% 위약금 청구
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="table_area table_style01 mt-4">
          <table className="border-t-gray-c">
            <colgroup>
              <col style={{ width: '35%' }} />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th colSpan={2}>
                  위탁신청 시 거래 수수료 및 운용비용 수수료 정책
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">수수료</td>
                <td className="text-center">9.8%</td>
              </tr>
              <tr>
                <td className="text-center">
                  운영비용 <br />
                  (택배비, 포장 및 <br />
                  부자재비, 정품감정비, <br />
                  인건비 등등)
                </td>
                <td className="text-center">20%</td>
              </tr>
              <tr>
                <td className="text-center">정산금</td>
                <td className="text-center">70.2%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  ) : null;
}
