import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import imgEvent01 from '@assets/images/temp/event_img01.jpg';
import Header from '@components/shared/header';
import HeaderNav from '@components/shared/headerNav';
import Footer from '@components/shared/footer';
import BottomNav from '@components/shared/bottomNav';
// import { valids } from '@config/index';
import { common } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import PlanEventApi from '@api/plan-event/planEvent.query';
import AuthApi from '@api/auth/auth.query';
// import { promotionData } from '@temp/promotionData';

function Page() {
  const { setLoading } = useStore((state) => state);
  const [tab, setTab] = useState(0);
  const [data, setData] = useState<any[]>([]);

  // api
  const isLogin = AuthApi.useIsLogin();
  const { data: callData, isLoading } = PlanEventApi.usePlanningEventAllQuery();
  const listData = callData?.data?.data?.result;
  const listData2 = PlanEventApi.usePlanningQuery()?.data?.data?.data?.result;
  const listData3 = PlanEventApi.useEventQuery()?.data?.data?.data?.result;

  useEffect(() => {
    if (tab === 1) setData(listData2 ?? []);
    else if (tab === 2) setData(listData3 ?? []);
    else setData(listData ?? []);
  }, [tab, listData, listData2, listData3]);

  useEffect(() => {
    if (listData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [listData, isLoading]);

  return (
    <div id="root_layout">
      <div className="root_container">
        <div className="sticky_menu">
          <Header logo searchCart />
          <HeaderNav active="PROMOTION" />
        </div>

        {/* 리스트 */}
        <div className="pb-10 pt-4">
          <div className="inner">
            <div className="tab_area02 grid03 pb-4">
              <ul>
                <li className={tab === 0 ? 'on' : ''}>
                  <button type="button" onClick={() => setTab(0)}>
                    전체
                  </button>
                </li>
                <li className={tab === 1 ? 'on' : ''}>
                  <button type="button" onClick={() => setTab(1)}>
                    기획전
                  </button>
                </li>
                <li className={tab === 2 ? 'on' : ''}>
                  <button type="button" onClick={() => setTab(2)}>
                    이벤트
                  </button>
                </li>
              </ul>
            </div>
            <div className="promo_list_area">
              <ul>
                {!isLogin && tab !== 1 && (
                  <li>
                    <Link to="/promotion/detail/new">
                      <div className="img_area rect02">
                        <img src={imgEvent01} alt="신규회원 할인 쿠폰 이벤트" />
                      </div>
                    </Link>
                  </li>
                )}
                {data?.length > 0
                  ? data?.map((item, index) => {
                      return (
                        <li key={index}>
                          <Link
                            to={
                              (tab === 0 && item.type === 'planning') ||
                              tab === 1
                                ? `/promotion/product/${item.id}`
                                : `/promotion/detail/${item.id}`
                            }
                          >
                            <div className="img_area rect02">
                              {(tab === 0 && item.type === 'planning') ||
                              tab === 1 ? (
                                item?.main_image_file ? (
                                  <img
                                    src={`${common.imageUrl}/${item?.main_image_file?.fileUrl}`}
                                    alt="기획전 썸네일 이미지"
                                  />
                                ) : (
                                  <i className="no_data_img" />
                                  // <div className="no_img_text">
                                  //   <p>{item?.title ?? ''}</p>
                                  //   <span>{item?.subtitle ?? ''}</span>
                                  // </div>
                                )
                              ) : item?.list_thumbnail_file ? (
                                <img
                                  src={`${common.imageUrl}/${item?.list_thumbnail_file?.fileUrl}`}
                                  alt="이벤트 썸네일 이미지"
                                />
                              ) : (
                                <i className="no_data_img" />
                                // <div className="no_img_text">
                                //   <p>{item?.title ?? ''}</p>
                                //   <span>{item?.subtitle ?? ''}</span>
                                // </div>
                              )}
                            </div>
                          </Link>
                        </li>
                      );
                    })
                  : null}
                {/* ) : (
                  <li className="no_data_text">
                    <p>{valids.noData}</p>
                  </li>
                )} */}
              </ul>
            </div>
          </div>
        </div>

        <Footer btmNav />
        <BottomNav />
      </div>
    </div>
  );
}
export default Page;
