import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import icPlus from '@assets/images/icon_plus.svg';
import Header from '@components/shared/header';
import ToggleItem from '@components/toggleItem';
import CustomSelect from '@components/forms/customSelect';
import SearchAddress from '@components/forms/searhAddress';
import CancelPenaltyModal from '@components/modal/cancelPenalty';
import { valids, options } from '@config/index';

function Page() {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [warranty, setWarranty] = useState(options.warranties[0].id);
  const [period, setPeriod] = useState(options.consignmentPeriods[0].id);
  const [bank, setBank] = useState(options.banks[0].id);
  const [zone, setZone] = useState('');
  const [address, setAddress] = useState('');
  const [penaltyOpen, setPenaltyOpen] = useState(false);

  return (
    <div id="root_layout">
      <div className="root_container">
        <div className="sticky_menu">
          {step === 0 ? (
            <Header title="럭스쉐어 신청" back="/luxshare" searchCart />
          ) : (
            <Header
              title="럭스쉐어 신청"
              back
              backFunc={() => setStep(0)}
              searchCart
            />
          )}
        </div>

        {step === 0 ? (
          <div className="pb-24 pt-4">
            <div className="inner">
              <div className="label_input_area">
                <label htmlFor="status">제품 상태 선택</label>
              </div>
              <div className="input_list_area grid02 mt-1">
                <ul className="gap-x-2">
                  <li>
                    <div className="chk_input03">
                      <input type="radio" name="status" id="status01" />
                      <label htmlFor="status01">새상품(미착용)</label>
                    </div>
                  </li>
                  <li>
                    <div className="chk_input03">
                      <input type="radio" name="status" id="status02" />
                      <label htmlFor="status02">중고</label>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="label_input_area mt-4">
                <label htmlFor="brand">브랜드</label>
                <input
                  type="text"
                  id="brand"
                  className="input"
                  placeholder={valids.inputShareBrand}
                />
              </div>
              <div className="label_input_area mt-4">
                <label htmlFor="product">모델 및 상품명</label>
                <input
                  type="text"
                  id="product"
                  className="input"
                  placeholder={valids.inputShareProduct}
                />
              </div>
              <div className="label_input_area mt-4">
                <label htmlFor="warranty">보증서</label>
                <CustomSelect
                  type={2}
                  options={options.warranties}
                  value={warranty}
                  setValue={setWarranty}
                />
              </div>
              <div className="label_input_area mt-4">
                <label htmlFor="hope_price">희망 렌트 가격</label>
                <input
                  type="text"
                  id="hope_price"
                  className="input"
                  placeholder={valids.inputHopePrice}
                />
              </div>
              <div className="label_input_area mt-4">
                <label htmlFor="period">위탁 계약기간</label>
                <CustomSelect
                  type={2}
                  options={options.consignmentPeriods}
                  value={period}
                  setValue={setPeriod}
                />
              </div>
              <div className="label_input_area mt-4">
                <label htmlFor="account">정산계좌</label>
                <CustomSelect
                  type={2}
                  options={options.banks}
                  value={bank}
                  setValue={setBank}
                />
                <input
                  type="text"
                  id="account_number"
                  className="input"
                  placeholder={valids.inputAccountNumber}
                />
                <input
                  type="text"
                  id="account_depositor"
                  className="input"
                  placeholder={valids.inputDepositor}
                />
              </div>
              <div className="label_input_area mt-4">
                <label htmlFor="address">
                  배송 주소(위탁 취소 시 받으실 주소 입력)
                </label>
                <SearchAddress
                  addr={address}
                  setAddr={setAddress}
                  zone={zone}
                  setZone={setZone}
                />
                <input
                  type="text"
                  id="address_detail"
                  className="input mt-1"
                  placeholder={valids.inputAddressDetail}
                />
              </div>

              <div className="fixed_btm_btn">
                <div className="fixed_btn bg_style01">
                  {/* TODO: input 내용 입력하면 활성화 처리 */}
                  <button
                    type="button"
                    className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                    // disabled
                    onClick={() => setStep(1)}
                  >
                    다음
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="lux_apply_wrap pb-24">
            <div className="inner">
              <ToggleItem title="패널티 결제 카드 정보">
                <div className="p-4">
                  <div className="card_area">
                    {/* TODO: 클릭 시토스페이먼츠 카드 등록 레이어 팝업 노출 */}
                    <button type="button" className="card_btn">
                      <img src={icPlus} alt="+" />
                      <span>카드 등록</span>
                    </button>
                  </div>
                  {/* <div className="card_area">
                    <div className="card_info">
                      <span>samsung</span>
                      <p>9391-16**-****-185</p>
                    </div>
                  </div> */}
                  <p className="my-4 text-center text-xs font-medium text-gray-9">
                    본인 명의 카드만 추가 가능합니다.
                  </p>
                  <div className="rounded-sm bg-gray-f8 p-4">
                    <p className="text-xs font-bold text-black">
                      패널티 카드 안내
                    </p>
                    <span className="mt-1 block text-xs font-normal text-gray-3">
                      패널티는 일시불만 지원하며, 카드사 홈페이지나 앱에서
                      분할납부로 변경 가능합니다. 분할 납부 변경 시 수수료가
                      발생할 수 있습니다.
                    </span>
                  </div>
                </div>
              </ToggleItem>
              <ToggleItem title="정산 금액 안내">
                <div className="p-4">
                  <dl className="text_list_area">
                    <dt>희망렌트가</dt>
                    <dd className="text-right">50,000원</dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>거래 수수료</dt>
                    <dd className="text-right">확정렌탈가 * 9.8%</dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>운용 비용</dt>
                    <dd className="text-right">확정렌탈가 * 20%</dd>
                  </dl>
                </div>
                <div className="flex items-center justify-between rounded-b-sm bg-main-02 px-4 py-2">
                  <p className="text-sm font-bold text-main">
                    최종 정산금 지급
                  </p>
                  <p className="text-lg font-bold text-black">70.2%</p>
                </div>
              </ToggleItem>
              <div className="chk_input mt-4">
                <input type="checkbox" id="agree01" />
                <label htmlFor="agree01">
                  [필수] 전문가의 진단 진행에 동의합니다.
                  <span className="block">
                    상품이 입고되면 렌트백 전문가가 정품 감정과 컨디션 진단을
                    진행합니다. 진단 결과에 따라 상품의 위탁 가능 여부 및
                    렌트백이 제안하는 최적 렌트가를 확인할 수 있습니다.
                  </span>
                </label>
              </div>
              <div className="chk_input mt-4">
                <input type="checkbox" id="agree02" />
                <label htmlFor="agree02">
                  [필수] 진단 결과 안내 후 진행 과정을 확인하였습니다.
                  <span className="block">
                    진단 결과 ‘합격’ 상품에 한하여 최종 승인 과정을 거쳐 판매를
                    진행합니다. ‘조건부 합격’ 상품은 업그레이드 후 판매
                    가능하며, ‘불합격’ 상품은 반송됩니다.
                  </span>
                </label>
              </div>
              <div className="mt-4">
                <div className="chk_input">
                  <input type="checkbox" id="agree03" />
                  <label htmlFor="agree03">
                    [필수] 거래 취소 수수료 및 페널티 정책을 확인했습니다.
                  </label>
                </div>
                <button
                  type="button"
                  className="ms-5 block text-xs text-gray-9 underline"
                  onClick={() => setPenaltyOpen(true)}
                >
                  거래 취소 수수료 및 페널티 정책 보기
                </button>
              </div>

              <div className="fixed_btm_btn">
                <div className="fixed_btn bg_style01">
                  {/* TODO: input 모두 체크하면 활성화 처리 */}
                  <button
                    type="button"
                    className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                    // disabled
                    onClick={() => navigate('/luxshare/complete')}
                  >
                    위탁 상품 등록 신청
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <CancelPenaltyModal open={penaltyOpen} setOpen={setPenaltyOpen} />
      </div>
    </div>
  );
}
export default Page;
