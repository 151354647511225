import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '@components/shared/header';
import HeaderNav from '@components/shared/headerNav';
import Footer from '@components/shared/footer';
import BottomNav from '@components/shared/bottomNav';
import ProductListSlider from '@components/slider/productList';
import LikeBtn from '@components/likeBtn';
import { common, valids } from '@config/index';
import useStore from '@store/index';
import HomeApi from '@api/home/home.query';
import CategoryApi from '@api/category/category.query';
// import { productData } from '@temp/productData';
// import { brandData } from '@temp/brandData';

function Page() {
  const { setLoading } = useStore((state) => state);
  const [popularItemsTab, setPopularItemsTab] = useState(0);

  // api
  const { data: callData, isLoading, refetch } = HomeApi.useLuxuryQuery();
  const resultData = callData?.data?.data;
  const {
    data: callData2,
    isLoading: isLoading2,
    refetch: refetch2,
  } = HomeApi.useLuxuryPopularQuery(
    popularItemsTab !== 0 ? popularItemsTab : undefined,
  );
  const resultData2 = callData2?.data?.data;
  const categoryData =
    CategoryApi.useCategoriesQuery()?.data?.data?.data?.result ?? [];

  useEffect(() => {
    if (
      resultData !== undefined &&
      !isLoading &&
      resultData2 !== undefined &&
      !isLoading2
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [resultData, isLoading, resultData2, isLoading2]);

  return (
    <div id="root_layout">
      <div className="root_container">
        <div className="sticky_menu">
          <Header logo searchCart />
          <HeaderNav active="LUXURY" />
        </div>

        {/* BEST Wishlist items - 최소 5개 ~ 최대 10개 */}
        <section className="pt-4">
          <div className="inner">
            <div className="title_area">
              <h3>BEST Wishlist items</h3>
            </div>
          </div>
          <ProductListSlider
            data={resultData?.likeItmes ?? []}
            refetch={() => {
              refetch();
              refetch2();
            }}
            toastStyle={78}
          />
        </section>

        {/* TOP Brands - 최대 8개 */}
        <section className="pt-10">
          <div className="inner">
            <div className="title_area">
              <h3>TOP Brands</h3>
            </div>
            <div className="list_area list_style03 grid04">
              <ul>
                {(resultData?.brands ?? [])?.length > 0 ? (
                  resultData?.brands?.map((item) => {
                    return (
                      <li key={item?.id}>
                        <div className="img_area w-15">
                          {item?.image_file ? (
                            <img
                              src={`${common.imageUrl}/${item?.image_file?.fileUrl}`}
                              alt="브랜드"
                            />
                          ) : (
                            <i className="no_data_img" />
                          )}
                        </div>
                        <p>{item?.nameKorean ?? ''}</p>
                      </li>
                    );
                  })
                ) : (
                  <li className="no_data_text">
                    <p>{valids.noData}</p>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </section>

        {/* Popular Luxury - 최소 10개 ~ 최대 20개 */}
        <section className="py-10">
          <div className="inner">
            <div className="title_area">
              <h3>Popular Luxury</h3>
              <p>지금 바로 도착한 최신 입고 아이템들!</p>
            </div>
            <div className="tab_area pb-4">
              <ul>
                {[
                  {
                    id: 0,
                    name: '전체',
                  },
                  ...categoryData,
                ]?.map((item) => {
                  return (
                    <li
                      key={item.id}
                      className={popularItemsTab === item.id ? 'on' : ''}
                    >
                      <button
                        type="button"
                        onClick={() => setPopularItemsTab(item.id)}
                      >
                        {item.name}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="list_area list_style01 grid02">
              <ul>
                {(resultData2 ?? [])?.length > 0 ? (
                  resultData2?.map((item) => {
                    return (
                      <li key={item.id}>
                        <LikeBtn
                          id={item?.id}
                          likeYn={item?.likeYn}
                          refetch={() => {
                            refetch();
                            refetch2();
                          }}
                          toastBottom={78}
                        />
                        <Link to={`/product/detail/${item?.id}`}>
                          <div className="img_wrap">
                            <div className="img_area rounded-lg">
                              {item?.product_images?.length > 0 ? (
                                <img
                                  src={`${common.imageUrl}/${item?.product_images?.[0]?.file?.fileUrl}`}
                                  alt="상품 이미지"
                                />
                              ) : (
                                <i className="no_data_img" />
                              )}
                              <span className="tag">렌트</span>
                            </div>
                          </div>
                          <div className="text_area">
                            <span className="brand">
                              {item?.brand?.nameEnglish.toUpperCase() ?? ''}
                            </span>
                            <p className="product">{item?.productName ?? ''}</p>
                            <div className="flex items-center justify-start gap-1">
                              {Number(item?.discountRate ?? 0) > 0 && (
                                <span className="price_discount">
                                  {`${Number(item?.discountRate ?? 0)}%`}
                                </span>
                              )}
                              <em className="price_desc">일</em>
                              <p className="price_total">
                                {Number(item?.discountRate ?? 0) > 0
                                  ? Number(
                                      item?.discountPrice ?? 0,
                                    ).toLocaleString()
                                  : Number(
                                      item?.dailyRentalPrice ?? 0,
                                    ).toLocaleString()}
                              </p>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })
                ) : (
                  <li className="no_data_text">
                    <p>{valids.noData}</p>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </section>

        <Footer btmNav />
        <BottomNav />
      </div>
    </div>
  );
}
export default Page;
