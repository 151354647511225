import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import icArrow from '@assets/images/icon_arrow_right_gray.svg';
import Header from '@components/shared/header';
import BottomNav from '@components/shared/bottomNav';
import UpdateReviewModal from '@components/modal/updateReview';
import CheckIsLogin from '@components/checkIsLogin';
import { common, valids } from '@config/index';
import { formatMmddd, sleep } from '@utils/common';
import useStore from '@store/index';
import CommonDto from '@api/common.dto';
import MypageApi from '@api/mypage/mypage.query';
// import { productData } from '@temp/productData';
// import { reviewData } from '@temp/reviewData';

export interface ReviewProductType {
  id?: number; // 리뷰 ID
  rating?: string; // 별점
  content?: string; // 내용
  createdAt?: string; // 등록일 'yyyy-mm-ddThh:mm:ss.SSS[Z]'
  image_file_id_1_file?: CommonDto.File; // 이미지 파일 1
  image_file_id_2_file?: CommonDto.File; // 이미지 파일 2
  image_file_id_3_file?: CommonDto.File; // 이미지 파일 3
  order_item: {
    id?: number; // 주문아이템 ID
    finalAmount: number; // 결제 금액
    order: {
      rentalStartDate: string; // 렌탈 시작일 'yyyy-mm-dd'
      rentalEndDate: string; // 렌탈 종료일 'yyyy-mm-dd'
    };
    product: CommonDto.Product;
  };
}

function Page() {
  const { setLoading } = useStore((state) => state);
  const [tab, setTab] = useState(0);
  const [reviewOpen, setReviewOpen] = useState(false);
  const [reviewProdut, setReviewProduct] = useState<
    ReviewProductType | undefined
  >(undefined);

  const {
    data: callData,
    isLoading,
    refetch,
  } = MypageApi.useWritableReviewsQuery();
  const listData = callData?.data?.data?.result;
  const { data: callData2, refetch: refetch2 } =
    MypageApi.useWrittenReviewsQuery();
  const listData2 = callData2?.data?.data?.result;

  useEffect(() => {
    // console.log(listData, listData2);
    if (listData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [listData, isLoading]);

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="나의 리뷰" back="/mypage" searchCart />
            <div className="tab_area03 bg-white">
              <ul>
                <li className={tab === 0 ? 'on' : ''}>
                  <button type="button" onClick={() => setTab(0)}>
                    <b>작성 가능한 리뷰</b> {listData?.length ?? 0}
                  </button>
                </li>
                <li className={tab === 1 ? 'on' : ''}>
                  <button type="button" onClick={() => setTab(1)}>
                    <b>작성한 리뷰</b> {listData2?.length ?? 0}
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div className="mypage_review_wrap">
            {tab === 0 ? (
              <>
                <div className="banner_area">
                  <div className="inner">
                    <p className="tit">리뷰 작성하고 적립금 받으세요!</p>
                    <ul>
                      <li>
                        <p>상품 리뷰 작성 시</p>
                        <span>1,000P</span>
                      </li>
                      <li>
                        <p>포토 리뷰 작성 시</p>
                        <span>1,500P 추가!</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="inner pb-24 pt-4">
                  <div className="flex flex-col gap-4">
                    {(listData ?? [])?.length > 0 ? (
                      listData?.map((item, index) => {
                        return (
                          <div key={index} className="rounded-lg bg-white p-4">
                            <div className="item_area">
                              <div className="img_wrap w-25">
                                <div className="img_area">
                                  {item.product?.product_images?.length > 0 ? (
                                    <img
                                      src={`${common.imageUrl}/${item.product?.product_images?.[0]?.file?.fileUrl}`}
                                      alt="상품 이미지"
                                    />
                                  ) : (
                                    <i className="no_data_img" />
                                  )}
                                </div>
                              </div>
                              <div className="text_area">
                                <p className="brand">
                                  {item?.product?.brand?.nameEnglish.toUpperCase() ??
                                    ''}
                                </p>
                                <p className="product">
                                  {item?.product?.productName ?? ''}
                                </p>
                                <p className="price_total mt-3">
                                  {Number(
                                    item?.finalAmount ?? 0,
                                  ).toLocaleString()}
                                </p>
                                <span className="desc mt-3">
                                  이용기간 :{' '}
                                  {formatMmddd(
                                    item?.order?.rentalStartDate ?? '',
                                  )}
                                  {' - '}
                                  {formatMmddd(
                                    item?.order?.rentalEndDate ?? '',
                                  )}
                                </span>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="btn btn_style01 mt-6 h-10 w-full rounded-sm text-sm font-medium"
                              onClick={() => {
                                setReviewProduct(
                                  {
                                    order_item: item,
                                  } ?? undefined,
                                );
                                setReviewOpen(true);
                              }}
                            >
                              리뷰 작성
                            </button>
                          </div>
                        );
                      })
                    ) : (
                      <div className="no_data_text py-4 text-center">
                        <p>{valids.noDataReview}</p>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="inner pb-24 pt-4">
                <div className="flex flex-col gap-4">
                  {(listData2 ?? [])?.length > 0 ? (
                    listData2?.map((item, index) => {
                      const scorePercent = Number(item?.rating ?? 0) * 20;

                      return (
                        <div key={index} className="rounded-lg bg-white p-4">
                          <div className="item_area items-center">
                            <div className="img_wrap w-17.5">
                              <div className="img_area">
                                {item?.order_item?.product?.product_images
                                  ?.length > 0 ? (
                                  <img
                                    src={`${common.imageUrl}/${item?.order_item?.product?.product_images?.[0]?.file?.fileUrl}`}
                                    alt="상품 이미지"
                                  />
                                ) : (
                                  <i className="no_data_img" />
                                )}
                              </div>
                            </div>
                            <div className="text_area">
                              <p className="brand">
                                {item?.order_item?.product?.brand?.nameEnglish.toUpperCase() ??
                                  ''}
                              </p>
                              <p className="product">
                                {item?.order_item?.product?.productName ?? ''}
                              </p>
                            </div>
                          </div>
                          <div className="star_area mt-4">
                            <div
                              className="star_on"
                              style={{ width: `${scorePercent}%` }}
                            />
                          </div>
                          {item?.image_file_id_1_file ||
                          item?.image_file_id_2_file ||
                          item?.image_file_id_3_file ? (
                            <div className="mt-4 flex items-center gap-1">
                              {item?.image_file_id_1_file && (
                                <div className="w-17.5">
                                  <div className="img_area">
                                    <img
                                      src={`${common.imageUrl}/${item?.image_file_id_1_file?.fileUrl}`}
                                      alt="상품 이미지"
                                    />
                                  </div>
                                </div>
                              )}
                              {item?.image_file_id_2_file && (
                                <div className="w-17.5">
                                  <div className="img_area">
                                    <img
                                      src={`${common.imageUrl}/${item?.image_file_id_2_file?.fileUrl}`}
                                      alt="상품 이미지"
                                    />
                                  </div>
                                </div>
                              )}
                              {item?.image_file_id_3_file && (
                                <div className="w-17.5">
                                  <div className="img_area">
                                    <img
                                      src={`${common.imageUrl}/${item?.image_file_id_3_file?.fileUrl}`}
                                      alt="상품 이미지"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : null}
                          <p className="mt-4 text-xs font-medium text-gray-9">
                            {item?.content ?? ''}
                          </p>
                          <div className="mt-4 flex items-center justify-between">
                            <div className="flex items-center gap-1">
                              <span className="block text-xs font-medium text-gray-9">
                                {item?.createdAt
                                  ? format(item?.createdAt, 'yyyy.MM.dd')
                                  : ''}
                              </span>
                            </div>
                            <button
                              type="button"
                              className="flex items-center gap-1 text-xs font-medium text-gray-9"
                              onClick={() => {
                                setReviewProduct(item ?? undefined);
                                setReviewOpen(true);
                              }}
                            >
                              수정하기 <img src={icArrow} alt="arrow" />
                            </button>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="no_data_text py-4 text-center">
                      <p>{valids.noDataReviewed}</p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <UpdateReviewModal
            open={reviewOpen}
            setOpen={setReviewOpen}
            productData={reviewProdut}
            refetch={refetch}
            refetch2={refetch2}
          />

          <BottomNav active="MYPAGE" />
        </div>
      </div>
    </>
  );
}
export default Page;
