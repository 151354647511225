import { useQuery } from '@tanstack/react-query';
import { useAxios, Utils } from '@api/index';
import HomeQueryKey from '@api/home/home.key';
import HomeDto from '@api/home/home.dto';
import CommonDto from '@api/common.dto';

namespace HomeApi {
  /**
   * footer
   */
  export function useFooterInfoQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [HomeQueryKey.FOOTER],
      queryFn() {
        return axios.get<Utils.View.Response<HomeDto.Footer>>(
          '/api/users/footer',
        );
      },
    });
  }

  /**
   * 추천 조회
   */
  export function useRecommendQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [HomeQueryKey.RECOMMEND],
      queryFn() {
        return axios.get<Utils.View.Response<HomeDto.Recommend.Response>>(
          '/api/users/recommend',
        );
      },
    });
  }

  /**
   * 추천 new 아이템 조회
   * @param {number} categoryId - 카테고리 ID
   */
  export function useRecommendNewItemsQuery(categoryId?: number) {
    const axios = useAxios();
    const params = categoryId ? { categoryId } : {};

    return useQuery({
      queryKey: [HomeQueryKey.RECOMMEND, categoryId],
      queryFn() {
        return axios.get<Utils.View.Response<CommonDto.Product[]>>(
          '/api/users/recommend-newitems',
          { params },
        );
      },
    });
  }

  /**
   * 럭셔리 조회
   */
  export function useLuxuryQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [HomeQueryKey.LUXURY],
      queryFn() {
        return axios.get<Utils.View.Response<HomeDto.Luxury.Response>>(
          '/api/users/luxury',
        );
      },
    });
  }

  /**
   * 럭셔리 인기상품 조회
   * @param {number} categoryId - 카테고리 ID
   */
  export function useLuxuryPopularQuery(categoryId?: number) {
    const axios = useAxios();
    const params = categoryId ? { categoryId } : {};

    return useQuery({
      queryKey: [HomeQueryKey.LUXURY, categoryId],
      queryFn() {
        return axios.get<Utils.View.Response<CommonDto.Product[]>>(
          '/api/users/luxury-popular',
          { params },
        );
      },
    });
  }
}

export default HomeApi;
