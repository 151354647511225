import React, { useState } from 'react';
import Modal from '@components/modal/modal';
import imgPopup from '@assets/images/main/main_popup_img.jpg';

export default function MainImageModal() {
  const [open, setOpen] = useState(false);

  return open ? (
    <Modal className="md_main">
      <div className="modal_mid">
        <img src={imgPopup} alt="상품 이미지" />
      </div>
      <div className="modal_btm">
        <button type="button">오늘하루 보지않기</button>
        <button type="button" onClick={() => setOpen(false)}>
          닫기
        </button>
      </div>
    </Modal>
  ) : null;
}
