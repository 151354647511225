import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '@components/shared/header';
import JoinForm from '@app/auth/join/form';
import JoinAgree from '@app/auth/join/agree';
import IsJoinModal, { IsJoinDataType } from '@components/modal/isJoin';
import CheckIsLogin from '@components/checkIsLogin';
import { common, valids } from '@config/index';
import useStore from '@store/index';
import AuthDto from '@api/auth/auth.dto';
import AuthApi from '@api/auth/auth.query';

function Page() {
  const navigate = useNavigate();
  const { setLoading, setAlert } = useStore((state) => state);
  const [step, setStep] = useState(0);
  const [data, setData] = useState<AuthDto.Join.Request | null>(null);
  const [isAgree, setIsAgree] = useState(false);
  const [infoData, setInfoData] = useState<IsJoinDataType | null>(null);
  const [infoOpen, setInfoOpen] = useState(false);

  // api
  const useJoinMutation = AuthApi.useJoinMutation();

  const onFormSubmit = () => {
    if (!data) return;

    setLoading(true);

    const formData = { ...data };
    formData.marketingAgreement = isAgree;

    // console.log(formData);
    useJoinMutation.mutate(data);
  };

  // ERROR !!!
  useEffect(() => {
    if (!useJoinMutation.isError) return;
    if (useJoinMutation.error?.response?.data?.data) {
      setInfoData({
        ...useJoinMutation.error?.response?.data?.data,
        desc: useJoinMutation.error?.response?.data?.message,
      });
      setInfoOpen(true);
    } else {
      setAlert(
        useJoinMutation.error?.response?.data?.message ?? valids.errored,
      );
    }
    setLoading(false);
  }, [useJoinMutation.isError]);

  // 회원가입 SUCCESS !!!
  useEffect(() => {
    if (!useJoinMutation.isSuccess) return;
    sessionStorage.setItem(common.PASS_DATA_KEY, data?.name ?? '');
    navigate('/join/complete');
    setLoading(false);
  }, [useJoinMutation.isSuccess]);

  return (
    <>
      <CheckIsLogin loginCheck />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="회원가입" back="/login" />
          </div>

          {step === 0 ? (
            <JoinForm setStep={setStep} setData={setData} />
          ) : (
            <JoinAgree onClick={onFormSubmit} setIsAgree={setIsAgree} />
          )}
          <IsJoinModal
            open={infoOpen}
            setOpen={setInfoOpen}
            data={infoData}
            onConfirmClick={() => navigate('/login')}
          />
        </div>
      </div>
    </>
  );
}
export default Page;
