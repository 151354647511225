import React from 'react';
import { Link } from 'react-router-dom';
import icDoor from '@assets/images/deco/icon_withdraw_complete.svg';
import Header from '@components/shared/header';

function Page() {
  return (
    <div id="root_layout">
      <div className="root_container">
        <div className="sticky_menu">
          <Header title="탈퇴완료" />
        </div>

        <div className="mypage_withdraw_wrap">
          <div className="text_area">
            <img src={icDoor} alt="문" />
            <p>회원탈퇴가 완료되었습니다</p>
            <span>
              고객님의 회원탈퇴 요청이 정상적으로 처리되었습니다 <br />
              그동안 렌티크 서비스를 이용해주셔서 감사드립니다.
            </span>
          </div>

          <div className="fixed_btm_btn">
            <div className="fixed_btn bg_style01">
              <Link
                to="/"
                className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
              >
                확인
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page;
