import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import {
  JwtDto,
  TOKEN_STORE_KEY,
  useAxios,
  useContext,
  Utils,
} from '@api/index';
import AuthQueryKey from '@api/auth/auth.key';
import AuthDto from '@api/auth/auth.dto';

namespace AuthApi {
  /**
   * 본인인증
   */
  export function useIdentityVerificationQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [AuthQueryKey.IDENTITY_VERIFICATION],
      queryFn() {
        return axios.get<Utils.View.Response<AuthDto.IdentityVerification>>(
          '/api/auth/identity-verification',
        );
      },
    });
  }

  /**
   * 회원가입
   * @param {AuthDto.Join.Request} body - Body
   */
  export function useJoinMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<null>>,
      AxiosError<{ message: string; data: AuthDto.Join.Response2 }>,
      AuthDto.Join.Request
    >({
      mutationFn(body: AuthDto.Join.Request) {
        return axios.post<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>,
          AuthDto.Join.Request
        >('/api/auth/join', body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [AuthQueryKey.JOIN],
        });
      },
    });
  }

  /**
   * SNS 회원가입
   * @param {AuthDto.Join.Request2} body - Body
   */
  export function useSnsJoinMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<null>>,
      AxiosError<{ message: string; data: AuthDto.Join.Response2 }>,
      AuthDto.Join.Request2
    >({
      mutationFn(body: AuthDto.Join.Request2) {
        return axios.post<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>,
          AuthDto.Join.Request2
        >('/api/auth/sns-join', body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [AuthQueryKey.SNS_JOIN],
        });
      },
    });
  }

  /**
   * 이메일 중복 확인
   * @param {AuthDto.EmailDuplicateCheck.Request} body - Body
   */
  export function useEmailDuplicateCheckMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<null>>,
      AxiosError<{ message: string }>,
      AuthDto.EmailDuplicateCheck.Request
    >({
      mutationFn(body: AuthDto.EmailDuplicateCheck.Request) {
        return axios.post<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>,
          AuthDto.EmailDuplicateCheck.Request
        >('/api/auth/email-duplicate-check', body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [AuthQueryKey.EMAIL_DUPLICATE_COHECK],
        });
      },
    });
  }

  /**
   * 로그인
   * @param {AuthDto.Login.Request} body - Body
   */
  export function useLoginMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();
    const [, setToken] = useContext();

    return useMutation<
      AxiosResponse<Utils.View.Response<JwtDto.Token>>,
      AxiosError<{ message: string }>,
      AuthDto.Login.Request
    >({
      mutationFn(body: AuthDto.Login.Request) {
        return axios.post<
          Utils.View.Response<JwtDto.Token>,
          AxiosResponse<Utils.View.Response<JwtDto.Token>>,
          AuthDto.Login.Request
        >('/api/auth/login', body);
      },
      onSuccess({ data: { data: responseData } }) {
        setToken(responseData);
      },
      onSettled() {
        return queryClient.resetQueries();
      },
    });
  }

  /**
   * 로그인 상태 확인
   */
  export function useIsLogin() {
    const [token] = useContext();
    // console.log('useIsLogin token', token);
    return !!token;
  }

  /**
   * 로그인 토큰 정보
   */
  export function useGetToken() {
    const [token] = useContext();
    return token;
  }

  /**
   * 로그아웃
   */
  export function useLogout() {
    const [, setToken] = useContext();
    return async () => {
      localStorage.removeItem(TOKEN_STORE_KEY);
      setToken(null);
    };
  }

  /**
   * 이메일(아이디 찾기)
   * @param {AuthDto.FindEmail.Request} body - Body
   */
  export function useFindEmailMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<AuthDto.FindEmail.Response>>,
      AxiosError<{ message: string }>,
      AuthDto.FindEmail.Request
    >({
      mutationFn(body: AuthDto.FindEmail.Request) {
        return axios.post<
          Utils.View.Response<AuthDto.FindEmail.Response>,
          AxiosResponse<Utils.View.Response<AuthDto.FindEmail.Response>>,
          AuthDto.FindEmail.Request
        >('/api/auth/find-email', body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [AuthQueryKey.FIND_EMAIL],
        });
      },
    });
  }

  /**
   * 이메일 인증코드 발송
   * @param {AuthDto.VerificationCode} body - Body
   */
  export function useVerificationCodeSendMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<null>>,
      AxiosError<{ message: string }>,
      AuthDto.VerificationCode
    >({
      mutationFn(body: AuthDto.VerificationCode) {
        return axios.post<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>,
          AuthDto.VerificationCode
        >('/api/auth/verification-code-send', body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [AuthQueryKey.VERIFICATION_CODE_SEND],
        });
      },
    });
  }

  /**
   * 이메일 인증코드 검증
   * @param {AuthDto.VerificationCode} body - Body
   */
  export function useVerificationCodeCheckMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<null>>,
      AxiosError<{ message: string }>,
      AuthDto.VerificationCode
    >({
      mutationFn(body: AuthDto.VerificationCode) {
        return axios.post<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>,
          AuthDto.VerificationCode
        >('/api/auth/verification-code-check', body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [AuthQueryKey.VERIFICATION_CODE_CHECK],
        });
      },
    });
  }

  /**
   * 비밀번호 재설정
   * @param {AuthDto.PasswordReset.Request} body - Body
   */
  export function usePasswordResetMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<null>>,
      AxiosError<{ message: string }>,
      AuthDto.PasswordReset.Request
    >({
      mutationFn(body: AuthDto.PasswordReset.Request) {
        return axios.put<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>,
          AuthDto.PasswordReset.Request
        >('/api/auth/password-reset', body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [AuthQueryKey.PASSWORD_RESET],
        });
      },
    });
  }
}

export default AuthApi;
