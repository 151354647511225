import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Main from '@app/page';
import Login from '@app/auth/login/page';
import FindId from '@app/auth/find-id/page';
import FindPassword from '@app/auth/find-password/page';
import FindPasswordEmail from '@app/auth/find-password/email/page';
import ResetPassword from '@app/auth/reset-password/page';
import Join from '@app/auth/join/page';
import JoinComplete from '@app/auth/join/complete/page';
import JoinAgree from '@app/auth/join/agree/page';
import Kakao from '@app/redirect/kakao/page';
import KakaoIsLogin from '@app/redirect/kakao/islogin/page';
import Naver from '@app/redirect/naver/page';
import NaverIsLogin from '@app/redirect/naver/islogin/page';
import Apple from '@app/redirect/apple/page';
import AppleIsLogin from '@app/redirect/apple/islogin/page';
import Pass from '@app/redirect/pass/page';
import Toss from '@app/redirect/toss/page';
import TossResult from '@app/redirect/toss/result/page';
import Search from '@app/search/page';
import Cart from '@app/cart/page';
import Luxury from '@app/luxury/page';
import Promotion from '@app/promotion/page';
import PromotionProduct from '@app/promotion/product/page';
import PromotionDetail from '@app/promotion/detail/page';
import PromotionDetailNew from '@app/promotion/detail/new/page';
import Brand from '@app/brand/page';
import BrandProduct from '@app/brand/product/page';
import Ranking from '@app/ranking/page';
import Interest from '@app/interest/page';
import Category from '@app/category/page';
import Product from '@app/product/page';
import ProductDetail from '@app/product/detail/page';
import ProductSimilar from '@app/product/similar/page';
import ProductRent from '@app/product/rent/page';
import Order from '@app/order/page';
import OrderComplete from '@app/order/complete/page';
import OrderDetail from '@app/order/detail/page';
import Luxshare from '@app/luxshare/page';
import LuxshareApply from '@app/luxshare/apply/page';
import LuxshareComplete from '@app/luxshare/complete/page';
import Mypage from '@app/mypage/page';
import MypageUserInfo from '@app/mypage/user-info/page';
import MypageChangePassword from '@app/mypage/change-password/page';
import MypageRent from '@app/mypage/rent/page';
import MypageCancelComplete from '@app/mypage/rent/cancel-complete/page';
import MypagePayment from '@app/mypage/payment/page';
import MypageDelivery from '@app/mypage/delivery/page';
import MypageAccount from '@app/mypage/account/page';
import MypageConsignment from '@app/mypage/consignment/page';
import MypageConsignmentDetail from '@app/mypage/consignment/detail/page';
import MypageSettlement from '@app/mypage/settlement/page';
import MypageSettlementDetail from '@app/mypage/settlement/detail/page';
import MypageCoupon from '@app/mypage/coupon/page';
import MypageSavings from '@app/mypage/savings/page';
import MypageReview from '@app/mypage/review/page';
import MypageCustomer from '@app/mypage/customer/page';
import MypageCustomerFaq from '@app/mypage/customer/faq/page';
import MypageCustomerNotice from '@app/mypage/customer/notice/page';
import MypageInquiry from '@app/mypage/inquiry/page';
import MypageWithdraw from '@app/mypage/withdraw/page';
import MypageWithdrawComplete from '@app/mypage/withdraw/complete/page';
import ServiceRetique from '@app/service-info/rentique/page';
import ServiceLuxshare from '@app/service-info/luxshare/page';
import Terms from '@app/agree/terms/page';
import Policy from '@app/agree/policy/page';
import OutOfService from '@app/error/out-of-service/page';
import NotFound from '@app/error/not-found/page';

function Router() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/login" element={<Login />} />
      <Route path="/find-id" element={<FindId />} />
      <Route path="/find-password" element={<FindPassword />} />
      <Route path="/find-password/email" element={<FindPasswordEmail />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/join" element={<Join />} />
      <Route path="/join/complete" element={<JoinComplete />} />
      <Route path="/join/agree" element={<JoinAgree />} />
      <Route path="/kakao" element={<Kakao />} />
      <Route path="/kakao/islogin" element={<KakaoIsLogin />} />
      <Route path="/naver" element={<Naver />} />
      <Route path="/naver/islogin" element={<NaverIsLogin />} />
      <Route path="/apple" element={<Apple />} />
      <Route path="/apple/islogin" element={<AppleIsLogin />} />
      <Route path="/pass" element={<Pass />} />
      <Route path="/toss" element={<Toss />} />
      <Route path="/toss/:status" element={<TossResult />} />
      <Route path="/search" element={<Search />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/luxury" element={<Luxury />} />
      <Route path="/promotion" element={<Promotion />} />
      <Route path="/promotion/product/:id" element={<PromotionProduct />} />
      <Route path="/promotion/detail/new" element={<PromotionDetailNew />} />
      <Route path="/promotion/detail/:id" element={<PromotionDetail />} />
      <Route path="/brand" element={<Brand />} />
      <Route path="/brand/product" element={<BrandProduct />} />
      <Route path="/ranking" element={<Ranking />} />
      <Route path="/interest" element={<Interest />} />
      <Route path="/category" element={<Category />} />
      <Route path="/product" element={<Product />} />
      <Route path="/product/detail/:id" element={<ProductDetail />} />
      <Route path="/product/similar/:id" element={<ProductSimilar />} />
      <Route path="/product/rent/:id" element={<ProductRent />} />
      <Route path="/order" element={<Order />} />
      <Route path="/order/complete" element={<OrderComplete />} />
      <Route path="/order/detail/:id" element={<OrderDetail />} />
      <Route path="/luxshare" element={<Luxshare />} />
      <Route path="/luxshare/apply" element={<LuxshareApply />} />
      <Route path="/luxshare/complete" element={<LuxshareComplete />} />
      <Route path="/mypage" element={<Mypage />} />
      <Route path="/mypage/user-info" element={<MypageUserInfo />} />
      <Route
        path="/mypage/change-password"
        element={<MypageChangePassword />}
      />
      <Route path="/mypage/rent" element={<MypageRent />} />
      <Route
        path="/mypage/rent/cancel-complete"
        element={<MypageCancelComplete />}
      />
      <Route path="/mypage/payment" element={<MypagePayment />} />
      <Route path="/mypage/delivery" element={<MypageDelivery />} />
      <Route path="/mypage/account" element={<MypageAccount />} />
      <Route path="/mypage/consignment" element={<MypageConsignment />} />
      <Route
        path="/mypage/consignment/detail/:id"
        element={<MypageConsignmentDetail />}
      />
      <Route path="/mypage/settlement" element={<MypageSettlement />} />
      <Route
        path="/mypage/settlement/detail/:id"
        element={<MypageSettlementDetail />}
      />
      <Route path="/mypage/coupon" element={<MypageCoupon />} />
      <Route path="/mypage/savings" element={<MypageSavings />} />
      <Route path="/mypage/review" element={<MypageReview />} />
      <Route path="/mypage/customer" element={<MypageCustomer />} />
      <Route path="/mypage/customer/faq" element={<MypageCustomerFaq />} />
      <Route
        path="/mypage/customer/notice"
        element={<MypageCustomerNotice />}
      />
      <Route path="/mypage/inquiry" element={<MypageInquiry />} />
      <Route path="/mypage/withdraw" element={<MypageWithdraw />} />
      <Route
        path="/mypage/withdraw/complete"
        element={<MypageWithdrawComplete />}
      />
      <Route path="/service-info/rentique" element={<ServiceRetique />} />
      <Route path="/service-info/luxshare" element={<ServiceLuxshare />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/policy" element={<Policy />} />
      <Route path="/out-of-service" element={<OutOfService />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Router;
