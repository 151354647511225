import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import Header from '@components/shared/header';
import CustomPassword from '@components/forms/customPassword';
import MsgModal from '@components/modal/msg';
import CheckIsLogin from '@components/checkIsLogin';
import { common, regex, valids } from '@config/index';
import useStore from '@store/index';
import AuthDto from '@api/auth/auth.dto';
import AuthApi from '@api/auth/auth.query';

function Page() {
  const navigate = useNavigate();
  const { setLoading, setAlert } = useStore((state) => state);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<AuthDto.PasswordReset.Request>();
  const [pwConfirm, setPwConfirm] = useState('');
  const [pwConfirmError, setPwConfirmError] = useState('');
  const [msgOpen, setMsgOpen] = useState(false);

  // api
  const usePasswordResetMutation = AuthApi.usePasswordResetMutation();

  const onFormSubmit: SubmitHandler<AuthDto.PasswordReset.Request> = (data) => {
    if (data.password !== pwConfirm) {
      setPwConfirmError(valids.incorrectPasswordConfirm);
      return;
    }

    setLoading(true);
    const storageData = sessionStorage.getItem(common.VERIFICATION_DATA_KEY);
    let formData = { ...data };
    if (storageData) formData = { ...formData, ...JSON.parse(storageData) };

    // console.log(formData);
    usePasswordResetMutation.mutate(formData);
  };

  // ERROR !!!
  useEffect(() => {
    if (!usePasswordResetMutation.isError) return;
    setAlert(
      usePasswordResetMutation.error?.response?.data?.message ?? valids.errored,
    );
    setLoading(false);
  }, [usePasswordResetMutation.isError]);

  // 비밀번호 재설정 SUCCESS !!!
  useEffect(() => {
    if (!usePasswordResetMutation.isSuccess) return;
    setMsgOpen(true);
    setLoading(false);
  }, [usePasswordResetMutation.isSuccess]);

  return (
    <>
      <CheckIsLogin loginCheck />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header
              title="비밀번호 재설정"
              back
              backFunc={() => {
                localStorage.removeItem(common.VERIFICATION_DATA_KEY);
                navigate('/login');
              }}
            />
          </div>

          <div className="auth_wrap type01">
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div className="inner">
                <p className="desc_text">
                  본인 인증이 완료되었습니다. <br />
                  새로운 비밀번호를 입력해 주세요.
                </p>

                <div className="label_input_area">
                  <label htmlFor="password">새로운 비밀번호</label>
                  <CustomPassword
                    id="password"
                    className={`input ${errors.password ? 'error' : ''}`}
                    placeholder={valids.formatPassword}
                    maxLength={16}
                    {...register('password', {
                      required: valids.inputPassword,
                      pattern: {
                        value: regex.regPassword,
                        message: valids.inputPasswordFormat,
                      },
                    })}
                  />
                  {errors.password && (
                    <span className="input_error_text">
                      {errors.password.message}
                    </span>
                  )}
                </div>
                <div className="label_input_area mt-4">
                  <label htmlFor="password-confirm">비밀번호 확인</label>
                  <CustomPassword
                    id="password-confirm"
                    className={`input ${pwConfirmError ? 'error' : ''}`}
                    placeholder={valids.inputPasswordConfirm}
                    value={pwConfirm}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setPwConfirm(e.target.value);
                      setPwConfirmError('');
                    }}
                  />
                  {pwConfirmError && (
                    <span className="input_error_text">{pwConfirmError}</span>
                  )}
                </div>
              </div>

              <div className="fixed_btm_btn">
                <div className="fixed_btn bg_style01">
                  <button
                    type="submit"
                    className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                    disabled={!(watch('password') && pwConfirm)}
                  >
                    확인
                  </button>
                </div>
              </div>
            </form>
          </div>

          <MsgModal
            open={msgOpen}
            setOpen={setMsgOpen}
            btns={
              <button
                type="button"
                className="btn btn_style02 h-10 w-full rounded-sm text-sm font-bold"
                onClick={() => {
                  sessionStorage.removeItem(common.VERIFICATION_DATA_KEY);
                  navigate('/login');
                }}
              >
                확인
              </button>
            }
          >
            <p className="tit">알림</p>
            <span className="desc">{valids.newPasswordSaved}</span>
          </MsgModal>
        </div>
      </div>
    </>
  );
}
export default Page;
