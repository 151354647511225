import React, { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { format } from 'date-fns';
import icDownload from '@assets/images/icon_coupon_download.svg';
import Header from '@components/shared/header';
import { common, valids } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import CouponApi from '@api/coupon/coupon.query';
// import { couponData } from '@temp/couponData';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id?: number;
}

export default function GetCouponModal({ open, setOpen, id }: Props) {
  const { setLoading, setToastContainerStyle } = useStore((state) => state);

  // api
  const { data: callData, isLoading, refetch } = CouponApi.useCouponQuery(id);
  const resultData = callData?.data?.data?.result;
  const useCouponDownloadMutation = CouponApi.useCouponDownloadMutation();

  const handleDownload = (cId: number, endDate: string) => {
    if (!cId) return;

    setLoading(true);
    useCouponDownloadMutation.mutate({
      couponId: cId,
      expiresAt: endDate ?? '',
    });
  };

  const handleDownloadAll = () => {
    setLoading(true);
    (resultData ?? [])
      ?.filter((item) => !item?.downloadYn)
      ?.map((item) => {
        return useCouponDownloadMutation.mutate({
          couponId: item.id,
          expiresAt: item.endDate ?? '',
        });
      });
  };

  // ERROR !!!
  useEffect(() => {
    if (!useCouponDownloadMutation.isError) return;
    setLoading(false);
    setToastContainerStyle({ bottom: 78 });
    toast(valids.errored, { style: common.toastStyle });
  }, [useCouponDownloadMutation.isError]);

  // 쿠폰 다운로드 SUCCESS !!!
  useEffect(() => {
    if (!useCouponDownloadMutation.isSuccess) return;
    setLoading(false);
    setToastContainerStyle({ bottom: 78 });
    toast(valids.downloadCoupon, { style: common.toastStyle });
    refetch();
  }, [useCouponDownloadMutation.isSuccess]);

  useEffect(() => {
    if (id && resultData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [id, resultData, isLoading]);

  // NO DATA !!!
  if (!id && !resultData && !isLoading) {
    alert(valids.noDataCoupon);
    setOpen(false);
  }
  if (!resultData) return null;

  return open ? (
    <div className="modal_wrap full">
      <div className="modal_area">
        <Header back backFunc={() => setOpen(false)} title="쿠폰 받기" />

        <section className="prod_detail_coupon_wrap">
          <div className="inner">
            <div className="list_area list_style07">
              <ul>
                {(resultData ?? [])?.length > 0 ? (
                  resultData.map((item) => {
                    const formatEndDate = format(
                      item.endDate,
                      'yyyy-MM-dd hh:mm',
                    );

                    return (
                      <li
                        key={item.id}
                        className={!item?.downloadYn ? '' : 'disabled'}
                      >
                        <div className="top_area">
                          <div>
                            <p>
                              {item?.discountType === 'percentage'
                                ? `${Number(item?.discountValue)}%`
                                : item?.discountType === 'amount'
                                  ? `${Number(
                                      item?.discountValue,
                                    ).toLocaleString()}원`
                                  : ''}
                            </p>
                            <span>{item?.name ?? ''}</span>
                          </div>
                          {!item?.downloadYn && (
                            <button
                              type="button"
                              onClick={() =>
                                handleDownload(item.id, formatEndDate)
                              }
                            >
                              <img src={icDownload} alt="download" />
                            </button>
                          )}
                        </div>
                        <div className="btm_area">
                          {item?.minPurchaseAmount && (
                            <p>
                              <b>사용 조건</b>
                              <span>
                                {Number(
                                  item?.minPurchaseAmount ?? 0,
                                ).toLocaleString()}
                                원 이상 구매시
                              </span>
                            </p>
                          )}
                          {item?.endDate && (
                            <p>
                              <b>유효 기간</b>
                              <span>
                                {format(item?.endDate ?? '', 'yyyy.MM.dd')}{' '}
                                23:59 까지
                              </span>
                            </p>
                          )}
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <li className="no_data_text">
                    <p>{valids.noDataCoupon}</p>
                  </li>
                )}
              </ul>
            </div>

            <div className="fixed_btm_btn">
              <div className="fixed_btn bg_style02">
                <button
                  type="button"
                  className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                  onClick={handleDownloadAll}
                  disabled={
                    (resultData ?? [])?.filter((item) => !item?.downloadYn)
                      ?.length <= 0
                  }
                >
                  쿠폰 모두 받기
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  ) : null;
}
