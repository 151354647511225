import React, { useEffect, useState } from 'react';
import { format, subDays } from 'date-fns';
import Header from '@components/shared/header';
import BottomNav from '@components/shared/bottomNav';
import CheckIsLogin from '@components/checkIsLogin';
import { options, tabs } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import MypageApi from '@api/mypage/mypage.query';
// import { savingData } from '@temp/savingData';

function Page() {
  const { setLoading } = useStore((state) => state);
  const [tab, setTab] = useState(tabs.savingTabs[0].id);

  // api
  const dashboardData = MypageApi.useDashboardQuery()?.data?.data?.data;
  const { data: callData, isLoading } = MypageApi.usePointsQuery({
    gubun: tab !== 'total' ? tab : undefined,
  });
  const listData = callData?.data?.data?.result;

  useEffect(() => {
    // console.log(listData);
    if (dashboardData !== undefined && listData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [dashboardData, listData, isLoading]);

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="나의 적립금" back="/mypage" searchCart />
          </div>

          <div className="mypage_savings_wrap pb-24 pt-4">
            <div className="inner">
              <div className="hold_area">
                <span>보유 적립금</span>
                <p>{Number(dashboardData?.points ?? 0).toLocaleString()}</p>
              </div>

              <div className="tab_area mt-4">
                <ul>
                  {tabs.savingTabs.map((item) => {
                    return (
                      <li key={item.id} className={tab === item.id ? 'on' : ''}>
                        <button type="button" onClick={() => setTab(item.id)}>
                          {item.text}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* 리스트 */}
              <div className="savings_list_area mt-4">
                <ul>
                  {(listData ?? [])?.length > 0
                    ? listData?.map((item) => {
                        return (
                          <li
                            key={item.id}
                            className={
                              item?.type === 'review' ||
                              item?.type === 'photo_review' ||
                              item?.type === 'cancle'
                                ? 'type01'
                                : item?.type === 'usage'
                                  ? 'type02'
                                  : 'type03'
                            }
                          >
                            <i />
                            <div className="text_area">
                              <p>
                                {options.pointContents.filter(
                                  (el) => el.id === item?.type,
                                )?.[0]?.text ?? ''}
                              </p>
                              {item?.createdAt && (
                                <>
                                  {(item?.type === 'review' ||
                                    item?.type === 'photo_review' ||
                                    item?.type === 'cancle') && (
                                    <span>
                                      {format(
                                        item?.createdAt ?? '',
                                        'yyyy.MM.dd',
                                      )}
                                    </span>
                                  )}
                                  {item?.type === 'expiration' && (
                                    <span>
                                      {format(
                                        subDays(item?.createdAt ?? '', 1),
                                        'yyyy.MM.dd',
                                      )}{' '}
                                      23:59까지
                                    </span>
                                  )}
                                </>
                              )}
                            </div>
                            <p className="point">
                              {item?.type === 'review' ||
                              item?.type === 'photo_review' ||
                              item?.type === 'cancle'
                                ? '+'
                                : ''}
                              {Number(item?.points ?? 0).toLocaleString()}{' '}
                              <br />
                              {item?.type === 'review' ||
                              item?.type === 'photo_review' ||
                              item?.type === 'cancle'
                                ? '적립'
                                : item.type === 'usage'
                                  ? '사용'
                                  : null}
                            </p>
                          </li>
                        );
                      })
                    : null}
                </ul>
              </div>
              <span className="mt-4 block text-center text-xs font-medium text-gray-9">
                2년이 지난 적립금 내역은 노출되지 않습니다.
              </span>
            </div>
          </div>

          <BottomNav active="MYPAGE" />
        </div>
      </div>
    </>
  );
}
export default Page;
