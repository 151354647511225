import { common } from '@config/index';
import SearchDto from '@api/search/search.dto';
// import axios from 'axios';

// setTimeout
export const sleep = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

// random
export const generateRandomString = () => {
  return window.btoa(Math.random().toString()).slice(0, 20);
};

// 날짜 포맷 변환 'yyyymmdd' => 'yyyy-mm-dd'
export const formatYyyyMmddBar = (dateString: string) => {
  if (dateString === '') return dateString;
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);

  return `${year}-${month}-${day}`;
};
// 날짜 포맷 변환 'yyyy-mm-dd' => 'yyyy.mm.dd'
export const formatYyyyMmddDot = (dateString: string) => {
  if (dateString === '') return dateString;
  const [year, month, day] = dateString.split('-');

  return `${year}.${month}.${day}`;
};
// 날짜 포맷 변환 'yyyy-mm-dd' => 'mm.dd(d)'
export const formatMmddd = (dateString: string) => {
  if (dateString === '') return dateString;
  const [, month, day] = dateString.split('-');

  const date = new Date(dateString);
  const dayOfWeek = ['일', '월', '화', '수', '목', '금', '토'][date.getDay()];

  return `${month}.${day}(${dayOfWeek})`;
};
// 날짜 포맷 변환 'yyyy-mm-dd' => 'yy.mm.dd(d)'
export const formatYyMmddd = (dateString: string) => {
  if (dateString === '') return dateString;
  const [year, month, day] = dateString.split('-');

  const date = new Date(dateString);
  const dayOfWeek = ['일', '월', '화', '수', '목', '금', '토'][date.getDay()];

  return `${year.slice(-2)}.${month}.${day}(${dayOfWeek})`;
};
// 날짜 포맷 변환 'yyyy-mm' => 'yyyy년 m월'
export const formatYyyyM = (dateString: string) => {
  if (dateString === '') return dateString;
  const [year, month] = dateString.split('-');

  return `${year}년 ${Number(month)}월`;
};

// 휴대폰번호 포맷 변환 '000-0000-0000'
export const formatPhone = (phoneString: string) => {
  if (phoneString === '') return phoneString;
  const cleaned = phoneString.replace(/\D/g, '');
  return cleaned.replace(/(\d{2,3})(\d{3,4})(\d{4})/, '$1-$2-$3');
};

// 이미지 변환 URL => File
export const convertURLtoFile = async (url: string): Promise<File> => {
  const img_ext = url.split('.')[1];

  const img = await fetch(`${common.imageUrl}/${url}`);
  const img_as_blob = await img.blob();
  const img_as_file = new File([img_as_blob], `image.${img_ext}`, {
    lastModified: new Date().getTime(),
    type: img_as_blob.type,
  });
  return img_as_file;
};

// 이메일 마스킹 처리
export const maskEmail = (email: string) => {
  const [localPart, domain] = email.split('@');
  const localLength = localPart.length;
  const maskedPart = '*'.repeat(5);

  if (localLength <= 5) {
    return `${'*'.repeat(localLength)}@${domain}`;
  }

  const startVisualPart = localPart.slice(0, localLength > 7 ? 2 : 1);
  const endVisualPart =
    localLength > 6
      ? localLength === 7
        ? localPart.slice(-1)
        : localPart.slice(6 - localLength)
      : '';

  return `${startVisualPart}${maskedPart}${endVisualPart}@${domain}`;
};

// 검색어 하이라이트 처리
export const highlightSearchKeyword = (
  data: SearchDto.Search.Response[],
  text: string,
) => {
  const searchTerm = text.replace(/\s+/g, '');

  return data.map((item) => {
    const searchProductName = item.productName.replace(/\s+/g, '');
    const index = searchProductName
      .toLowerCase()
      .indexOf(searchTerm.toLowerCase());

    if (index !== -1) {
      const regex = new RegExp(
        text.split('').join('\\s*').replace(/\s+/g, '\\s*'),
        'gi',
      );

      const highlightedName = item.productName.replace(
        regex,
        (match) => `<span>${match}</span>`,
      );

      return { ...item, html: highlightedName };
    }

    return { ...item, html: item.productName };
  });
};

// URL 복사
export const copyUrl = () => {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        alert('URL이 복사되었습니다.');
      })
      .catch((err) => {
        alert(`복사 실패: ${err}`);
      });
  } else if (document.queryCommandSupported('copy')) {
    const input = document.createElement('input');
    input.value = window.location.href;
    document.body.appendChild(input);
    input.select();

    try {
      const successful = document.execCommand('copy');
      alert(successful ? 'URL이 복사되었습니다.' : '복사에 실패했습니다.');
    } catch (err) {
      alert(`복사 실패: ${err}`);
    }

    document.body.removeChild(input);
  } else {
    alert('클립보드 복사 기능이 지원되지 않는 브라우저입니다.');
  }
};

// TOSS - 부모창에 데이터 전달
export const postChildMessage = (status: string, msg?: string) => {
  const newParams = {
    key: common.TOSS_DATA_KEY,
    status,
    msg,
  };

  window.addEventListener('unload', function () {
    if (window.opener) {
      window.opener.postMessage(newParams, '*');
    }
  });
  window.close();
};
