import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { sleep } from '@utils/common';

interface Props {
  data: any[];
  active: any;
  setActive: React.Dispatch<React.SetStateAction<any>>;
  spaceBetween?: number;
}

export default function TabBtnSlider({
  data,
  active,
  setActive,
  spaceBetween,
}: Props) {
  const swiperRef = useRef<SwiperCore | null>(null);

  useEffect(() => {
    if (swiperRef.current) {
      const activeIndex = data?.findIndex((item) => item.id === active);
      swiperRef.current.slideTo(activeIndex);

      (async () => {
        await sleep(400);
        swiperRef.current.update();
        // swiperRef.current.updateSize();
        // swiperRef.current.updateSlides();
      })();
    }
  }, [active]);

  return data?.length > 0 ? (
    <Swiper
      spaceBetween={spaceBetween ?? 8}
      slidesPerView="auto"
      onSwiper={(swiper: any) => {
        swiperRef.current = swiper;
      }}
    >
      {data?.map((item) => {
        return (
          <SwiperSlide key={item.id} className={active === item.id ? 'on' : ''}>
            <button type="button" onClick={() => setActive(item.id)}>
              {item.text}
            </button>
          </SwiperSlide>
        );
      })}
    </Swiper>
  ) : null;
}
