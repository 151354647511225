import { useEffect } from 'react';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import { valids } from '@config/index';
import { generateRandomString, postChildMessage } from '@utils/common';

const clientKey = process.env.REACT_APP_TOSS_CLIENT_KEY;
const customerKey = generateRandomString();

function Page() {
  useEffect(() => {
    if (!clientKey) return;

    (async () => {
      const tossPayments = await loadTossPayments(clientKey);

      tossPayments
        .requestBillingAuth('카드', {
          customerKey, // 고객 식별자 (고유 값)
          successUrl: `${window.location.origin}/toss/success`, // 성공 시 리다이렉트될 URL
          failUrl: `${window.location.origin}/toss/fail`, // 실패 시 리다이렉트될 URL
        })
        .catch((error) => {
          if (error.code === 'USER_CANCEL')
            postChildMessage('fail', valids.cardCanceled);
          else postChildMessage('fail', error.message);
        });
    })();
  }, []);

  return null;
}
export default Page;
