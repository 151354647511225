import React from 'react';
import icKakao from '@assets/images/icon_kakao.svg';
import icNaver from '@assets/images/icon_naver.svg';
import icApple from '@assets/images/icon_apple.svg';
import Modal from '@components/modal/modal';
import { valids } from '@config/index';
import { maskEmail } from '@utils/common';

export type IsJoinDataType = {
  desc?: string;
  email: string;
  providerType: string;
};

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: IsJoinDataType | null;
  onConfirmClick?: () => void;
}

export default function IsJoinModal({
  open,
  setOpen,
  data,
  onConfirmClick,
}: Props) {
  return open ? (
    <Modal type="BOTTOM">
      <div className="modal_mid text-center">
        <p className="text-sm font-bold text-black">가입 내역 안내</p>
        <span className="black mt-2 text-xs font-medium text-black">
          {data?.desc ?? valids.alreadyJoin}
        </span>
        <div className="mt-4 flex h-16 w-full items-center justify-center gap-1 rounded-sm bg-gray-f4 px-2">
          {data?.providerType === 'kakao' ? (
            <img src={icKakao} alt={data?.providerType} />
          ) : data?.providerType === 'naver' ? (
            <img src={icNaver} alt={data?.providerType} />
          ) : data?.providerType === 'apple' ? (
            <img src={icApple} alt={data?.providerType} />
          ) : null}
          <span className="block text-sm text-black">
            {maskEmail(data?.email ?? '')}
          </span>
        </div>
      </div>
      <div className="modal_btm mt-4">
        <button
          type="button"
          className="btn btn_style02 h-12 w-full rounded-sm text-sm"
          onClick={() => {
            setOpen(false);
            onConfirmClick?.();
          }}
        >
          확인
        </button>
      </div>
    </Modal>
  ) : null;
}
