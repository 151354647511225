import React from 'react';
import { useNavigate } from 'react-router-dom';
import imgLuxShare from '@assets/images/lux_text01.svg';
import imgMoney from '@assets/images/lux_img01.png';
import imgProcess01 from '@assets/images/lux_process_img01.png';
import imgProcess02 from '@assets/images/lux_process_img02.png';
import imgProcess03 from '@assets/images/lux_process_img03.png';
import imgProcess04 from '@assets/images/lux_process_img04.png';
import imgProcess05 from '@assets/images/lux_process_img05.png';
import imgProcess06 from '@assets/images/lux_process_img06.png';
import Header from '@components/shared/header';
import HeaderNav from '@components/shared/headerNav';
import BottomNav from '@components/shared/bottomNav';

function Page() {
  const navigate = useNavigate();

  return (
    <div id="root_layout">
      <div className="root_container">
        <div className="sticky_menu">
          <Header logo searchCart />
          <HeaderNav />
        </div>

        <div className="lux_con_wrap01">
          <div className="inner">
            <span>
              집에서 잠자고 있던 내 명품백으로 <br />
              최대 수익을 창출하는 꿀팁
            </span>
            <p>쉽고 빠르게</p>
            <img src={imgLuxShare} alt="LUX SHARE" />
            <div className="img_wrap">
              <img src={imgMoney} alt="deco" />
            </div>
          </div>
        </div>
        <div className="lux_con_wrap02">
          <div className="inner">
            <h3 className="lux_tit">간단한 위탁신청 프로세스 안내 드릴게요</h3>
            <ul>
              <li>
                <img src={imgProcess01} alt="STEP 1" />
                <span>STEP 1</span>
                <p>
                  <b>
                    위탁 상품 <br />
                    신청정보 입력
                  </b>
                </p>
              </li>
              <li>
                <img src={imgProcess02} alt="STEP 2" />
                <span>STEP 2</span>
                <p>
                  <b>
                    위탁 상품 전달 <br />
                    택배 발송
                  </b>
                </p>
              </li>
              <li>
                <img src={imgProcess03} alt="STEP 3" />
                <span>STEP 3</span>
                <p>
                  <b>
                    상품 검수 및 진단 <br />
                    위탁 승인
                  </b>
                </p>
              </li>
              <li>
                <img src={imgProcess04} alt="STEP 4" />
                <span>STEP 4</span>
                <p>
                  <b>계약서 작성</b>
                  <br />
                  계약 진행
                </p>
              </li>
              <li>
                <img src={imgProcess05} alt="STEP 5" />
                <span>STEP 5</span>
                <p>
                  <b>럭스쉐어 시작</b>
                  <br />
                  수익 실현
                </p>
              </li>
              <li>
                <img src={imgProcess06} alt="STEP 6" />
                <span>STEP 6</span>
                <p>
                  <b>쉐어백 종료</b>
                  <br />
                  판매/연장/회수
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="lux_con_wrap03">
          <div className="inner">
            <h3 className="lux_tit">위탁신청 방식 및 정산</h3>
            <div className="bg_wrap">
              <div className="text_area">
                <span>적극 추천</span>
                <h4>위탁 신청 방식</h4>
                <p>
                  렌티크에 상품만 전달해주시면, <br />
                  모든 절차를 대행해 드립니다.
                </p>
              </div>
              <dl className="type01">
                <dt>
                  <span>1</span>
                  <p>위탁자</p>
                </dt>
                <dd>위탁 신청 및 상품 정보 입력</dd>
              </dl>
              <dl className="type01">
                <dt>
                  <span>2</span>
                  <p>위탁자</p>
                </dt>
                <dd>상품 전달(택배 발송)</dd>
              </dl>
              <dl className="type02">
                <dt>
                  <span>3</span>
                  <p>렌티크</p>
                </dt>
                <dd>감정진단 및 촬영</dd>
              </dl>
              <dl className="type02">
                <dt>
                  <span>4</span>
                  <p>렌티크</p>
                </dt>
                <dd>럭스쉐어 승인 후 위탁 시작</dd>
              </dl>
              <div className="info_area">
                <ul>
                  <li>
                    사용자 <br />
                    결제
                  </li>
                  <li>
                    <span>매월 20일</span>마다 <br />
                    전월 확정 렌트 매출에서 <br />
                    운용비용+수수료 차감 금액
                  </li>
                  <li>정산완료</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="lux_con_wrap04">
          <div className="inner">
            <h3 className="lux_tit">수수료 및 취소 패널티</h3>
            <div className="text_area">
              <div className="table_area table_style01">
                <table className="border-t-gray-c">
                  <colgroup>
                    <col style={{ width: '35%' }} />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th colSpan={2}>수수료 및 취소 패널티</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">위탈 신청 후</td>
                      <td className="text-center">입고 거부 패널티 없음</td>
                    </tr>
                    <tr>
                      <td className="text-center" rowSpan={2}>
                        상품 진단 후
                      </td>
                      <td className="text-center">
                        합격 후 판매포기 패널티 없음
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        불합격(가품) 반품비+검수비 청구
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">판매 중 포기</td>
                      <td className="text-center">
                        계약 이후 거래액의 30% 위약금 청구
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table_area table_style01 mt-4">
                <table className="border-t-gray-c">
                  <colgroup>
                    <col style={{ width: '35%' }} />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th colSpan={2}>
                        위탁신청 시 거래 수수료 및 운용비용 수수료 정책
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">수수료</td>
                      <td className="text-center">9.8%</td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        운영비용 <br />
                        (택배비, 포장 및 <br />
                        부자재비, 정품감정비, <br />
                        인건비 등등)
                      </td>
                      <td className="text-center">20%</td>
                    </tr>
                    <tr>
                      <td className="text-center">정산금</td>
                      <td className="text-center">70.2%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <button
              type="button"
              className="btn btn_style02 mt-8 h-12 w-full rounded-sm text-sm font-bold"
              onClick={() => navigate('/luxshare/apply')}
            >
              지금 바로 위탁 신청하기
            </button>
          </div>
        </div>

        <BottomNav active="LUX" />
      </div>
    </div>
  );
}
export default Page;
