import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAxios, Utils } from '@api/index';
import CartQueryKey from '@api/cart/cart.key';
import CartDto from '@api/cart/cart.dto';

namespace CartApi {
  /**
   * 장바구니 목록
   */
  export function useCartQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [CartQueryKey.CART],
      queryFn() {
        return axios.get<Utils.View.ResponseResult<CartDto.Cart.Response[]>>(
          '/api/users/cart',
        );
      },
    });
  }

  /**
   * 장바구니 담기
   * @param {CartDto.Cart.Request} body - Body
   */
  export function useCreateCartMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<null>>,
      AxiosError<{ message: string }>,
      CartDto.Cart.Request
    >({
      mutationFn(body: CartDto.Cart.Request) {
        return axios.post<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>,
          CartDto.Cart.Request
        >('/api/users/cart', body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [CartQueryKey.CART],
        });
      },
    });
  }

  /**
   * 장바구니 삭제
   * @param {number} cartId - 장바구니 ID
   */
  export function useDeleteCartMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation({
      mutationFn(cartId: number) {
        return axios.delete(`/api/users/cart/${cartId}`);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [CartQueryKey.CART],
        });
      },
    });
  }

  /**
   * 장바구니 결제 확인
   * @param {CartDto.Cart.PaymentCheckRequest} body - Body
   */
  export function useCartPaymentCheckMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<null>>,
      AxiosError<{ message: string }>,
      CartDto.Cart.PaymentCheckRequest
    >({
      mutationFn(body: CartDto.Cart.PaymentCheckRequest) {
        return axios.post<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>,
          CartDto.Cart.PaymentCheckRequest
        >('/api/users/cart-payment-check', body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [CartQueryKey.CART_PAYMENT_CHECK],
        });
      },
    });
  }
}

export default CartApi;
