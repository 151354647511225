import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import LikeBtn from '@components/likeBtn';
import { common, valids } from '@config/index';
// import HomeDto from '@api/home/home.dto';

interface Props {
  // data: HomeDto.Product[];
  data: any[];
  refetch?: () => void;
  options?: {
    spaceBetween?: number;
    slidesPerView?: number;
    rows?: number;
  };
  toastStyle?: number;
}

export default function ProductListSlider({
  data,
  refetch = () => {},
  options,
  toastStyle,
}: Props) {
  return data?.length > 0 ? (
    <div className="list_area list_style01 is_pdx">
      <Swiper
        modules={[Grid]}
        spaceBetween={options?.spaceBetween ?? 16}
        slidesPerView={options?.slidesPerView ?? 2.5}
        grid={{
          rows: options?.rows ?? 1,
          fill: 'row',
        }}
      >
        {data?.map((item, index) => {
          return (
            <SwiperSlide key={item?.id ?? index}>
              <LikeBtn
                id={item?.id}
                likeYn={item?.likeYn}
                refetch={refetch}
                toastBottom={toastStyle}
              />
              <Link to={`/product/detail/${item?.id}`}>
                <div className="img_area rounded-lg">
                  {item?.product_images?.length > 0 ? (
                    <img
                      src={`${common.imageUrl}/${item?.product_images?.[0]?.file?.fileUrl}`}
                      alt="상품 이미지"
                    />
                  ) : (
                    <i className="no_data_img" />
                  )}
                  <span className="tag">렌트</span>
                </div>
                <div className="text_area">
                  <span className="brand">
                    {item?.brand?.nameEnglish.toUpperCase() ?? ''}
                  </span>
                  <p className="product line1_text">
                    {item?.productName ?? ''}
                  </p>
                  <div className="flex items-center justify-start gap-1">
                    {Number(item?.discountRate ?? 0) > 0 && (
                      <span className="price_discount">
                        {`${Number(item?.discountRate ?? 0)}%`}
                      </span>
                    )}
                    <em className="price_desc">일</em>
                    <p className="price_total">
                      {Number(item?.discountRate ?? 0) > 0
                        ? Number(item?.discountPrice ?? 0).toLocaleString()
                        : Number(item?.dailyRentalPrice ?? 0).toLocaleString()}
                    </p>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  ) : (
    <div className="no_data_text">
      <div className="inner">
        <p>{valids.noData}</p>
      </div>
    </div>
  );
}
