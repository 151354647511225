import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TOKEN_STORE_KEY } from '@api/index';

function Page() {
  const [searchParams] = useSearchParams();
  const spName = searchParams.get('name');
  const spAccessToken = searchParams.get('accessToken');
  const spRefreshToken = searchParams.get('refreshToken');
  const spEmail = searchParams.get('email');
  const spProviderType = searchParams.get('providerType');
  const spFail = searchParams.get('fail');

  useEffect(() => {
    // sns 회원가입 되어있음 CASE !!!
    if (spName) {
      const params = {
        name: spName,
        accessToken: spAccessToken,
        refreshToken: spRefreshToken,
      };

      localStorage.setItem(TOKEN_STORE_KEY, JSON.stringify(params));
      window.addEventListener('unload', function () {
        if (window.opener) {
          window.opener.postMessage(params, '*');
        }
      });
    }

    // 중복계정 존재 CASE !!!
    if (spFail) {
      const params = {
        isJoin: true,
        email: spEmail,
        providerType: spProviderType,
      };

      window.addEventListener('unload', function () {
        if (window.opener) {
          window.opener.postMessage(params, '*');
        }
      });
    }
    window.close();
  }, []);

  return null;
}
export default Page;
