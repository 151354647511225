import React, { useEffect, useState } from 'react';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import icFilter from '@assets/images/icon_filter.svg';
import icReset from '@assets/images/icon_reset.svg';
import icDelete from '@assets/images/icon_x_black.svg';
import FilterModal from '@components/modal/filter';
import { useFilter } from '@hooks/useFilter';
import { common, options } from '@config/index';
import BrandDto from '@api/brand/brand.dto';

export type FilterTypes = 'brand' | 'discount' | 'category';
export interface FilterCategoryType {
  // Dto 로 수정 예정
  id: number;
  name: string;
}
export interface FilterDataType {
  brand: BrandDto.SearchBrand.Response[];
  discount: common.ConfigType[];
  category: FilterCategoryType | null;
}
export interface FilterTotalDataType {
  id: number | string;
  name: string;
  type: FilterTypes;
}

interface Props {
  isCategory?: boolean;
}

export default function FilterWrap({ isCategory = false }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { filterData, filterTotalData, setFilterData } = useFilter({
    brand: [],
    discount: [],
    category: null,
  });
  const [init, setInit] = useState(false);
  const [open, setOpen] = useState(false);

  // DELETE
  const handleDeleteSelected = (
    type: FilterTypes,
    id: number | string,
    name: string,
  ) => {
    const sps = new URLSearchParams(location.search);

    if (sps.get(`${type}Id`)) {
      const idArr = searchParams
        .getAll(`${type}Id`)
        .filter((value) => value !== String(id))?.[0];
      if (idArr?.length > 0) sps.set(`${type}Id`, idArr);
      else sps.delete(`${type}Id`);
    }
    if (sps.get(`${type}Name`)) {
      const nameArr = searchParams
        .getAll(`${type}Name`)
        .filter((value) => value !== name)?.[0];
      if (nameArr?.length > 0) sps.set(`${type}Name`, nameArr);
      else sps.delete(`${type}Name`);
    }
    navigate(`${location.pathname}?${sps.toString()}`);
  };

  // 초기화
  useEffect(() => {
    if (!init) return;

    const sps = new URLSearchParams(location.search);
    [
      'brandId',
      'brandName',
      'discountId',
      'categoryId',
      'categoryName',
    ].forEach((param) => sps.delete(param));
    navigate(`${location.pathname}?${sps.toString()}`);
    setInit(false);
  }, [init]);

  useEffect(() => {
    const brands =
      searchParams.getAll('brandId')?.map((id, index) => ({
        id: Number(id),
        nameKorean: searchParams.getAll('brandName')[index] ?? '',
        nameEnglish: searchParams.getAll('brandName')[index] ?? '',
      })) ?? [];

    const discounts = searchParams.getAll('discountId').map((id) => ({
      id,
      text:
        options.discountRates?.filter((item) => item.id === id)?.[0]?.text ??
        '',
    }));

    const categoryId = searchParams.get('categoryId');
    const category = categoryId
      ? {
          id: Number(categoryId),
          name: searchParams.get('categoryName') ?? '',
        }
      : null;

    setFilterData({
      brand: brands,
      discount: discounts,
      category,
    });
  }, [location]);

  return (
    <>
      <div className="filter_area">
        <div className="ft_top">
          <button type="button" onClick={() => setOpen(true)}>
            필터
            <img src={icFilter} alt="필터" />
          </button>
          {filterTotalData?.length > 0 && (
            <button type="button" onClick={() => setInit(true)}>
              초기화
              <img src={icReset} alt="초기화" />
            </button>
          )}
        </div>
        {filterTotalData?.length > 0 && (
          <div className="ft_list ft_style01 pt-4">
            <Swiper spaceBetween={8} slidesPerView="auto">
              {filterTotalData?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="ft_tag">
                      <span>{item.name}</span>
                      <button
                        type="button"
                        onClick={() =>
                          handleDeleteSelected(item.type, item.id, item.name)
                        }
                      >
                        <img src={icDelete} alt="X" />
                      </button>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        )}
      </div>

      <FilterModal
        open={open}
        setOpen={setOpen}
        data={filterData}
        isCategory={isCategory}
      />
    </>
  );
}
