import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { format } from 'date-fns';
import icDownload from '@assets/images/icon_coupon_download02.svg';
import Header from '@components/shared/header';
import BottomNav from '@components/shared/bottomNav';
import CheckIsLogin from '@components/checkIsLogin';
import { common, valids } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import CouponApi from '@api/coupon/coupon.query';
import MypageApi from '@api/mypage/mypage.query';
// import { couponData } from '@temp/couponData';

function Page() {
  const { setLoading, setToastContainerStyle } = useStore((state) => state);
  const [tab, setTab] = useState(0);
  const [data, setData] = useState<any[]>([]);

  // api
  const {
    data: callData,
    isLoading,
    refetch,
  } = MypageApi.useAvailableCouponsQuery();
  const listData = callData?.data?.data?.result;
  const { data: callData2, refetch: refetch2 } =
    MypageApi.useDownloadableCouponsQuery();
  const listData2 = callData2?.data?.data?.result;
  const useCouponDownloadMutation = CouponApi.useCouponDownloadMutation();

  const handleDownload = (cId: number, endDate: string) => {
    if (!cId) return;

    setLoading(true);
    useCouponDownloadMutation.mutate({
      couponId: cId,
      expiresAt: endDate ?? '',
    });
  };

  // ERROR !!!
  useEffect(() => {
    if (!useCouponDownloadMutation.isError) return;
    setLoading(false);
    setToastContainerStyle({ bottom: 78 });
    toast(valids.errored, { style: common.toastStyle });
  }, [useCouponDownloadMutation.isError]);

  // 쿠폰 다운로드 SUCCESS !!!
  useEffect(() => {
    if (!useCouponDownloadMutation.isSuccess) return;
    setLoading(false);
    setToastContainerStyle({ bottom: 78 });
    toast(valids.downloadCoupon, { style: common.toastStyle });
    refetch();
    refetch2();
  }, [useCouponDownloadMutation.isSuccess]);

  useEffect(() => {
    if (tab === 1) setData(listData2 ?? []);
    else setData(listData ?? []);
  }, [tab, listData, listData2]);

  useEffect(() => {
    if (listData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [listData, isLoading]);

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="나의 쿠폰" back="/mypage" searchCart />
            <div className="tab_area03 bg-white">
              <ul>
                <li className={tab === 0 ? 'on' : ''}>
                  <button type="button" onClick={() => setTab(0)}>
                    <b>사용가능 쿠폰</b> {listData?.length ?? 0}
                  </button>
                </li>
                <li className={tab === 1 ? 'on' : ''}>
                  <button type="button" onClick={() => setTab(1)}>
                    <b>다운 가능한 쿠폰</b>
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div className="mypage_coupon_wrap pb-24 pt-4">
            <div className="inner">
              <div className="list_area list_style07">
                <ul>
                  {(data ?? [])?.length > 0 ? (
                    data?.map((item) => {
                      const formatEndDate = format(
                        item.endDate,
                        'yyyy-MM-dd hh:mm',
                      );

                      return (
                        <li key={item.id}>
                          <div className="top_area">
                            <div>
                              <p>
                                {item?.discountType === 'percentage'
                                  ? `${Number(item?.discountValue)}%`
                                  : item?.discountType === 'amount'
                                    ? `${Number(
                                        item?.discountValue,
                                      ).toLocaleString()}원`
                                    : ''}
                              </p>
                              <span>{item?.name ?? ''}</span>
                            </div>
                            {tab === 1 && (
                              <button
                                type="button"
                                onClick={() =>
                                  handleDownload(item.id, formatEndDate)
                                }
                              >
                                <img src={icDownload} alt="download" />
                              </button>
                            )}
                          </div>
                          <div className="btm_area">
                            <p>
                              <b>사용 조건</b>
                              <span>
                                {Number(
                                  item?.minPurchaseAmount ?? 0,
                                ).toLocaleString()}
                                원 이상 구매시
                              </span>
                            </p>
                            <p>
                              <b>유효 기간</b>
                              <span>
                                {format(item?.endDate ?? '', 'yyyy.MM.dd')}{' '}
                                23:59 까지
                              </span>
                            </p>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <li className="no_data_text">
                      <p>{valids.noDataUseCoupon}</p>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <BottomNav active="MYPAGE" />
        </div>
      </div>
    </>
  );
}
export default Page;
