const AuthQueryKey = {
  IDENTITY_VERIFICATION: 'IDENTITY_VERIFICATION',
  JOIN: 'JOIN',
  SNS_JOIN: 'SNS_JOIN',
  EMAIL_DUPLICATE_COHECK: 'EMAIL_DUPLICATE_COHECK',
  FIND_EMAIL: 'FIND_EMAIL',
  VERIFICATION_CODE_SEND: 'VERIFICATION_CODE_SEND',
  VERIFICATION_CODE_CHECK: 'VERIFICATION_CODE_CHECK',
  PASSWORD_RESET: 'PASSWORD_RESET',
};

export default AuthQueryKey;
