import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import Header from '@components/shared/header';
import CustomPassword from '@components/forms/customPassword';
import MsgModal from '@components/modal/msg';
import CheckIsLogin from '@components/checkIsLogin';
import { regex, valids } from '@config/index';
import useStore from '@store/index';
import MypageDto from '@api/mypage/mypage.dto';
import MypageApi from '@api/mypage/mypage.query';

function Page() {
  const navigate = useNavigate();
  const { setLoading, setAlert } = useStore((state) => state);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<MypageDto.MyInfo.PasswordRequest>();
  const [pwConfirm, setPwConfirm] = useState('');
  const [pwConfirmError, setPwConfirmError] = useState('');
  const [msgOpen, setMsgOpen] = useState(false);

  // api
  const useUpdatePasswordMutation = MypageApi.useUpdatePasswordMutation();

  const onFormSubmit: SubmitHandler<MypageDto.MyInfo.PasswordRequest> = (
    data,
  ) => {
    if (data.password !== pwConfirm) {
      setPwConfirmError(valids.incorrectPasswordConfirm);
      return;
    }

    setLoading(true);
    useUpdatePasswordMutation.mutate(data);
  };

  // ERROR !!!
  useEffect(() => {
    if (!useUpdatePasswordMutation.isError) return;
    setAlert(
      useUpdatePasswordMutation.error?.response?.data?.message ??
        valids.errored,
    );
    setLoading(false);
  }, [useUpdatePasswordMutation.isError]);

  // 비밀번호 변경 SUCCESS !!!
  useEffect(() => {
    if (!useUpdatePasswordMutation.isSuccess) return;
    setLoading(false);
    setMsgOpen(true);
  }, [useUpdatePasswordMutation.isSuccess]);

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="비밀번호 변경" back />
          </div>

          <div className="pb-24 pt-4">
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div className="inner">
                <div className="label_input_area">
                  <label htmlFor="password">새로운 비밀번호</label>
                  <CustomPassword
                    id="password"
                    className={`input ${errors.password ? 'error' : ''}`}
                    placeholder={valids.formatPassword}
                    maxLength={16}
                    {...register('password', {
                      required: valids.inputPassword,
                      pattern: {
                        value: regex.regPassword,
                        message: valids.inputPasswordFormat,
                      },
                    })}
                  />
                  {errors.password && (
                    <span className="input_error_text">
                      {errors.password.message}
                    </span>
                  )}
                </div>
                <div className="label_input_area mt-4">
                  <label htmlFor="password">비밀번호 확인</label>
                  <CustomPassword
                    id="password-confirm"
                    className={`input ${pwConfirmError ? 'error' : ''}`}
                    placeholder={valids.inputPasswordConfirm}
                    value={pwConfirm}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setPwConfirm(e.target.value);
                      setPwConfirmError('');
                    }}
                  />
                  {pwConfirmError && (
                    <span className="input_error_text">{pwConfirmError}</span>
                  )}
                </div>

                <div className="fixed_btm_btn">
                  <div className="fixed_btn bg_style01">
                    <button
                      type="submit"
                      className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                      disabled={!(watch('password') && pwConfirm)}
                    >
                      저장
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <MsgModal
            open={msgOpen}
            setOpen={setMsgOpen}
            btns={
              <button
                type="button"
                className="btn btn_style02 h-10 w-full rounded-sm text-sm font-bold"
                onClick={() => navigate('/mypage/user-info', { replace: true })}
              >
                확인
              </button>
            }
          >
            <p className="tit">알림</p>
            <span className="desc">{valids.newPasswordSaved}</span>
          </MsgModal>
        </div>
      </div>
    </>
  );
}
export default Page;
