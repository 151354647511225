import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import icBack from '@assets/images/shared/header/icon_back.svg';
import icCart from '@assets/images/shared/header/icon_cart_off.svg';
import icSearch from '@assets/images/icon_search.svg';
import SearchInit from '@app/search/init';
import SearchKeyword from '@app/search/keyword';
import SearchProducts from '@app/search/products';
import { valids } from '@config/index';
import useStore from '@store/index';
import SearchQueryKey from '@api/search/search.key';

function Page() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const spKeyword = searchParams.get('keyword');
  const { setLoading } = useStore((state) => state);
  const [type, setType] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');

  const saveRecentSearch = (text: string) => {
    const storageData = localStorage.getItem(SearchQueryKey.RECENT_SEARCH_KEY);
    const storageParseData = storageData && JSON.parse(storageData);
    let newData;

    if (storageParseData) {
      const filteredData = storageParseData.filter(
        (item: string) => item !== text,
      );

      if (filteredData.length >= 10) filteredData.pop();

      newData = [text, ...filteredData];
    } else {
      newData = [text];
    }
    localStorage.setItem(
      SearchQueryKey.RECENT_SEARCH_KEY,
      JSON.stringify(newData),
    );
    // console.log(newData);
    navigate(`/search?keyword=${text}`);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    saveRecentSearch(searchInput);
  };

  useEffect(() => {
    setLoading(true);
  }, [type]);

  useEffect(() => {
    if (!spKeyword) {
      if (searchInput) setType(1);
      else setType(0);
    } else {
      setType(2);
    }
  }, [searchInput, spKeyword]);

  useEffect(() => {
    setSearchInput(spKeyword ?? '');
  }, []);

  return (
    <div id="root_layout">
      <div className="root_container">
        <div className="sticky_menu">
          <header id="header">
            <div className="inner">
              <button
                type="button"
                onClick={() => {
                  if (searchInput === '') {
                    navigate(-1);
                  } else {
                    setSearchInput('');
                    navigate('/search');
                  }
                }}
              >
                <img src={icBack} alt="이전 페이지 이동" />
              </button>
              <div className="hd_search">
                <form className="search_area" onSubmit={handleSubmit}>
                  <img src={icSearch} alt="검색" />
                  <input
                    type="text"
                    value={searchInput}
                    onChange={(e) => {
                      setSearchInput(e.target.value);
                      navigate('/search');
                    }}
                    className="input"
                    placeholder={valids.inputProductBrand}
                  />
                  <button type="submit" className="hidden_input">
                    검색
                  </button>
                </form>
                {searchKeyword !== '' && (
                  <button
                    type="button"
                    className="cart_btn on"
                    onClick={() => navigate('/cart')}
                  >
                    <img src={icCart} alt="장바구니 페이지 이동" />
                  </button>
                )}
              </div>
            </div>
          </header>
        </div>

        {type === 0 ? (
          <SearchInit setInput={setSearchInput} saveFunc={saveRecentSearch} />
        ) : type === 1 ? (
          <SearchKeyword
            input={searchInput}
            setInput={setSearchInput}
            setKeyword={setSearchKeyword}
            saveFunc={saveRecentSearch}
          />
        ) : (
          <SearchProducts />
        )}
      </div>
    </div>
  );
}
export default Page;
