import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '@components/shared/header';
import Footer from '@components/shared/footer';
import LikeBtn from '@components/likeBtn';
import { common, valids } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import ProductApi from '@api/product/product.query';
// import { productData } from '@temp/productData';

function Page() {
  const params = useParams();
  const paramsId = params.id;
  const id = Number(paramsId);
  const { setLoading } = useStore((state) => state);

  // api
  const {
    data: callData,
    isLoading,
    refetch,
  } = ProductApi.useProductsSimilarQuery(id);
  const listData = callData?.data?.data?.result;

  useEffect(() => {
    if (paramsId && listData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [paramsId, listData, isLoading]);

  // NO DATA !!!
  if (!paramsId && !listData && !isLoading) {
    alert(valids.noDataPage);
    window.history.back();
  }
  if (!listData) return null;

  return (
    <div id="root_layout">
      <div className="root_container">
        <div className="sticky_menu">
          <Header back title="유사한 상품" />
        </div>

        {/* 리스트 */}
        <div className="pb-10 pt-6">
          <div className="inner">
            <div className="list_area list_style01 grid02">
              <ul>
                {(listData ?? [])?.length > 0 ? (
                  listData?.map((item) => {
                    return (
                      <li key={item.id}>
                        <LikeBtn
                          id={item?.id}
                          likeYn={item?.likeYn}
                          refetch={refetch}
                        />
                        <Link to={`/product/detail/${item.id}`}>
                          <div className="img_wrap">
                            <div className="img_area rounded-lg">
                              {item?.product_images?.length > 0 ? (
                                <img
                                  src={`${common.imageUrl}/${item?.product_images?.[0]?.file?.fileUrl}`}
                                  alt="상품 이미지"
                                />
                              ) : (
                                <i className="no_data_img" />
                              )}
                              <span className="tag">렌트</span>
                            </div>
                          </div>
                          <div className="text_area">
                            <span className="brand">
                              {item?.brand?.nameEnglish.toUpperCase() ?? ''}
                            </span>
                            <p className="product">{item?.productName ?? ''}</p>
                            <div className="flex items-center justify-start gap-1">
                              {Number(item?.discountRate ?? 0) > 0 && (
                                <span className="price_discount">
                                  {`${Number(item?.discountRate ?? 0)}%`}
                                </span>
                              )}
                              <em className="price_desc">일</em>
                              <p className="price_total">
                                {Number(item?.discountRate ?? 0) > 0
                                  ? Number(
                                      item?.discountPrice ?? 0,
                                    ).toLocaleString()
                                  : Number(
                                      item?.dailyRentalPrice ?? 0,
                                    ).toLocaleString()}
                              </p>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })
                ) : (
                  <li className="no_data_text">
                    <p>{valids.noData}</p>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
export default Page;
