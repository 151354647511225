import React from 'react';
import icClose from '@assets/images/icon_modal_x.svg';
import Modal from '@components/modal/modal';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  imgSrc: string;
}

export default function ViewImageModal({ open, setOpen, imgSrc }: Props) {
  return open ? (
    <Modal className="md_large_img">
      <div className="modal_top">
        <p className="md_tit">이미지 크게 보기</p>
        <button type="button" className="x_btn" onClick={() => setOpen(false)}>
          <img src={icClose} alt="모달 닫기" />
        </button>
      </div>
      <div className="modal_mid">
        <img src={imgSrc} alt="이미지" />
      </div>
    </Modal>
  ) : null;
}
