import { StateCreator } from 'zustand';

export interface CommonSliceType {
  loading: boolean;
  setLoading: (loading: boolean) => void;

  alert: string;
  setAlert: (alert: string) => void;

  toastContainerStyle: React.CSSProperties;
  setToastContainerStyle: (toastContainerStyle: React.CSSProperties) => void;
}

export const createCommonSlice: StateCreator<CommonSliceType> = (set) => ({
  loading: false,
  setLoading: (loading) => set({ loading }),

  alert: '',
  setAlert: (alert) => set({ alert }),

  toastContainerStyle: {},
  setToastContainerStyle: (toastContainerStyle) => set({ toastContainerStyle }),
});
