import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAxios, Utils } from '@api/index';
import FavoriteQueryKey from '@api/favorite/favorite.key';
import FavoriteDto from '@api/favorite/favorite.dto';
import CommonDto from '@api/common.dto';

namespace FavoriteApi {
  /**
   * 찜한 상품 목록
   */
  export function useFavoriteQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [FavoriteQueryKey.FAVORITE],
      queryFn() {
        return axios.get<Utils.View.ResponseResult<CommonDto.Product[]>>(
          '/api/users/favorite',
        );
      },
    });
  }

  /**
   * 찜하기
   * @param {FavoriteDto.Favorite.Request} body - Body
   */
  export function useFavoriteMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<null>>,
      AxiosError<{ message: string }>,
      FavoriteDto.Favorite.Request
    >({
      mutationFn(body: FavoriteDto.Favorite.Request) {
        return axios.post<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>,
          FavoriteDto.Favorite.Request
        >('/api/users/favorite', body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [FavoriteQueryKey.FAVORITE],
        });
      },
    });
  }

  /**
   * 찜 삭제
   * @param {number} productId - 상품 ID
   */
  export function useDeleteFavoriteMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation({
      mutationFn(productId: number) {
        return axios.delete(`/api/users/favorite/${productId}`);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [FavoriteQueryKey.FAVORITE],
        });
      },
    });
  }
}

export default FavoriteApi;
