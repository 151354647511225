import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Header from '@components/shared/header';
import BottomNav from '@components/shared/bottomNav';
import ToggleItem from '@components/toggleItem';
import CheckIsLogin from '@components/checkIsLogin';
import { valids } from '@config/index';
import { formatYyyyM, sleep } from '@utils/common';
import useStore from '@store/index';
import ConsignmentDto from '@api/consignment/consignment.dto';
import ConsignmentApi from '@api/consignment/consignment.query';

function Page() {
  const params = useParams();
  const paramsId = params.id;
  const id = Number(paramsId);
  const [searchParams] = useSearchParams();
  const spNum = searchParams.get('num');
  const { setLoading } = useStore((state) => state);
  const [listData, setListData] = useState<
    ConsignmentDto.Settlement.DetailResponse[]
  >([]);
  const [isListCount, setIsListCount] = useState(0);

  // api
  const { data: callData, isLoading } =
    ConsignmentApi.useSettlementDetailQuery(id);
  const resultData = callData?.data?.data?.result;

  // 더보기 버튼
  const handleMoreList = () => {
    let newData;
    if (resultData && resultData?.length > isListCount + 3)
      newData = resultData?.slice(0, isListCount + 3);
    else newData = resultData ?? [];
    setListData(newData);
    setIsListCount(newData.length);
  };

  useEffect(() => {
    if (paramsId && resultData !== undefined && !isLoading) {
      handleMoreList();

      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [resultData, isLoading]);

  // NO DATA !!!
  if (!resultData && !isLoading) {
    alert(valids.noDataPage);
    window.history.back();
  }
  if (!resultData) return null;

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="정산 정보 상세" back />
          </div>

          <div className="mypage_settlement_detail_wrap pb-24 pt-4">
            <div className="inner">
              <div className="border-b border-b-gray-c pb-2">
                <p className="text-xs font-medium text-gray-9">
                  위탁번호 {spNum ?? '-'}
                </p>
              </div>

              {listData?.length > 0 ? (
                listData.map((item, index) => {
                  return (
                    <ToggleItem
                      key={index}
                      title={`${formatYyyyM(item?.yymonth ?? '')} 정산 내역`}
                    >
                      <div className="flex flex-col gap-2 p-4">
                        <dl className="text_list_area">
                          <dt>일 렌트 확정가</dt>
                          <dd>
                            {Number(
                              item?.assessedRentalPrice ?? 0,
                            ).toLocaleString()}
                            원
                          </dd>
                        </dl>
                        <dl className="text_list_area">
                          <dt>거래수수료+운용비용</dt>
                          <dd>{item?.transactionFee ?? 0}%</dd>
                        </dl>
                        <dl className="text_list_area">
                          <dt>렌트 이용일</dt>
                          <dd>{item?.useDate ?? 0}일</dd>
                        </dl>
                      </div>
                      <dl className="text_list_area bg-gray-f4 px-4 py-3.5">
                        <dt>
                          <p className="font-bold text-black">총 렌트 금액</p>
                        </dt>
                        <dd>
                          <p className="font-bold text-black">
                            {Number(item?.totalRentPrice ?? 0).toLocaleString()}
                          </p>
                        </dd>
                      </dl>
                      <div className="flex flex-col gap-2 p-4">
                        <dl className="text_list_area">
                          <dt>쿠폰사용금</dt>
                          <dd>
                            {Number(item?.couponDiscount ?? 0).toLocaleString()}
                            원
                          </dd>
                        </dl>
                        <dl className="text_list_area">
                          <dt>실 결제금액</dt>
                          <dd>
                            {Number(item?.finalAmount ?? 0).toLocaleString()}원
                          </dd>
                        </dl>
                      </div>
                      <dl className="text_list_area rounded-b-sm bg-main-02 px-4 py-3.5">
                        <dt>
                          <p className="font-bold text-main">
                            {item?.settlementStatus === 'pending'
                              ? '정산예정'
                              : item?.settlementStatus === 'completed'
                                ? '정산완료'
                                : '-'}
                          </p>
                        </dt>
                        <dd>
                          <p className="font-bold text-black">
                            {item?.expectedSettlementAmount
                              ? Number(
                                  item?.expectedSettlementAmount ?? 0,
                                ).toLocaleString()
                              : '-'}
                          </p>
                        </dd>
                      </dl>
                    </ToggleItem>
                  );
                })
              ) : (
                <div className="no_data_text py-4 text-center">
                  <p>{valids.noDataSettlementDetail}</p>
                </div>
              )}

              {resultData?.length > isListCount && (
                <button
                  type="button"
                  className="btn btn_style01 mt-4 h-10 w-full rounded-sm text-sm font-medium"
                  onClick={handleMoreList}
                >
                  더보기
                </button>
              )}
            </div>
          </div>

          <BottomNav active="MYPAGE" />
        </div>
      </div>
    </>
  );
}
export default Page;
