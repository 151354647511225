import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import icCheck from '@assets/images/deco/icon_check.svg';
import Header from '@components/shared/header';
import CheckIsLogin from '@components/checkIsLogin';
import { common } from '@config/index';
import CommonDto from '@api/common.dto';

function Page() {
  const [data, setData] = useState<CommonDto.Product | null>(null);

  const onStorageDelete = () =>
    sessionStorage.removeItem(common.CANCEL_DATA_KEY);
  useEffect(() => {
    const storageData = sessionStorage.getItem(common.CANCEL_DATA_KEY);
    if (storageData) setData(JSON.parse(storageData));
    else setData(null);
  }, []);

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="취소 완료" />
          </div>

          <div className="mypage_cancel_wrap">
            <div className="inner">
              <div>
                <div className="text-center">
                  <img src={icCheck} alt="check" className="mx-auto" />
                  <h3 className="mt-8 text-base font-bold text-black">
                    렌트 취소가 완료되었습니다!
                  </h3>
                </div>
                {data && (
                  <div className="item_area mt-8 items-center border border-gray-c p-2">
                    <div className="img_wrap w-25">
                      <div className="img_area">
                        {data?.product_images?.length > 0 ? (
                          <img
                            src={`${common.imageUrl}/${data?.product_images?.[0]?.file?.fileUrl}`}
                            alt="상품 이미지"
                          />
                        ) : (
                          <i className="no_data_img" />
                        )}
                      </div>
                    </div>
                    <div className="text_area">
                      <p className="brand">
                        {data?.brand?.nameEnglish.toUpperCase() ?? ''}
                      </p>
                      <p className="product">{data?.productName ?? ''}</p>
                    </div>
                  </div>
                )}
                <div className="mt-8 rounded-sm bg-main-02 p-4">
                  <p className="text-xs font-bold text-black">환불 안내</p>
                  <span className="block text-xs text-gray-3">
                    카드사의 사정에 따라 2-3일 영업일이 소요될 수 있습니다.
                  </span>
                </div>
              </div>
              <div className="fixed_btm_btn">
                <div className="fixed_btn p-4">
                  <Link
                    to="/mypage/rent"
                    className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                    onClick={onStorageDelete}
                  >
                    확인
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Page;
