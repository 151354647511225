import React from 'react';
import { Link } from 'react-router-dom';
import Header from '@components/shared/header';
import CheckIsLogin from '@components/checkIsLogin';
// import PassAuthWrap, { AuthType } from '@components/passAuthWrap';
// import { common } from '@config/index';

function Page() {
  // const navigate = useNavigate();
  // const [authData, setAuthData] = useState<AuthType | null>(null);

  // 본인인증 DATA
  // useEffect(() => {
  //   if (!(authData && authData?.phoneNumber)) return;
  //   sessionStorage.setItem(
  //     common.VERIFICATION_DATA_KEY,
  //     JSON.stringify({ phoneNumber: authData.phoneNumber }),
  //   );
  //   navigate('/reset-password');
  // }, [authData]);

  return (
    <>
      <CheckIsLogin loginCheck />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="비밀번호 찾기" back="/login" />
          </div>

          <div className="findpw_page_wrap auth_wrap">
            <div className="inner">
              <p className="desc_text">
                개인정보보호를 위해 본인확인이 필요합니다. <br />
                아래 방식으로 진행해 주세요.
              </p>

              <ul>
                <li>
                  <Link to="/find-password/email">
                    <div>
                      <p>이메일 인증</p>
                      <span>회원가입 시 입력한 이메일 아이디로 인증</span>
                    </div>
                  </Link>
                </li>
                {/* <li>
                  <PassAuthWrap setData={setAuthData}>
                    <button type="button">
                      <div>
                        <p>본인 인증</p>
                        <span>본인 휴대폰 번호로 인증</span>
                      </div>
                    </button>
                  </PassAuthWrap>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Page;
