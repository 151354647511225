import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import icClose from '@assets/images/icon_modal_x.svg';
import Modal from '@components/modal/modal';
import CustomSelect from '@components/forms/customSelect';
import { common, options, valids } from '@config/index';
import useStore from '@store/index';
import ConsignmentApi from '@api/consignment/consignment.query';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id?: number;
  refetch?: () => void;
}

export default function AddTrackingModal({
  open,
  setOpen,
  id,
  refetch,
}: Props) {
  const { setLoading, setAlert, setToastContainerStyle } = useStore(
    (state) => state,
  );
  const [shippingCompany, setShippingCompany] = useState('empty');
  const [trackingNumber, setTrackingNumber] = useState('');

  // api
  const useTrackingNumberMutation = ConsignmentApi.useTrackingNumberMutation();

  const onFormSubmit = () => {
    if (!id) return;

    setLoading(true);
    const formData = {
      consignmentId: id,
      shippingCompany,
      trackingNumber,
    };
    // console.log(formData);
    useTrackingNumberMutation.mutate(formData);
  };

  // ERROR !!!
  useEffect(() => {
    if (!useTrackingNumberMutation.isError) return;
    setAlert(
      useTrackingNumberMutation.error?.response?.data?.message ??
        valids.errored,
    );
    setLoading(false);
  }, [useTrackingNumberMutation.isError]);

  // 송장번호 입력 SUCCESS !!!
  useEffect(() => {
    if (!useTrackingNumberMutation.isSuccess) return;
    setOpen(false);
    setToastContainerStyle({ bottom: 78 });
    toast(valids.trackingNumberSaved, { style: common.toastStyle });
    setLoading(false);
    refetch?.();
  }, [useTrackingNumberMutation.isSuccess]);

  useEffect(() => {
    if (open) return;

    setShippingCompany('empty');
    setTrackingNumber('');
  }, [open, id]);

  return open ? (
    <Modal className="small">
      <div className="modal_top">
        <p className="md_tit">송장번호 입력</p>
        <button type="button" className="x_btn" onClick={() => setOpen(false)}>
          <img src={icClose} alt="모달 닫기" />
        </button>
      </div>
      <div className="modal_mid px-4 pb-2 text-center">
        <span className="block text-xs font-medium text-black">
          발송하신 상품의 송장번호를 입력해주세요.
        </span>
        <div className="mt-4 flex flex-col gap-2">
          <CustomSelect
            type={2}
            options={options.deliveryCompanys}
            value={shippingCompany}
            setValue={setShippingCompany}
            placeholder="택배사 선택"
          />
          <input
            type="text"
            className="input"
            value={trackingNumber}
            onChange={(e) => setTrackingNumber(e.target.value)}
            placeholder={valids.inputTrackingNumber}
          />
        </div>
      </div>
      <div className="modal_btm mt-4 px-4 pb-4">
        <button
          type="button"
          className="btn btn_style02 h-12 w-full rounded-sm text-sm"
          disabled={shippingCompany === 'empty' || !trackingNumber}
          onClick={onFormSubmit}
        >
          저장
        </button>
      </div>
    </Modal>
  ) : null;
}
