import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import icClose from '@assets/images/icon_modal_x.svg';
import icMinus from '@assets/images/deco/icon_minus.svg';
import Modal from '@components/modal/modal';
import MsgModal from '@components/modal/msg';
import { common, options, valids } from '@config/index';
import useStore from '@store/index';
import PaymentApi from '@api/payment/payment.query';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id?: number;
}

export default function CancelReasonModal({ open, setOpen, id }: Props) {
  const navigate = useNavigate();
  const { setLoading, setAlert } = useStore((state) => state);
  const [selected, setSelected] = useState('');
  const [selectedEtc, setSelectedEtc] = useState('');
  const [msgOpen, setMsgOpen] = useState(false);

  // api
  const useOrderItemCancelMutation = PaymentApi.useOrderItemCancelMutation();

  const onFormSubmit = () => {
    if (!id) return;

    setLoading(true);
    useOrderItemCancelMutation.mutate({
      orderItemId: id,
      cancellationReason: selected === 'etc' ? selectedEtc : selected,
    });
  };

  // ERROR !!!
  useEffect(() => {
    if (!useOrderItemCancelMutation.isError) return;
    setAlert(
      useOrderItemCancelMutation.error?.response?.data?.message ??
        valids.errored,
    );
    setLoading(false);
  }, [useOrderItemCancelMutation.isError]);

  // 주문 취소 SUCCESS !!!
  useEffect(() => {
    if (!useOrderItemCancelMutation.isSuccess) return;
    const resultData = useOrderItemCancelMutation?.data?.data?.data;
    if (resultData)
      sessionStorage.setItem(
        common.CANCEL_DATA_KEY,
        JSON.stringify(resultData),
      );
    navigate('/mypage/rent/cancel-complete');
  }, [useOrderItemCancelMutation.isSuccess]);

  useEffect(() => {
    if (!open) return;

    setSelected('');
    setSelectedEtc('');
  }, [open, id]);

  return (
    <>
      {open ? (
        <Modal>
          <div className="modal_top">
            <p className="md_tit">취소 사유</p>
            <button
              type="button"
              className="x_btn"
              onClick={() => setOpen(false)}
            >
              <img src={icClose} alt="모달 닫기" />
            </button>
          </div>
          <div className="modal_mid p-4 pb-2">
            <div className="text-center">
              <p className="text-sm font-bold text-black">
                어떤 점이 마음에 들지 않으셨나요?
              </p>
              <span className="mt-2 block text-xs font-medium text-black">
                보다 나은 서비스를 제공하기 위해 <br />
                고객님의 소중한 말씀을 듣고 싶습니다.
              </span>
            </div>
            <div className="input_list_area grid01 mt-4">
              <ul className="gap-y-2">
                {options.cancelReasons.map((item) => {
                  return (
                    <li key={item.id}>
                      <div className="flex items-center justify-between">
                        <div className="chk_input">
                          <input
                            type="radio"
                            name="reason"
                            onChange={() => setSelected(item.id)}
                            checked={selected === item.id}
                            id={`reason_0${item.id}`}
                          />
                          <label htmlFor={`reason_0${item.id}`}>
                            {item.text}
                          </label>
                        </div>
                      </div>
                      {item.id === 'etc' && selected === 'etc' && (
                        <div className="pl-5">
                          <input
                            type="text"
                            className="input"
                            placeholder={valids.inputCancelReason}
                            value={selectedEtc}
                            onChange={(e) => setSelectedEtc(e.target.value)}
                          />
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            <span className="mt-4 block text-center text-2xs font-medium text-gray-9">
              선택해주신 소중한 정보를 바탕으로 좋은 서비스로 찾아뵙겠습니다.
            </span>
          </div>
          <div className="modal_btm px-4 pb-4">
            <button
              type="button"
              className="btn btn_style02 h-12 w-full rounded-sm text-sm"
              disabled={!selected || (selected === 'etc' && !selectedEtc)}
              onClick={() => setMsgOpen(true)}
            >
              제출하기
            </button>
          </div>
        </Modal>
      ) : null}

      <MsgModal
        open={msgOpen}
        setOpen={setMsgOpen}
        btns={
          <>
            <button
              type="button"
              className="btn btn_style03 h-10 rounded-sm text-sm font-bold"
              onClick={() => setMsgOpen(false)}
            >
              아니요
            </button>
            <button
              type="button"
              className="btn btn_style02 h-10 rounded-sm text-sm font-bold"
              onClick={onFormSubmit}
            >
              확인
            </button>
          </>
        }
      >
        <img src={icMinus} alt="minus" className="mx-auto" />
        <p className="mt-4 text-sm font-bold text-black">
          정말 취소하시겠습니까?
        </p>
        <span className="mt-2 block text-xs font-medium text-black">
          확인 클릭 시 취소됩니다.
        </span>
      </MsgModal>
    </>
  );
}
