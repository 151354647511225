const ConsignmentQueryKey = {
  CONSIGNMENT: 'CONSIGNMENT',
  CONSIGNMENT_HISTORY: 'CONSIGNMENT_HISTORY',
  CONSIGNMENT_DETAIL: 'CONSIGNMENT_DETAIL',
  TRACKING_NUMBER: 'TRACKING_NUMBER',
  SETTLEMENT: 'SETTLEMENT',
  SETTLEMENT_DETAIL: 'SETTLEMENT_DETAIL',
};

export default ConsignmentQueryKey;
