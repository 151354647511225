const PaymentQueryKey = {
  RENTING_PRODUCT: 'RENTING_PRODUCT',
  ORDER_HISTORY: 'ORDER_HISTORY',
  ORDER_DETAIL: 'ORDER_DETAIL',
  ORDER_CANCEL: 'ORDER_CANCEL',
  ORDER_RETURN: 'ORDER_RETURN',
  PRODUCT_INQUIRY: 'PRODUCT_INQUIRY',
  PAYMENT_CARD: 'PAYMENT_CARD',
  PAYMENT_HISTORY: 'PAYMENT_HISTORY',
};

export default PaymentQueryKey;
