import React, { useEffect } from 'react';
import { highlightSearchKeyword, sleep } from '@utils/common';
import useStore from '@store/index';
import SearchApi from '@api/search/search.query';

interface Props {
  input: string;
  setInput: React.Dispatch<React.SetStateAction<string>>;
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
  saveFunc: (text: string) => void;
}

// const searchingTexts = [
//   { text: '셀린느', html: '<span>셀린느</span>' },
//   { text: '셀린느 가방', html: '<span>셀린느</span> 가방' },
//   { text: '셀린느 벨트', html: '<span>셀린느</span> 벨트' },
//   { text: '셀린느 모자', html: '<span>셀린느</span> 모자' },
//   { text: '셀린느 선글라스', html: '<span>셀린느</span> 선글라스' },
//   { text: '셀린느 파우치', html: '<span>셀린느</span> 파우치' },
//   { text: '셀린느 핸드백', html: '<span>셀린느</span> 핸드백' },
//   { text: '셀린느 아바', html: '<span>셀린느</span> 아바' },
//   { text: '셀린느 키링', html: '<span>셀린느</span> 키링' },
// ];

export default function SearchKeyword({
  input,
  setInput,
  setKeyword,
  saveFunc,
}: Props) {
  const { setLoading } = useStore((state) => state);

  // api
  const { data: callData, isLoading } = SearchApi.useSearchQuery({
    productName: input,
  });
  const resultData = callData?.data?.data?.result ?? [];

  useEffect(() => {
    if (resultData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    }
  }, [resultData, isLoading]);

  return (
    <div className="inner pb-10 pt-4">
      <div className="search_text_area">
        {highlightSearchKeyword(resultData, input)?.map((item, index) => {
          return (
            <button
              key={index}
              type="button"
              aria-label="검색어"
              onClick={() => {
                setInput(item.productName);
                setKeyword(item.productName);
                saveFunc(item.productName);
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: item.html }} />
            </button>
          );
        })}
      </div>
    </div>
  );
}
