export const deliveryData = [
  {
    id: 1,
    name: '홍길동(회사)',
    phone: '010-1234-5678',
    zone_number: '12345',
    address: '서울특별시 구로구 디지털로 32길 30',
    address_detail: '코오롱디티털타워빌란트 1510호',
    default: true,
  },
  {
    id: 2,
    name: '홍길동',
    phone: '010-1234-5678',
    zone_number: '12345',
    address: '서울특별시 구로구 디지털로 32길 30',
    address_detail: '코오롱디티털타워빌란트 1510호',
    default: false,
  },
  {
    id: 3,
    name: '이름을 너무 길게 설정하면 말줄임 처리하여 보여줍니다',
    phone: '010-1234-5678',
    zone_number: '12345',
    address: '서울특별시 구로구 디지털로 32길 30',
    address_detail: '코오롱디티털타워빌란트 1510호',
    default: false,
  },
];
