import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAxios, Utils } from '@api/index';
import AccountQueryKey from '@api/account/account.key';
import AccountDto from '@api/account/account.dto';

namespace AccountApi {
  /**
   * 은행 조회
   */
  export function useBankInfoQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [AccountQueryKey.BANK],
      queryFn() {
        return axios.get<Utils.View.ResponseResult<AccountDto.Bank[]>>(
          '/api/users/bank-info',
        );
      },
    });
  }

  /**
   * 계좌 조회
   */
  export function useAccountInfoQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [AccountQueryKey.ACCOUNT],
      queryFn() {
        return axios.get<Utils.View.Response<AccountDto.Account>>(
          '/api/users/account-info',
        );
      },
    });
  }

  /**
   * 계좌 등록
   * @param {AccountDto.Account} body - Body
   */
  export function useUpdateAccountInfoMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<null>>,
      AxiosError<{ message: string }>,
      AccountDto.Account
    >({
      mutationFn(body: AccountDto.Account) {
        return axios.put<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>,
          AccountDto.Account
        >('/api/users/account-info', body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [AccountQueryKey.ACCOUNT],
        });
      },
    });
  }

  /**
   * 계좌 인증
   * @param {AccountDto.Account} body - Body
   */
  export function useAccountVerifyMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<null>>,
      AxiosError<{ message: string }>,
      AccountDto.Account
    >({
      mutationFn(body: AccountDto.Account) {
        return axios.post<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>,
          AccountDto.Account
        >('/api/users/account-verify', body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [AccountQueryKey.ACCOUNT_VERIFY],
        });
      },
    });
  }
}

export default AccountApi;
