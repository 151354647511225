import React, { useEffect, useState } from 'react';
import icPrev from '@assets/images/icon_page_prev.svg';
import icNext from '@assets/images/icon_page_next.svg';

interface Props {
  total: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  limit?: number; // 리스트 갯수 제한
  size?: number; // 페이지 갯수 제한
}

export default function Pagination({
  total,
  page,
  setPage,
  limit = 5,
  size = 5,
}: Props) {
  const [prevDisabled, setPrevDisabled] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(false);

  const totalPage = Math.ceil(total / limit);
  const startPage = Math.floor((page - 1) / size) * size + 1;
  let endPage = startPage + size - 1;
  if (endPage > totalPage) endPage = totalPage;

  const pageListData = [];
  for (let i = startPage; i <= endPage; i += 1) {
    pageListData.push(i);
  }

  useEffect(() => {
    if (page === 1) {
      setPrevDisabled(true);
    } else {
      setPrevDisabled(false);
    }
    if (page === totalPage) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, [page, totalPage]);

  if (total <= 0) return null;

  return (
    <div className="page_area">
      <button
        type="button"
        disabled={prevDisabled}
        onClick={() => setPage((state) => state - 1)}
      >
        <img src={icPrev} alt="이전 페이지" />
      </button>
      {pageListData?.map((item) => (
        <button
          key={item}
          type="button"
          className={page === item ? 'on' : ''}
          onClick={() => setPage(item)}
        >
          {item}
        </button>
      ))}
      <button
        type="button"
        disabled={nextDisabled}
        onClick={() => setPage((state) => state + 1)}
      >
        <img src={icNext} alt="다음 페이지" />
      </button>
    </div>
  );
}
