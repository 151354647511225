import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format, addMonths } from 'date-fns';
import imgRent from '@assets/images/rent_img.png';
import Header from '@components/shared/header';
import RentCalendar from '@app/product/rent/calendar';
import { valids } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import ProductApi from '@api/product/product.query';
import PaymentApi from '@api/payment/payment.query';

function Page() {
  const params = useParams();
  const paramsId = params.id;
  const id = Number(paramsId);
  const { setLoading } = useStore((state) => state);
  const [rentingData, setRentingData] = useState<string[]>([]);

  // api
  const { data: callData, isLoading } = ProductApi.useProductsDetailQuery(id);
  const resultData = callData?.data?.data;
  const rentingData1 = PaymentApi.useRentingProductQuery(
    new Date()
      ? {
          productId: id,
          filterDate: format(new Date(), 'yyyy-MM'),
        }
      : undefined,
  )?.data?.data?.data?.result;
  const rentingData2 = PaymentApi.useRentingProductQuery(
    new Date()
      ? {
          productId: id,
          filterDate: format(addMonths(new Date(), 1), 'yyyy-MM'),
        }
      : undefined,
  )?.data?.data?.data?.result;
  const rentingData3 = PaymentApi.useRentingProductQuery(
    new Date()
      ? {
          productId: id,
          filterDate: format(addMonths(new Date(), 2), 'yyyy-MM'),
        }
      : undefined,
  )?.data?.data?.data?.result;
  // const rentingData3 = [
  //   { rentalStartDate: '2024-10-22', rentalEndDate: '2024-10-25' },
  //   { rentalStartDate: '2024-10-10', rentalEndDate: '2024-10-30' },
  //   { rentalStartDate: '2024-10-10', rentalEndDate: '2024-11-10' },
  //   { rentalStartDate: '2024-09-10', rentalEndDate: '2024-10-10' },
  //   { rentalStartDate: '2024-11-10', rentalEndDate: '2024-11-15' },
  // ];

  useEffect(() => {
    if (
      rentingData1 !== undefined &&
      rentingData2 !== undefined &&
      rentingData3 !== undefined
    ) {
      const allData = [
        ...(rentingData1 ?? []),
        ...(rentingData2 ?? []),
        ...(rentingData3 ?? []),
      ];
      const allDates = new Set<string>();

      allData.forEach(({ rentalStartDate, rentalEndDate }) => {
        const currentDate = new Date(rentalStartDate);
        const end = new Date(rentalEndDate);

        while (currentDate <= end) {
          allDates.add(format(currentDate, 'yyyy-MM-dd')); // YYYY-MM-DD 형식으로 추가
          currentDate.setDate(currentDate.getDate() + 1); // 다음 날짜로 이동
        }
      });

      const uniqueDates = Array.from(allDates).sort(
        (a, b) => new Date(a).getTime() - new Date(b).getTime(),
      );

      // console.log(uniqueDates);
      setRentingData(uniqueDates);
    } else {
      setRentingData([]);
    }
  }, [rentingData1, rentingData2, rentingData3]);

  useEffect(() => {
    // console.log(resultData);
    if (paramsId && resultData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [paramsId, resultData, isLoading]);

  // NO DATA !!!
  if (!paramsId && !resultData && !isLoading) {
    alert(valids.noDataPage);
    window.history.back();
  }
  if (!resultData) return null;

  return (
    <div id="root_layout">
      <div className="root_container">
        <div className="sticky_menu">
          <Header back title="렌트하기" />
        </div>

        <div className="prod_rent_wrap pb-10 pt-4">
          <div className="inner">
            <RentCalendar data={resultData} rentingData={rentingData} />

            <div className="info_area mt-6">
              <p>렌트 가격 추가할인 꿀팁!</p>
              <span>기간이 길어질수록 렌트 가격이 할인됩니다.</span>
              <img
                src={imgRent}
                alt="렌트 가격 추가할인 정보"
                className="mt-2.5"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page;
