import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import icCart from '@assets/images/deco/icon_cart.svg';
import icDelete from '@assets/images/icon_x_gray.svg';
import Header from '@components/shared/header';
import MsgModal from '@components/modal/msg';
import CheckIsLogin from '@components/checkIsLogin';
import { common, valids } from '@config/index';
import { formatMmddd, sleep } from '@utils/common';
import useStore from '@store/index';
import CartApi from '@api/cart/cart.query';
// import { productData } from '@temp/productData';

type CheckedTypes = {
  id: number;
  price: number;
  startDate: string;
  endDate: string;
};

function Page() {
  const navigate = useNavigate();
  const { setLoading, setToastContainerStyle } = useStore((state) => state);
  const [checked, setChecked] = useState<CheckedTypes[]>([]);
  const [checkedPrice, setCheckedPrice] = useState(0);
  const [isSameDate, setIsSameDate] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgContents, setMsgContents] = useState<
    JSX.Element | JSX.Element[] | null
  >(null);
  const [msgBtns, setMsgBtns] = useState<JSX.Element | JSX.Element[] | null>(
    null,
  );

  // api
  const { data: callData, isLoading } = CartApi.useCartQuery();
  const resultData = callData?.data?.data?.result;
  const useDeleteCartMutation = CartApi.useDeleteCartMutation();
  const useCartPaymentCheckMutation = CartApi.useCartPaymentCheckMutation();

  const handleCheckClick = (thisData: CheckedTypes) => {
    if (!(checked && setChecked)) return;

    const index = checked.findIndex((item) => item.id === thisData.id);
    let newChecked = checked;

    if (index !== -1)
      newChecked = checked.filter((item) => item.id !== thisData.id);
    else newChecked = [...checked, thisData];

    const totalPrice = newChecked.reduce((acc, item) => acc + item.price, 0);
    const checkSameDate =
      newChecked.length > 0
        ? newChecked.every(
            (item) =>
              item.startDate === newChecked[0].startDate &&
              item.endDate === newChecked[0].endDate,
          )
        : false;

    setChecked(newChecked);
    setCheckedPrice(totalPrice);
    setIsSameDate(checkSameDate);
  };

  const handleDeleteClick = (id?: number) => {
    if (!id) return;

    setLoading(true);
    setMsgOpen(false);
    useDeleteCartMutation.mutate(id);
  };

  const handleModalOpen = (btn: string, id?: number) => {
    switch (btn) {
      case '장바구니 삭제':
        setMsgContents(
          <>
            <p className="tit">장바구니 상품 삭제</p>
            <span className="desc">
              선택한 상품을 장바구니에서 <br />
              삭제하시겠습니까?
            </span>
          </>,
        );
        setMsgBtns(
          <>
            <button
              type="button"
              className="btn btn_style03 h-10 w-full rounded-sm text-sm font-bold"
              onClick={() => setMsgOpen(false)}
            >
              취소
            </button>
            <button
              type="button"
              className="btn btn_style02 h-10 w-full rounded-sm text-sm font-bold"
              onClick={() => handleDeleteClick(id)}
            >
              확인
            </button>
          </>,
        );
        setMsgOpen(true);
        break;
      case '주문불가 안내':
        setToastContainerStyle({ bottom: 84 });
        toast(valids.checkPeriod, { style: common.toastStyle });
        break;
      case '주문불가 안내2':
        setToastContainerStyle({ bottom: 84 });
        toast(valids.alreadyRent, {
          style: common.toastStyle,
        });
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    if (checked.length <= 0) return;

    setLoading(true);
    useCartPaymentCheckMutation.mutate({
      cartId: checked.map((item) => item.id),
    });
  };

  // ERROR !!!
  useEffect(() => {
    if (!useCartPaymentCheckMutation.isError) return;
    setLoading(false);
    setToastContainerStyle({ bottom: 84 });
    toast(
      useCartPaymentCheckMutation.error?.response?.data?.message ??
        valids.errored,
      { style: common.toastStyle },
    );
  }, [useCartPaymentCheckMutation.isError]);

  // 장바구니 삭제 SUCCESS !!!
  useEffect(() => {
    if (!useDeleteCartMutation.isSuccess) return;
    setLoading(false);
    setToastContainerStyle({ bottom: 84 });
    toast(valids.deleted, { style: common.toastStyle });
  }, [useDeleteCartMutation.isSuccess]);

  // 장바구니 결제 확인 SUCCESS !!!
  useEffect(() => {
    if (!useCartPaymentCheckMutation.isSuccess) return;
    setLoading(false);
    navigate('/product/rent/1'); // TODO : 렌트 진행시 ID 값 추가
  }, [useCartPaymentCheckMutation.isSuccess]);

  useEffect(() => {
    if (resultData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [resultData, isLoading]);

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="장바구니" back home />
          </div>

          {(resultData ?? [])?.length > 0 ? (
            <div className="cart_page_wrap pt-4">
              <div className="inner">
                {resultData?.map((item, index) => {
                  return item?.cart?.length > 0 ? (
                    <div key={index}>
                      <div className="flex items-center justify-between gap-1 pb-3">
                        <p className="text-sm font-bold text-main">
                          {formatMmddd(item?.rentalStartDate ?? '')} -{' '}
                          {formatMmddd(item?.rentalEndDate ?? '')}
                        </p>
                        {item?.cart?.length > 1 && (
                          <span className="block text-xs font-medium text-gray-9">
                            개별 상품 주문도 가능합니다.
                          </span>
                        )}
                      </div>
                      <div className="list_area list_style05">
                        <ul>
                          {item?.cart?.map((item2, index2) => {
                            return (
                              <li
                                key={index2}
                                className={
                                  checked?.findIndex(
                                    (el) => el.id === item2?.id,
                                  ) !== -1
                                    ? 'on'
                                    : ''
                                }
                              >
                                <button
                                  type="button"
                                  className="x_btn"
                                  onClick={() =>
                                    handleModalOpen('장바구니 삭제', item2?.id)
                                  }
                                >
                                  <img src={icDelete} alt="삭제" />
                                </button>
                                <button
                                  type="button"
                                  className={`img_wrap ${
                                    item2?.reservationYn ? 'cursor-default' : ''
                                  }`}
                                  onClick={() => {
                                    const price =
                                      Number(
                                        item2?.product?.discountRate ?? 0,
                                      ) > 0
                                        ? Number(
                                            item2?.product?.discountPrice ?? 0,
                                          )
                                        : Number(
                                            item2?.product?.dailyRentalPrice ??
                                              0,
                                          );
                                    if (!item2?.reservationYn)
                                      handleCheckClick({
                                        id: item2?.id ?? null,
                                        price,
                                        startDate: item?.rentalStartDate ?? '',
                                        endDate: item?.rentalEndDate ?? '',
                                      });
                                  }}
                                >
                                  <div className="img_area rounded-lg">
                                    <img
                                      src={`${common.imageUrl}/${item2?.product?.product_images?.[0]?.file?.fileUrl}`}
                                      alt="상품 이미지"
                                    />
                                  </div>
                                </button>
                                <div className="text_area">
                                  <span className="brand">
                                    {item2?.product?.brand?.nameEnglish.toUpperCase() ??
                                      ''}
                                  </span>
                                  <p className="product">
                                    {item2?.product?.productName ?? ''}
                                  </p>
                                  {item2?.reservationYn ? (
                                    <div className="mt-4 flex items-center justify-end">
                                      <p className="text-base font-extrabold text-black">
                                        예약마감
                                      </p>
                                    </div>
                                  ) : Number(
                                      item2?.product?.discountRate ?? 0,
                                    ) > 0 ? (
                                    <div className="mt-4 flex items-center justify-between gap-1">
                                      <span className="price_discount">
                                        {`${Number(
                                          item2?.product?.discountRate ?? 0,
                                        )}%`}
                                      </span>
                                      <div className="flex items-center gap-1">
                                        <span className="price_original">
                                          {Number(
                                            item2?.product?.supplyPrice ?? 0,
                                          ).toLocaleString()}
                                        </span>
                                        <p className="price_total">
                                          {Number(
                                            item2?.product?.discountPrice ?? 0,
                                          ).toLocaleString()}
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="mt-4 flex items-center justify-end gap-1">
                                      <div className="flex items-center gap-1">
                                        <p className="price_total">
                                          {Number(
                                            item2?.product?.dailyRentalPrice ??
                                              0,
                                          ).toLocaleString()}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <button
                        type="button"
                        className="btn btn_style01 mt-3 h-10 w-full rounded-sm text-sm"
                        onClick={() =>
                          navigate(
                            `/product?start=${item.rentalStartDate ?? ''}&end=${
                              item.rentalEndDate ?? ''
                            }`,
                          )
                        }
                      >
                        + 더 담아보기
                      </button>
                    </div>
                  ) : null;
                })}
              </div>
              <div className="fixed_btm_btn">
                <div className="fixed_btn bg_style01">
                  <button
                    type="button"
                    className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                    disabled={checked?.length <= 0}
                    onClick={() => {
                      if (!isSameDate) handleModalOpen('주문불가 안내');
                      else handleSubmit();
                    }}
                  >
                    총 {checked?.length ?? 0}건 |{' '}
                    {checked?.length > 0
                      ? `${Number(checkedPrice).toLocaleString()}원 렌트하기`
                      : '렌트할 상품을 선택해 주세요'}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="cart_empty">
              <div className="text_area">
                <img src={icCart} alt="하트" />
                <p>장바구니가 비어있습니다.</p>
                <span>매주 새롭게 업데이트되는 핫아이템을 만나보세요!</span>
              </div>
              <div className="fixed_btm_btn">
                <div className="fixed_btn p-4">
                  <button
                    type="button"
                    className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                    onClick={() => navigate('/')}
                  >
                    핫아이템 구경하러 가기
                  </button>
                </div>
              </div>
            </div>
          )}

          <MsgModal open={msgOpen} setOpen={setMsgOpen} btns={msgBtns}>
            {msgContents}
          </MsgModal>
        </div>
      </div>
    </>
  );
}
export default Page;
