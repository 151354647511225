import React from 'react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { common } from '@config/index';
import HomeDto from '@api/home/home.dto';

interface Props {
  data: HomeDto.Recommend.Banners[];
}

export default function MainVisualSlider({ data }: Props) {
  return data?.length > 0 ? (
    <div className={`main_visual_wrap ${data?.length <= 1 ? 'no_page' : ''}`}>
      <Swiper
        modules={[Autoplay, Pagination]}
        spaceBetween={0}
        slidesPerView={1}
        loop
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          type: 'fraction',
        }}
      >
        {data?.map((item) => {
          const imageContent = (
            <div className="img_area rect01">
              <div className="hidden md:block">
                {item.pc_image_file?.fileUrl ? (
                  <img
                    src={`${common.imageUrl}/${item.pc_image_file?.fileUrl}`}
                    alt="메인 비주얼"
                  />
                ) : (
                  <i className="no_data_img" />
                )}
              </div>
              <div className="block md:hidden">
                {item.mobile_image_file?.fileUrl ? (
                  <img
                    src={`${common.imageUrl}/${item.mobile_image_file?.fileUrl}`}
                    alt="메인 비주얼"
                  />
                ) : (
                  <i className="no_data_img" />
                )}
              </div>
            </div>
          );

          return (
            <SwiperSlide key={item.id}>
              {item.linkUrl ? (
                <a
                  href={item.linkUrl}
                  target={item.target ?? '_blank'}
                  rel="noopener noreferrer"
                >
                  {imageContent}
                </a>
              ) : (
                imageContent
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  ) : null;
}
