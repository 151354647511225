import React, { useEffect, useState } from 'react';
import { valids } from '@config/index';

interface Props {
  timeLeft: number;
  setTimeLeft: React.Dispatch<React.SetStateAction<number>>;
  setIsTimeEnd: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<string>>;
}

export default function AuthTimer({
  timeLeft,
  setTimeLeft,
  setIsTimeEnd,
  setError,
}: Props) {
  const [timeLeftText, setTimeLeftText] = useState('');

  useEffect(() => {
    const timer = setInterval(() => {
      const min = String(Math.floor(timeLeft / 60)).padStart(2, '0');
      const sec = String(Math.floor(timeLeft % 60)).padStart(2, '0');
      setTimeLeftText(`${min}:${sec}`);
      setTimeLeft((state) => state - 1);
    }, 1000);

    if (timeLeft < 0) {
      clearInterval(timer);
      setError(valids.expirationTimer);
      setIsTimeEnd(true);
    }

    return () => {
      clearInterval(timer);
    };
  }, [timeLeft]);

  return timeLeftText !== '00:00' ? (
    <span className="input_time_text">남은시간 {timeLeftText}</span>
  ) : null;
}
