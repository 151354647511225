import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import icPlus from '@assets/images/icon_plus.svg';
import icQuestion from '@assets/images/icon_question.svg';
import imgProduct01 from '@assets/images/temp/product_img08.jpg';
import Header from '@components/shared/header';
import ToggleItem from '@components/toggleItem';
import CustomSelect from '@components/forms/customSelect';
import SearchAddress from '@components/forms/searhAddress';
import UseCouponModal from '@components/modal/useCoupon';
import JoinAgreeModal from '@components/modal/joinAgree';
import CancelRefundPolicyModal from '@components/modal/cancelRefundPolicy';
import CheckIsLogin from '@components/checkIsLogin';
import { options, valids } from '@config/index';

const checkListData = [
  {
    id: 1,
    text: '렌티크 서비스 약관',
    title: '렌티크 서비스 약관',
  },
  {
    id: 2,
    text: '결제 대행 서비스 자동 승인, 개인정보 및 이용약관',
    title: '결제 대행 서비스 자동 승인 <br/>개인정보 및 이용약관',
  },
  {
    id: 3,
    text: '개인(신용)정보 수집, 이용 동의',
    title: '개인(신용)정보 수집, <br/>이용 동의 이용약관',
  },
  {
    id: 4,
    text: '개인신용정보 제공 및 조희 동의',
    title: '개인신용정보 제공 및 조회 동의 <br/>이용약관',
  },
];

function Page() {
  const [zone, setZone] = useState('');
  const [address, setAddress] = useState('');
  const [memo, setMemo] = useState(options.deliveryMemos[0].id);
  const [installment, setInstallment] = useState(options.installments[0].id);
  const [agreeIndex, setAgreeIndex] = useState(1);
  const [agreeTitle, setAgreeTitle] = useState('');
  const [agreeOpen, setAgreeOpen] = useState(false);
  const [couponOpen, setCouponOpen] = useState(false);
  const [policyOpen, setPolicyOpen] = useState(false);

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header back title="주문/결제" />
          </div>

          <div className="order_wrap">
            <div className="inner">
              {/* 자동 결제 수단 미등록 시 */}
              <ToggleItem title="자동 결제 수단 등록">
                <div className="p-4">
                  <div className="card_area">
                    {/* TODO: 클릭 시토스페이먼츠 카드 등록 레이어 팝업 노출 */}
                    <button type="button" className="card_btn">
                      <img src={icPlus} alt="+" />
                      <span>카드 등록</span>
                    </button>
                  </div>
                  <p className="my-4 text-center text-xs font-medium text-gray-9">
                    본인 명의 카드만 추가 가능합니다.
                  </p>
                  <div className="rounded-sm bg-gray-f8 p-4">
                    <p className="text-xs font-bold text-black">
                      카드 변경 안내
                    </p>
                    <span className="mt-1 block text-xs font-normal text-gray-3">
                      카드 변경은{' '}
                      <Link to="/" className="underline">
                        마이페이지 &gt; 결제관리
                      </Link>
                      에서 가능합니다.
                    </span>
                  </div>
                </div>
              </ToggleItem>
              <ToggleItem title="렌트 상품 정보">
                <div className="item_area px-4 py-6">
                  <div className="img_wrap w-25">
                    <div className="img_area">
                      <img src={imgProduct01} alt="상품 이미지" />
                    </div>
                  </div>
                  <div className="text_area">
                    <p className="brand">PRADA</p>
                    <p className="product">캐러멜 가죽 핸드백</p>
                    <div className="mt-3">
                      <p className="price_discount">123,800</p>
                      <div className="mt-1 flex items-center gap-1">
                        <p className="discount_percent">12%</p>
                        <p className="price_total">123,800</p>
                      </div>
                      <div className="mt-1 flex items-center justify-between">
                        <span className="desc">기간할인</span>
                        <p className="desc_main">-3,800</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between bg-gray-f8 px-4 py-2">
                  <div className="flex items-center justify-center gap-2">
                    <button
                      type="button"
                      className="text-sm font-medium text-black"
                    >
                      쿠폰 <b className="text-main">4</b>장
                    </button>
                    {/* TODO
                    - 버튼명 쿠폰 적용되어있을 때 '사용안함', 클릭 시 0원 처리
                    - 쿠폰 적용 안되어있을 때 '사용', 클릭 시 모달 노출
                  */}
                    <button
                      type="button"
                      className="btn btn_style05 h- h-7.5 rounded-sm px-2 text-xs font-bold"
                      onClick={() => setCouponOpen(true)}
                    >
                      사용안함
                    </button>
                  </div>
                  <p className="text-sm font-medium text-main">-44,200</p>
                </div>
                <div className="flex items-center justify-between rounded-b-sm bg-main-02 px-4 py-2">
                  <p className="text-sm font-bold text-main">결제금액</p>
                  <p className="text-lg font-bold text-black">103,180</p>
                </div>
              </ToggleItem>
              <ToggleItem title="이용 정보">
                <div className="p-4">
                  <dl className="text_list_area">
                    <dt>이용 기간</dt>
                    <dd>24.07.20(토) ~ 24.07.22(월)</dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>회수일</dt>
                    <dd>24.07.23(화)</dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>환불 정책</dt>
                    <dd>
                      <div className="flex items-center gap-1">
                        <span className="text-red">
                          주문 후 2시간 이내 무료 취소 가능
                        </span>
                        <button
                          type="button"
                          onClick={() => setPolicyOpen(true)}
                        >
                          <img src={icQuestion} alt="취소 환불 정책 정보" />
                        </button>
                      </div>
                      <span className="text-red">
                        * 상품 출고 이후에는 취소 불가
                      </span>
                    </dd>
                  </dl>
                </div>
              </ToggleItem>
              <ToggleItem title="주문자 정보">
                <div className="p-4">
                  <dl className="text_list_area">
                    <dt>이름</dt>
                    <dd>김렌트</dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>연락처</dt>
                    <dd>01012341234</dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>이메일</dt>
                    <dd>2000.12.31</dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>성별</dt>
                    <dd>여성</dd>
                  </dl>
                </div>
              </ToggleItem>
              <ToggleItem title="배송지 정보">
                {/* 배송지 정보 미등록 시 */}
                <div className="p-4">
                  <dl className="text_list_area">
                    <dt>
                      <label htmlFor="name">
                        이름
                        <span className="text-main">*</span>
                      </label>
                    </dt>
                    <dd>
                      <input
                        type="text"
                        id="name"
                        className="input"
                        placeholder={valids.inputName}
                      />
                    </dd>
                  </dl>
                  <dl className="text_list_area mt-4">
                    <dt>
                      <label htmlFor="phone">
                        연락처
                        <span className="text-main">*</span>
                      </label>
                    </dt>
                    <dd>
                      <input
                        type="text"
                        id="phone"
                        className="input"
                        placeholder={valids.inputPhone}
                      />
                    </dd>
                  </dl>
                  <dl className="text_list_area mt-4">
                    <dt>
                      <label htmlFor="address">배송 주소</label>
                    </dt>
                    <dd>
                      <SearchAddress
                        addr={address}
                        setAddr={setAddress}
                        zone={zone}
                        setZone={setZone}
                      />
                      <input
                        type="text"
                        id="address"
                        className="input mt-1"
                        placeholder={valids.inputAddressDetail}
                      />
                    </dd>
                  </dl>
                  <dl className="text_list_area mt-4">
                    <dt>
                      <label htmlFor="memo">배송메모</label>
                    </dt>
                    <dd>
                      <CustomSelect
                        type={2}
                        options={options.deliveryMemos}
                        value={memo}
                        setValue={setMemo}
                      />
                      {memo === 'etc' && (
                        <input
                          type="text"
                          id="memo"
                          className="input mt-1"
                          placeholder={valids.inputDeliveryMemo}
                        />
                      )}
                    </dd>
                  </dl>

                  <div className="mt-4 rounded-sm bg-gray-f8 p-4">
                    <p className="text-xs font-bold text-black">
                      기본 배송지 설정 안내
                    </p>
                    <span className="mt-1 block text-xs font-normal text-gray-3">
                      기본 배송지로 배송지 관리에 등록됩니다.
                    </span>
                  </div>
                </div>
                {/* 배송지 정보 등록 시 */}
                {/* <div className="p-4">
                <span className="inline-flex h-5 items-center rounded-sm bg-main-02 px-2 text-2xs font-medium text-main">
                  기본배송지
                </span>
                <div className="mt-2 flex items-center justify-between gap-1">
                  <p className="text-base font-bold text-black">홍길동(회사)</p>
                  <button
                    type="button"
                    className="btn btn_style01 h-7.5 rounded-sm px-3 text-xs font-medium"
                  >
                    변경
                  </button>
                </div>
                <p className="mt-2 text-xs font-medium text-gray-9">
                  010-1234-5678
                </p>
                <p className="mt-2 text-xs font-medium text-black">
                  [12345] 서울특별시 구로구 디지털로 32길 30 <br />
                  코오롱디지털타워빌란트 1510호
                </p>
              </div> */}
              </ToggleItem>
              {/* 자동 결제 수단 등록 시 */}
              <ToggleItem title="자동 결제 수단 등록">
                <div className="p-4">
                  <div className="card_area">
                    <div className="card_info">
                      <span>samsung</span>
                      <p>9391-16**-****-185</p>
                    </div>
                  </div>
                  <div className="my-4">
                    <div className="mx-auto max-w-50">
                      <CustomSelect
                        type={2}
                        options={options.installments}
                        value={installment}
                        setValue={setInstallment}
                      />
                    </div>
                    <p className="mt-2 text-center text-xs font-medium text-gray-9">
                      5만원 이상 결제 시 할부 가능, 체크카드 할부 불가
                    </p>
                  </div>
                  <div className="rounded-sm bg-gray-f8 p-4">
                    <p className="text-xs font-bold text-black">
                      카드 변경 안내
                    </p>
                    <span className="mt-1 block text-xs font-normal text-gray-3">
                      카드 변경은{' '}
                      <Link to="/" className="underline">
                        마이페이지 &gt; 결제관리
                      </Link>
                      에서 가능합니다.
                    </span>
                  </div>
                </div>
              </ToggleItem>
              <ToggleItem title="적립금 사용">
                <div className="p-4">
                  <dl className="text_list_area">
                    <dt>보유</dt>
                    <dd className="text-right">1,000원</dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt className="py-2">사용</dt>
                    <dd>
                      <div className="flex gap-3">
                        <input
                          type="text"
                          className="input flex-1 text-right"
                          placeholder="0"
                        />
                        <button
                          type="button"
                          className="btn btn_style01 h-9 rounded-sm px-3 text-xs font-medium"
                        >
                          전액사용
                        </button>
                      </div>
                    </dd>
                  </dl>
                </div>
              </ToggleItem>
              <ToggleItem title="자동 결제 수단 등록">
                <div className="p-4">
                  <dl className="text_list_area">
                    <dt>총 렌트 금액</dt>
                    <dd className="text-right">15,200원</dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>기간할인</dt>
                    <dd className="text-right">-3,800원</dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>쿠폰</dt>
                    <dd className="text-right">-44,200원</dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>적립금</dt>
                    <dd className="text-right">0원</dd>
                  </dl>
                </div>
                <div className="flex items-center justify-between rounded-b-sm bg-main-02 px-4 py-2">
                  <p className="text-sm font-bold text-main">최종 결제 금액</p>
                  <p className="text-lg font-bold text-black">103,180</p>
                </div>
              </ToggleItem>

              <div className="caution_text_area">
                <p className="text-xs font-bold text-black">예약 전 주의사항</p>
                <ul>
                  <li>
                    <p>
                      취소 시점에 따라 취소 수수료가 발생할 수 있습니다. <br />
                      <b>출고 이후에는 취소 및 부분 환불이 불가합니다.</b>{' '}
                      <br />
                      <span>
                        시작일 2일 전 결제 금액의 50% 환불 수수료 발생 <br />
                        시작일 3일 전 결제 금액의 30% 환불 수수료 발생 <br />
                        시작일 4일 전 취소 시 환불 수수료 없음
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>사용 내역과 상품에 따라 보증금이 발생할 수 있습니다.</p>
                  </li>
                  <li>
                    <p>
                      부득이한 사정으로 렌트가 불가할 경우 별도 적립금을 지급해
                      드립니다.
                    </p>
                  </li>
                  <li>
                    <p>
                      결제 후 예약 변경은 불가하며, 취소 후 예약 부탁드립니다.
                    </p>
                  </li>
                </ul>
              </div>

              <div className="my-4">
                <div className="chk_input">
                  <input type="checkbox" id="agree" />
                  <label htmlFor="agree">
                    <b>렌트 내역 확인 및 모든 약관에 동의합니다.</b>
                  </label>
                </div>
                <div className="mt-2 border-t border-t-gray-c">
                  {checkListData.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="mt-2 flex items-center justify-between"
                      >
                        <p className="text-xs text-gray-9">{item.text}</p>
                        <button
                          type="button"
                          className="text-2xs text-gray-9 underline"
                          onClick={() => {
                            setAgreeIndex(item.id);
                            setAgreeTitle(item.title);
                            setAgreeOpen(true);
                          }}
                        >
                          보기
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="py-4">
                {/* TODO: input 필수 내용 입력, 약관 동의 체크하면 활성화 처리 */}
                <button
                  type="button"
                  className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                  // disabled
                >
                  103,180원 결제하기
                </button>
              </div>
            </div>
          </div>

          <UseCouponModal open={couponOpen} setOpen={setCouponOpen} />
          <JoinAgreeModal
            index={agreeIndex}
            title={agreeTitle}
            open={agreeOpen}
            setOpen={setAgreeOpen}
          />
          <CancelRefundPolicyModal open={policyOpen} setOpen={setPolicyOpen} />
        </div>
      </div>
    </>
  );
}
export default Page;
