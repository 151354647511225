import React from 'react';
import SingleImgUpload from '@components/forms/singleImgUpload';

interface Props {
  files?: (File | null)[];
  setFiles?: React.Dispatch<React.SetStateAction<(File | null)[]>>;
}

export default function MultiImgUpload({ files = [null], setFiles }: Props) {
  const handleAddFile = (file: File, index: number) => {
    // console.log('add:', files, file);
    const newFiles = [...files];
    newFiles[index] = file;
    setFiles?.(
      newFiles.length < 3 ? [...newFiles, null] : newFiles.slice(0, 3),
    );
  };

  const handleDeleteFile = (index: number) => {
    // console.log('delete:', index, files);
    const newFiles = files?.filter((_, i) => i !== index);
    setFiles?.(
      newFiles.length < 3 && newFiles[newFiles.length - 1] !== null
        ? [...newFiles, null]
        : newFiles.slice(0, 3),
    );
  };

  return (
    <div className="flex flex-wrap items-center gap-2">
      {files?.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <SingleImgUpload
              initFile={item}
              onAddFile={(file) => handleAddFile(file, index)}
              onDeleteFile={() => handleDeleteFile(index)}
            />
          </React.Fragment>
        );
      })}
      <span className="block text-xs font-medium text-gray-9">
        10MB 이하 JPG, PNG만 등록 가능합니다. <br />(
        {files?.filter((item) => item !== null).length ?? 0}/3)
      </span>
    </div>
  );
}
