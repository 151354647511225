import React from 'react';

interface Props {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

export default function CustomStar({ value, setValue }: Props) {
  return (
    <div className="star_input">
      <label htmlFor="star1">
        <input
          type="radio"
          name="star"
          id="star1"
          value="0.5"
          checked={value === '0.5'}
          onChange={(e) => setValue(e.target.value)}
        />
        <i />
      </label>
      <label htmlFor="star2">
        <input
          type="radio"
          name="star"
          id="star2"
          value="1.0"
          checked={value === '1.0'}
          onChange={(e) => setValue(e.target.value)}
        />
        <i />
      </label>
      <label htmlFor="star3">
        <input
          type="radio"
          name="star"
          id="star3"
          value="1.5"
          checked={value === '1.5'}
          onChange={(e) => setValue(e.target.value)}
        />
        <i />
      </label>
      <label htmlFor="star4">
        <input
          type="radio"
          name="star"
          id="star4"
          value="2.0"
          checked={value === '2.0'}
          onChange={(e) => setValue(e.target.value)}
        />
        <i />
      </label>
      <label htmlFor="star5">
        <input
          type="radio"
          name="star"
          id="star5"
          value="2.5"
          checked={value === '2.5'}
          onChange={(e) => setValue(e.target.value)}
        />
        <i />
      </label>
      <label htmlFor="star6">
        <input
          type="radio"
          name="star"
          id="star6"
          value="3.0"
          checked={value === '3.0'}
          onChange={(e) => setValue(e.target.value)}
        />
        <i />
      </label>
      <label htmlFor="star7">
        <input
          type="radio"
          name="star"
          id="star7"
          value="3.5"
          checked={value === '3.5'}
          onChange={(e) => setValue(e.target.value)}
        />
        <i />
      </label>
      <label htmlFor="star8">
        <input
          type="radio"
          name="star"
          id="star8"
          value="4.0"
          checked={value === '4.0'}
          onChange={(e) => setValue(e.target.value)}
        />
        <i />
      </label>
      <label htmlFor="star9">
        <input
          type="radio"
          name="star"
          id="star9"
          value="4.5"
          checked={value === '4.5'}
          onChange={(e) => setValue(e.target.value)}
        />
        <i />
      </label>
      <label htmlFor="star10">
        <input
          type="radio"
          name="star"
          id="star10"
          value="5.0"
          checked={value === '5.0'}
          onChange={(e) => setValue(e.target.value)}
        />
        <i />
      </label>
    </div>
  );
}
