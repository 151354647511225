import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import icArrow from '@assets/images/icon_arrow_right_gray.svg';
import icInquiry from '@assets/images/icon_mypage_inquiry.svg';
import icDelivery from '@assets/images/icon_mypage_delivery.svg';
import icCoupon from '@assets/images/icon_mypage_coupon.svg';
import icPoint from '@assets/images/icon_mypage_point.svg';
import Header from '@components/shared/header';
import BottomNav from '@components/shared/bottomNav';
import MsgModal from '@components/modal/msg';
import CheckIsLogin from '@components/checkIsLogin';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import AuthApi from '@api/auth/auth.query';
import MypageApi from '@api/mypage/mypage.query';

function Page() {
  const navigate = useNavigate();
  const { setLoading } = useStore((state) => state);
  const [msgOpen, setMsgOpen] = useState(false);

  // api
  const token = AuthApi.useGetToken();
  const goLogut = AuthApi.useLogout();
  const { data: callData, isLoading } = MypageApi.useDashboardQuery();
  const resultData = callData?.data?.data;

  useEffect(() => {
    if (resultData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [resultData, isLoading]);

  return (
    <>
      <CheckIsLogin loginCheck={false} once />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="마이렌트백" back searchCart />
          </div>

          <section className="mypage_main_wrap pb-24 pt-4">
            <div className="inner">
              <div className="flex items-center justify-between">
                <h3 className="text-xs font-medium text-black">
                  <b className="text-base font-bold">{token?.name ?? ''}</b>님
                  안녕하세요!
                </h3>
                <Link
                  to="/mypage/user-info"
                  className="flex items-center gap-1 text-xs font-medium text-gray-9"
                >
                  회원정보 관리
                  <img src={icArrow} alt="arrow" />
                </Link>
              </div>
              <div className="info_area mt-4">
                <ul>
                  <li>
                    <img src={icInquiry} alt="문의답글" />
                    <p>문의답글</p>
                    <span>
                      {Number(resultData?.inquiriesCnt ?? 0).toLocaleString()}
                    </span>
                  </li>
                  <li>
                    <img src={icDelivery} alt="배송중" />
                    <p>배송중</p>
                    <span>
                      {Number(resultData?.shippedCnt ?? 0).toLocaleString()}
                    </span>
                  </li>
                  <li>
                    <img src={icCoupon} alt="쿠폰" />
                    <p>쿠폰</p>
                    <span>
                      {Number(resultData?.couponCnt ?? 0).toLocaleString()}
                    </span>
                  </li>
                  <li>
                    <img src={icPoint} alt="적립금" />
                    <p>적립금</p>
                    <span>
                      {Number(resultData?.points ?? 0).toLocaleString()}
                    </span>
                  </li>
                </ul>
              </div>

              <div className="tit_btn_area">
                <p>렌트 정보</p>
                <Link to="/mypage/rent">렌트 내역</Link>
                <Link to="/mypage/payment">결제 관리</Link>
                <Link to="/mypage/delivery">배송지 관리</Link>
                <Link to="/mypage/account">계좌 관리</Link>
              </div>
              <div className="tit_btn_area">
                <p>위탁 정보</p>
                <Link to="/mypage/consignment">위탁 내역</Link>
                <Link to="/mypage/settlement">정산 내역</Link>
              </div>
              <div className="tit_btn_area">
                <p>혜택 정보</p>
                <Link to="/mypage/coupon">나의 쿠폰</Link>
                <Link to="/mypage/savings">나의 적립금</Link>
                <Link to="/mypage/review">나의 리뷰</Link>
              </div>
              <div className="tit_btn_area">
                <p>문의 확인</p>
                <Link to="/mypage/customer">고객센터</Link>
                <Link to="/mypage/inquiry">문의 내역</Link>
              </div>

              <button
                type="button"
                className="btn btn_style01 mt-4 h-10 w-full rounded-sm text-sm font-medium"
                onClick={() => {
                  goLogut();
                  setMsgOpen(true);
                }}
              >
                로그아웃
              </button>
              <div className="mt-4 text-center">
                <Link
                  to="/mypage/withdraw"
                  className="inline-block text-xs font-medium text-gray-9 underline"
                >
                  회원 탈퇴
                </Link>
              </div>
            </div>
          </section>

          <MsgModal
            open={msgOpen}
            setOpen={setMsgOpen}
            btns={
              <button
                type="button"
                className="btn btn_style02 h-10 w-full rounded-sm text-sm font-bold"
                onClick={() => navigate('/')}
              >
                확인
              </button>
            }
          >
            <p className="tit">알림</p>
            <span className="desc">성공적으로 로그아웃하셨습니다.</span>
          </MsgModal>

          <BottomNav active="MYPAGE" />
        </div>
      </div>
    </>
  );
}
export default Page;
