import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAxios, Utils } from '@api/index';
import DeliveryQueryKey from '@api/delivery/delivery.key';
import DeliveryDto from '@api/delivery/delivery.dto';

namespace DeliveryApi {
  /**
   * 배송지 리스트 조회
   */
  export function useDeliveryQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [DeliveryQueryKey.DELIVERY],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<DeliveryDto.Delivery.Response[]>
        >('/api/users/delivery-address');
      },
    });
  }

  /**
   * 배송지 상세 조회
   * @param {number} deliveryId - 배송지 ID
   */
  export function useDeliveryDetailQuery(deliveryId?: number) {
    const axios = useAxios();

    return useQuery({
      queryKey: [DeliveryQueryKey.DELIVERY_DETAIL, deliveryId],
      queryFn() {
        return axios.get<Utils.View.Response<DeliveryDto.Delivery.Response>>(
          `/api/users/delivery-address/${deliveryId}`,
        );
      },
      enabled: !!deliveryId,
    });
  }

  /**
   * 배송지 추가
   * @param {DeliveryDto.Delivery.Infos} body - Body
   */
  export function useCreateDeliveryMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<null>>,
      AxiosError<{ message: string }>,
      DeliveryDto.Delivery.Infos
    >({
      mutationFn(body: DeliveryDto.Delivery.Infos) {
        return axios.post<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>,
          DeliveryDto.Delivery.Infos
        >('/api/users/delivery-address', body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [DeliveryQueryKey.DELIVERY],
        });
      },
    });
  }

  /**
   * 배송지 수정
   * @param {number} deliveryId - 배송지 ID
   * @param {DeliveryDto.Delivery.Infos} body - Body
   */
  export function useUpdateDeliveryMutation(deliveryId?: number) {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<null>>,
      AxiosError<{ message: string }>,
      DeliveryDto.Delivery.Infos
    >({
      mutationFn(body: DeliveryDto.Delivery.Infos) {
        return axios.put<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>,
          DeliveryDto.Delivery.Infos
        >(`/api/users/delivery-address/${deliveryId}`, body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [DeliveryQueryKey.DELIVERY_DETAIL, deliveryId],
        });
      },
    });
  }

  /**
   * 배송지 삭제
   * @param {number} deliveryId - 배송지 ID
   */
  export function useDeleteDeliveryMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation({
      mutationFn(deliveryId: number) {
        return axios.delete(`/api/users/delivery-address/${deliveryId}`);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [DeliveryQueryKey.DELIVERY_DETAIL],
        });
      },
    });
  }
}

export default DeliveryApi;
