import React, { useEffect, useState } from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import icArrow from '@assets/images/icon_arrow_down.svg';
import Header from '@components/shared/header';
import Footer from '@components/shared/footer';
import BottomNav from '@components/shared/bottomNav';
import FilterWrap from '@components/filterWrap';
import CustomSelect from '@components/forms/customSelect';
import TabBtnSlider from '@components/slider/tabBtn';
import LikeBtn from '@components/likeBtn';
import { common, options, valids } from '@config/index';
import { formatYyMmddd, sleep } from '@utils/common';
import useStore from '@store/index';
import CategoryApi from '@api/category/category.query';
import ProductApi from '@api/product/product.query';
// import { categoryData } from '@temp/categoryData';
// import { productData } from '@temp/productData';

function Page() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const spBrandIds = searchParams.getAll('brandId');
  const spDiscountIds = searchParams.getAll('discountId');
  const spStartDate = searchParams.get('start');
  const spEndDate = searchParams.get('end');
  const spCategory1 = searchParams.get('c1');
  const spCategory1Name = searchParams.get('c1Name');
  const spCategory2 = searchParams.get('c2');
  const spCategory2Name = searchParams.get('c2Name');
  const spCategory3 = searchParams.get('c3');
  const categoryId = spCategory3
    ? Number(spCategory3)
    : spCategory2
      ? Number(spCategory2)
      : spCategory1
        ? Number(spCategory1)
        : undefined;
  const { setLoading } = useStore((state) => state);
  const [tab, setTab] = useState<number | null>(null);
  const [sorts, setSorts] = useState(options.productSorts[0].id);
  const [depth03Open, setDepth03Open] = useState(false);

  // api
  const categoryData3 =
    CategoryApi.useTertiaryCategoriesQuery(
      spCategory2 ? Number(spCategory2) : undefined,
    )?.data?.data?.data?.result ?? [];
  const {
    data: callData,
    isLoading,
    refetch,
  } = ProductApi.useRecommendProductsQuery({
    orders: sorts,
    brandId:
      spBrandIds?.length > 0
        ? spBrandIds?.map((item) => Number(item))
        : undefined,
    discountRate: spDiscountIds?.length > 0 ? spDiscountIds : undefined,
    categoryId: categoryId ? Number(categoryId) : undefined,
    rentalStartDate: spStartDate ?? undefined,
    rentalEndDate: spEndDate ?? undefined,
  });
  const resultData = callData?.data?.data;
  const listData = resultData?.result ?? [];

  // 탭 변경 시, 페이지 이동
  useEffect(() => {
    if (!tab) return;

    const sps = new URLSearchParams(location.search);
    sps.delete('c3');
    sps.set('c3', tab.toString());
    navigate(`${location.pathname}?${sps.toString()}`);
    setDepth03Open(false);
  }, [tab]);

  // depth03 데이터 있을 시, tab 세팅
  useEffect(() => {
    if (spCategory3 && categoryData3) setTab(Number(spCategory3));
    else setTab(null);
  }, [categoryData3]);

  useEffect(() => {
    if (resultData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    }
  }, [resultData, isLoading]);

  // NO DATA !!!
  if (!resultData && !isLoading) {
    alert(valids.noDataPage);
    window.history.back();
  }
  if (!resultData) return null;

  return (
    <div id="root_layout">
      <div className="root_container">
        <div className="sticky_menu">
          <Header back searchCart />
          {categoryId && (
            <div className="prod_list_category_wrap">
              <div className="inner flex h-10 items-center justify-start gap-2">
                <h2 className="depth01">{spCategory1Name ?? ''}</h2>
                <button
                  type="button"
                  className="depth02"
                  onClick={() => navigate('/category')}
                >
                  {spCategory2 ? spCategory2Name ?? '' : '전체'}
                  <img src={icArrow} alt="화살표" />
                </button>
              </div>
              {categoryData3?.length > 0 && (
                <div className="depth03_style01">
                  <button
                    type="button"
                    className="open_btn"
                    onClick={() => setDepth03Open(true)}
                  >
                    <img src={icArrow} alt="화살표" />
                  </button>
                  <TabBtnSlider
                    data={categoryData3?.map((item) => ({
                      ...item,
                      text: item.name ?? '',
                    }))}
                    active={tab}
                    setActive={setTab}
                    spaceBetween={16}
                  />
                </div>
              )}
            </div>
          )}
          {spStartDate && spEndDate && (
            <div className="prod_list_date_wrap">
              <div className="inner">
                <div className="date_area">
                  <p>
                    {formatYyMmddd(spStartDate)} ~ {formatYyMmddd(spEndDate)}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        {categoryData3?.length > 0 && depth03Open && (
          <div className="modal_wrap top">
            <div className="modal_area">
              <Header back backFunc={() => setDepth03Open(false)} searchCart />
              <div className="prod_list_category_wrap">
                <div className="inner flex h-10 items-center justify-start gap-2">
                  <h2 className="depth01">여성</h2>
                  <button
                    type="button"
                    className="depth02"
                    onClick={() => navigate('/category')}
                  >
                    가방 <img src={icArrow} alt="화살표" />
                  </button>
                </div>
                <div className="depth03_style02">
                  <ul>
                    {categoryData3?.map((item) => {
                      return (
                        <li
                          key={item.id}
                          className={tab === item.id ? 'on' : ''}
                        >
                          <button type="button" onClick={() => setTab(item.id)}>
                            {item.name}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* 필터 */}
        <div className="inner pb-6 pt-4">
          <FilterWrap />
        </div>

        {/* 리스트 */}
        <div className="pb-10">
          <div className="inner">
            <div className="flex items-center justify-between pb-4">
              <p className="text-xs font-medium text-gray-9">
                {resultData?.cntAll ?? 0}건
              </p>
              <CustomSelect
                type={1}
                optionClassName="w-24 right-0"
                options={options.productSorts}
                value={sorts}
                setValue={setSorts}
              />
            </div>
            <div className="list_area list_style01 grid02">
              <ul>
                {listData?.length > 0 ? (
                  listData?.map((item) => {
                    return (
                      <li key={item.id}>
                        <LikeBtn
                          id={item?.id}
                          likeYn={item?.likeYn}
                          refetch={refetch}
                          toastBottom={78}
                        />
                        <Link to={`/product/detail/${item.id}`}>
                          <div className="img_wrap">
                            <div className="img_area rounded-lg">
                              {item?.product_images?.length > 0 ? (
                                <img
                                  src={`${common.imageUrl}/${item?.product_images?.[0]?.file?.fileUrl}`}
                                  alt="상품 이미지"
                                />
                              ) : (
                                <i className="no_data_img" />
                              )}
                              <span className="tag">렌트</span>
                            </div>
                          </div>
                          <div className="text_area">
                            <span className="brand">
                              {item?.brand?.nameEnglish.toUpperCase() ?? ''}
                            </span>
                            <p className="product">{item?.productName ?? ''}</p>
                            <div className="flex items-center justify-start gap-1">
                              {Number(item?.discountRate ?? 0) > 0 && (
                                <span className="price_discount">
                                  {`${Number(item?.discountRate ?? 0)}%`}
                                </span>
                              )}
                              <em className="price_desc">일</em>
                              <p className="price_total">
                                {Number(item?.discountRate ?? 0) > 0
                                  ? Number(
                                      item?.discountPrice ?? 0,
                                    ).toLocaleString()
                                  : Number(
                                      item?.dailyRentalPrice ?? 0,
                                    ).toLocaleString()}
                              </p>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })
                ) : (
                  <li className="no_data_text">
                    <p>{valids.noData}</p>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>

        <BottomNav />
        <Footer btmNav />
      </div>
    </div>
  );
}
export default Page;
