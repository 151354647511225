import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '@components/shared/header';
import JoinAgree from '@app/auth/join/agree';
import { AuthType } from '@components/passAuthWrap';
import IsJoinModal, { IsJoinDataType } from '@components/modal/isJoin';
import CheckIsLogin from '@components/checkIsLogin';
import { common, valids } from '@config/index';
import useStore from '@store/index';
import AuthApi from '@api/auth/auth.query';

function Page() {
  const navigate = useNavigate();
  const { setLoading, setAlert } = useStore((state) => state);
  const [isAgree, setIsAgree] = useState(false);
  const [authData, setAuthData] = useState<AuthType | null>(null);
  const [infoData, setInfoData] = useState<IsJoinDataType | null>(null);
  const [infoOpen, setInfoOpen] = useState(false);

  // api
  const useSnsJoinMutation = AuthApi.useSnsJoinMutation();

  useEffect(() => {
    const storageData = sessionStorage.getItem(common.SNS_DATA_KEY);
    if (!storageData || !authData) return;

    setLoading(true);
    const formData = {
      ...JSON.parse(storageData),
      ...authData,
      marketingAgreement: isAgree,
    };
    formData.gender = formData.gender === '1';
    delete formData.key;

    // console.log(formData);
    useSnsJoinMutation.mutate(formData);
  }, [authData]);

  // ERROR !!!
  useEffect(() => {
    if (!useSnsJoinMutation.isError) return;
    if (useSnsJoinMutation.error?.response?.data?.data) {
      setInfoData({
        ...useSnsJoinMutation.error?.response?.data?.data,
        desc: useSnsJoinMutation.error?.response?.data?.message,
      });
      setInfoOpen(true);
    } else {
      setAlert(
        useSnsJoinMutation.error?.response?.data?.message ?? valids.errored,
      );
    }
    setLoading(false);
  }, [useSnsJoinMutation.isError]);

  // SNS 회원가입 SUCCESS !!!
  useEffect(() => {
    if (!useSnsJoinMutation.isSuccess) return;
    sessionStorage.setItem(common.PASS_DATA_KEY, authData?.name ?? '');
    sessionStorage.removeItem(common.SNS_DATA_KEY);
    navigate('/join/complete');
    setLoading(false);
  }, [useSnsJoinMutation.isSuccess]);

  return (
    <>
      <CheckIsLogin loginCheck />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header
              title="회원가입"
              back
              backFunc={() => {
                sessionStorage.removeItem(common.SNS_DATA_KEY);
                navigate('/login');
              }}
            />
          </div>

          <JoinAgree setData={setAuthData} setIsAgree={setIsAgree} />
          <IsJoinModal
            open={infoOpen}
            setOpen={setInfoOpen}
            data={infoData}
            onConfirmClick={() => navigate('/login')}
          />
        </div>
      </div>
    </>
  );
}
export default Page;
