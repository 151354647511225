import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAxios, Utils } from '@api/index';
import BrandQueryKey from '@api/brand/brand.key';
import BrandDto from '@api/brand/brand.dto';
import CommonDto from '@api/common.dto';

namespace BrandApi {
  /**
   * 추천 브랜드 호출
   */
  export function useRecommendBrandsQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [BrandQueryKey.RECOMMEND_BRAND],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<BrandDto.RecommendBrand.Response[]>
        >('/api/users/recommend-brands');
      },
    });
  }

  /**
   * 브랜드 검색
   * @param {BrandDto.SearchBrand.Request} body - Body
   */
  export function useSearchBrandsQuery(params: BrandDto.SearchBrand.Request) {
    const axios = useAxios();

    return useQuery({
      queryKey: [BrandQueryKey.SEARCH_BRAND, params],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<BrandDto.SearchBrand.Response[]>
        >('/api/users/search-brands', { params });
      },
    });
  }

  /**
   * 브랜드 상품 검색
   * @param {BrandDto.SearchBrand.ProductRequest} body - Body
   */
  export function useSearchBrandsProductsQuery(
    params: BrandDto.SearchBrand.ProductRequest,
  ) {
    const axios = useAxios();

    return useQuery<
      AxiosResponse<Utils.View.ResponseResult<CommonDto.Product[]>>,
      AxiosError<{ message: string }>,
      { data: Utils.View.ResponseResult<CommonDto.Product[]> }
    >({
      queryKey: [BrandQueryKey.SEARCH_BRAND_PRODUCTS, params],
      queryFn() {
        return axios.get<Utils.View.ResponseResult<CommonDto.Product[]>>(
          '/api/users/search-brands-products',
          { params },
        );
      },
    });
  }
}

export default BrandApi;
