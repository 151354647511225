import { useEffect, useState } from 'react';
import {
  FilterTypes,
  FilterDataType,
  FilterTotalDataType,
} from '@components/filterWrap';

export function useFilter(initData: FilterDataType) {
  const [filterData, setFilterData] = useState<FilterDataType>(initData);
  const [filterTotalData, setFilterTotalData] = useState<FilterTotalDataType[]>(
    [],
  );

  // ADD
  const handleAddSelected = (type: FilterTypes, data: any) => {
    const newData = { ...filterData };

    if (type === 'category') newData[type] = data;
    else newData[type] = [...newData[type], data];

    setFilterData(newData);
  };

  // DELETE
  const handleDeleteSelected = (type: FilterTypes, data: any) => {
    const newData = { ...filterData };

    if (type === 'category') newData[type] = null;
    else
      newData[type] = (newData[type] as Array<any>)?.filter(
        (f) => f.id !== data.id,
      );

    setFilterData(newData);
  };

  // RESET
  const handleResetSelected = () => {
    setFilterData({
      brand: [],
      discount: [],
      category: null,
    });
    setFilterTotalData([]);
  };

  useEffect(() => {
    const { brand, discount, category } = filterData;
    const newData = [
      ...brand.map((item) => ({
        type: 'brand' as const,
        id: item?.id,
        name: item?.nameKorean ?? '',
      })),
      ...discount.map((item) => ({
        type: 'discount' as const,
        id: item?.id,
        name: item?.text ?? '',
      })),
      ...(category
        ? [
            {
              type: 'category' as const,
              id: category?.id,
              name: category?.name ?? '',
            },
          ]
        : []),
    ];

    setFilterTotalData(newData ?? []);
  }, [filterData]);

  return {
    filterData,
    filterTotalData,
    setFilterData,
    handleAddSelected,
    handleDeleteSelected,
    handleResetSelected,
  };
}
