import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import icArrow from '@assets/images/icon_arrow_right_gray.svg';
import Header from '@components/shared/header';
import BottomNav from '@components/shared/bottomNav';
import CheckIsLogin from '@components/checkIsLogin';
import { valids } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import CustomerApi from '@api/customer/customer.query';
// import { faqData, noticeData } from '@temp/customerData';

function Page() {
  const { setLoading } = useStore((state) => state);

  // api
  const { data: callData, isLoading } = CustomerApi.useServiceCenterQuery();
  const resultData = callData?.data?.data;

  useEffect(() => {
    console.log(resultData);
    if (resultData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [resultData, isLoading]);

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="고객센터" back="/mypage" searchCart />
          </div>

          <div className="mypage_customer_wrap pb-24 pt-4">
            <div className="inner">
              <div className="customer_tit">
                <h3>자주찾는 질문 TOP4</h3>
                <Link to="/mypage/customer/faq">
                  더보기
                  <img src={icArrow} alt="arrow" />
                </Link>
              </div>
              <div className="customer_list_area">
                <ul>
                  {(resultData?.faqs ?? [])?.length > 0 ? (
                    resultData?.faqs.map((item, index) => {
                      return (
                        <li key={item.id}>
                          <p>
                            <b>0{index + 1}.</b>
                            <span>{item?.question ?? '-'}</span>
                          </p>
                        </li>
                      );
                    })
                  ) : (
                    <li className="no_data_text">
                      <p>{valids.noDataFaq}</p>
                    </li>
                  )}
                </ul>
              </div>

              <div className="banner_area mt-8">
                <a
                  href="http://pf.kakao.com/_eGJxgG/chat"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>간편하게 카톡으로 문의하세요!</p>
                  <span>렌티크 카톡 채널로 언제든지 문의해보세요.</span>
                </a>
              </div>

              <div className="customer_tit mt-8">
                <h3>공지사항</h3>
                <Link to="/mypage/customer/notice">
                  더보기
                  <img src={icArrow} alt="arrow" />
                </Link>
              </div>
              <div className="customer_list_area">
                <ul>
                  {(resultData?.notices ?? [])?.length > 0 ? (
                    resultData?.notices.map((item) => {
                      return (
                        <li key={item.id}>
                          <span>
                            {format(item?.createdAt ?? '', 'yyyy.MM.dd')}
                          </span>
                          <p>{item?.title ?? ''}</p>
                        </li>
                      );
                    })
                  ) : (
                    <li className="no_data_text">
                      <p>{valids.noDataNotice}</p>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <BottomNav active="MYPAGE" />
        </div>
      </div>
    </>
  );
}
export default Page;
