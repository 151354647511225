import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useForm, SubmitHandler } from 'react-hook-form';
import Header from '@components/shared/header';
import MultiImgUpload from '@components/forms/multiImgUpload';
import { common, valids } from '@config/index';
// import { sleep } from '@utils/common';
import useStore from '@store/index';
import CommonDto from '@api/common.dto';
import PaymentDto from '@api/payment/payment.dto';
import PaymentApi from '@api/payment/payment.query';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  productData?: CommonDto.Product;
}

export default function OrderInquiryModal({
  open,
  setOpen,
  productData,
}: Props) {
  const { setLoading, setToastContainerStyle } = useStore((state) => state);
  const { register, handleSubmit, reset } =
    useForm<PaymentDto.Product.InquiryRequest>();
  const [files, setFiles] = useState<(File | null)[]>([null]);

  // api
  const useProductInquiriesMutation = PaymentApi.useProductInquiriesMutation();

  const onFormSubmit: SubmitHandler<PaymentDto.Product.InquiryRequest> = (
    data,
  ) => {
    if (!productData?.id) return;

    setLoading(true);
    const filteredFiles = files?.filter((item) => item !== null);
    const imageMap: { [key: string]: File } = {};
    filteredFiles.forEach((item, index) => {
      imageMap[`inquiriesFile${index + 1}`] = item as File;
    });
    const formData = { ...data, ...imageMap, productId: productData.id };

    // console.log(formData);
    useProductInquiriesMutation.mutate(formData);
  };

  // ERROR !!!
  useEffect(() => {
    if (!useProductInquiriesMutation.isError) return;
    setLoading(false);
    setToastContainerStyle({ bottom: 84 });
    toast(valids.errored, { style: common.toastStyle });
  }, [useProductInquiriesMutation.isError]);

  // 상품문의하기 SUCCESS !!!
  useEffect(() => {
    if (!useProductInquiriesMutation.isSuccess) return;
    setLoading(false);
    setToastContainerStyle({ bottom: 16 });
    toast(valids.inquirySaved, { style: common.toastStyle });
    setOpen(false);
  }, [useProductInquiriesMutation.isSuccess]);

  useEffect(() => {
    if (open) return;

    reset();
    setFiles([null]);
  }, [open]);

  return open ? (
    <div className="modal_wrap full">
      <div className="modal_area">
        <Header title="상품 문의하기" closeFunc={() => setOpen(false)} />

        <div className="order_inquiry_wrap">
          <div className="inner">
            {productData && (
              <div className="item_area items-cente mb-6">
                <div className="img_wrap w-17.5">
                  <div className="img_area">
                    {productData?.product_images?.length > 0 ? (
                      <img
                        src={`${common.imageUrl}/${productData?.product_images?.[0]?.file?.fileUrl}`}
                        alt="상품 이미지"
                      />
                    ) : (
                      <i className="no_data_img" />
                    )}
                  </div>
                </div>
                <div className="text_area">
                  <p className="brand">
                    {productData?.brand?.nameEnglish.toUpperCase() ?? ''}
                  </p>
                  <p className="product">{productData?.productName ?? ''}</p>
                </div>
              </div>
            )}
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div>
                <div className="label_input_area">
                  <label htmlFor="title">제목</label>
                  <input
                    type="text"
                    id="title"
                    className="input"
                    placeholder={valids.inputTitle}
                    {...register('title', { required: true })}
                  />
                </div>
                <div className="label_input_area mt-4">
                  <label htmlFor="content">내용</label>
                  <textarea
                    id="content"
                    className="textarea"
                    cols={30}
                    rows={9}
                    placeholder={valids.inputContent}
                    {...register('content', { required: true })}
                  />
                </div>
              </div>
              <div className="mt-4">
                <MultiImgUpload files={files} setFiles={setFiles} />
              </div>

              <div className="fixed_btm_btn">
                <div className="fixed_btn bg_style01">
                  <button
                    type="submit"
                    className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                  >
                    등록
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
