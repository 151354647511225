const MypageQueryKey = {
  MYPAGE: 'MYPAGE',
  MYPAGE_AVAILABLE_COUPON: 'MYPAGE_AVAILABLE_COUPON',
  MYPAGE_DOWNLOADABLE_COUPON: 'MYPAGE_DOWNLOADABLE_COUPON',
  MYPAGE_POINT: 'MYPAGE_POINT',
  MYPAGE_REVIEW: 'MYPAGE_REVIEW',
  MYPAGE_WRITABLE_REVIEW: 'MYPAGE_WRITABLE_REVIEW',
  MYPAGE_WRITTEN_REVIEW: 'MYPAGE_WRITTEN_REVIEW',
  MYPAGE_INQUIRY: 'MYPAGE_INQUIRY',
  MYPAGE_PRODUCT_INQUIRY: 'MYPAGE_PRODUCT_INQUIRY',
  MYPAGE_ONETOONE_INQUIRY: 'MYPAGE_ONETOONE_INQUIRY',
  MYPAGE_PASSWORD_CHECK: 'MYPAGE_PASSWORD_CHECK',
  MYPAGE_WITHDRAWAL: 'MYPAGE_WITHDRAWAL',
  MYPAGE_INFO: 'MYPAGE_INFO',
  MYPAGE_PASSWORD: 'MYPAGE_PASSWORD',
};

export default MypageQueryKey;
