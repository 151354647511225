import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import icBag from '@assets/images/deco/icon_join_complete.svg';
import Header from '@components/shared/header';
import { common } from '@config/index';

function Page() {
  const [name, setName] = useState('');

  const onStorageDelete = () => sessionStorage.removeItem(common.PASS_DATA_KEY);
  useEffect(() => {
    setName(sessionStorage.getItem(common.PASS_DATA_KEY) ?? '신규 회원');
  }, []);

  return (
    <div id="root_layout">
      <div className="root_container">
        <div className="sticky_menu">
          <Header title="가입완료" />
        </div>

        <div className="join_complete_page_wrap auth_wrap type02">
          <div className="text_area">
            <img src={icBag} alt="쇼핑백" />
            <p>{name}님, 환영합니다!</p>
            <span>렌티크에서 럭셔리 렌트 생활을 즐겨보아요!</span>
          </div>

          <div className="fixed_btm_btn">
            <div className="fixed_btn bg_style01 flex flex-col gap-2">
              <Link
                to="/promotion/detail/new"
                className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                onClick={onStorageDelete}
              >
                첫 렌트 주문 쿠폰팩 받기
              </Link>
              <Link
                to="/"
                className="btn btn_style03 h-12 w-full rounded-sm text-sm font-bold"
                onClick={onStorageDelete}
              >
                메인으로 가기
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page;
