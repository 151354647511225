import React, { JSXElementConstructor, ReactElement, useEffect } from 'react';
import { common } from '@config/index';
import AuthApi from '@api/auth/auth.query';

export type AuthType = {
  name: string;
  gender: string;
  phoneNumber: string;
  birthDate: string;
};

interface Props {
  children: ReactElement<
    { onClick: () => void },
    string | JSXElementConstructor<any>
  >;
  setData: React.Dispatch<React.SetStateAction<AuthType | null>>;
  setIsData?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PassAuthWrap({ children, setData, setIsData }: Props) {
  // api
  const resultData = AuthApi.useIdentityVerificationQuery()?.data?.data?.data;

  const handlePopupOpen = () => {
    window.open(
      '/pass',
      'checkPopup',
      'width=480, height=800, top=0, left=0, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no',
    );

    // form
    const form = document.createElement('form');
    form.method = 'post';
    form.action = common.passApiUrl;
    form.target = 'checkPopup';

    const inputs = [
      { name: 'm', value: 'service' },
      { name: 'token_version_id', value: resultData?.token_version_id ?? '' },
      { name: 'enc_data', value: resultData?.enc_data ?? '' },
      { name: 'integrity_value', value: resultData?.integrity_value ?? '' },
    ];

    inputs.forEach((inputData) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = inputData.name;
      input.value = inputData.value;
      form.classList.add('hidden_input');
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
  };

  useEffect(() => {
    window.name = 'parentWindow';

    // 본인인증 SUCCESS !!!
    const handleMessage = (event: MessageEvent) => {
      if (event.data?.key === common.PASS_DATA_KEY) {
        setData(event.data);
        setIsData?.(true);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return React.cloneElement(children, {
    onClick: handlePopupOpen,
  });
}
