import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import icSearch from '@assets/images/icon_search.svg';
import Header from '@components/shared/header';
import HeaderNav from '@components/shared/headerNav';
import Footer from '@components/shared/footer';
import BottomNav from '@components/shared/bottomNav';
import TabBtnSlider from '@components/slider/tabBtn';
import { common, tabs, valids } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import BrandApi from '@api/brand/brand.query';
// import { brandData } from '@temp/brandData';

function Page() {
  const { setLoading } = useStore((state) => state);
  const [tab, setTab] = useState(0);
  const [sortsTab, setSortsTab] = useState(tabs.brandSearchTabs[1].id);
  const [searchInput, setSearchInput] = useState('');

  // api
  const { data: callData, isLoading } = BrandApi.useRecommendBrandsQuery();
  const resultData = callData?.data?.data?.result;
  const { data: callData2, isLoading: isLoading2 } =
    BrandApi.useSearchBrandsQuery({
      brandName: searchInput !== '' ? searchInput : undefined,
      order: sortsTab === tabs.brandSearchTabs[0].id ? 'name' : undefined,
      filterRecommend: sortsTab === tabs.brandSearchTabs[1].id,
      filterVal:
        sortsTab !== tabs.brandSearchTabs[0].id &&
        sortsTab !== tabs.brandSearchTabs[1].id
          ? sortsTab
          : undefined,
    });
  const resultData2 = callData2?.data?.data?.result;

  useEffect(() => {
    if (
      (tab === 0 && resultData !== undefined && !isLoading) ||
      (tab === 1 && resultData2 !== undefined && !isLoading2)
    ) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [tab, resultData, isLoading, resultData2, isLoading2]);

  return (
    <div id="root_layout">
      <div className="root_container">
        <div className="sticky_menu">
          <Header logo searchCart />
          <HeaderNav active="BRAND" />
        </div>

        <div className="pb-10 pt-4">
          {/* 탭 */}
          <div className="tab_area02 grid02 pb-6">
            <div className="inner">
              <ul>
                <li className={tab === 0 ? 'on' : ''}>
                  <button type="button" onClick={() => setTab(0)}>
                    추천 브랜드
                  </button>
                </li>
                <li className={tab === 1 ? 'on' : ''}>
                  <button type="button" onClick={() => setTab(1)}>
                    브랜드 검색
                  </button>
                </li>
              </ul>
            </div>
          </div>
          {tab === 0 ? (
            <>
              {/* 추천 브랜드 - 최대 20개 */}
              <div className="list_area list_style03 grid03">
                <div className="inner">
                  <ul>
                    {(resultData ?? [])?.length > 0 ? (
                      resultData?.map((item) => {
                        return (
                          <li key={item.id}>
                            <Link
                              to={`/brand/product?brandId=${item.id}&brandName=${item.nameKorean}`}
                            >
                              <div className="img_area w-17.5">
                                {item?.image_file ? (
                                  <img
                                    src={`${common.imageUrl}/${item?.image_file?.fileUrl}`}
                                    alt="브랜드 로고"
                                  />
                                ) : (
                                  <i className="no_data_img" />
                                )}
                              </div>
                              <p>{item?.nameEnglish ?? ''}</p>
                              <span>{item?.nameKorean ?? ''}</span>
                            </Link>
                          </li>
                        );
                      })
                    ) : (
                      <li className="no_data_text">
                        <p>{valids.noData}</p>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* 브랜드 검색 */}
              <div className="inner">
                <div className="search_area">
                  <img src={icSearch} alt="검색" />
                  <input
                    type="text"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    className="input"
                    placeholder={valids.inputBrand}
                  />
                </div>
              </div>
              <div className="tab_area is_pdx">
                <TabBtnSlider
                  data={tabs.brandSearchTabs}
                  active={sortsTab}
                  setActive={setSortsTab}
                />
              </div>
              <div className="list_area list_style04">
                <div className="inner">
                  <ul>
                    {(resultData2 ?? [])?.length > 0 ? (
                      resultData2?.map((item) => {
                        return (
                          <li key={item.id}>
                            <Link
                              to={`/brand/product?brandId=${item.id}&brandName=${item.nameKorean}`}
                            >
                              <p>{item?.nameEnglish ?? ''}</p>
                              <span>{item?.nameKorean ?? ''}</span>
                            </Link>
                          </li>
                        );
                      })
                    ) : (
                      <li className="no_data_text">
                        <p>{valids.noDataSearch}</p>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </>
          )}
        </div>

        <Footer btmNav />
        <BottomNav />
      </div>
    </div>
  );
}
export default Page;
