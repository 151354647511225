import { useQuery } from '@tanstack/react-query';
import { useAxios, Utils } from '@api/index';
import RankingQueryKey from '@api/ranking/ranking.key';
import RankingDto from '@api/ranking/ranking.dto';
import CommonDto from '@api/common.dto';

namespace RankingApi {
  /**
   * 랭킹 상품 조회
   * @param {RankingDto.Ranking.Request} body - Body
   */
  export function useRankingQuery(params: RankingDto.Ranking.Request) {
    const axios = useAxios();

    return useQuery({
      queryKey: [RankingQueryKey.RANKING, params],
      queryFn() {
        return axios.get<Utils.View.ResponseResult<CommonDto.Product[]>>(
          '/api/users/ranking-products',
          { params },
        );
      },
    });
  }
}

export default RankingApi;
