import React from 'react';
import Header from '@components/shared/header';
import TermsOfService from '@components/agree/termsOfService';

function Page() {
  return (
    <div id="root_layout">
      <div className="root_container">
        <Header back />

        <section className="agree_page_wrap">
          <div className="inner">
            <div className="title_area">
              <h3>렌티크 서비스 이용 약관</h3>
            </div>
            <div className="agree_area">
              <TermsOfService />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default Page;
