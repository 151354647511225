import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useAxios, Utils } from '@api/index';
import CouponQueryKey from '@api/coupon/coupon.key';
import CouponDto from '@api/coupon/coupon.dto';

namespace CouponApi {
  /**
   * 쿠폰 리스트
   * @param {number} productId - 상품 ID
   */
  export function useCouponQuery(productId?: number) {
    const axios = useAxios();

    return useQuery({
      queryKey: [CouponQueryKey.COUPON, productId],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<CouponDto.Coupons.Response[]>
        >(`/api/users/coupons-list/${productId}`);
      },
      enabled: !!productId,
    });
  }

  /**
   * 쿠폰 다운로드
   * @param {CouponDto.Coupons.DownloadRequest} body - Body
   */
  export function useCouponDownloadMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation({
      mutationFn(body: CouponDto.Coupons.DownloadRequest) {
        return axios.post<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>,
          CouponDto.Coupons.DownloadRequest
        >(`/api/users/coupons-download/${body.couponId}`, body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [CouponQueryKey.COUPON_DOWNLOAD],
        });
      },
    });
  }
}

export default CouponApi;
