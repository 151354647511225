import React, { ReactNode, useRef, useState } from 'react';

interface Props {
  title: string | JSX.Element | JSX.Element[];
  children: ReactNode;
  initOpen?: boolean;
}

export default function ToggleItem({
  title,
  children,
  initOpen = true,
}: Props) {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(initOpen);

  return (
    <div className={`toggle_area02 ${open ? 'on' : ''}`}>
      <div className="t_tit">{title}</div>
      {open && (
        <div className="t_content" ref={contentRef}>
          {children}
        </div>
      )}
      <button
        type="button"
        className="btn btn_style01 mt-4 h-10 w-full rounded-sm text-sm font-medium"
        onClick={() => setOpen((state) => !state)}
      >
        내용 {open ? '닫기' : '보기'}
      </button>
    </div>
  );
}
