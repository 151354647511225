import React, { ReactNode, useState } from 'react';
import ViewImageModal from '@components/modal/viewImage';

interface Props {
  children: ReactNode;
  imgSrc: string;
}

export default function LargeImage({ children, imgSrc }: Props) {
  const [imageOpen, setImageOpen] = useState(false);
  return (
    <>
      <button type="button" onClick={() => setImageOpen(true)}>
        {children}
      </button>
      <ViewImageModal open={imageOpen} setOpen={setImageOpen} imgSrc={imgSrc} />
    </>
  );
}
