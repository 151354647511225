import React from 'react';
import { useNavigate } from 'react-router-dom';
import icCheck from '@assets/images/deco/icon_check.svg';
import Header from '@components/shared/header';

function Page() {
  const navigate = useNavigate();

  return (
    <div id="root_layout">
      <div className="root_container">
        <div className="sticky_menu">
          <Header title="럭스쉐어 신청 완료" />
        </div>

        <div className="lux_complete_wrap">
          <div className="inner">
            <div>
              <div className="text-center">
                <img src={icCheck} alt="check" className="mx-auto" />
                <h3 className="mt-8 text-base font-bold text-black">
                  위탁 신청이 완료되었습니다!
                </h3>
              </div>
              <div className="mt-8 rounded-sm bg-main-02 p-4">
                <p className="text-xs font-bold text-black">안내사항</p>
                <div className="disc_text_area mt-1 break-keep">
                  <ul>
                    <li className="text-xs">
                      신청 후 3일 내에 발송해주셔야 합니다.
                    </li>
                    <li className="text-xs">
                      렌트백에서 택배 수령 후 검수기간 3일 이상 소요될 수
                      있습니다.
                    </li>
                    <li className="text-xs">
                      확인 후 검수 결과는 알림톡으로 별도 안내 드립니다.
                    </li>
                    <li className="text-xs">
                      별도 문의사항은 고객센터로 연락 부탁드립니다.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="fixed_btm_btn">
              <div className="fixed_btn p-4">
                <button
                  type="button"
                  className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                  onClick={() => navigate('/')}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page;
