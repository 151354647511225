import { useQuery } from '@tanstack/react-query';
import { useAxios, Utils } from '@api/index';
import SearchQueryKey from '@api/search/search.key';
import SearchDto from '@api/search/search.dto';
import CommonDto from '@api/common.dto';

namespace SearchApi {
  /**
   * 추천 검색어 조회
   */
  export function useRecommendSearchQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [SearchQueryKey.RECOMMEND_SEARCH],
      queryFn() {
        return axios.get<
          Utils.View.Response<SearchDto.RecommendSearchResponse>
        >('/api/users/recommend-search');
      },
    });
  }

  /**
   * 연관 검색어 조회
   * @param {SearchDto.Search.Request} body - Body
   */
  export function useSearchQuery(params: SearchDto.Search.Request) {
    const axios = useAxios();

    return useQuery({
      queryKey: [SearchQueryKey.SEARCH, params],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<SearchDto.Search.Response[]>
        >('/api/users/search-keyword', { params });
      },
    });
  }

  /**
   * 검색 결과 조회
   * @param {SearchDto.SearchResult.Request} body - Body
   */
  export function useSearchResultQuery(params: SearchDto.SearchResult.Request) {
    const axios = useAxios();

    return useQuery({
      queryKey: [SearchQueryKey.SEARCH_RESULT, params],
      queryFn() {
        return axios.get<Utils.View.ResponseResult<CommonDto.Product[]>>(
          '/api/users/search-result',
          { params },
        );
      },
    });
  }
}

export default SearchApi;
