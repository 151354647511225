import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import icKakao from '@assets/images/icon_kakao.svg';
import icNaver from '@assets/images/icon_naver.svg';
import icApple from '@assets/images/icon_apple.svg';
import IsJoinModal, { IsJoinDataType } from '@components/modal/isJoin';
import { common } from '@config/index';

export default function LoginSns() {
  const navigate = useNavigate();
  const [infoData, setInfoData] = useState<IsJoinDataType | null>(null);
  const [infoOpen, setInfoOpen] = useState(false);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data?.providerType) {
        if (event.data?.isJoin) {
          setInfoData({
            providerType: event.data?.providerType,
            email: event.data?.email ?? '',
          });
          setInfoOpen(true);
          return;
        }
        sessionStorage.setItem(common.SNS_DATA_KEY, JSON.stringify(event.data));
        navigate('/join/agree');
      } else if (event.data?.name) {
        window.location.reload();
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <>
      <ul className="btns_style02">
        <li>
          <button
            type="button"
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_API_URL}/api/auth/kakao`,
                'kakaoPopup',
                'width=480, height=800, top=0, left=0, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no',
              )
            }
          >
            <img src={icKakao} alt="kakao" />
            카카오로 로그인
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_API_URL}/api/auth/naver`,
                'naverPopup',
                'width=480, height=800, top=0, left=0, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no',
              )
            }
          >
            <img src={icNaver} alt="naver" />
            네이버로 로그인
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_API_URL}/api/auth/apple`,
                'applePopup',
                'width=480, height=800, top=0, left=0, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no',
              )
            }
          >
            <img src={icApple} alt="apple" />
            Apple로 로그인
          </button>
        </li>
      </ul>
      <IsJoinModal
        open={infoOpen}
        setOpen={setInfoOpen}
        data={infoData}
        onConfirmClick={() => navigate('/login')}
      />
    </>
  );
}
