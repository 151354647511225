import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Header from '@components/shared/header';
// import BottomNav from '@components/shared/bottomNav';
import CustomSelect from '@components/forms/customSelect';
import MsgModal from '@components/modal/msg';
import CheckIsLogin from '@components/checkIsLogin';
import { common, regex, valids } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import AccountDto from '@api/account/account.dto';
import AccountApi from '@api/account/account.query';

function Page() {
  const { setLoading, setToastContainerStyle } = useStore((state) => state);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<AccountDto.Account>();
  const [bankCode, setBankCode] = useState('empty');
  const [isBankCodeError, setIsBankCodeError] = useState(false);
  const [verifiedError, setVerifiedError] = useState('');
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgContents, setMsgContents] = useState<
    JSX.Element | JSX.Element[] | null
  >(null);
  const [msgBtns, setMsgBtns] = useState<JSX.Element | JSX.Element[] | null>(
    null,
  );

  // api
  const bankData =
    AccountApi.useBankInfoQuery()?.data?.data?.data?.result ?? [];
  const {
    data: callData,
    isLoading,
    refetch,
  } = AccountApi.useAccountInfoQuery();
  const resultData = callData?.data?.data;
  const useAccountVerifyMutation = AccountApi.useAccountVerifyMutation();
  const useUpdateAccountInfoMutation =
    AccountApi.useUpdateAccountInfoMutation();

  const onFormSubmit: SubmitHandler<AccountDto.Account> = (data) => {
    if (bankCode === 'empty') {
      setIsBankCodeError(true);
      return;
    }

    setLoading(true);
    const formData = {
      ...data,
      bankCode: bankCode ?? '',
    };
    useAccountVerifyMutation.mutate(formData);
  };

  const handleSave = () => {
    setLoading(true);
    const bankName = bankData?.filter((item) => item.bankCode === bankCode)?.[0]
      ?.bankName;
    const formData = {
      ...watch(),
      bankCode: bankCode ?? '',
      bankName: bankName ?? '',
    };
    useUpdateAccountInfoMutation.mutate(formData);
  };

  const handleModalOpen = (btn: string) => {
    switch (btn) {
      case '계좌인증':
        setMsgContents(
          <>
            <p className="tit">계좌인증</p>
            <span className="desc">계좌인증을 진행해주세요.</span>
          </>,
        );
        setMsgBtns(null);
        break;
      case '저장':
        setMsgContents(
          <>
            <p className="tit">저장</p>
            <span className="desc">계좌정보를 저장하시겠습니까?</span>
          </>,
        );
        setMsgBtns(
          <button
            type="button"
            className="btn btn_style02 h-10 w-full rounded-sm text-sm font-bold"
            onClick={handleSave}
          >
            확인
          </button>,
        );
        break;
      default:
        break;
    }
    setMsgOpen(true);
  };

  // ERROR !!!
  useEffect(() => {
    if (!useAccountVerifyMutation.isError) return;
    setVerifiedError(
      useAccountVerifyMutation.error?.response?.data?.message ?? valids.errored,
    );
    setLoading(false);
  }, [useAccountVerifyMutation.isError]);

  // 계좌 인증 SUCCESS !!!
  useEffect(() => {
    if (!useAccountVerifyMutation.isSuccess) return;
    setVerifiedError(valids.accountSuccess);
    setLoading(false);
  }, [useAccountVerifyMutation.isSuccess]);

  // 계좌 등록 SUCCESS !!!
  useEffect(() => {
    if (!useUpdateAccountInfoMutation.isSuccess) return;
    refetch();
    setVerifiedError('');
    setMsgOpen(false);
    setLoading(false);
    setToastContainerStyle({ bottom: 84 });
    toast(valids.saved, { style: common.toastStyle });
  }, [useUpdateAccountInfoMutation.isSuccess]);

  useEffect(() => {
    if (resultData !== undefined && !isLoading) {
      reset({
        accountNumber: resultData?.accountNumber ?? '',
        accountHolderName: resultData?.accountHolderName ?? '',
      });
      setBankCode(resultData?.bankCode ?? 'empty');

      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else {
      reset({
        accountNumber: '',
        accountHolderName: '',
      });
      setBankCode('empty');
      setLoading(true);
    }
  }, [resultData, isLoading]);

  useEffect(() => {
    setVerifiedError('');
  }, [bankCode, watch('accountNumber'), watch('accountHolderName')]);

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="계좌 관리" back="/mypage" searchCart />
          </div>

          <div className="pb-24 pt-4">
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div className="inner">
                <div>
                  <h3 className="text-xs font-medium text-gray-9">계좌 정보</h3>
                  <div className="mt-4 rounded-sm bg-main-02 p-4 text-center">
                    <p className="text-xs text-gray-3">
                      상품 위탁판매대금을 위한 정산 정보를 입력하는 곳이며,{' '}
                      <br />
                      정보를 정확하게 입력해주시기 바랍니다.
                    </p>
                  </div>

                  <div className="label_input_area mt-4">
                    <label htmlFor="bank">
                      은행명
                      <span className="text-main">*</span>
                    </label>
                    <CustomSelect
                      type={3}
                      options={bankData?.map((item) => ({
                        id: item?.bankCode ?? '',
                        text: item?.bankName ?? '',
                      }))}
                      value={bankCode}
                      setValue={setBankCode}
                      placeholder={valids.inputBank}
                      isError={isBankCodeError}
                      setIsError={setIsBankCodeError}
                    />
                  </div>

                  <div className="label_input_area mt-4">
                    <label htmlFor="accountNumber">
                      계좌번호
                      <span className="text-main">*</span>
                    </label>
                    <input
                      type="text"
                      id="accountNumber"
                      className={`input ${errors.accountNumber ? 'error' : ''}`}
                      placeholder={valids.inputAccountNumber}
                      {...register('accountNumber', {
                        required: true,
                        pattern: {
                          value: regex.regAccount,
                          message: valids.inputOnlyNumber,
                        },
                      })}
                    />
                    {errors.accountNumber && (
                      <span className="input_error_text">
                        {errors.accountNumber.message}
                      </span>
                    )}
                  </div>

                  <div className="label_input_area mt-4">
                    <label htmlFor="accountHolderName">
                      예금주
                      <span className="text-main">*</span>
                    </label>
                    <input
                      type="text"
                      id="accountHolderName"
                      className={`input ${
                        errors.accountHolderName ? 'error' : ''
                      }`}
                      placeholder={valids.inputDepositor}
                      {...register('accountHolderName', { required: true })}
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn btn_style01 bold-medium mt-4 h-10 w-full rounded-sm text-sm"
                    disabled={verifiedError === valids.accountSuccess}
                  >
                    계좌 인증
                  </button>

                  {/* TODO: 계좌인증 케이스
                  1. 계좌인증 성공 valids.accountSuccess
                  2. 계좌인증 실패 valids.accountFailed
                */}
                  {verifiedError && (
                    <span className="input_error_text mt-1">
                      {verifiedError}
                    </span>
                  )}
                </div>

                <div className="fixed_btm_btn">
                  <div className="fixed_btn bg_style01">
                    <button
                      type="button"
                      className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                      onClick={() => {
                        if (verifiedError !== valids.accountSuccess)
                          handleModalOpen('계좌인증');
                        else handleModalOpen('저장');
                      }}
                    >
                      저장하기
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <MsgModal open={msgOpen} setOpen={setMsgOpen} btns={msgBtns}>
            {msgContents}
          </MsgModal>

          {/* <BottomNav active="MYPAGE" /> */}
        </div>
      </div>
    </>
  );
}
export default Page;
