import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Header from '@components/shared/header';
import CustomPassword from '@components/forms/customPassword';
import MsgModal from '@components/modal/msg';
import CheckIsLogin from '@components/checkIsLogin';
import { regex, valids } from '@config/index';
import useStore from '@store/index';
import MypageApi from '@api/mypage/mypage.query';
import AuthApi from '@api/auth/auth.query';

interface FormProps {
  password: string;
  withdrawalReason: string;
}

function Page() {
  const navigate = useNavigate();
  const { setLoading, setAlert } = useStore((state) => state);
  const {
    register,
    handleSubmit,
    formState: { errors },
    // setValue,
    watch,
  } = useForm<FormProps>();
  const [msgOpen, setMsgOpen] = useState(false);

  // api
  const goLogut = AuthApi.useLogout();
  const usePasswordCheckMutation = MypageApi.usePasswordCheckMutation();
  const useWithdrawalMutation = MypageApi.useWithdrawalMutation();

  const onFormSubmit = () => {
    setLoading(true);
    usePasswordCheckMutation.mutate({
      password: watch()?.password ?? '',
    });
  };

  // ERROR !!!
  useEffect(() => {
    if (!usePasswordCheckMutation.isError) return;
    setAlert(
      usePasswordCheckMutation.error?.response?.data?.message ?? valids.errored,
    );
    setMsgOpen(false);
    setLoading(false);
  }, [usePasswordCheckMutation.isError]);

  useEffect(() => {
    if (!useWithdrawalMutation.isError) return;
    setAlert(
      useWithdrawalMutation.error?.response?.data?.message ?? valids.errored,
    );
    setLoading(false);
  }, [useWithdrawalMutation.isError]);

  // 비밀번호 체크 SUCCESS !!!
  useEffect(() => {
    if (!usePasswordCheckMutation.isSuccess) return;
    useWithdrawalMutation.mutate({
      withdrawalReason: watch()?.withdrawalReason ?? '',
    });
  }, [usePasswordCheckMutation.isSuccess]);

  // 회원탈퇴 SUCCESS !!!
  useEffect(() => {
    if (!useWithdrawalMutation.isSuccess) return;
    setLoading(false);
    navigate('/mypage/withdraw/complete');
    goLogut();
  }, [useWithdrawalMutation.isSuccess]);

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="회원탈퇴" back="/mypage" searchCart />
          </div>

          <div className="pb-24 pt-4">
            <form onSubmit={handleSubmit(() => setMsgOpen(true))}>
              <div className="inner">
                <div className="rounded-sm bg-main-02 p-4">
                  <h3 className="text-xs font-bold text-black">
                    회원탈퇴 안내
                  </h3>
                  <p className="text-xs text-gray-3">
                    고객님께서 회원 탈퇴를 원하신다니 저희 렌티크 서비스가 많이
                    부족하고 미흡했나 봅니다. <br />
                    불편하셨던 점이나 불만사항을 알려주시면 적극 반영해서
                    고객님의 불편함을 해결해 드리도록 노력하겠습니다.
                  </p>
                  <p className="mt-4 text-xs text-main">
                    아울러 회원 탈퇴 시 아래 사항을 숙지하시기 바랍니다.
                  </p>
                  <div className="mt-4">
                    <p className="flex text-xs text-gray-3">
                      <b className="w-3 font-normal">1.</b>
                      <span className="block flex-1">
                        회원 탈퇴 시 고객님의 정보는 관계 법령의 규정에 의거하여
                        보존할 필요가 있는 경우, 관계 법령에서 정한 일정한
                        기간동안 주문 관련 서비스를 위해 전자상거래 등에서의
                        소비자 보호에 관한 법률에 의거한 고객정보 보호 정책에
                        따라 관리됩니다.
                      </span>
                    </p>
                    <p className="flex text-xs text-gray-3">
                      <b className="w-3 font-normal">2.</b>
                      <span className="block flex-1">
                        탈퇴하신 계정은 복구가 불가하며, 회원 탈퇴 후 3개월간
                        재가입이 불가능합니다.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="label_input_area mt-4">
                  <label htmlFor="password">비밀번호 입력</label>
                  <CustomPassword
                    id="password"
                    className={`input ${errors.password ? 'error' : ''}`}
                    placeholder={valids.inputCurrentPassword}
                    maxLength={16}
                    {...register('password', {
                      required: valids.inputPassword,
                      pattern: {
                        value: regex.regPassword,
                        message: valids.inputPasswordFormat,
                      },
                    })}
                  />
                  {errors.password && (
                    <span className="input_error_text">
                      {errors.password.message}
                    </span>
                  )}
                </div>
                <div className="label_input_area mt-4">
                  <label htmlFor="withdrawalReason">무엇이 불편하였나요?</label>
                  <input
                    type="text"
                    id="withdrawalReason"
                    className="input"
                    placeholder={valids.inputAdvice}
                    {...register('withdrawalReason')}
                  />
                  {/* <textarea
                    id="content"
                    className="textarea"
                    cols={30}
                    rows={9}
                    placeholder={valids.inputAdvice}
                  /> */}
                </div>

                <div className="fixed_btm_btn">
                  <div className="fixed_btn bg_style01">
                    <div className="flex gap-2">
                      <button
                        type="button"
                        className="btn btn_style03 h-12 w-full rounded-sm text-sm font-bold"
                        onClick={() => navigate(-1)}
                      >
                        취소
                      </button>
                      <button
                        type="submit"
                        className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                      >
                        탈퇴
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <MsgModal
        open={msgOpen}
        setOpen={setMsgOpen}
        btns={
          <>
            <button
              type="button"
              className="btn btn_style03 h-10 rounded-sm text-sm font-bold"
              onClick={() => setMsgOpen(false)}
            >
              아니요
            </button>
            <button
              type="button"
              className="btn btn_style02 h-10 rounded-sm text-sm font-bold"
              onClick={onFormSubmit}
            >
              확인
            </button>
          </>
        }
      >
        <p className="tit">회원 탈퇴</p>
        <span className="desc">
          회원탈퇴 시 회원님의 모든 데이터가 삭제됩니다. <br />
          그래도 회원을 탈퇴하시겠습니까?
        </span>
      </MsgModal>
    </>
  );
}
export default Page;
