import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import CustomPassword from '@components/forms/customPassword';
import PassAuthWrap, { AuthType } from '@components/passAuthWrap';
import { valids, regex } from '@config/index';
import useStore from '@store/index';
import AuthDto from '@api/auth/auth.dto';
import AuthApi from '@api/auth/auth.query';

interface Props {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setData: React.Dispatch<React.SetStateAction<AuthDto.Join.Request | null>>;
}

export default function JoinForm({ setStep, setData }: Props) {
  const { setLoading } = useStore((state) => state);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<AuthDto.Join.Request>();
  const [totalData, setTotalData] = useState<AuthDto.Join.Request | null>();
  const [pwConfirm, setPwConfirm] = useState('');
  const [gender, setGender] = useState('');
  const [authData, setAuthData] = useState<AuthType | null>(null);
  const [isAuthData, setIsAuthData] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [pwConfirmError, setPwConfirmError] = useState('');

  // api
  const useEmailDuplicateCheckMutation =
    AuthApi.useEmailDuplicateCheckMutation();

  const onFormSubmit: SubmitHandler<AuthDto.Join.Request> = (data) => {
    if (data.password !== pwConfirm) {
      setPwConfirmError(valids.incorrectPasswordConfirm);
      return;
    }

    setLoading(true);
    useEmailDuplicateCheckMutation.mutate({
      email: data.email,
    });
    setTotalData(data);
  };

  // ERROR !!!
  useEffect(() => {
    if (!useEmailDuplicateCheckMutation.isError) return;
    setEmailError(
      useEmailDuplicateCheckMutation.error?.response?.data?.message ??
        valids.errored,
    );
    setLoading(false);
  }, [useEmailDuplicateCheckMutation.isError]);

  // 이메일 중복 확인 SUCCESS !!!
  useEffect(() => {
    if (!useEmailDuplicateCheckMutation.isSuccess || !totalData) return;
    const formData = { ...totalData };
    if (gender !== '') formData.gender = gender === '1';

    // console.log(formData);
    setData(formData);
    setStep(1);
    setLoading(false);
  }, [useEmailDuplicateCheckMutation.isSuccess]);

  // 본인인증 DATA
  useEffect(() => {
    if (!authData) return;
    setValue('name', authData?.name ?? '');
    setValue('phoneNumber', authData?.phoneNumber ?? '');
    setValue('birthDate', authData?.birthDate ?? '');
    setGender(authData?.gender ?? '');
  }, [authData]);

  useEffect(() => {
    setEmailError('');
  }, [watch('email')]);

  return (
    <div className="join_page_wrap auth_wrap type01">
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="inner">
          <div className="label_input_area">
            <label htmlFor="email">
              이메일 아이디
              <span className="text-main">*</span>
            </label>
            <input
              type="text"
              id="email"
              className={`input ${errors.email || emailError ? 'error' : ''}`}
              placeholder={valids.inputEmail}
              autoComplete="email"
              {...register('email', {
                required: valids.inputEmail,
                pattern: {
                  value: regex.regEmail,
                  message: valids.inputRightlyEmail,
                },
              })}
            />
            {errors.email && (
              <span className="input_error_text">{errors.email.message}</span>
            )}
            {emailError && (
              <span className="input_error_text">{emailError}</span>
            )}
          </div>
          <div className="label_input_area mt-4">
            <label htmlFor="password">
              비밀번호
              <span className="text-main">*</span>
            </label>
            <CustomPassword
              id="password"
              className={`input ${errors.password ? 'error' : ''}`}
              placeholder={valids.inputPassword}
              maxLength={16}
              {...register('password', {
                required: valids.inputPassword,
                pattern: {
                  value: regex.regPassword,
                  message: valids.inputPasswordFormat,
                },
              })}
            />
            {errors.password && (
              <span className="input_error_text">
                {errors.password.message}
              </span>
            )}
          </div>
          <div className="label_input_area mt-4">
            <label htmlFor="password-confirm">
              비밀번호 확인
              <span className="text-main">*</span>
            </label>
            <CustomPassword
              id="password-confirm"
              className={`input ${pwConfirmError ? 'error' : ''}`}
              placeholder={valids.inputPasswordConfirm}
              value={pwConfirm}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPwConfirm(e.target.value);
                setPwConfirmError('');
              }}
            />
            {pwConfirmError && (
              <span className="input_error_text">{pwConfirmError}</span>
            )}
          </div>

          <div className="mt-4">
            {!isAuthData ? (
              <PassAuthWrap setData={setAuthData} setIsData={setIsAuthData}>
                <button type="button" className="vertify vtf_style01">
                  <div>
                    <p>본인 인증</p>
                    <span>본인 휴대폰 번호로 인증</span>
                  </div>
                </button>
              </PassAuthWrap>
            ) : (
              <div className="vertify vtf_style02">
                <div>
                  <p>본인인증</p>
                  <span>본인 인증이 완료되었습니다.</span>
                </div>
              </div>
            )}
          </div>

          <div className="label_input_area mt-4">
            <label htmlFor="name">이름</label>
            <input
              type="text"
              id="name"
              className="input"
              placeholder={valids.autoInputName}
              disabled
              {...register('name')}
            />
          </div>
          <div className="label_input_area mt-4">
            <label htmlFor="phoneNumber">휴대폰 번호</label>
            <input
              type="text"
              id="phoneNumber"
              className="input"
              placeholder={valids.autoInputPhone}
              disabled
              {...register('phoneNumber')}
            />
          </div>
          <div className="label_input_area mt-4">
            <label htmlFor="birthDate">생년월일</label>
            <input
              type="text"
              id="birthDate"
              className="input"
              placeholder={valids.autoInputBirth}
              disabled
              {...register('birthDate')}
            />
          </div>
          <div className="input_list_area grid02 mt-4">
            <ul className="gap-x-2">
              <li>
                <div className="chk_input03">
                  <input
                    type="radio"
                    name="gender"
                    id="gender01"
                    disabled
                    checked={gender === '1'}
                  />
                  <label htmlFor="gender01">남성</label>
                </div>
              </li>
              <li>
                <div className="chk_input03">
                  <input
                    type="radio"
                    name="gender"
                    id="gender02"
                    disabled
                    checked={gender === '0'}
                  />
                  <label htmlFor="gender02">여성</label>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="fixed_btm_btn">
          <div className="fixed_btn bg_style01">
            <button
              type="submit"
              className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
              disabled={
                !(
                  watch('email') &&
                  watch('password') &&
                  pwConfirm &&
                  isAuthData
                )
              }
            >
              다음
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
