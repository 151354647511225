import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { common } from '@config/index';

function Page() {
  const [searchParams] = useSearchParams();
  const spEmail = searchParams.get('email');
  const spSnsId = searchParams.get('snsId');

  useEffect(() => {
    // sns 회원가입 필요 CASE !!!
    const params = {
      providerType: common.SNS_NAVER_KEY,
      email: spEmail,
      snsId: spSnsId,
    };

    window.addEventListener('unload', function () {
      if (window.opener) {
        window.opener.postMessage(params, '*');
      }
    });
    window.close();
  }, []);

  return null;
}
export default Page;
