import { ConfigType, ConfigIdType } from '@config/common';
import CommonDto from '@api/common.dto';
import RankingDto from '@api/ranking/ranking.dto';
import PaymentDto from '@api/payment/payment.dto';
import MypageDto from '@api/mypage/mypage.dto';

/* ===== SELECT OPTIONS & INPUT LIST ===== */
export const productSorts: ConfigIdType<CommonDto.OrderTypes>[] = [
  { id: 'popularity', text: '인기순' },
  { id: 'recent', text: '최신순' },
  { id: 'discount', text: '할인율순' },
  { id: 'priceDesc', text: '가격 높은순' },
  { id: 'priceAsc', text: '가격 낮은순' },
];

export const rankSorts: ConfigIdType<RankingDto.DateTypes>[] = [
  { id: 'days', text: '일간' },
  { id: 'week', text: '주간' },
  { id: 'month', text: '월간' },
];

export const discountRates: ConfigType[] = [
  { id: 'below30', text: '30% 이하 세일' },
  { id: 'between30and50', text: '50~70% 세일' },
  { id: 'between50and70', text: '30~50% 세일' },
  { id: 'above70', text: '70% 이상 세일' },
];

export const deliveryMemos: ConfigType[] = [
  { id: '1', text: '배송 전 연락바랍니다.' },
  { id: '2', text: '부재 시 경비실(관리실)에 맡겨주세요.' },
  { id: '3', text: '부재 시 문 앞에 놓아주세요.' },
  { id: 'etc', text: '메모 직접입력' },
];

export const installments: ConfigType[] = [
  { id: '1', text: '일시불' },
  { id: '2', text: '2개월' },
  { id: '3', text: '3개월' },
  { id: '4', text: '4개월' },
  { id: '5', text: '5개월' },
  { id: '6', text: '6개월' },
  { id: '7', text: '7개월' },
  { id: '8', text: '8개월' },
  { id: '9', text: '9개월' },
  { id: '10', text: '10개월' },
  { id: '11', text: '11개월' },
  { id: '12', text: '12개월' },
];

export const orderStatus: ConfigIdType<CommonDto.OrderStatusTypes>[] = [
  { id: 'new', text: '신규주문' },
  { id: 'ready_for_delivery', text: '배송준비' },
  { id: 'shipped', text: '배송중' },
  { id: 'delivered', text: '배송완료' },
  { id: 'collected', text: '수거완료' },
  { id: 'cancel', text: '예약취소' },
  { id: 'end', text: '렌트종료' },
  { id: 'return_requested', text: '반품신청' },
  { id: 'return_collecting', text: '반품 수거중' },
  { id: 'return_collected', text: '반품 수거완료' },
  { id: 'return_completed', text: ' 반품완료' },
];

export const paymentStatus: ConfigIdType<PaymentDto.PaymentStatus>[] = [
  { id: 'pending', text: '결제 대기' },
  { id: 'completed', text: '결제 완료' },
  { id: 'failed', text: '결제 실패' },
];

export const paymentStatus2: ConfigIdType<PaymentDto.PaymentStatus2>[] = [
  { id: 'done', text: '결제 완료' },
  { id: 'canceled', text: '결제 취소' },
  { id: 'partial_canceled', text: '결제 부분 취소' },
  { id: 'aborted', text: '결제 실패' },
];

export const cancelReasons: ConfigIdType<string>[] = [
  { id: '이용 가격이 너무 비싸요', text: '이용 가격이 너무 비싸요' },
  { id: '상품 상태에 만족하지 않아요', text: '상품 상태에 만족하지 않아요' },
  { id: '상품을 변경하고 싶어요', text: '상품을 변경하고 싶어요' },
  { id: '일정이 변경/취소 되었어요', text: '일정이 변경/취소 되었어요' },
  {
    id: '보증금 발생으로 취고하고 싶어요',
    text: '보증금 발생으로 취고하고 싶어요',
  },
  {
    id: '수령/반납 등 이용 방법이 불편해요',
    text: '수령/반납 등 이용 방법이 불편해요',
  },
  { id: 'etc', text: '직접 입력' },
];

export const deliveryCompanys: ConfigType[] = [
  { id: '우체국택배', text: '우체국택배' },
  { id: 'CJ대한통운', text: 'CJ대한통운' },
  { id: '롯데택배', text: '롯데택배' },
  { id: '한진택배', text: '한진택배' },
  { id: '로젠택배', text: '로젠택배' },
  { id: '경동택배', text: '경동택배' },
];

// TODO: 나중에 안 쓸 것 같음
export const banks: ConfigType[] = [
  { id: '1', text: 'KEB하나은행' },
  { id: '2', text: '국민은행' },
  { id: '3', text: '우리은행' },
];

export const warranties: ConfigType[] = [
  { id: '1', text: '있음' },
  { id: '2', text: '없음' },
];

export const consignmentPeriods: ConfigType[] = [
  { id: 6, text: '6개월' },
  { id: 12, text: '12개월' },
  { id: 24, text: '2년' },
];

export const pointContents: ConfigIdType<MypageDto.PointTextTypes>[] = [
  { id: 'review', text: '리뷰 작성 포인트 적립' },
  { id: 'photo_review', text: '포토리뷰 작성 포인트 적립' },
  { id: 'cancle', text: '취소 포인트' },
  { id: 'usage', text: '렌탈주문 사용' },
  { id: 'expiration', text: '소멸' },
];
