import React, { ReactNode } from 'react';
import Modal from '@components/modal/modal';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
  btns?: JSX.Element | JSX.Element[] | null;
}

export default function MsgModal({ open, setOpen, children, btns }: Props) {
  return open ? (
    <Modal className="msg">
      <div className="modal_mid">{children}</div>
      <div className="modal_btm">
        {btns ?? (
          <button
            type="button"
            className="btn btn_style02 h-10 w-full rounded-sm text-sm font-bold"
            onClick={() => setOpen(false)}
          >
            확인
          </button>
        )}
      </div>
    </Modal>
  ) : null;
}
