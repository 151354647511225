import React from 'react';
import { useNavigate } from 'react-router-dom';
import imgCoupon from '@assets/images/event_coupon_img.png';
import Header from '@components/shared/header';
import BottomNav from '@components/shared/bottomNav';
import AuthApi from '@api/auth/auth.query';

function Page() {
  const navigate = useNavigate();

  // api
  const isLogin = AuthApi.useIsLogin();

  // LOGIN !!!
  if (isLogin) {
    window.history.back();
    return null;
  }

  return (
    <div id="root_layout">
      <div className="root_container">
        <div className="sticky_menu">
          <Header back searchCart />
        </div>

        {/* 상세 */}
        <div className="pb-17.5">
          <div className="event_con_wrap01">
            <p>
              지금 렌트백 가입하면 <br />
              누구나 최대 70% <br />첫 렌트 할인
            </p>
            <span>렌트 주문이 처음인 고객님께만 드리는 웰컴선물</span>
          </div>
          <div className="event_con_wrap02">
            <div className="inner">
              <p>
                원하는 날짜에 맞춰 <br />
                명품을 빌리고 싶다면?
              </p>
              <span>렌트백 신규 고객께만 드리는 쿠폰 받아가세요!</span>
              <img src={imgCoupon} alt="welcome gift 70% coupon" />
              <button
                type="button"
                onClick={() =>
                  navigate(
                    `/login?returnUrl=${encodeURIComponent('/mypage/coupon')}`,
                    { replace: true },
                  )
                }
              >
                다운 받고 쇼핑하러 가기
              </button>
            </div>
          </div>
          <div className="event_con_wrap03">
            <div className="text_area">
              <p>유의사항</p>
              <div className="disc_text_area mt-1 break-keep">
                <ul>
                  <li className="text-xs">
                    쿠폰 발급 대상은 신규가입 고객 대상입니다.
                  </li>
                  <li className="text-xs">
                    일부 상품은 쿠폰 사용에 제한이 있을 수 있습니다.
                  </li>
                  <li className="text-xs">쿠폰의 유효기간을 확인해주세요.</li>
                  <li className="text-xs">
                    본 혜택은 당사의 사정에 따라 임의 변경 또는 종료 될 수
                    있습니다.
                  </li>
                  <li className="text-xs">
                    탈퇴 후 재가입을 통해 신규회원 대상 혜택을 반복 수령하는
                    경우 해당 쿠폰 발급 및 사용이 제한될 수 있습니다.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <BottomNav />
      </div>
    </div>
  );
}
export default Page;
