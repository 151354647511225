import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import imgLogo from '@assets/images/shared/logo.svg';
import icBack from '@assets/images/shared/header/icon_back.svg';
import icHome from '@assets/images/shared/header/icon_home.svg';
import icSearch from '@assets/images/shared/header/icon_search.svg';
import icHeart from '@assets/images/shared/header/icon_heart.svg';
import icCart from '@assets/images/shared/header/icon_cart_off.svg';
import icClose from '@assets/images/icon_modal_x.svg';
import AuthApi from '@api/auth/auth.query';
import CartApi from '@api/cart/cart.query';

interface Props {
  title?: string; // 타이틀명
  logo?: boolean; // 로고
  back?: boolean | string; // history back | 이전 페이지 경로
  backFunc?: () => void; // back 함수
  home?: boolean; // 메인
  searchCart?: boolean; // 검색 & 장바구니
  interest?: boolean; // 관심목록
  closeFunc?: () => void; // 닫기
}

export default function Header({
  title,
  logo,
  back,
  backFunc,
  home,
  searchCart,
  interest,
  closeFunc,
}: Props) {
  const navigate = useNavigate();
  const [isCart, setIsCart] = useState(false);

  // api
  const isLogin = AuthApi.useIsLogin();
  const cartData = CartApi.useCartQuery()?.data?.data?.data?.result ?? [];

  useEffect(() => {
    if (isLogin && cartData.length > 0) setIsCart(true);
    else setIsCart(false);
  }, [isLogin]);

  return (
    <header id="header">
      <div className="inner">
        {logo ? (
          <h1>
            <Link to="/">
              <img src={imgLogo} alt="RENTIQUE LOGO" />
            </Link>
          </h1>
        ) : (
          <div className="hd_left">
            {back && (
              <button
                type="button"
                onClick={() => {
                  if (back === true) {
                    if (backFunc) {
                      backFunc();
                    } else {
                      navigate(-1);
                    }
                  } else {
                    navigate(back);
                  }
                }}
              >
                <img src={icBack} alt="이전 페이지 이동" />
              </button>
            )}
            {home && (
              <Link to="/">
                <img src={icHome} alt="메인 페이지 이동" />
              </Link>
            )}
          </div>
        )}
        <h1 className="hd_title">{title ?? ''}</h1>
        <div className="hd_right">
          {searchCart && (
            <button type="button" onClick={() => navigate('/search')}>
              <img src={icSearch} alt="검색 페이지 이동" />
            </button>
          )}
          {interest && (
            <button type="button" onClick={() => navigate('/interest')}>
              <img src={icHeart} alt="관심목록 페이지 이동" />
            </button>
          )}
          {searchCart && (
            <button
              type="button"
              className={`cart_btn ${isCart ? 'on' : ''}`}
              onClick={() => navigate('/cart')}
            >
              <img src={icCart} alt="장바구니 페이지 이동" />
            </button>
          )}
          {closeFunc && (
            <button type="button" onClick={closeFunc}>
              <img src={icClose} alt="닫기" />
            </button>
          )}
        </div>
      </div>
    </header>
  );
}
