import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import Header from '@components/shared/header';
import CheckIsLogin from '@components/checkIsLogin';
import { regex, valids } from '@config/index';
import { maskEmail } from '@utils/common';
import useStore from '@store/index';
import AuthDto from '@api/auth/auth.dto';
import AuthApi from '@api/auth/auth.query';

function Page() {
  const { setLoading } = useStore((state) => state);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<AuthDto.FindEmail.Request>();
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState('');
  const [phoneError, setPhoneError] = useState('');

  // api
  const useFindEmailMutation = AuthApi.useFindEmailMutation();

  const onFormSubmit: SubmitHandler<AuthDto.FindEmail.Request> = (data) => {
    // console.log(data);
    setLoading(true);
    useFindEmailMutation.mutate(data);
  };

  // ERROR !!!
  useEffect(() => {
    if (!useFindEmailMutation.isError) return;
    setPhoneError(valids.noMatchingUser);
    setLoading(false);
  }, [useFindEmailMutation.isError]);

  // 이메일 찾기 SUCCESS !!!
  useEffect(() => {
    if (!useFindEmailMutation.isSuccess) return;
    const data = useFindEmailMutation?.data?.data?.data?.email ?? '';
    setEmail(maskEmail(data));
    setStep(1);
    setLoading(false);
  }, [useFindEmailMutation.isSuccess]);

  useEffect(() => {
    setPhoneError('');
  }, [watch('phoneNumber')]);

  return (
    <>
      <CheckIsLogin loginCheck />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="이메일 아이디 찾기" back="/login" />
          </div>

          {step === 0 ? (
            <div className="auth_wrap type01">
              <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className="inner">
                  <p className="desc_text">
                    가입 시 본인 인증한 휴대폰 번호를 입력하시면 <br />
                    이메일 아이디를 알려드립니다.
                  </p>
                  <div className="label_input_area">
                    <label htmlFor="phoneNumber">휴대폰번호</label>
                    <input
                      type="text"
                      id="phoneNumber"
                      className={`input ${
                        errors.phoneNumber || phoneError ? 'error' : ''
                      }`}
                      placeholder={valids.inputOnlyNumberNoBar}
                      maxLength={11}
                      {...register('phoneNumber', {
                        required: true,
                        maxLength: 11,
                        pattern: {
                          value: regex.regPhone,
                          message: valids.checkPhone,
                        },
                      })}
                    />
                    {errors.phoneNumber && (
                      <span className="input_error_text">
                        {errors.phoneNumber.message}
                      </span>
                    )}
                    {phoneError && (
                      <span className="input_error_text">{phoneError}</span>
                    )}
                  </div>
                </div>

                <div className="fixed_btm_btn">
                  <div className="fixed_btn bg_style01">
                    <button
                      type="submit"
                      className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                      disabled={
                        !(
                          watch('phoneNumber') &&
                          watch('phoneNumber')?.length >= 10
                        )
                      }
                    >
                      이메일 아이디 찾기
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className="auth_wrap type02">
              <div className="inner">
                <p className="desc_text">
                  가입하신 아이디(이메일) 정보를 알려드립니다.
                </p>
                <div className="flex h-16 w-full items-center justify-center gap-1 rounded-sm bg-gray-f4 px-2">
                  <p className="text-sm text-black">{email}</p>
                </div>
              </div>

              <div className="fixed_btm_btn">
                <div className="fixed_btn bg_style01 flex flex-col gap-2">
                  <Link
                    to="/find-password"
                    className="btn btn_style03 h-12 w-full rounded-sm text-sm font-bold"
                  >
                    비밀번호 찾기
                  </Link>
                  <Link
                    to="/login"
                    className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                  >
                    로그인
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default Page;
