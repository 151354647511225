import React, { useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import imgLogo from '@assets/images/login_logo.svg';
import CustomPassword from '@components/forms/customPassword';
import LoginSns from '@app/auth/login/sns';
import CheckIsLogin from '@components/checkIsLogin';
import { regex, valids } from '@config/index';
import useStore from '@store/index';
import AuthDto from '@api/auth/auth.dto';
import AuthApi from '@api/auth/auth.query';

function Page() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const spReturnUrl = searchParams.get('returnUrl');
  const { setLoading, setAlert } = useStore((state) => state);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<AuthDto.Login.Request>();

  // api
  const useLoginMutation = AuthApi.useLoginMutation();

  const onFormSubmit: SubmitHandler<AuthDto.Login.Request> = (data) => {
    setLoading(true);
    useLoginMutation.mutate(data);
  };

  // ERROR !!!
  useEffect(() => {
    if (!useLoginMutation.isError) return;
    setAlert(useLoginMutation.error?.response?.data?.message ?? valids.errored);
    setLoading(false);
  }, [useLoginMutation.isError, useLoginMutation.error]);

  // 로그인 SUCCESS !!!
  useEffect(() => {
    if (!useLoginMutation.isSuccess) return;
    navigate(spReturnUrl ?? '/');
    setLoading(false);
  }, [useLoginMutation.isSuccess]);

  return (
    <>
      <CheckIsLogin loginCheck />
      <div id="root_layout">
        <div className="root_container">
          <div className="login_page_wrap py-17.5">
            <div className="inner">
              <img src={imgLogo} alt="RENTIQUE LOGO" className="mx-auto" />

              <form className="mt-12" onSubmit={handleSubmit(onFormSubmit)}>
                <div className="label_input_area">
                  <label htmlFor="email">이메일 아이디</label>
                  <input
                    type="text"
                    id="email"
                    className={`input ${errors.email ? 'error' : ''}`}
                    placeholder="ex) rentique@abc.com"
                    autoComplete="email"
                    {...register('email', {
                      required: valids.inputEmail,
                      pattern: {
                        value: regex.regEmail,
                        message: valids.inputRightlyEmail,
                      },
                    })}
                  />
                  {errors.email && (
                    <span className="input_error_text">
                      {errors.email.message}
                    </span>
                  )}
                </div>
                <div className="label_input_area mt-4">
                  <label htmlFor="password">비밀번호</label>
                  <CustomPassword
                    id="password"
                    className={`input ${errors.password ? 'error' : ''}`}
                    placeholder={valids.inputPassword}
                    autoComplete="current-password"
                    maxLength={16}
                    {...register('password', {
                      required: valids.inputPassword,
                      pattern: {
                        value: regex.regPassword,
                        message: valids.inputPasswordFormat,
                      },
                    })}
                  />
                  {errors.password && (
                    <span className="input_error_text">
                      {errors.password.message}
                    </span>
                  )}
                </div>
                <button
                  type="submit"
                  className="btn btn_style02 mt-8 h-12 w-full rounded-sm text-sm"
                  disabled={!(watch('email') && watch('password'))}
                >
                  로그인
                </button>
              </form>

              <ul className="btns_style01 mt-8">
                <li>
                  <Link to="/join">이메일 가입</Link>
                </li>
                <li>
                  <Link to="/find-id">아이디 찾기</Link>
                </li>
                <li>
                  <Link to="/find-password">비밀번호 찾기</Link>
                </li>
              </ul>

              <div className="mt-8">
                <LoginSns />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Page;
