import { useQuery } from '@tanstack/react-query';
import { useAxios, Utils } from '@api/index';
import CustomerQueryKey from '@api/customer/customer.key';
import CustomerDto from '@api/customer/customer.dto';

namespace CustomerApi {
  /**
   * 고객센터 조회
   */
  export function useServiceCenterQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [CustomerQueryKey.SERVICE_CENTER],
      queryFn() {
        return axios.get<
          Utils.View.Response<CustomerDto.ServiceCenter.Response>
        >('/api/users/service-center');
      },
    });
  }

  /**
   * 자주묻는 질문 리스트 조회
   * @param {CustomerDto.Faq.Request} Body - body
   */
  export function useFaqsQuery(params: CustomerDto.Faq.Request) {
    const axios = useAxios();

    return useQuery({
      queryKey: [CustomerQueryKey.FAQ, params],
      queryFn() {
        return axios.get<Utils.View.ResponseResult<CustomerDto.Faq.Response[]>>(
          '/api/users/faqs',
          { params },
        );
      },
    });
  }

  /**
   * 공지사항 리스트 조회
   */
  export function useNoticesQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [CustomerQueryKey.NOTICE],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<CustomerDto.Notice.Response[]>
        >('/api/users/notices');
      },
    });
  }
}

export default CustomerApi;
