import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  active?: 'HOME' | 'CATEGORY' | 'LUX' | 'INTEREST' | 'MYPAGE';
}

export default function BottomNav({ active }: Props) {
  return (
    <div id="bottom_nav">
      <div className="inner">
        <ul>
          <li className={active === 'HOME' ? 'on' : ''}>
            <Link to="/">
              <i className="icon_home" />
              <span>홈</span>
            </Link>
          </li>
          <li className={active === 'CATEGORY' ? 'on' : ''}>
            <Link to="/category">
              <i className="icon_category" />
              <span>카테고리</span>
            </Link>
          </li>
          <li className={active === 'LUX' ? 'on' : ''}>
            <Link to="/luxshare">
              <i className="icon_lux" />
              <span>럭스쉐어</span>
            </Link>
          </li>
          <li className={active === 'INTEREST' ? 'on' : ''}>
            <Link to="/interest">
              <i className="icon_interest" />
              <span>좋아요</span>
            </Link>
          </li>
          <li className={active === 'MYPAGE' ? 'on' : ''}>
            <Link to="/mypage">
              <i className="icon_mypage" />
              <span>마이</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
