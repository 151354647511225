import React, { useEffect, useState } from 'react';
import icDelete from '@assets/images/icon_x_black.svg';
import { valids } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import SearchQueryKey from '@api/search/search.key';
import SearchApi from '@api/search/search.query';

interface Props {
  setInput: React.Dispatch<React.SetStateAction<string>>;
  saveFunc: (text: string) => void;
}

// const recentTexts: string[] = ['루이비통', '구찌', '프라다'];
// const recommendTexts: string[] = ['가방', '루이비통', '구찌', '프라다'];

export default function SearchInit({ setInput, saveFunc }: Props) {
  const { setLoading } = useStore((state) => state);
  const [recentSearchData, setRecentSearchData] = useState<string[]>([]);

  // api
  const { data: callData, isLoading } = SearchApi.useRecommendSearchQuery();
  const resultData = callData?.data?.data?.recommendedSearchKeywords;

  const handleDeleteRecent = (keyword: string) => {
    const newData = recentSearchData?.filter((item) => item !== keyword);
    localStorage.setItem(
      SearchQueryKey.RECENT_SEARCH_KEY,
      JSON.stringify(newData),
    );
    setRecentSearchData(newData);
  };

  useEffect(() => {
    if (resultData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    }
  }, [resultData, isLoading]);

  useEffect(() => {
    const storageData = localStorage.getItem(SearchQueryKey.RECENT_SEARCH_KEY);
    if (storageData) {
      const storageParseData = JSON.parse(storageData);
      setRecentSearchData(
        Array.isArray(storageParseData) ? storageParseData : [],
      );
    }
  }, []);

  return (
    <div className="inner pb-10 pt-4">
      <div>
        <p className="text-xs font-medium text-black">최근 검색어</p>
        <div className="search_tag_area tag_style01 pt-2">
          {recentSearchData?.length > 0 ? (
            recentSearchData?.map((item, index) => {
              return (
                <div key={index} className="tag">
                  <button
                    type="button"
                    className="tag_text"
                    onClick={() => {
                      setInput(item);
                      saveFunc(item);
                    }}
                  >
                    {item}
                  </button>
                  <button
                    type="button"
                    className="x_btn"
                    onClick={() => handleDeleteRecent(item)}
                  >
                    <img src={icDelete} alt="X" />
                  </button>
                </div>
              );
            })
          ) : (
            <div className="no_data_text py-1">
              <p>{valids.noDataRecentlySearch}</p>
            </div>
          )}
        </div>
      </div>
      {(resultData ?? [])?.length > 0 ? (
        <div className="mt-4">
          <p className="text-xs font-medium text-black">추천 검색어</p>
          <div className="search_tag_area tag_style02 pt-2">
            {resultData?.map((item, index) => {
              return (
                <button
                  key={index}
                  type="button"
                  className="tag_text"
                  onClick={() => {
                    setInput(item);
                    saveFunc(item);
                  }}
                >
                  {item}
                </button>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
}
