import { useQuery } from '@tanstack/react-query';
import { useAxios, Utils } from '@api/index';
import PlanEventQueryKey from '@api/plan-event/planEvent.key';
import PlanEventDto from '@api/plan-event/planEvent.dto';

namespace PlanEventApi {
  /**
   * 전체 (기획전 & 이벤트) 조회
   */
  export function usePlanningEventAllQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [PlanEventQueryKey.PLANNING_EVENT],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<PlanEventDto.All.Response[]>
        >('/api/users/planning-event-all');
      },
    });
  }

  /**
   * 기획전 조회
   */
  export function usePlanningQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [PlanEventQueryKey.PLANNING],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<PlanEventDto.Planning.Response[]>
        >('/api/users/planning');
      },
    });
  }

  /**
   * 기획전 상품 조회
   * @param {PlanEventDto.Planning.ProductRequest} body - Body
   */
  export function usePlanningProductQuery(
    params: PlanEventDto.Planning.ProductRequest,
  ) {
    const axios = useAxios();

    return useQuery({
      queryKey: [PlanEventQueryKey.PLANNING, params],
      queryFn() {
        return axios.get<
          PlanEventDto.ResponseResult<PlanEventDto.Planning.ProductResponse[]>
        >(`/api/users/planning-products/${params.planningId}`, { params });
      },
    });
  }

  /**
   * 이벤트 조회
   */
  export function useEventQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [PlanEventQueryKey.EVENT],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<PlanEventDto.Event.Response[]>
        >('/api/users/event');
      },
    });
  }

  /**
   * 이벤트 상세 조회
   * @param {number} eventId - 이벤트 ID
   */
  export function useEventDetailQuery(eventId: number) {
    const axios = useAxios();

    return useQuery({
      queryKey: [PlanEventQueryKey.EVENT, eventId],
      queryFn() {
        return axios.get<
          Utils.View.Response<PlanEventDto.Event.DetailResponse>
        >(`/api/users/event/${eventId}`);
      },
      enabled: !!eventId,
    });
  }
}

export default PlanEventApi;
