import React, { ReactNode, useEffect } from 'react';

interface Props {
  type?: 'MIDDLE' | 'BOTTOM';
  className?: string;
  children: ReactNode;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Modal({
  type = 'MIDDLE',
  className,
  children,
  setOpen,
}: Props) {
  useEffect(() => {
    document.addEventListener('mouseup', function (e) {
      const target = e.target as Node;
      const modalWrap = document.querySelector('.modal_wrap');
      const modalArea = document.querySelector('.modal_area');

      if (
        setOpen !== undefined &&
        modalWrap?.contains(target) &&
        !modalArea?.contains(target)
      ) {
        setOpen(false);
      }
    });
  }, []);

  return (
    <div
      className={`modal_wrap ${type === 'BOTTOM' ? 'bottom' : 'middle'} ${
        className ?? ''
      }`}
    >
      <div className="modal_area">{children}</div>
    </div>
  );
}
