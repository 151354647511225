// no data
export const noData = '데이터가 없습니다.';
export const noDataSearch = '검색결과가 없습니다.';
export const noDataRecentlySearch = '최근 검색 내역이 없습니다.';
export const noDataCategory = '카테고리가 없습니다.';
export const noDataPayment = '결제 내역이 없습니다.';
export const noDataRent = '렌트 내역이 없습니다.';
export const noDataConsignment = '위탁 내역이 없습니다.';
export const noDataSettlement = '정산 내역이 없습니다.';
export const noDataSettlementDetail = '정산 내역 상세 정보가 없습니다.';
export const noDataCoupon = '등록된 쿠폰이 없습니다.';
export const noDataUseCoupon = '사용 가능한 쿠폰이 없습니다.';
export const noDataDownloadCoupon = '다운 가능한 쿠폰이 없습니다.';
export const noDataSavings = '적립금 내역이 없습니다.';
export const noDataReview = '작성하실 리뷰가 없습니다.';
export const noDataReviewed = '작성한 리뷰가 없습니다.';
export const noDataFaq = '자주 찾는 질문이 없습니다.';
export const noDataNotice = '공지사항이 없습니다.';
export const noDataProductInquiry = '상품 문의하신 내역이 없습니다.';
export const noDataInquiry = '1:1 문의하신 내역이 없습니다.';

// format
export const formatPassword = '8-16자리 영문, 숫자, 특수문자 조합';

// input
export const inputOnlyNumber = '숫자만 입력해 주세요.';
export const inputOnlyNumberNoBar = '- 없이 숫자만 입력해 주세요.';
export const inputEmail = '이메일 주소를 입력해 주세요.';
export const inputRightlyEmail = '정확한 이메일 주소를 입력해 주세요.';
export const inputPassword = '비밀번호를 입력해 주세요.';
export const inputPasswordConfirm = '비밀번호를 한번 더 입력해 주세요.';
export const inputPasswordFormat =
  '영문, 숫자, 특수문자를 조합해서 입력해 주세요. (8-16자)';
export const inputVertifyNumber = '인증번호를 입력해 주세요.';
export const inputProductBrand = '상품과 브랜드를 검색해보세요.';
export const inputDeliveryName = '배송지 이름을 입력해 주세요.';
export const inputName = '이름을 입력해 주세요.';
export const inputPhone = '연락처를 입력해 주세요.';
export const inputAdditionalPhone =
  '부재 시 연락받으실 추가 연락처를 입력해 주세요.';
export const inputAddress = '주소를 검색해 주세요.';
export const inputAddressDetail = '상세 주소를 입력해 주세요.';
export const inputBrand = '브랜드를 검색해보세요.';
export const inputDeliveryMemo = '배송메모를 입력해 주세요.';
export const inputCancelReason = '취소 사유를 입력해 주세요.';
export const inputTitle = '제목을 입력해 주세요.';
export const inputContent = '내용을 입력해 주세요.';
export const inputBank = '은행을 선택해 주세요.';
export const inputAccountNumber = '계좌번호를 입력해 주세요.';
export const inputDepositor = '예금주를 입력해 주세요.';
export const inputReview = '리뷰를 작성해 주세요.';
export const inputCurrentPassword = '현재 비밀번호를 입력해 주세요.';
export const inputAdvice = '고객님의 진심 어린 충고 부탁드립니다.';
export const inputShareBrand = '위탁 신청하는 브랜드명을 입력해 주세요.';
export const inputShareProduct =
  '위탁 신청하는 모델명 또는 상품명을 입력해 주세요.';
export const inputHopePrice = '일 희망 렌탈가를 입력해 주세요.';
export const inputTrackingNumber = '송장번호를 입력해 주세요.';

// check
export const checkPhone = '휴대폰번호 형식이 맞지 않습니다.';

// etc...
export const noDataPage = '해당 페이지의 데이터가 존재하지 않습니다.';
export const errored = '오류가 발생하였습니다. 다시 시도해 주세요.';
export const saved = '저장되었습니다.';
export const deleted = '삭제되었습니다.';
export const goLogin = '로그인 후 이용해주세요.';
export const noMatchingUser = '일치하는 사용자 정보를 찾을 수 없습니다.';
export const incorrectVertifyNumber =
  '입력하신 인증번호가 올바르지 않습니다. 다시 확인해 주세요.';
export const expirationTimer = '유효기간이 지났습니다. 다시 인증해주세요.';
export const resendVertifyNumber = '인증번호가 재발송 되었습니다.';
export const incorrectPasswordConfirm = '확인 비밀번호가 일치하지 않습니다.';
export const alreadyEmail = '이미 가입된 이메일 주소입니다.';
export const alreadyJoin = '이미 다른 계정에 가입되어 있습니다.';
export const autoInputName = '휴대폰 인증을 통해 본명이 자동 입력됩니다.';
export const autoInputPhone =
  '휴대폰 인증을 통해 휴대폰 번호가 자동 입력됩니다.';
export const autoInputBirth = '휴대폰 인증을 통해 생년월일이 자동 입력됩니다.';
export const checkPeriod =
  '같은 이용기간을 가진 상품만 주문할 수 있어요. \n이용기간을 확인해 주세요.';
export const alreadyRent = '해당 상품을 이미 다른 고객이 렌트했어요.';
export const addInterest = '해당 상품이 찜한 상품으로 등록되었습니다.';
export const downloadCoupon = '쿠폰을 다운로드 받았습니다.';
export const goCart = '선택하신 렌트일자에 설정된 상품 장바구니 담기 완료!';
export const addDelivery = '배송지가 등록되었습니다.';
export const updateDelivery = '배송지 정보가 수정되었습니다.';
export const deleteDelivery = '선택하신 배송지가 삭제되었습니다.';
export const accountSuccess = '인증이 완료되었습니다.';
export const accountFailed = '예금주 정보가 일치하지 않습니다.';
export const incorrectCurrentPassword = '현재 비밀번호가 일치하지 않습니다.';
export const inquirySaved = '작성하신 문의글 등록 완료!';
export const reviewSaved = '작성하신 리뷰 등록 완료!';
export const reviewUpdated = '작성하신 리뷰 수정 완료!';
export const requestedReturn = '반품 신청되었습니다.';
export const newPasswordSaved = '비밀번호가 변경되었습니다.';
export const updateUserInfo = '회원 정보가 수정되었습니다.';
export const trackingNumberSaved = '입력하신 송장번호가 저장되었습니다.';
export const cardCanceled = '사용자가 카드 정보 입력을 취소했습니다.';
export const cardSaved = '결제 카드 정보가 저장되었습니다.';
