import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAxios, Utils } from '@api/index';
import MypageQueryKey from '@api/mypage/mypage.key';
import MypageDto from '@api/mypage/mypage.dto';
import CouponDto from '@api/coupon/coupon.dto';

namespace MypageApi {
  /**
   * 대시보드
   */
  export function useDashboardQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [MypageQueryKey.MYPAGE],
      queryFn() {
        return axios.get<Utils.View.Response<MypageDto.MyDashboard.Response>>(
          '/api/users/my-dashboard',
        );
      },
    });
  }

  /**
   * 사용가능 쿠폰
   */
  export function useAvailableCouponsQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [MypageQueryKey.MYPAGE_AVAILABLE_COUPON],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<CouponDto.Coupons.Response[]>
        >('/api/users/my-available-coupons');
      },
    });
  }

  /**
   * 다운가능 쿠폰
   */
  export function useDownloadableCouponsQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [MypageQueryKey.MYPAGE_DOWNLOADABLE_COUPON],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<CouponDto.Coupons.Response[]>
        >('/api/users/my-downloadable-coupons');
      },
    });
  }

  /**
   * 나의 적립금 이력
   * @param {MypageDto.MyPoints.Request} body - Body
   */
  export function usePointsQuery(params: MypageDto.MyPoints.Request) {
    const axios = useAxios();

    return useQuery({
      queryKey: [MypageQueryKey.MYPAGE_POINT, params],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<MypageDto.MyPoints.Response[]>
        >('/api/users/my-points-history', { params });
      },
    });
  }

  /**
   * 작성 가능한 리뷰 리스트
   */
  export function useWritableReviewsQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [MypageQueryKey.MYPAGE_WRITABLE_REVIEW],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<MypageDto.MyReviews.WritableResponse[]>
        >('/api/users/my-writable-reviews');
      },
    });
  }

  /**
   * 작성한 리뷰 리스트
   */
  export function useWrittenReviewsQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [MypageQueryKey.MYPAGE_WRITTEN_REVIEW],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<MypageDto.MyReviews.WrittenResponse[]>
        >('/api/users/my-written-reviews');
      },
    });
  }

  /**
   * 리뷰 작성
   * @param {MypageDto.MyReviews.CreateRequest} body - Body
   */
  export function useCreateReviewMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation({
      mutationFn(body: MypageDto.MyReviews.CreateRequest) {
        const formData = new FormData();
        for (const [key, value] of Object.entries(body)) {
          formData.append(key, value);
        }

        return axios.post<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>
        >('/api/users/my-reviews', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [MypageQueryKey.MYPAGE_REVIEW],
        });
      },
    });
  }

  /**
   * 리뷰 수정
   * @param {MypageDto.MyReviews.UpdateRequest} body - Body
   */
  export function useUpdateReviewMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation({
      mutationFn(body: MypageDto.MyReviews.UpdateRequest) {
        const formData = new FormData();
        for (const [key, value] of Object.entries(body)) {
          formData.append(key, value);
        }

        return axios.put<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>
        >(`/api/users/my-reviews/${body.reviewId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [MypageQueryKey.MYPAGE_REVIEW],
        });
      },
    });
  }

  /**
   * 상품문의 리스트
   */
  export function useProductInquiriesQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [MypageQueryKey.MYPAGE_PRODUCT_INQUIRY],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<MypageDto.MyInquiries.ProductResponse[]>
        >('/api/users/my-products-inquiries');
      },
    });
  }

  /**
   * 1:1문의 리스트
   */
  export function useOnetooneInquiriesQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [MypageQueryKey.MYPAGE_ONETOONE_INQUIRY],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<MypageDto.MyInquiries.OneResponse[]>
        >('/api/users/my-onetoone-inquiries');
      },
    });
  }

  /**
   * 1:1 문의하기
   * @param {MypageDto.MyInquiries.Request} body - Body
   */
  export function useCreateInquiryMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation({
      mutationFn(body: MypageDto.MyInquiries.Request) {
        const formData = new FormData();
        for (const [key, value] of Object.entries(body)) {
          formData.append(key, value);
        }

        return axios.post<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>
        >('/api/users/my-onetoone-inquiries', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [MypageQueryKey.MYPAGE_INQUIRY],
        });
      },
    });
  }

  /**
   * 비밀번호 체크
   * @param {MypageDto.MyPwCheck.Request} body - Body
   */
  export function usePasswordCheckMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<null>>,
      AxiosError<{ message: string }>,
      MypageDto.MyPwCheck.Request
    >({
      mutationFn(body: MypageDto.MyPwCheck.Request) {
        return axios.post<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>,
          MypageDto.MyPwCheck.Request
        >('/api/users/my-password-check', body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [MypageQueryKey.MYPAGE_PASSWORD_CHECK],
        });
      },
    });
  }

  /**
   * 회원탈퇴
   * @param {MypageDto.MyWithdrawal.Request} body - Body
   */
  export function useWithdrawalMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<null>>,
      AxiosError<{ message: string }>,
      MypageDto.MyWithdrawal.Request
    >({
      mutationFn(body: MypageDto.MyWithdrawal.Request) {
        const queryParams = new URLSearchParams({
          withdrawalReason: body.withdrawalReason.toString(),
        });
        const queryString = queryParams.toString();

        return axios.delete(`/api/users/my-withdrawal?${queryString}`);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [MypageQueryKey.MYPAGE_WITHDRAWAL],
        });
      },
    });
  }

  /**
   * 회원조회
   */
  export function useInfoQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [MypageQueryKey.MYPAGE_INFO],
      queryFn() {
        return axios.get<Utils.View.Response<MypageDto.MyInfo.Response>>(
          '/api/users/my-info',
        );
      },
    });
  }

  /**
   * 회원수정
   * @param {MypageDto.MyInfo.Request} body - Body
   */
  export function useUpdateInfoMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<null>>,
      AxiosError<{ message: string }>,
      MypageDto.MyInfo.Request
    >({
      mutationFn(body: MypageDto.MyInfo.Request) {
        return axios.put<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>,
          MypageDto.MyInfo.Request
        >('/api/users/my-info', body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [MypageQueryKey.MYPAGE_INFO],
        });
      },
    });
  }

  /**
   * 비밀번호 수정
   * @param {MypageDto.MyInfo.PasswordRequest} body - Body
   */
  export function useUpdatePasswordMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<null>>,
      AxiosError<{ message: string }>,
      MypageDto.MyInfo.PasswordRequest
    >({
      mutationFn(body: MypageDto.MyInfo.PasswordRequest) {
        return axios.put<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>,
          MypageDto.MyInfo.PasswordRequest
        >('/api/users/my-info-password', body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [MypageQueryKey.MYPAGE_PASSWORD],
        });
      },
    });
  }
}

export default MypageApi;
