import React, { useEffect, useRef, useState } from 'react';
import { ConfigType } from '@config/common';

interface Props {
  type?: number;
  optionClassName?: string;
  options: ConfigType[];
  value: any;
  setValue: React.Dispatch<React.SetStateAction<any>>;
  placeholder?: string;
  isError?: boolean;
  setIsError?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CustomSelect({
  type,
  optionClassName,
  options,
  value,
  setValue,
  placeholder,
  isError = false,
  setIsError,
}: Props) {
  const hasMounted = useRef(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const [selectedText, setSelectedText] = useState(
    placeholder ?? options.filter((item) => item.id === value)?.[0]?.text,
  );

  const handleSelectClick = () => {
    setSelectOpen((state) => !state);
  };
  const handleOptionClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setValue(e.currentTarget.value);
    setSelectedText(e.currentTarget.textContent || e.currentTarget.innerText);
    setIsError?.(false);
    setSelectOpen(false);
  };

  useEffect(() => {
    if (!value) return;
    if (!hasMounted.current) {
      if (value !== 'empty') {
        const isValueItem = options?.filter((item) => item.id === value);
        if (isValueItem?.length > 0) {
          setValue(isValueItem[0].id);
          setSelectedText(isValueItem[0].text);
        }
      } else {
        setValue(value);
        setSelectedText(
          placeholder ?? options.filter((item) => item.id === value)?.[0]?.text,
        );
      }
      hasMounted.current = true;
    }
  }, [value]);

  return (
    <div
      className={`select sl_style0${type ?? 1} ${selectOpen ? 'on' : ''} ${
        isError ? 'errored' : ''
      }`}
    >
      <button type="button" onClick={handleSelectClick}>
        {selectedText} <i />
      </button>
      <ul className={optionClassName ?? ''}>
        {options?.length > 0 ? (
          options?.map((item) => {
            return (
              <li key={item.id}>
                <button
                  type="button"
                  value={item.id}
                  onClick={handleOptionClick}
                >
                  {item.text}
                </button>
              </li>
            );
          })
        ) : (
          <li className="no_data_text">
            <p>No Data</p>
          </li>
        )}
      </ul>
    </div>
  );
}
