import React, { JSXElementConstructor, ReactElement, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { common, valids } from '@config/index';
import useStore from '@store/index';

interface Props {
  children: ReactElement<
    { onClick: () => void },
    string | JSXElementConstructor<any>
  >;
  refetch?: () => void;
}

export default function ToassAuthWrap({ children, refetch }: Props) {
  const { setToastContainerStyle } = useStore((state) => state);

  const handlePopupOpen = async () => {
    const top = (window.innerHeight - 650) / 2;
    const left = (window.innerWidth - 650) / 2;

    window.open(
      '/toss',
      'tossPopup',
      `width=650, height=650, top=${top}, left=${left}, menubar=no, status=no, toolbar=no, titlebar=yes`,
    );
  };

  useEffect(() => {
    window.name = 'tossParent';

    const handleMessage = (event: MessageEvent) => {
      if (event.data?.key === common.TOSS_DATA_KEY) {
        setToastContainerStyle({ bottom: 78 });
        toast(event.data?.msg ?? valids.errored, { style: common.toastStyle });

        if (event.data?.status === 'success') refetch?.();
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return React.cloneElement(children, {
    onClick: handlePopupOpen,
  });
}
