import React, { useState, forwardRef } from 'react';

function CustomPassword(props: any, ref: React.Ref<HTMLInputElement>) {
  const [show, setShow] = useState(false);

  return (
    <div className="pw_input">
      <input
        className="input"
        autoComplete="new-password"
        {...props}
        type={show ? 'text' : 'password'}
        ref={ref}
      />
      <button
        type="button"
        className={`eye_btn ${show ? 'on' : ''}`}
        onClick={() => setShow((state) => !state)}
      >
        <i />
        <span className="hidden_text">비밀번호 숨기기/보이기</span>
      </button>
    </div>
  );
}
export default forwardRef(CustomPassword);
