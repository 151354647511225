export const couponData = [
  {
    id: 1,
    percent: 50,
    name: '생애 첫 렌트 할인',
    condition: '3만원 이상 결제 시',
    period: '2024년 6월 30일까지',
    disabled: false,
  },
  {
    id: 2,
    percent: 50,
    name: '생애 첫 렌트 할인',
    condition: '3만원 이상 결제 시',
    period: '2024년 6월 30일까지',
    disabled: false,
  },
  {
    id: 3,
    percent: 50,
    name: '생애 첫 렌트 할인',
    condition: '3만원 이상 결제 시',
    period: '2024년 6월 30일까지',
    disabled: true,
  },
  {
    id: 4,
    percent: 50,
    name: '생애 첫 렌트 할인',
    condition: '3만원 이상 결제 시',
    period: '2024년 6월 30일까지',
    disabled: true,
  },
];
