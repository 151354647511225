import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import icHeart from '@assets/images/deco/icon_heart.svg';
import Header from '@components/shared/header';
import BottomNav from '@components/shared/bottomNav';
import MsgModal from '@components/modal/msg';
import CheckIsLogin from '@components/checkIsLogin';
import { common } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import FavoriteApi from '@api/favorite/favorite.query';
// import { productData } from '@temp/productData';

function Page() {
  const { setLoading } = useStore((state) => state);
  const [checked, setChecked] = useState<number[]>([]);
  const [msgOpen, setMsgOpen] = useState(false);

  // api
  const { data: callData, isLoading, refetch } = FavoriteApi.useFavoriteQuery();
  const resultData = callData?.data?.data;
  const listData = resultData?.result ?? [];
  const useDeleteFavoriteMutation = FavoriteApi.useDeleteFavoriteMutation();

  const handleCheckAllClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!(checked && setChecked)) return;

    if (e.target.checked) {
      const newSelecteds = listData?.map((n) => n.id);
      setChecked(newSelecteds);

      return;
    }
    setChecked([]);
  };
  const handleCheckClick = (id: number) => {
    if (!(checked && setChecked)) return;
    const newChecked = checked.includes(id)
      ? checked.filter((item) => item !== id)
      : [...checked, id];

    setChecked(newChecked);
  };

  const onDeleteClick = () => {
    setLoading(true);
    checked?.map((item) => {
      return useDeleteFavoriteMutation.mutate(item);
    });
  };

  // 찜 삭제 SUCCESS !!!
  useEffect(() => {
    if (!useDeleteFavoriteMutation.isSuccess) return;
    setMsgOpen(false);
    setLoading(false);
    refetch?.();
  }, [useDeleteFavoriteMutation.isSuccess]);

  useEffect(() => {
    if (resultData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [resultData, isLoading]);

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="찜한상품" back />
          </div>

          {/* 리스트 */}
          <div className="pb-24 pt-4">
            <div className="inner">
              <p className="text-xs font-medium text-black">
                총 {resultData?.cntAll ?? 0}개의 상품
              </p>
              <div className="flex items-center justify-between pt-6">
                <div className="chk_input">
                  <input
                    type="checkbox"
                    id="chk_all"
                    value="chk_all"
                    onChange={handleCheckAllClick}
                    checked={
                      (resultData?.cntAll ?? 0) > 0 &&
                      checked?.length === (resultData?.cntAll ?? 0)
                    }
                  />
                  <label htmlFor="chk_all">전체 상품</label>
                </div>
                <button
                  type="button"
                  className="text-2xs underline"
                  onClick={() => setMsgOpen(true)}
                  disabled={checked?.length <= 0}
                >
                  선택삭제
                </button>
              </div>
              {listData?.length > 0 ? (
                <div className="list_area list_style01 grid02 check_style pt-6">
                  <ul>
                    {listData?.map((item) => {
                      return (
                        <li
                          key={item.id}
                          className={
                            checked?.indexOf(item.id) !== -1 ? 'on' : ''
                          }
                        >
                          <button
                            type="button"
                            className="w-full"
                            onClick={() => handleCheckClick(item.id)}
                          >
                            <div className="img_wrap">
                              <div className="img_area rounded-lg">
                                {item?.product_images?.length > 0 ? (
                                  <img
                                    src={`${common.imageUrl}/${item?.product_images?.[0]?.file?.fileUrl}`}
                                    alt="상품 이미지"
                                  />
                                ) : (
                                  <i className="no_data_img" />
                                )}
                                <span className="tag">렌트</span>
                              </div>
                            </div>
                          </button>
                          <Link
                            to={`/product/detail/${item?.id}`}
                            className="block"
                          >
                            <div className="text_area">
                              <span className="brand">
                                {item?.brand?.nameEnglish.toUpperCase() ?? ''}
                              </span>
                              <p className="product">
                                {item?.productName ?? ''}
                              </p>
                              <div className="flex items-center justify-start gap-1">
                                {Number(item?.discountRate ?? 0) > 0 && (
                                  <span className="price_discount">
                                    {`${Number(item?.discountRate ?? 0)}%`}
                                  </span>
                                )}
                                <em className="price_desc">일</em>
                                <p className="price_total">
                                  {Number(item?.discountRate ?? 0) > 0
                                    ? Number(
                                        item?.discountPrice ?? 0,
                                      ).toLocaleString()
                                    : Number(
                                        item?.dailyRentalPrice ?? 0,
                                      ).toLocaleString()}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <div className="interest_empty">
                  <img src={icHeart} alt="하트" />
                  <div className="text_area">
                    <p>관심 아이템을 담아 주세요.</p>
                    <span>
                      클릭 한 번으로 고객님 마음에 드는 아이템을 <br />
                      위시리스트에 담아보세요!
                    </span>
                  </div>
                  <Link
                    to="/"
                    className="btn btn_style02 h-10 rounded-sm px-4 text-xs font-bold"
                  >
                    계속 쇼핑하기
                  </Link>
                </div>
              )}
            </div>
          </div>

          <MsgModal
            open={msgOpen}
            setOpen={setMsgOpen}
            btns={
              <>
                <button
                  type="button"
                  className="btn btn_style03 h-10 w-full rounded-sm text-sm font-bold"
                  onClick={() => setMsgOpen(false)}
                >
                  취소
                </button>
                <button
                  type="button"
                  className="btn btn_style02 h-10 w-full rounded-sm text-sm font-bold"
                  onClick={onDeleteClick}
                >
                  확인
                </button>
              </>
            }
          >
            <p className="tit">찜한 상품 삭제</p>
            <span className="desc">
              선택한 상품을 찜목록에서 <br />
              삭제하시겠습니까?
            </span>
          </MsgModal>
          <BottomNav active="INTEREST" />
        </div>
      </div>
    </>
  );
}
export default Page;
