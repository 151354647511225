import React, { ReactNode, useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import icArrow from '@assets/images/icon_arrow_up.svg';

interface Props {
  title: string | JSX.Element | JSX.Element[];
  children: ReactNode;
  initOpen?: boolean;
  isArrow?: boolean;
}

export default function ToggleItem({
  title,
  children,
  initOpen = true,
  isArrow = true,
}: Props) {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(initOpen);

  useEffect(() => {
    if (contentRef.current) {
      if (open) {
        $(contentRef.current).stop(false, false).slideDown();
      } else {
        $(contentRef.current).stop(false, false).slideUp();
      }
    }
  }, [open]);

  return (
    <div
      className={`toggle_area ${initOpen === false ? 'default_close' : ''} ${
        open ? 'on' : ''
      }`}
    >
      <button
        type="button"
        className="t_btn"
        onClick={() => setOpen((state) => !state)}
      >
        {title}
        {isArrow && <img src={icArrow} alt="arrow" />}
      </button>
      <div className="t_content" ref={contentRef}>
        {children}
      </div>
    </div>
  );
}
