import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import icPlus from '@assets/images/icon_plus.svg';
import Header from '@components/shared/header';
import BottomNav from '@components/shared/bottomNav';
import ToassAuthWrap from '@components/tossAuth';
import CheckIsLogin from '@components/checkIsLogin';
import { valids } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import ConsignmentApi from '@api/consignment/consignment.query';

function Page() {
  const params = useParams();
  const paramsId = params.id;
  const id = Number(paramsId);
  const { setLoading } = useStore((state) => state);

  // api
  const {
    data: callData,
    isLoading,
    refetch,
  } = ConsignmentApi.useConsignmentDetailQuery(id);
  const resultData = callData?.data?.data;

  useEffect(() => {
    // console.log(resultData);
    if (paramsId && resultData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [paramsId, resultData, isLoading]);

  // NO DATA !!!
  if (!resultData && !isLoading) {
    alert(valids.noDataPage);
    window.history.back();
  }
  if (!resultData) return null;

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="위탁 정보 상세" back />
          </div>

          <div className="mypage_consignment_detail_wrap pb-24 pt-4">
            <div className="inner">
              <div className="border-b border-b-gray-c pb-2">
                <p className="text-xs font-medium text-gray-9">
                  위탁번호 {resultData?.consignmentNumber ?? '-'}
                </p>
              </div>

              <div className="mt-2">
                <h3 className="consignment_tit">위탈상품 상세 정보</h3>
                <div className="mt-4 flex flex-col gap-2">
                  <dl className="text_list_area">
                    <dt>제품상태</dt>
                    <dd>
                      {resultData?.productCondition === 'new'
                        ? '신상'
                        : resultData?.productCondition === 'user'
                          ? '중고'
                          : '-'}
                    </dd>
                  </dl>
                  <dl className="text_list_area">
                    <dt>브랜드</dt>
                    <dd>{resultData?.brand?.toUpperCase() ?? '-'}</dd>
                  </dl>
                  <dl className="text_list_area">
                    <dt>모델 및 상품명</dt>
                    <dd>{resultData?.modelName ?? '-'}</dd>
                  </dl>
                  <dl className="text_list_area">
                    <dt>보증서</dt>
                    <dd>
                      {resultData?.warranty === 'yes'
                        ? '있음'
                        : resultData?.warranty === 'no'
                          ? '없음'
                          : '-'}
                    </dd>
                  </dl>
                  <dl className="text_list_area">
                    <dt>렌트 확정가</dt>
                    <dd>
                      일{' '}
                      {Number(
                        resultData?.desiredRentalPrice ?? 0,
                      ).toLocaleString()}
                      원
                    </dd>
                  </dl>
                  <dl className="text_list_area">
                    <dt>정산계좌</dt>
                    <dd>
                      {resultData?.account?.bankName ?? ''}{' '}
                      {resultData?.account?.accountNumber ?? ''}
                      {(resultData?.account?.bankName ||
                        resultData?.account?.accountNumber) &&
                        resultData?.account?.accountHolderName && <br />}
                      {resultData?.account?.accountHolderName ?? ''}
                    </dd>
                  </dl>
                  <dl className="text_list_area">
                    <dt>배송지</dt>
                    <dd>
                      {resultData?.postCode ?? `[${resultData?.postCode}] `}
                      {resultData?.address1 ?? ''}
                      {(resultData?.postCode || resultData?.address1) &&
                        resultData?.address2 && <br />}
                      {resultData?.address2 ?? ''}
                    </dd>
                  </dl>
                  {resultData?.shippingCompany &&
                    resultData?.trackingNumber && (
                      <dl className="text_list_area">
                        <dt>송장번호</dt>
                        <dd>
                          {resultData?.shippingCompany}{' '}
                          {resultData?.trackingNumber}
                        </dd>
                      </dl>
                    )}
                </div>
              </div>

              <div className="mt-4">
                <h3 className="consignment_tit">패널티 결제 수단</h3>
                <div className="card_area mt-6">
                  {resultData?.payment_method ? (
                    <div className="card_info">
                      <span>
                        {resultData?.payment_method?.cardCompany ?? ''}
                      </span>
                      <p>{resultData?.payment_method?.cardNumber ?? ''}</p>
                    </div>
                  ) : (
                    <ToassAuthWrap refetch={refetch}>
                      <button type="button" className="card_btn">
                        <img src={icPlus} alt="+" />
                        <span>카드 등록</span>
                      </button>
                    </ToassAuthWrap>
                  )}
                </div>
                {resultData?.payment_method && (
                  <ToassAuthWrap refetch={refetch}>
                    <button
                      type="button"
                      className="btn btn_style01 mt-6 h-10 w-full rounded-sm text-sm font-bold"
                    >
                      카드 변경
                    </button>
                  </ToassAuthWrap>
                )}
              </div>

              <div className="mt-6">
                <h3 className="consignment_tit">최종 정산 금액 안내</h3>
                <div className="mt-4 flex flex-col gap-2">
                  <dl className="text_list_area">
                    <dt>렌트확정가</dt>
                    <dd>
                      {Number(
                        resultData?.desiredRentalPrice ?? 0,
                      ).toLocaleString()}
                      원
                    </dd>
                  </dl>
                  <dl className="text_list_area">
                    <dt>거래 수수료</dt>
                    <dd>렌트 확정가 * 9.8%</dd>
                  </dl>
                  <dl className="text_list_area">
                    <dt>운용비용</dt>
                    <dd>20%</dd>
                  </dl>
                  <dl className="text_list_area">
                    <dt>렌트 대여일</dt>
                    <dd>매달 렌트 이용 일자 정보</dd>
                  </dl>
                  <dl className="text_list_area">
                    <dt>
                      <span className="text-main">총 정산금액</span>
                    </dt>
                    <dd>
                      렌트 확정가 * 거래 수수료 + 운용비용 <br />= 총 금액 -
                      70.2%
                    </dd>
                  </dl>
                </div>
                <div className="mt-4 rounded-sm bg-main-02 p-4 text-center">
                  <p className="text-xs text-gray-3">
                    정산은 매월 20일 전월 확정 렌트 매출에서 운용비용+수수료
                    <br />
                    차감금액 제외 후 입금됩니다.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <BottomNav active="MYPAGE" />
        </div>
      </div>
    </>
  );
}
export default Page;
