import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import imgEvent from '@assets/images/temp/event_img.jpg';
import Header from '@components/shared/header';
import BottomNav from '@components/shared/bottomNav';
import { common, valids } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import PlanEventApi from '@api/plan-event/planEvent.query';

function Page() {
  const params = useParams();
  const paramsId = params.id;
  const id = Number(paramsId);
  const { setLoading } = useStore((state) => state);

  // api
  const { data: callData, isLoading } = PlanEventApi.useEventDetailQuery(id);
  const itemData = callData?.data?.data;

  useEffect(() => {
    if (paramsId && itemData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [paramsId, itemData, isLoading]);

  // NO DATA !!!
  if (!itemData && !isLoading) {
    alert(valids.noDataPage);
    window.history.back();
  }
  if (!itemData) return null;

  return (
    <div id="root_layout">
      <div className="root_container">
        <div className="sticky_menu">
          <Header back searchCart />
        </div>

        {/* 썸네일 */}
        {itemData?.detail_thumbnail_file && (
          <div className="img_area rect03">
            <img
              src={`${common.imageUrl}/${itemData?.detail_thumbnail_file?.fileUrl}`}
              alt="기획전 썸네일 이미지"
            />
          </div>
        )}

        {/* 상세 */}
        <div className="editor_area pb-17.5 pt-4">
          <div className="inner">
            <div
              dangerouslySetInnerHTML={{ __html: itemData?.content ?? '' }}
            />
          </div>
        </div>

        <BottomNav />
      </div>
    </div>
  );
}
export default Page;
