import React from 'react';
import imgLogo from '@assets/images/shared/logo02.svg';
import imgService01 from '@assets/images/service_img01.jpg';
import imgService02 from '@assets/images/service_img02.jpg';
import imgService03 from '@assets/images/service_img03.jpg';
import imgService04 from '@assets/images/service_img04.jpg';
import imgService05 from '@assets/images/service_img05.jpg';
import Header from '@components/shared/header';
import BottomNav from '@components/shared/bottomNav';

function Page() {
  return (
    <div id="root_layout">
      <div className="root_container">
        <div className="sticky_menu">
          <Header back="/" searchCart />
        </div>

        <div className="service_visual_area">
          <div className="text_area">
            <img src={imgLogo} alt="RENTIQUE" />
            <p>
              <b>렌티크</b>는 명품을 소유하지 않고도 <br />
              다양한 명품을 즐길 수 있는 <br />
              <b>
                혁신적인 <span>명품 렌탈 서비스</span>
              </b>
              입니다. <br />
              최신 패션 트렌드부터 클래식 디자인까지, <br />
              원하는 명품을 <b>간편하게 렌탈</b>하여 <br />
              <b>특별한 순간을 더욱 빛나게</b> 만들어 보세요.
            </p>
          </div>
        </div>
        <div className="pb-24 pt-8">
          <div className="inner">
            <h3 className="service_tit mb-8">렌티크(RENTIQUE) 소개</h3>
            <dl className="service_info_area">
              <dt>
                <p>1. 다양한 컬렉션 이용</p>
                <span>
                  렌티크를 통해 끊임없이 진화하는 명품 컬렉션을 이용할 수
                  있습니다. <br />
                  아이코닉한 클래식 디자인부터 최신 런웨이 아이템까지 <br />
                  이를 통해 회원들은 다양한 스타일과 브랜드, 디자인을 즐길 수
                  있습니다.
                </span>
              </dt>
              <dd>
                <img src={imgService01} alt="다양한 컬렉션 이용" />
              </dd>
            </dl>
            <dl className="service_info_area">
              <dt>
                <p>2. 환경을 생각하는 소비</p>
                <span>
                  우리는 전통적인 명품 쇼핑에 대한 지속 가능한 대안을
                  제공함으로써 신중한 소비를 옹호합니다. 우리 회원들은 환경
                  훼손에 기여하지 않고 최신 패션 트렌드를 즐길 수 있습니다.
                </span>
                <span>
                  각 품목의 수명이 연장되어 패션에 대한 보다 친환경적인 접근
                  방식이 장려됩니다.
                </span>
                <span>
                  지속 가능성에 대한 약속에 따라 렌트백에서는 친환경 포장을
                  사용합니다. <br />
                  환경을 고려한 이러한 접근 방식은 회원들이 럭셔리함을 누리는
                  동시에 환경적으로 책임 있는 선택을 할 수 있도록 보장합니다.
                </span>
              </dt>
              <dd>
                <img src={imgService02} alt="환경을 생각하는 소비" />
              </dd>
            </dl>
            <dl className="service_info_area">
              <dt>
                <p>3. 합리적 소비</p>
                <span>
                  렌티크는 럭셔리 패션을 즐길 수 있는 똑똑하고 스타일리시하며
                  지속 가능한 방법을 제공합니다.
                </span>
                <span>
                  다양한 컬렉션을 제공하고, 효율적인 솔루션을 제공하며, 지속
                  가능성을 촉진하고 유연성과 편의성을 제공함으로써 럭셔리 패션을
                  경험하는 방식에 혁명을 일으키고 있습니다.
                </span>
                <span>
                  럭셔리 백 공유로 패션의 미래를 받아들이고 옷장과
                  라이프스타일에 가져다주는 수많은 이점을 발견하세요.
                </span>
              </dt>
              <dd>
                <img src={imgService03} alt="합리적 소비" />
              </dd>
            </dl>
            <dl className="service_info_area">
              <dt>
                <p>4. 맞춤형 스타일링 및 컨 시어지 서비스</p>
                <span>
                  렌티크는 맞춤형 스타일링 조언과 컨시어지 서비스를 제공합니다.
                </span>
                <span>
                  전문 스타일리스트는 개인의 취향, 상황, 옷장 요구 사항에 가장
                  잘 맞는 가방을 추천하여 원활하고 세련된 경험을 보장합니다.
                </span>
                <span>
                  이러한 수준의 개인화된 서비스는 고급스러움과 편리함을 더해
                  회원들이 진정으로 특별하다는 느낌을 갖게 합니다.
                </span>
              </dt>
              <dd>
                <img
                  src={imgService04}
                  alt="맞춤형 스타일링 및 컨 시어지 서비스"
                />
              </dd>
            </dl>
            <dl className="service_info_area">
              <dt>
                <p>5. 한정판 및 희귀 제품에 대한 액세스</p>
                <span>
                  렌티크는 종종 찾기 어려운 한정판 가방과 희귀한 디자이너 가방을
                  제공합니다.
                </span>
                <span>
                  이를 통해 회원들은 자신들을 돋보이게 하는 독특하고 가치 있는
                  제품을 휴대할 수 있는 기회를 얻게 됩니다.
                </span>
                <span>
                  이러한 독점적인 아이템을 일시적으로라도 소유하면 스타일 지수가
                  향상되고 컬렉션에 희귀성이 추가됩니다.
                </span>
              </dt>
              <dd>
                <img
                  src={imgService05}
                  alt="한정판 및 희귀 제품에 대한 액세스"
                />
              </dd>
            </dl>
          </div>
        </div>

        <BottomNav />
      </div>
    </div>
  );
}
export default Page;
