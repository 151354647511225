import imgProduct01 from '@assets/images/temp/product_img08.jpg';

export const rentData = [
  {
    id: 1,
    order_number: '20240707C12345',
    status: 'DELIVERY_READY',
    img_src: imgProduct01,
    brand: 'PRADA',
    product: '캐러멜 가죽 핸드백',
    price: 18400,
    period: '07. 01(월) - 07. 07(일)',
  },
  {
    id: 2,
    order_number: '20240707C12345',
    status: 'CANCEL_RESERVATION',
    img_src: imgProduct01,
    brand: 'PRADA',
    product: '캐러멜 가죽 핸드백',
    price: 18400,
    period: '07. 01(월) - 07. 07(일)',
  },
  {
    id: 3,
    order_number: '20240707C12345',
    status: 'DELIVERYING',
    img_src: imgProduct01,
    brand: 'PRADA',
    product: '캐러멜 가죽 핸드백',
    price: 18400,
    period: '07. 01(월) - 07. 07(일)',
  },
  {
    id: 4,
    order_number: '20240707C12345',
    status: 'DELIVERY_COMPLETE',
    img_src: imgProduct01,
    brand: 'PRADA',
    product: '캐러멜 가죽 핸드백',
    price: 18400,
    period: '07. 01(월) - 07. 07(일)',
  },
  {
    id: 5,
    order_number: '20240707C12345',
    status: 'RETURN_COMPLETE',
    img_src: imgProduct01,
    brand: 'PRADA',
    product: '캐러멜 가죽 핸드백',
    price: 18400,
    period: '07. 01(월) - 07. 07(일)',
  },
];

export const thisRentData = {
  startDate: new Date('2024-08-21'),
  endDate: new Date('2024-09-30'),
  price: 27800,
};

export const holiday2024Data = [
  '2024-01-01',
  '2024-02-09',
  '2024-02-10',
  '2024-02-11',
  '2024-02-12',
  '2024-03-01',
  '2024-04-10',
  '2024-05-05',
  '2024-05-06',
  '2024-05-15',
  '2024-06-06',
  '2024-08-15',
  '2024-09-16',
  '2024-09-17',
  '2024-09-18',
  '2024-10-03',
  '2024-10-09',
  '2024-12-25',
];
