import React from 'react';

export default function ProductItemsUseInfo() {
  return (
    <div className="inner">
      <div className="prod_detail_use_info">
        <h3>이용안내</h3>
        <dl>
          <dt>주문/배송</dt>
          <dd>
            이용하고 싶은 날짜(시작일/종료일)를 선택해 주세요. <br />
            선택 한 시작일로부터 2일 전 상품이 발송됩니다. <br />
            (배송비는 무료입니다.)
          </dd>
        </dl>
        <dl>
          <dt>결제</dt>
          <dd>할인쿠폰 및 적립금은 주문페이지에서 사용 가능합니다.</dd>
        </dl>
        <dl>
          <dt>도착</dt>
          <dd>
            상품이 도착했다면 바로 사용 가능하며, <br />
            함께 발송된 투명 리빙박스는 이용하는 동안 보관해주세요. <br />
            (반납 시 리빙박스에 상품을 넣어 반납하시면 됩니다.)
          </dd>
        </dl>
        <dl>
          <dt>반납</dt>
          <dd>
            종료일 다음날, 택배 기사님이 방문할 예정입니다. <br />
            상품을 기사님께 전달해주시거나, 문 앞에 반납해주세요. <br />
            (반납 택배는 자동 접수되며, 반납 택배비도 무료입니다.)
          </dd>
        </dl>
        <dl>
          <dt>취소</dt>
          <dd>
            출고 이후에는 취소 및 부분 환불이 불가합니다. <br /> 출고는 2일전에
            택배로 배송되기 때문에 <br />
            <span className="text-gray-6">
              시작일 2일 전 결제금액의 50% 환불 수수료 발생 <br /> 3일 전
              결제금액의 30% 환불 수수료 발생 <br /> 4일 전 취소 시 환불 수수료
              없음
            </span>
          </dd>
        </dl>
      </div>
    </div>
  );
}
