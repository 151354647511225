import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Header from '@components/shared/header';
import SearchAddress from '@components/forms/searhAddress';
import { common, regex, valids } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import DeliveryApi from '@api/delivery/delivery.query';
import DeliveryDto from '@api/delivery/delivery.dto';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id?: number;
  isFirst?: boolean;
  onDelete?: (btn: string, id?: number) => void;
}

export default function UpdateDeliveryModal({
  open,
  setOpen,
  id,
  isFirst = true,
  onDelete = () => {},
}: Props) {
  const { setLoading, setToastContainerStyle } = useStore((state) => state);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DeliveryDto.Delivery.Infos>();
  const [postCode, setPostCode] = useState('');
  const [address1, setAddress1] = useState('');
  const [isDefault, setIsDefault] = useState(isFirst);

  // api
  const { data: callData, isLoading } = DeliveryApi.useDeliveryDetailQuery(id);
  const itemData = callData?.data?.data;
  const useCreateDeliveryMutation = DeliveryApi.useCreateDeliveryMutation();
  const useUpdateDeliveryMutation = DeliveryApi.useUpdateDeliveryMutation(id);

  const onFormSubmit: SubmitHandler<DeliveryDto.Delivery.Infos> = (data) => {
    setLoading(true);
    const formData = {
      ...data,
      postCode: postCode ?? '',
      address1: address1 ?? '',
      isDefault: isDefault ?? false,
    };
    if (id) useUpdateDeliveryMutation.mutate(formData);
    else useCreateDeliveryMutation.mutate(formData);
  };

  // ERROR !!!
  useEffect(() => {
    if (
      !(useCreateDeliveryMutation.isError && useUpdateDeliveryMutation.isError)
    )
      return;
    setLoading(false);
    setToastContainerStyle({ bottom: 78 });
    toast(valids.errored, { style: common.toastStyle });
  }, [useCreateDeliveryMutation.isError]);

  // 배송지 추가 SUCCESS !!!
  useEffect(() => {
    if (!useCreateDeliveryMutation.isSuccess) return;
    setOpen(false);
    setToastContainerStyle({ bottom: 84 });
    toast(valids.addDelivery, { style: common.toastStyle });
    setLoading(false);
  }, [useCreateDeliveryMutation.isSuccess]);

  // 배송지 수정 SUCCESS !!!
  useEffect(() => {
    if (!useUpdateDeliveryMutation.isSuccess) return;
    setOpen(false);
    setToastContainerStyle({ bottom: 84 });
    toast(valids.updateDelivery, { style: common.toastStyle });
    setLoading(false);
  }, [useUpdateDeliveryMutation.isSuccess]);

  useEffect(() => {
    if (!open) return;
    if (id) {
      setLoading(true);
      if (itemData !== undefined && !isLoading) {
        reset({
          addressName: itemData?.addressName ?? '',
          recipientName: itemData?.recipientName ?? '',
          phoneNumber_1: itemData?.phoneNumber_1 ?? '',
          phoneNumber_2: itemData?.phoneNumber_2 ?? '',
          address2: itemData?.address2 ?? '',
        });
        setPostCode(itemData?.postCode ?? '');
        setAddress1(itemData?.address1 ?? '');
        setIsDefault(itemData?.isDefault ?? false);

        (async () => {
          await sleep(400);
          setLoading(false);
        })();
      }
    } else {
      reset({
        addressName: '',
        recipientName: '',
        phoneNumber_1: '',
        phoneNumber_2: '',
        address2: '',
      });
      setPostCode('');
      setAddress1('');
      setIsDefault(isFirst ?? false);
    }
  }, [open, id, itemData, isLoading]);

  return open ? (
    <div className="modal_wrap full">
      <div className="modal_area">
        <Header
          title={`배송지 ${id ? '수정' : '추가'}`}
          closeFunc={() => setOpen(false)}
        />

        <div className="mypage_delivery_wrap">
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <div className="inner">
              <div className="label_input_area">
                <label htmlFor="addressName">
                  배송지 명칭
                  <span className="text-main">*</span>
                </label>
                <input
                  type="text"
                  id="addressName"
                  className={`input ${errors.addressName ? 'error' : ''}`}
                  placeholder={valids.inputDeliveryName}
                  {...register('addressName', { required: true })}
                />
              </div>
              <div className="label_input_area mt-4">
                <label htmlFor="recipientName">
                  이름
                  <span className="text-main">*</span>
                </label>
                <input
                  type="text"
                  id="recipientName"
                  className={`input ${errors.recipientName ? 'error' : ''}`}
                  placeholder={valids.inputName}
                  {...register('recipientName', { required: true })}
                />
              </div>
              <div className="label_input_area mt-4">
                <label htmlFor="phoneNumber_1">
                  연락처
                  <span className="text-main">*</span>
                </label>
                <input
                  type="text"
                  id="phoneNumber_1"
                  className={`input ${errors.phoneNumber_1 ? 'error' : ''}`}
                  placeholder={valids.inputPhone}
                  maxLength={11}
                  {...register('phoneNumber_1', {
                    required: true,
                    maxLength: 11,
                    pattern: {
                      value: regex.regPhone,
                      message: valids.checkPhone,
                    },
                  })}
                />
                {errors.phoneNumber_1 && (
                  <span className="input_error_text">
                    {errors.phoneNumber_1.message}
                  </span>
                )}
              </div>
              <div className="label_input_area mt-4">
                <label htmlFor="phoneNumber_2">추가 연락처</label>
                <input
                  type="text"
                  id="phoneNumber_2"
                  className={`input ${errors.phoneNumber_2 ? 'error' : ''}`}
                  placeholder={valids.inputAdditionalPhone}
                  maxLength={11}
                  {...register('phoneNumber_2', {
                    maxLength: 11,
                    pattern: {
                      value: regex.regPhone,
                      message: valids.checkPhone,
                    },
                  })}
                />
                {errors.phoneNumber_2 && (
                  <span className="input_error_text">
                    {errors.phoneNumber_2.message}
                  </span>
                )}
              </div>
              <div className="label_input_area mt-4">
                <label htmlFor="address">배송 주소</label>
                <SearchAddress
                  addr={address1}
                  setAddr={setAddress1}
                  zone={postCode}
                  setZone={setPostCode}
                />
                <input
                  type="text"
                  id="address2"
                  className="input mt-1"
                  placeholder={valids.inputAddressDetail}
                  {...register('address2')}
                />
              </div>
              <div className="chk_input mt-4">
                <input
                  type="checkbox"
                  id="isDefault"
                  checked={isDefault}
                  onChange={() => setIsDefault((state) => !state)}
                />
                <label htmlFor="isDefault">
                  <b>기본 배송지로 설정</b>
                </label>
              </div>

              <div className="fixed_btm_btn">
                <div className="fixed_btn bg_style01">
                  <div className="flex gap-2">
                    {/* TODO: 삭제 버튼일 시, 배송지 삭제 API 연동 */}
                    <button
                      type="button"
                      className="btn btn_style04 h-12 w-full rounded-sm text-sm font-bold"
                      onClick={() => {
                        if (id) {
                          if (itemData?.isDefault) onDelete('기본배송지 삭제');
                          else onDelete('삭제', id);
                        } else setOpen(false);
                      }}
                    >
                      {id ? '삭제' : '취소'}
                    </button>
                    <button
                      type="submit"
                      className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                    >
                      {id ? '수정' : '추가'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  ) : null;
}
