import React from 'react';
import imgService01 from '@assets/images/service_img06.jpg';
import imgService02 from '@assets/images/service_img07.jpg';
import imgService03 from '@assets/images/service_img08.jpg';
import imgService04 from '@assets/images/service_img09.jpg';
import imgService05 from '@assets/images/service_img10.jpg';
import Header from '@components/shared/header';
import BottomNav from '@components/shared/bottomNav';

function Page() {
  return (
    <div id="root_layout">
      <div className="root_container">
        <div className="sticky_menu">
          <Header back="/" searchCart />
        </div>

        <div className="service_visual02_area">
          <div className="text_area">
            <p>
              고수익 제테크, 부업으로도 가능! <br />
              안쓰는 <span>명품쉐어로 꾸준한 추가 수입</span>으로 <br />
              전환할 수 있는 특별한 기회를 제공합니다
            </p>
          </div>
        </div>
        <div className="pb-24">
          <div className="inner">
            <dl className="service_info_area">
              <dd>
                <img src={imgService01} alt="뛰어난 서비스 제공" />
              </dd>
              <dt>
                <p>1. 럭스쉐어는 모든 면에서 뛰어난 서비스를 제공합니다.</p>
                <span>
                  상품 위탁부터 렌트까지 쉽고 빠르게 럭스쉐어는 상품을 위탁하고
                  렌트하는 과정을 쉽고 빠르게 제공합니다. 단 몇 번의 클릭으로
                  명품을 렌탈할 수 있으며, 모든 과정은 사용자 친화적으로
                  설계되었습니다.
                </span>
              </dt>
            </dl>
            <dl className="service_info_area">
              <dd>
                <img
                  src={imgService02}
                  alt="간편한 위탁상품등록 프로세스 지원"
                />
              </dd>
              <dt>
                <p>2. 럭스쉐어는 간편한 위탁상품등록 프로세스를 지원합니다.</p>
                <span>
                  직관적인 UI를 제공하여, 럭스쉐어에 명품을 등록하는 과정은 매우
                  간단합니다. 상품 사진을 업로드하고, 간단한 설명을 추가한 후,
                  몇 가지 세부 정보를 입력하기만 하면 됩니다. 모든 과정은 몇 분
                  내에 완료할 수 있습니다.
                </span>
              </dt>
            </dl>
            <dl className="service_info_area">
              <dd>
                <img
                  src={imgService03}
                  alt="전문적인 명품 감정, 보관 관리 제공"
                />
              </dd>
              <dt>
                <p>
                  3. 럭스쉐어는 명품 감정과 보관 관리는 전문적으로 제공합니다.
                </p>
                <span>
                  명품의 가치를 정확하게 평가하기 위해 전문 감정사를 통해 감정
                  서비스를 제공합니다. 또한, 안전하고 전문적인 보관 관리를 통해
                  고객의 소중한 명품을 보호합니다.
                </span>
              </dt>
            </dl>
            <dl className="service_info_area">
              <dd>
                <img
                  src={imgService04}
                  alt="배송부터 CS관리, 한 번에 모든 서비스 지원"
                />
              </dd>
              <dt>
                <p>
                  4. 럭스쉐어는 배송부터 CS관리까지 한 번에 모든 서비스를
                  지원합니다.
                </p>
                <span>
                  명품의 렌트 과정에서 발생하는 모든 서비스를 한 번에 제공하며,
                  전문적인 배송 서비스부터 고객 서비스(CS) 관리까지 고객의
                  편의를 최우선으로 생각합니다.
                </span>
              </dt>
            </dl>
            <dl className="service_info_area">
              <dd>
                <img src={imgService05} alt="국내 최저 비용 이용 가능" />
              </dd>
              <dt>
                <p>
                  5. 럭스쉐어는 국내 최저 비용으로 부담없이 이용 가능합니다.
                </p>
                <span>
                  국내 최저 비용으로 명품을 렌트할 수 있는 서비스를 지원하며,
                  고가의 명품도 저렴한 비용으로 렌트하여 사용할 수 있는 기회를
                  제공합니다.
                </span>
              </dt>
            </dl>
          </div>
        </div>

        <BottomNav />
      </div>
    </div>
  );
}
export default Page;
