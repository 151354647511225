import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { common } from '@config/index';

function Page() {
  const [searchParams] = useSearchParams();
  const spName = searchParams.get('name');
  const spGender = searchParams.get('gender'); // '1', '3' : 남자 | '0', '2' : 여자
  const spMobileNo = searchParams.get('mobileno');
  const spBirthDate = searchParams.get('birthdate');

  useEffect(() => {
    const params = {
      key: common.PASS_DATA_KEY,
      name: spName,
      gender: spGender === '1' || spGender === '3' ? '1' : '0',
      phoneNumber: spMobileNo,
      birthDate: spBirthDate,
    };

    window.addEventListener('unload', function () {
      if (window.opener) {
        window.opener.postMessage(params, '*');
      }
    });
    window.close();
  }, []);

  return null;
}
export default Page;
