import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import icClose from '@assets/images/icon_modal_x.svg';
import icReset from '@assets/images/icon_reset.svg';
import icDelete from '@assets/images/icon_x_white.svg';
import icSearch from '@assets/images/icon_search02.svg';
import Modal from '@components/modal/modal';
import { options, valids } from '@config/index';
import CategoryApi from '@api/category/category.query';
import { FilterTypes, FilterDataType } from '@components/filterWrap';
import { useFilter } from '@hooks/useFilter';
import BrandApi from '@api/brand/brand.query';
import BrandDto from '@api/brand/brand.dto';
// import { brandData } from '@temp/brandData';

interface BrandProps {
  item: BrandDto.SearchBrand.Response;
  filterData: FilterDataType;
  handleDeleteSelected: (type: FilterTypes, data: any) => void;
  handleAddSelected: (type: FilterTypes, data: any) => void;
}
interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: FilterDataType;
  isCategory?: boolean;
}

function BrandItem({
  item,
  filterData,
  handleDeleteSelected,
  handleAddSelected,
}: BrandProps) {
  return (
    <li key={item.id}>
      <div className="chk_input">
        <input
          type="checkbox"
          id={`brand_${item.id}`}
          value={item.id}
          checked={filterData.brand?.some((f) => f.id === item.id)}
          onChange={() => {
            if (filterData.brand?.some((f) => f.id === item.id))
              handleDeleteSelected('brand', item);
            else handleAddSelected('brand', item);
          }}
        />
        <label htmlFor={`brand_${item.id}`}>
          <b className="block">{item?.nameEnglish.toUpperCase() ?? ''}</b>
          <span>{item?.nameKorean ?? ''}</span>
        </label>
      </div>
    </li>
  );
}

export default function FilterModal({
  open,
  setOpen,
  data,
  isCategory = false,
}: Props) {
  /**
   * 1. 모달 내에서 일단 선택한 필터가 있어야 하고...
   * 2. 실제로 적용한 필터 데이터가 있어야 하고...
   * 3. 필터 데이터 중에도 브랜드는 좀 별개로 빠져있어야 하고...
   * 4. 실제 적용한 필터 데이터는 목록까지 넘겨져야 함
   * 5. 필터 적용 버튼 클릭 시 링크 이동시키게 할까? 고민되네 그래야 새로고침해도 보존되게 할 수 있을 것 같은데...
   */
  const navigate = useNavigate();
  const location = useLocation();
  const {
    filterData,
    filterTotalData,
    setFilterData,
    handleAddSelected,
    handleDeleteSelected,
    handleResetSelected,
  } = useFilter({
    brand: [],
    discount: [],
    category: null,
  });
  const [search, setSearch] = useState('');
  const [init, setInit] = useState(false);
  const [apply, setApply] = useState(false);

  // api
  const categoryData =
    CategoryApi.useCategoriesQuery()?.data?.data?.data?.result ?? [];
  const brandData =
    BrandApi.useSearchBrandsQuery({
      brandName: search,
    })?.data?.data?.data?.result ?? [];

  // 필터 적용
  useEffect(() => {
    if (!apply) return;

    const sps = new URLSearchParams(location.search);
    [
      'brandId',
      'brandName',
      'discountId',
      'categoryId',
      'categoryName',
    ].forEach((param) => sps.delete(param));

    filterData?.brand?.forEach((item) => {
      sps.append('brandId', item?.id.toString() ?? '');
      sps.append('brandName', item?.nameKorean ?? '');
    });
    filterData?.discount?.forEach((item) =>
      sps.append('discountId', item?.id.toString() ?? ''),
    );
    if (filterData?.category) {
      sps.append('categoryId', filterData?.category?.id.toString() ?? '');
      sps.append('categoryName', filterData?.category?.name ?? '');
    }

    navigate(`${location.pathname}?${sps.toString()}`);
    setOpen(false);
    setApply(false);
  }, [apply]);

  // 초기화
  useEffect(() => {
    if (!init) return;

    handleResetSelected();
    setInit(false);
  }, [init]);

  useEffect(() => {
    if (!open) return;

    setSearch('');
    setFilterData(data);
  }, [open]);

  return open ? (
    <Modal className="md_filter">
      <div className="modal_top">
        <p className="md_tit">필터</p>
        <button type="button" className="x_btn" onClick={() => setOpen(false)}>
          <img src={icClose} alt="모달 닫기" />
        </button>
      </div>
      <div className="modal_mid">
        <div className="inner">
          {filterTotalData?.length > 0 && (
            <div className="filter_area pb-4">
              <div className="ft_top">
                <p>선택한 조건</p>
                <button type="button" onClick={() => setInit(true)}>
                  초기화
                  <img src={icReset} alt="필터" />
                </button>
              </div>
              <div className="ft_list ft_style02 pt-2">
                <Swiper spaceBetween={8} slidesPerView="auto">
                  {filterTotalData?.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className="ft_tag">
                          <span>{item.name}</span>
                          <button
                            type="button"
                            onClick={() =>
                              handleDeleteSelected(item.type, item)
                            }
                          >
                            <img src={icDelete} alt="X" />
                          </button>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          )}
          <div className="pb-4">
            <p className="ft_tit">브랜드</p>
            <div className="search_area02">
              <input
                type="text"
                className="input"
                placeholder={valids.inputBrand}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button type="button">
                <img src={icSearch} alt="검색" />
              </button>
            </div>
            <div className="input_list_area grid01 mt-3">
              <ul className="gap-y-2">
                {search
                  ? brandData?.length > 0 &&
                    brandData?.map((item) => {
                      return (
                        <BrandItem
                          key={item.id}
                          item={item}
                          filterData={filterData}
                          handleDeleteSelected={handleDeleteSelected}
                          handleAddSelected={handleAddSelected}
                        />
                      );
                    })
                  : filterData.brand?.length > 0 &&
                    filterData.brand?.map((item) => {
                      return (
                        <BrandItem
                          key={item.id}
                          item={item}
                          filterData={filterData}
                          handleDeleteSelected={handleDeleteSelected}
                          handleAddSelected={handleAddSelected}
                        />
                      );
                    })}
              </ul>
            </div>
          </div>
          <div>
            <p className="ft_tit">할인율</p>
            <div className="input_list_area grid02 pt-2">
              <ul className="gap-y-2">
                {options.discountRates.map((item) => {
                  return (
                    <li key={item.id}>
                      <div className="chk_input">
                        <input
                          type="checkbox"
                          id={`discount_${item.id}`}
                          checked={filterData.discount?.some(
                            (f) => f.id === item.id,
                          )}
                          onChange={() => {
                            if (
                              filterData.discount?.some((f) => f.id === item.id)
                            )
                              handleDeleteSelected('discount', item);
                            else handleAddSelected('discount', item);
                          }}
                        />
                        <label htmlFor={`discount_${item.id}`}>
                          {item.text}
                        </label>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          {isCategory && categoryData?.length > 0 && (
            <div className="pt-4">
              <p className="ft_tit">대 카테고리</p>
              <div className="input_list_area pt-2">
                <ul className="gap-x-2">
                  {categoryData?.map((item) => {
                    return (
                      <li key={item.id}>
                        <div className="chk_input02">
                          <input
                            type="radio"
                            name="category"
                            id={`category_${item.id}`}
                            checked={filterData.category?.id === item.id}
                            onChange={() => handleAddSelected('category', item)}
                          />
                          <label htmlFor={`category_${item.id}`}>
                            {item.name}
                          </label>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="modal_btm">
        <button type="button" onClick={() => setApply(true)}>
          필터 적용하기
        </button>
      </div>
    </Modal>
  ) : null;
}
