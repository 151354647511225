import React, { useEffect, useState } from 'react';
import Header from '@components/shared/header';
import BottomNav from '@components/shared/bottomNav';
import TabBtnSlider from '@components/slider/tabBtn';
import ToggleItem from '@components/toggleItem';
import CheckIsLogin from '@components/checkIsLogin';
import { tabs, valids } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import CustomerApi from '@api/customer/customer.query';
// import { faqData } from '@temp/customerData';

function Page() {
  const { setLoading } = useStore((state) => state);
  const [tab, setTab] = useState(tabs.customerFaqTabs[0].id);

  // api
  const { data: callData, isLoading } = CustomerApi.useFaqsQuery({
    type: tab !== 'total' ? tab : undefined,
  });
  const listData = callData?.data?.data?.result;

  useEffect(() => {
    if (listData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [listData, isLoading]);

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="자주 찾는 질문" back />
            <div className="tab_area03 is_pdx bg-white">
              <TabBtnSlider
                data={tabs.customerFaqTabs}
                active={tab}
                setActive={setTab}
                spaceBetween={16}
              />
            </div>
          </div>

          <div className="mypage_customer_detail_wrap pb-24 pt-4">
            <div className="inner">
              {(listData ?? [])?.length > 0 ? (
                listData?.map((item, index) => {
                  return (
                    <ToggleItem
                      key={item.id}
                      initOpen={index === 0}
                      title={
                        <p className="flex gap-2">
                          <b>Q.</b>
                          <span className="flex-1">
                            {item?.question ?? '-'}
                          </span>
                        </p>
                      }
                    >
                      <div className="p-4">
                        <p className="text-xs font-medium text-gray-9">
                          {item?.answer ?? '-'}
                        </p>
                      </div>
                    </ToggleItem>
                  );
                })
              ) : (
                <div className="no_data_text text-center">
                  <p>{valids.noDataFaq}</p>
                </div>
              )}
            </div>
          </div>

          <BottomNav active="MYPAGE" />
        </div>
      </div>
    </>
  );
}
export default Page;
