import React from 'react';
import { Toaster } from 'react-hot-toast';
import imgLoading from '@assets/images/shared/loading.gif';
import Router from '@app/Router';
import useStore from '@store/index';
import Alert from '@components/modal/alert';

function App() {
  const { loading, toastContainerStyle } = useStore((state) => state);

  return (
    <>
      <Router />

      {/* LOADING */}
      {loading && (
        <div className="load_area">
          <img src={imgLoading} alt="loading" />
        </div>
      )}
      {/* ALERT */}
      <Alert />
      {/* TOAST */}
      <Toaster
        position="bottom-center"
        toastOptions={{
          duration: 5000,
        }}
        containerStyle={toastContainerStyle}
      />
    </>
  );
}

export default App;
