import React, { useEffect } from 'react';
import { format, addMonths, subDays } from 'date-fns';
import { Link } from 'react-router-dom';
import icArrow from '@assets/images/icon_arrow_right_gray.svg';
import Header from '@components/shared/header';
import BottomNav from '@components/shared/bottomNav';
import CheckIsLogin from '@components/checkIsLogin';
import { common, valids } from '@config/index';
import { formatYyMmddd, sleep } from '@utils/common';
import useStore from '@store/index';
import ConsignmentApi from '@api/consignment/consignment.query';
// import { consignmentData } from '@temp/consignmentData';

function Page() {
  const { setLoading } = useStore((state) => state);

  // api
  const { data: callData, isLoading } = ConsignmentApi.useSettlementQuery();
  const listData = callData?.data?.data?.result;

  useEffect(() => {
    console.log(listData);
    if (listData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [listData, isLoading]);

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header title="정산 내역" back="/mypage" searchCart />
          </div>

          <div className="pb-24 pt-4">
            <div className="inner">
              <ul className="flex flex-col gap-8">
                {(listData ?? [])?.length > 0 ? (
                  listData?.map((item) => {
                    const consigningEndDate = item?.consigningDate
                      ? format(
                          subDays(
                            addMonths(
                              item?.consigningDate,
                              item?.consignmentPeriod,
                            ),
                            1,
                          ),
                          'yyyy-MM-dd',
                        )
                      : '';

                    return (
                      <li key={item.id}>
                        <div className="flex items-center justify-between border-b border-b-gray-c pb-2">
                          <p className="text-xs font-medium text-gray-9">
                            위탁번호 {item?.consignmentNumber ?? '-'}
                          </p>
                          <Link
                            to={`/mypage/settlement/detail/${item?.id}?num=${
                              item?.consignmentNumber ?? '-'
                            }`}
                            className="flex items-center gap-1 text-xs font-medium text-gray-9"
                          >
                            상세 정보 <img src={icArrow} alt="arrow" />
                          </Link>
                        </div>
                        <div className="item_area pt-4">
                          <div className="img_wrap w-25">
                            <div className="img_area">
                              {item.product?.product_images?.length > 0 ? (
                                <img
                                  src={`${common.imageUrl}/${item.product?.product_images?.[0]?.file?.fileUrl}`}
                                  alt="상품 이미지"
                                />
                              ) : (
                                <i className="no_data_img" />
                              )}
                            </div>
                          </div>
                          <div className="text_area">
                            <div className="mb-3">
                              {item.status === 'application' ? (
                                <p className="status text-black">신청 완료</p>
                              ) : item.status === 'diagnosis' ? (
                                <p className="status text-main">진단 중</p>
                              ) : item.status === 'consigning' ? (
                                <p className="status text-main">위탁 중</p>
                              ) : item.status === 'end' ? (
                                <p className="status text-black">위탁 종료</p>
                              ) : item.status === 'impossible' ? (
                                <p className="status text-gray-9">위탁 불가</p>
                              ) : null}
                            </div>
                            <p className="brand">
                              {item?.product?.brand?.nameEnglish.toUpperCase() ??
                                ''}
                            </p>
                            <p className="product">
                              {item?.product?.productName ?? ''}
                            </p>
                            {(item.status === 'consigning' ||
                              item.status === 'end') && (
                              <div className="mt-3">
                                <span className="desc">
                                  감정한 일 렌트 금액
                                </span>
                                <p className="price_total">
                                  {Number(
                                    item?.assessedRentalPrice ?? 0,
                                  ).toLocaleString()}
                                </p>
                              </div>
                            )}
                            {item?.consigningDate && (
                              <p className="desc mt-3">
                                계약기간 :{' '}
                                {formatYyMmddd(item?.consigningDate ?? '')} -{' '}
                                {formatYyMmddd(consigningEndDate ?? ' ')}
                              </p>
                            )}
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <li className="no_data_text py-1 text-center">
                    <p>{valids.noDataSettlement}</p>
                  </li>
                )}
              </ul>
            </div>
          </div>

          <BottomNav active="MYPAGE" />
        </div>
      </div>
    </>
  );
}
export default Page;
