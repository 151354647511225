import React from 'react';
import icOutOfService from '@assets/images/deco/icon_out_of_service.svg';
import Header from '@components/shared/header';

function Page() {
  return (
    <div id="root_layout">
      <div className="root_container">
        <Header title="시스템 점검" />

        <div className="error_page_wrap">
          <div className="text">
            <img src={icOutOfService} alt="시스템 점검" />
            <p>시스템 점검 중입니다.</p>
            <span>
              안정적인 서비스 제공을 위한 시스템 점검 중이므로 <br />
              잠시 후 다시 접속해 주세요.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page;
