import React from 'react';
import Header from '@components/shared/header';
import PrivacyPolicy from '@components/agree/privacyPolicy';

function Page() {
  return (
    <div id="root_layout">
      <div className="root_container">
        <Header back />

        <section className="agree_page_wrap">
          <div className="inner">
            <div className="title_area">
              <h3>렌티크 개인정보 처리방침</h3>
            </div>
            <div className="agree_area">
              <PrivacyPolicy />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default Page;
