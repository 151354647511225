import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import FilterWrap from '@components/filterWrap';
import CustomSelect from '@components/forms/customSelect';
import LikeBtn from '@components/likeBtn';
import { common, options, valids } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import CategoryApi from '@api/category/category.query';
import SearchApi from '@api/search/search.query';
// import { productData } from '@temp/productData';

export default function SearchProducts() {
  const [searchParams] = useSearchParams();
  const spKeyword = searchParams.get('keyword');
  const spBrandIds = searchParams.getAll('brandId');
  const spDiscountIds = searchParams.getAll('discountId');
  const { setLoading } = useStore((state) => state);
  const [tab, setTab] = useState(0);
  const [sorts, setSorts] = useState(options.productSorts[0].id);

  // api
  const categoryData =
    CategoryApi.useCategoriesQuery()?.data?.data?.data?.result ?? [];
  const {
    data: callData,
    isLoading,
    refetch,
  } = SearchApi.useSearchResultQuery({
    productName: spKeyword ?? '',
    brandId:
      spBrandIds?.length > 0
        ? spBrandIds?.map((item) => Number(item))
        : undefined,
    discountRate: spDiscountIds?.length > 0 ? spDiscountIds : undefined,
    categoryId: tab !== 0 ? Number(tab) : undefined,
  });
  const listData = callData?.data?.data?.result;

  useEffect(() => {
    if (listData !== undefined && !isLoading) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    }
  }, [listData, isLoading]);

  return (
    <>
      {/* 카테고리 */}
      <div className="search_tab_area">
        <div className="inner">
          <ul>
            {[
              {
                id: 0,
                name: '전체',
              },
              ...categoryData,
            ]?.map((item) => {
              return (
                <li key={item.id} className={tab === item.id ? 'on' : ''}>
                  <button type="button" onClick={() => setTab(item.id)}>
                    {item.name}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      {/* 필터 */}
      <div className="inner pb-6 pt-4">
        <FilterWrap />
      </div>

      {/* 리스트 */}
      <div className="pb-10">
        <div className="inner">
          <div className="flex items-center justify-between pb-4">
            <p className="text-xs font-medium text-black">
              <span className="text-main">{spKeyword ?? ''}</span>에 대한{' '}
              <span className="text-main">{listData?.length ?? 0}</span>
              개의 검색 결과
            </p>
            <CustomSelect
              type={1}
              optionClassName="w-24 right-0"
              options={options.productSorts}
              value={sorts}
              setValue={setSorts}
            />
          </div>
          <div className="list_area list_style01 grid02">
            <ul>
              {(listData ?? [])?.length > 0 ? (
                listData?.map((item) => {
                  return (
                    <li key={item.id}>
                      <LikeBtn
                        id={item?.id}
                        likeYn={item?.likeYn}
                        refetch={refetch}
                      />
                      <Link to={`/product/detail/${item.id}`}>
                        <div className="img_wrap">
                          <div className="img_area rounded-lg">
                            {item?.product_images?.length > 0 ? (
                              <img
                                src={`${common.imageUrl}/${item?.product_images?.[0]?.file?.fileUrl}`}
                                alt="상품 이미지"
                              />
                            ) : (
                              <i className="no_data_img" />
                            )}
                            <span className="tag">렌트</span>
                          </div>
                        </div>
                        <div className="text_area">
                          <span className="brand">
                            {item?.brand?.nameEnglish.toUpperCase() ?? ''}
                          </span>
                          <p className="product">{item?.productName ?? ''}</p>
                          <div className="flex items-center justify-start gap-1">
                            {Number(item?.discountRate ?? 0) > 0 && (
                              <span className="price_discount">
                                {`${Number(item?.discountRate ?? 0)}%`}
                              </span>
                            )}
                            <em className="price_desc">일</em>
                            <p className="price_total">
                              {Number(item?.discountRate ?? 0) > 0
                                ? Number(
                                    item?.discountPrice ?? 0,
                                  ).toLocaleString()
                                : Number(
                                    item?.dailyRentalPrice ?? 0,
                                  ).toLocaleString()}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })
              ) : (
                <li className="no_data_text">
                  <p>{valids.noDataSearch}</p>
                </li>
              )}
            </ul>
          </div>
          {(listData ?? [])?.length > 20 && (
            <button
              type="button"
              className="btn btn_style01 text-medium mt-4 h-10 w-full rounded-lg text-sm"
            >
              더보기 +
            </button>
          )}
        </div>
      </div>
    </>
  );
}
