import React, { useEffect, useState } from 'react';
import { format, addDays, differenceInDays } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '@components/shared/header';
import ToggleItem from '@components/toggleItem';
import OrderInquiryModal from '@components/modal/orderInquiry';
import CheckIsLogin from '@components/checkIsLogin';
import { common, options, valids } from '@config/index';
import { formatMmddd, formatYyMmddd, sleep } from '@utils/common';
import useStore from '@store/index';
import CommonDto from '@api/common.dto';
import PaymentApi from '@api/payment/payment.query';

function Page() {
  const navigate = useNavigate();
  const params = useParams();
  const paramsId = params.id;
  const id = Number(paramsId);
  const { setLoading } = useStore((state) => state);
  const [rentDates, setRentDates] = useState(0);
  const [collectDate, setCollectDate] = useState('');
  const [coupon, setCoupon] = useState(0);
  const [point, setPoint] = useState(0);
  const [cancel, setCancel] = useState(0);
  const [productData, setProductData] = useState<CommonDto.Product | undefined>(
    undefined,
  );
  const [inquiryOpen, setInquiryOpen] = useState(false);

  // api
  const { data: callData, isLoading } = PaymentApi.useOrderDetailQuery(id);
  const resultData = callData?.data?.data?.result;

  useEffect(() => {
    // console.log(resultData);
    if (paramsId && resultData !== undefined && !isLoading) {
      if (resultData?.rentalStartDate && resultData?.rentalEndDate) {
        const newDate = format(
          addDays(resultData?.rentalEndDate, 1),
          'yy.MM.dd',
        );
        const newWeek = common.weeks.filter(
          (item) => item.id === format(resultData?.rentalEndDate, 'c'),
        )?.[0]?.text;

        setRentDates(
          differenceInDays(
            resultData?.rentalEndDate,
            resultData?.rentalStartDate,
          ) + 1,
        );
        setCollectDate(`${newDate}(${newWeek})`);
      }
      if (resultData?.order_items?.length > 0) {
        setCoupon(
          resultData?.order_items?.reduce((acc, obj) => {
            return acc + parseFloat(obj.couponDiscount ?? 0);
          }, 0),
        );
        setPoint(
          resultData?.order_items?.reduce((acc, obj) => {
            return acc + parseFloat(obj.pointsUsed ?? 0);
          }, 0),
        );
      } else {
        setCoupon(0);
        setPoint(0);
      }
      if (resultData?.payments?.length > 1) {
        setCancel(
          resultData?.payments
            ?.filter((_, index) => index !== 0)
            ?.reduce((acc, obj) => {
              return acc + (obj.amount ?? 0);
            }, 0),
        );
      } else {
        setCancel(0);
      }

      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [paramsId, resultData, isLoading]);

  // NO DATA !!!
  if (!resultData && !isLoading) {
    alert(valids.noDataPage);
    window.history.back();
  }
  if (!resultData) return null;

  return (
    <>
      <CheckIsLogin loginCheck={false} />
      <div id="root_layout">
        <div className="root_container">
          <div className="sticky_menu">
            <Header back title="주문 상세" />
          </div>

          <div className="order_wrap">
            <div className="inner">
              {(resultData?.order_items ?? [])?.length > 0 && (
                <ToggleItem title="렌트 상품 정보">
                  {resultData?.order_items?.map((item, index) => {
                    return (
                      <div
                        key={item.id}
                        className={`item_area px-4 py-6 ${
                          index > 0 ? 'border-t border-t-gray-c' : ''
                        }`}
                      >
                        <div className="img_wrap w-25">
                          <div className="img_area">
                            {item.product?.product_images?.length > 0 ? (
                              <img
                                src={`${common.imageUrl}/${item.product?.product_images?.[0]?.file?.fileUrl}`}
                                alt="상품 이미지"
                              />
                            ) : (
                              <i className="no_data_img" />
                            )}
                          </div>
                        </div>
                        <div className="text_area">
                          <div className="mb-3 flex items-center justify-between">
                            {item?.status === 'new' ||
                            item?.status === 'ready_for_delivery' ? (
                              <p className="status text-main">배송 준비중</p>
                            ) : item?.status === 'shipped' ? (
                              <p className="status text-main">배송중</p>
                            ) : item?.status === 'delivered' ||
                              item?.status === 'end' ? (
                              <p className="status text-black">배송 완료</p>
                            ) : item?.status === 'collected' ? (
                              <p className="status text-black">반납 완료</p>
                            ) : item?.status === 'cancel' ? (
                              <p className="status text-gray-9">예약 취소</p>
                            ) : item?.status === 'return_requested' ||
                              item?.status === 'return_collecting' ||
                              item?.status === 'return_collected' ? (
                              <p className="status text-main">반품진행중</p>
                            ) : item?.status === 'return_completed' ? (
                              <p className="status text-black">반품완료</p>
                            ) : (
                              <p className="status">-</p>
                            )}
                            <button
                              type="button"
                              className="line_btn"
                              onClick={() => {
                                setProductData(item?.product);
                                setInquiryOpen(true);
                              }}
                            >
                              문의하기
                            </button>
                          </div>
                          <p className="brand">
                            {item.product?.brand?.nameEnglish.toUpperCase() ??
                              ''}
                          </p>
                          <p className="product">
                            {item.product?.productName ?? ''}
                          </p>
                          <p className="price_total mt-3">
                            {Number(item?.finalAmount ?? 0).toLocaleString()}
                          </p>
                          {/* {resultData?.order_items?.length === index + 1 && ( */}
                          <p className="desc mt-3">
                            이용기간 :{' '}
                            {formatMmddd(resultData?.rentalStartDate ?? '')} ~{' '}
                            {formatMmddd(resultData?.rentalEndDate ?? '')}
                          </p>
                          {/* )} */}
                        </div>
                      </div>
                    );
                  })}
                </ToggleItem>
              )}
              <ToggleItem title="주문자 정보">
                <div className="p-4">
                  <dl className="text_list_area">
                    <dt>이름</dt>
                    <dd>{resultData?.user?.name ?? '-'}</dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>연락처</dt>
                    <dd>{resultData?.user?.phoneNumber ?? '-'}</dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>이메일</dt>
                    <dd>{resultData?.user?.email ?? '-'}</dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>성별</dt>
                    <dd>
                      {resultData?.user?.gender === true
                        ? '남성'
                        : resultData?.user?.gender === false
                          ? '여성'
                          : '-'}
                    </dd>
                  </dl>
                </div>
              </ToggleItem>
              <ToggleItem title="배송지 정보">
                <div className="p-4">
                  <dl className="text_list_area">
                    <dt>수령인</dt>
                    <dd>{resultData?.shippingName ?? '-'}</dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>연락처</dt>
                    <dd>{resultData?.shippingPhone ?? '-'}</dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>주소</dt>
                    <dd>{resultData?.shippingAddress ?? '-'}</dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>배송메모</dt>
                    <dd>{resultData?.shippingNote ?? '-'}</dd>
                  </dl>
                </div>
              </ToggleItem>
              <ToggleItem title="이용 정보">
                <div className="p-4">
                  <dl className="text_list_area">
                    <dt>총 렌트일</dt>
                    <dd>{rentDates ?? 0}일</dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>이용 기간</dt>
                    <dd>
                      {!(
                        resultData?.rentalStartDate && resultData?.rentalEndDate
                      ) && '-'}
                      {formatYyMmddd(resultData?.rentalStartDate ?? '')} ~{' '}
                      {formatYyMmddd(resultData?.rentalEndDate ?? '')}
                    </dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>회수일</dt>
                    <dd>{collectDate ?? '-'}</dd>
                  </dl>
                </div>
              </ToggleItem>
              <ToggleItem title="할인 및 결제금액 정보">
                <div className="p-4">
                  <dl className="text_list_area">
                    <dt>카드정보</dt>
                    <dd>
                      {resultData?.payments?.length > 0 ? (
                        <>
                          {resultData.payments[0]?.cardCompany ?? ''}
                          {resultData.payments[0]?.cardNumber
                            ? ` (${resultData.payments[0]?.cardNumber})`
                            : ''}
                        </>
                      ) : (
                        '-'
                      )}
                    </dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>결제 상태</dt>
                    <dd>
                      {resultData?.payments?.length > 0
                        ? options.paymentStatus2?.filter(
                            (item) =>
                              item.id ===
                              resultData.payments[
                                resultData.payments.length - 1
                              ]?.status,
                          )?.[0]?.text
                        : '-'}
                    </dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>총 렌트 금액</dt>
                    <dd>
                      {(
                        Number(resultData?.totalAmount ?? 0) +
                        coupon +
                        point
                      ).toLocaleString()}
                      원
                    </dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>쿠폰</dt>
                    <dd>{coupon ? `-${coupon.toLocaleString()}` : 0}원</dd>
                  </dl>
                  <dl className="text_list_area mt-2">
                    <dt>적립금</dt>
                    <dd>{point ? `-${point.toLocaleString()}` : 0}원</dd>
                  </dl>
                  {cancel && (
                    <dl className="text_list_area mt-2">
                      <dt>
                        <span className="text-red">취소금액</span>
                      </dt>
                      <dd>{(cancel ?? 0).toLocaleString()}원</dd>
                    </dl>
                  )}
                  <dl className="text_list_area mt-2">
                    <dt>
                      <span className="text-main">결제금액</span>
                    </dt>
                    <dd>
                      <b>
                        {Number(
                          resultData?.payments?.[0]?.amount ?? 0,
                        ).toLocaleString()}
                        원
                      </b>
                    </dd>
                  </dl>
                </div>
              </ToggleItem>

              <div className="py-4">
                <button
                  type="button"
                  className="btn btn_style03 h-12 w-full rounded-sm text-sm font-bold"
                  onClick={() => navigate('/mypage/rent')}
                >
                  목록으로
                </button>
              </div>
            </div>
          </div>

          <OrderInquiryModal
            open={inquiryOpen}
            setOpen={setInquiryOpen}
            productData={productData}
          />
        </div>
      </div>
    </>
  );
}
export default Page;
