// import 'tailwindcss/tailwind.css';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// import 'assets/css/style.css';
import 'react-day-picker/style.css';
import '@assets/scss/style.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from '@app/App';
import { ReactQueryProvider } from '@api/index';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  // <React.StrictMode>
  // <BrowserRouter basename="/rentique-react">
  <ReactQueryProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ReactQueryProvider>,
  // </React.StrictMode>,
);
