import React, { useEffect, useState } from 'react';
import Modal from '@components/modal/modal';
import useStore from '@store/index';

export default function Alert() {
  const { alert, setAlert } = useStore((state) => state);
  const [isShakeClass, setIsShakeClass] = useState(false);

  useEffect(() => {
    document.addEventListener('mouseup', function (e) {
      const target = e.target as Node;
      const modalWrap = document.querySelector('.modal_wrap');
      const modalArea = document.querySelector('.modal_area');

      if (modalWrap?.contains(target) && !modalArea?.contains(target)) {
        setIsShakeClass(true);
        setTimeout(() => {
          setIsShakeClass(false);
        }, 500);
      }
    });
  }, []);

  return alert !== '' ? (
    <Modal className={`msg ${isShakeClass ? 'shake' : ''}`}>
      <div className="modal_mid">
        <p className="tit">알림</p>
        <span className="desc">{alert}</span>
      </div>
      <div className="modal_btm">
        <button
          type="button"
          className="btn btn_style02 h-10 w-full rounded-sm text-sm font-bold"
          onClick={() => setAlert('')}
        >
          확인
        </button>
      </div>
    </Modal>
  ) : null;
}
