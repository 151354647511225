import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '@components/shared/header';
import Footer from '@components/shared/footer';
import ProductImageSlider from '@app/product/detail/imageSlider';
import ProductTopInfo from '@app/product/detail/topInfo';
import ProductSimilarItems from '@app/product/detail/similarItems';
import ProductItemsInfo from '@app/product/detail/itemsInfo';
import ProductItemsReview from '@app/product/detail/itemsReview';
import ProductItemsUseInfo from '@app/product/detail/itemsUseInfo';
import { tabs, valids } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import ProductApi from '@api/product/product.query';
import LikeBtn from '@components/likeBtn';

function Page() {
  const navigate = useNavigate();
  const params = useParams();
  const paramsId = params.id;
  const id = Number(paramsId);
  const { setLoading } = useStore((state) => state);
  const sectionRefs = useRef<(HTMLElement | null)[]>([]);
  const [tab, setTab] = useState(tabs.productDetailTabs[0].id);
  const [reviewAverage, setReviewAverage] = useState(0);

  // api
  const {
    data: callData,
    isLoading,
    refetch,
  } = ProductApi.useProductsDetailQuery(id);
  const resultData = callData?.data?.data;

  const handleTabClick = (index: number) => {
    const offset = -90;

    const sectionEl = sectionRefs.current[index];
    if (sectionEl) {
      const sectionTop = sectionEl.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: sectionTop + offset,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (paramsId && resultData !== undefined && !isLoading) {
      if (resultData?.reviews?.length > 0) {
        const sum = resultData?.reviews?.reduce((acc, obj) => {
          return acc + parseFloat(obj.rating);
        }, 0);
        const avg = !Number.isNaN(sum) ? sum / resultData.reviews.length : 0;
        setReviewAverage(avg);
      } else setReviewAverage(0);

      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [paramsId, resultData, isLoading]);

  useEffect(() => {
    // 탭
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 100;
      // const scrollPosition = window.scrollY + window.innerHeight / 2;

      sectionRefs.current.forEach((section, index) => {
        if (section) {
          const sectionTop = section.offsetTop;
          const sectionBottom = sectionTop + section.offsetHeight;

          if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
            setTab(index);
          }
        }
      });
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // NO DATA !!!
  if (!paramsId && !resultData && !isLoading) {
    alert(valids.noDataPage);
    window.history.back();
  }
  if (!resultData) return null;

  return (
    <div id="root_layout">
      <div className="root_container">
        <div className="sticky_menu">
          <Header back home searchCart interest />
        </div>

        {/* 상품 이미지 슬라이드 */}
        <ProductImageSlider
          data={(resultData?.product_images ?? [])?.filter(
            (item) => item.type !== 'info',
          )}
        />

        <div className="pb-6">
          {/* 상품명, 가격, 쿠폰 */}
          <section className="pt-6">
            <ProductTopInfo
              data={resultData}
              reviewAverage={reviewAverage}
              onReviewClick={() => handleTabClick(1)}
            />
          </section>

          {/* 유사한 상품 */}
          <section className="pt-6">
            <ProductSimilarItems
              id={id}
              data={resultData?.similarProducts ?? []}
            />
          </section>
        </div>

        {/* 탭 */}
        <div className="prod_detail_tab_area tab_area03 grid03">
          <ul>
            {tabs.productDetailTabs.map((item) => {
              return (
                <li key={item.id} className={item.id === tab ? 'on' : ''}>
                  <button type="button" onClick={() => handleTabClick(item.id)}>
                    {item.text}
                    {item.id === 1 && (
                      <span className="text-xs">
                        {resultData?.reviews?.length ?? 0}
                      </span>
                    )}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>

        {/* 상품 정보 */}
        <section
          className="pt-6"
          ref={(el) => {
            sectionRefs.current[0] = el;
          }}
        >
          <ProductItemsInfo data={resultData} />
        </section>

        {/* 리뷰 */}
        <section
          className="pt-8"
          ref={(el) => {
            sectionRefs.current[1] = el;
          }}
        >
          <ProductItemsReview
            reviewAverage={reviewAverage}
            data={resultData?.reviews ?? []}
          />
        </section>

        {/* 이용안내 */}
        <section
          className="py-8"
          ref={(el) => {
            sectionRefs.current[2] = el;
          }}
        >
          <ProductItemsUseInfo />
        </section>

        {/* 하단 버튼 */}
        <div className="prod_detail_bottom_btn">
          <div className="inner">
            <LikeBtn
              id={id}
              likeYn={resultData?.likeYn}
              refetch={refetch}
              toastBottom={78}
              styleType={2}
            />
            <button
              type="button"
              className="btn btn_style02 h-12 rounded-sm text-sm font-bold"
              onClick={() => navigate(`/product/rent/${1}`)}
            >
              날짜 선택하기
            </button>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
export default Page;
