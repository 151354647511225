import React, { useEffect, useRef, useState } from 'react';
// import imgProduct01 from '@assets/images/temp/product_img08.jpg';
// import imgProduct02 from '@assets/images/temp/product_img09.jpg';
// import imgProduct03 from '@assets/images/temp/product_img10.jpg';
import { common } from '@config/index';
import { sleep } from '@utils/common';
import ProductDto from '@api/product/product.dto';

interface Props {
  data: ProductDto.Products.Response;
}

export default function ProductItemsInfo({ data }: Props) {
  const elementRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [isLimit, setIsLimit] = useState(true);

  useEffect(() => {
    // 상품정보
    (async () => {
      await sleep(400);
      if (elementRef.current) {
        const hideH =
          Math.floor(elementRef.current.offsetHeight / 3 / 100) * 100;
        if (hideH > 160) setHeight(hideH);
        else setIsLimit(false);
      }
    })();
  }, [data]);

  return (
    <div className="prod_detail_product_info">
      <div className="inner">
        <h3 className="prod_tit">기본 정보</h3>
        <div className="prod_table_area">
          <table className="mb-8 mt-3">
            <colgroup>
              <col style={{ width: '18%' }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>상품 코드</th>
                <td>{data?.productCode ?? ''}</td>
              </tr>
              {data?.color && (
                <tr>
                  <th>색상</th>
                  <td>{data?.color}</td>
                </tr>
              )}
              {data?.material && (
                <tr>
                  <th>재질</th>
                  <td>{data?.material}</td>
                </tr>
              )}
              {data?.origin && (
                <tr>
                  <th>원산지</th>
                  <td>{data?.origin}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* 에디터 영역 */}
        {(data?.description ||
          (data?.product_images ?? [])?.filter((item) => item.type === 'info')
            ?.length > 0) && (
          <div style={isLimit ? { height } : {}} className="overflow-hidden">
            <div ref={elementRef}>
              {data?.description && (
                <div
                  className="prod_editor_area"
                  dangerouslySetInnerHTML={{ __html: data?.description }}
                />
              )}
              {(data?.product_images ?? [])
                ?.filter((item) => item.type === 'info')
                ?.map((item, index) => {
                  return (
                    <img
                      key={index}
                      className="mt-4"
                      src={`${common.imageUrl}/${item.file.fileUrl}`}
                      alt="상품 이미지"
                    />
                  );
                })}
            </div>
          </div>
        )}
      </div>
      {isLimit && (
        <div className="inner">
          <button
            type="button"
            className="btn btn_style01 mt-4 h-10 w-full rounded-lg text-sm font-medium text-black"
            onClick={() => setIsLimit(false)}
          >
            상품정보 더보기 +
          </button>
        </div>
      )}
    </div>
  );
}
