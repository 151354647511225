import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import icArrow from '@assets/images/icon_arrow_right.svg';
import imgMenu01 from '@assets/images/main/main_menu01.png';
import imgMenu02 from '@assets/images/main/main_menu02.png';
import imgMenu03 from '@assets/images/main/main_menu03.png';
import imgMenu04 from '@assets/images/main/main_menu04.png';
import imgMenu05 from '@assets/images/main/main_menu05.png';
// import imgMenu06 from '@assets/images/main/main_menu06.png';
import imgBanner02 from '@assets/images/main/main_banner_img02.png';
import Header from '@components/shared/header';
import HeaderNav from '@components/shared/headerNav';
import Footer from '@components/shared/footer';
import BottomNav from '@components/shared/bottomNav';
import ProductListSlider from '@components/slider/productList';
import LikeBtn from '@components/likeBtn';
import MainVisualSlider from '@app/main/visual';
import MainHotDealSlider from '@app/main/hotDeal';
import MainTimer from '@app/main/timer';
import MainImageModal from '@components/modal/mainImage';
import { common } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import HomeApi from '@api/home/home.query';
import CategoryApi from '@api/category/category.query';
// import { productData } from '@temp/productData';
// import { mainBannerData } from '@temp/mainData';

function Page() {
  const { setLoading } = useStore((state) => state);
  const [newItemsTab, setNewItemsTab] = useState(0);
  const [hotDealIndex, setHotDealIndex] = useState(0);

  // api
  const { data: callData, isLoading, refetch } = HomeApi.useRecommendQuery();
  const resultData = callData?.data?.data;
  const {
    data: callData2,
    isLoading: isLoading2,
    refetch: refetch2,
  } = HomeApi.useRecommendNewItemsQuery(
    newItemsTab !== 0 ? newItemsTab : undefined,
  );
  const resultData2 = callData2?.data?.data;
  const categoryData =
    CategoryApi.useCategoriesQuery()?.data?.data?.data?.result ?? [];

  useEffect(() => {
    if (
      resultData !== undefined &&
      !isLoading &&
      resultData2 !== undefined &&
      !isLoading2
    ) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [resultData, isLoading, resultData2, isLoading2]);

  return (
    <div id="root_layout">
      <div className="root_container">
        <div className="sticky_menu">
          <Header logo searchCart />
          <HeaderNav active="HOME" />
        </div>

        {/* 메인 비주얼 - 최대 10개 */}
        <MainVisualSlider data={resultData?.banners ?? []} />

        {/* 메인 메뉴 */}
        <div className="py-5">
          <div className="main_menu_wrap py-5">
            <ul>
              <li>
                <Link to="/service-info/rentique">
                  <img src={imgMenu01} alt="렌티크" />
                  <span>렌티크</span>
                </Link>
              </li>
              <li>
                <Link to="/service-info/luxshare">
                  <img src={imgMenu02} alt="럭스쉐어" />
                  <span>럭스쉐어</span>
                </Link>
              </li>
              <li>
                <Link to="/promotion">
                  <img src={imgMenu03} alt="EVENT" />
                  <span>EVENT</span>
                </Link>
              </li>
              <li>
                <Link to="/product?c1=2&c1Name=남성">
                  <img src={imgMenu04} alt="남성 추천" />
                  <span>남성 추천</span>
                </Link>
              </li>
              <li>
                <Link to="/product?c1=1&c1Name=여성">
                  <img src={imgMenu05} alt="여성 추천" />
                  <span>여성 추천</span>
                </Link>
              </li>
              {/* <li>
                <Link to="/">
                  <img src={imgMenu06} alt="키즈 추천" />
                  <span>키즈 추천</span>
                </Link>
              </li> */}
            </ul>
          </div>
        </div>

        {/* 이벤트 배너 */}
        <div className="main_banner_wrap">
          <div className="inner">
            <Link to="/promotion/detail/new">
              <img src={imgBanner02} alt="신규 회원가입 할인 쿠폰 배너" />
            </Link>
          </div>
        </div>

        {/* HOT ITEMS - 최소 5개 ~ 최대 20개 */}
        <section className="pt-10">
          <div className="inner">
            <div className="title_area">
              <h3>HOT ITEMS</h3>
              <p>지금 많이 찾는 HOT한 아이템들!</p>
            </div>
          </div>
          <ProductListSlider
            data={
              resultData?.recommend
                ?.filter((item) => item.theme === 'HOT_ITEMS')?.[0]
                ?.recommended_products?.map(({ displayOrder, product }) => ({
                  displayOrder,
                  ...product,
                })) ?? []
            }
            refetch={() => {
              refetch();
              refetch2();
            }}
            toastStyle={78}
          />
        </section>

        {/* 렌티크 기획전 - 3개 */}
        <section className="pt-10">
          <div className="inner">
            <div className="title_area">
              <h3>렌티크 기획전</h3>
              <p>지금 바로 HIT! 2024 F/W 시즌 BEST</p>
            </div>
            <div className="main_banner_wrap">
              {(resultData?.planning ?? [])?.length > 0 && (
                <Link
                  to={`/promotion/product/${resultData?.planning?.[0]?.id}`}
                >
                  {resultData?.planning?.[0]?.main_image_file ? (
                    <img
                      src={`${common.imageUrl}/${resultData?.planning?.[0]?.main_image_file?.fileUrl}`}
                      alt="기획전 배너"
                    />
                  ) : (
                    <div className="text_area">
                      <p>{resultData?.planning?.[0]?.title ?? ''}</p>
                      <span>{resultData?.planning?.[0]?.subtitle ?? ''}</span>
                    </div>
                  )}
                </Link>
              )}
            </div>
            {(resultData?.planning?.[0]?.planning_event_products ?? [])
              ?.length > 0 && (
              <>
                <div className="list_area list_style02 pt-2">
                  <ul>
                    {resultData?.planning?.[0]?.planning_event_products?.map(
                      (item) => {
                        return (
                          <li key={item.product?.id}>
                            <LikeBtn
                              id={item.product?.id}
                              likeYn={item.product?.likeYn}
                              refetch={() => {
                                refetch();
                                refetch2();
                              }}
                              toastBottom={78}
                            />
                            <Link to={`/product/detail/${item.product?.id}`}>
                              <div className="img_wrap">
                                <div className="img_area rounded-lg">
                                  {item.product?.product_images?.length > 0 ? (
                                    <img
                                      src={`${common.imageUrl}/${item.product?.product_images?.[0]?.file?.fileUrl}`}
                                      alt="상품 이미지"
                                    />
                                  ) : (
                                    <i className="no_data_img" />
                                  )}
                                  <span className="tag">렌트</span>
                                </div>
                              </div>
                              <div className="text_area">
                                <span className="brand">
                                  {item.product?.brand?.nameEnglish.toUpperCase() ??
                                    ''}
                                </span>
                                <p className="product">
                                  {item.product?.productName ?? ''}
                                </p>
                                <div className="flex items-center justify-start gap-1">
                                  {Number(item.product?.discountRate ?? 0) >
                                    0 && (
                                    <span className="price_discount">
                                      {`${Number(
                                        item.product?.discountRate ?? 0,
                                      )}%`}
                                    </span>
                                  )}
                                  <em className="price_desc">일</em>
                                  <p className="price_total">
                                    {Number(item.product?.discountRate ?? 0) > 0
                                      ? Number(
                                          item.product?.discountPrice ?? 0,
                                        ).toLocaleString()
                                      : Number(
                                          item.product?.dailyRentalPrice ?? 0,
                                        ).toLocaleString()}
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        );
                      },
                    )}
                  </ul>
                </div>
                <Link
                  to="/promotion"
                  className="btn btn_style01 mt-4 h-10 w-full rounded-lg text-sm"
                >
                  전체 상품 보기
                  <img src={icArrow} alt="arrow" className="pl-2" />
                </Link>
              </>
            )}
          </div>
        </section>

        {/* RENT BAG PICK’S - 최소 5개 ~ 최대 20개 */}
        <section className="pt-10">
          <div className="inner">
            <div className="title_area">
              <h3>RENT BAG PICK’S</h3>
              <p>렌티크 주인장이 픽한 아이템!</p>
            </div>
          </div>
          <ProductListSlider
            data={
              resultData?.recommend
                ?.filter((item) => item.theme === 'RENT_BAG_PICKS')?.[0]
                ?.recommended_products?.map(({ displayOrder, product }) => ({
                  displayOrder,
                  ...product,
                })) ?? []
            }
            refetch={() => {
              refetch();
              refetch2();
            }}
            toastStyle={78}
          />
        </section>

        {/* NEW ITEMS - 최소 6개 ~ 최대 12개 */}
        <section className="pt-10">
          <div className="inner">
            <div className="title_area">
              <h3>NEW ITEMS</h3>
              <p>지금 바로 도착한 최신 입고 아이템들!</p>
            </div>
            <div className="main_tab_area tab_area pb-4">
              <ul>
                {[
                  {
                    id: 0,
                    name: '전체',
                  },
                  ...categoryData,
                ]?.map((item) => {
                  return (
                    <li
                      key={item.id}
                      className={newItemsTab === item.id ? 'on' : ''}
                    >
                      <button
                        type="button"
                        onClick={() => setNewItemsTab(item.id)}
                      >
                        {item.name}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <ProductListSlider
            data={resultData2 ?? []}
            options={{ rows: 2 }}
            refetch={() => {
              refetch();
              refetch2();
            }}
            toastStyle={78}
          />
        </section>

        {/* HOT DEAL - 최대 2개 */}
        <section className="py-10">
          <div className="inner">
            <div className="title_area flex items-center justify-between gap-1">
              <div className="flex-1">
                <h3>HOT DEAL</h3>
                <p>지금 아니면 놓칠 수 없는 할인 찬스!</p>
              </div>
              <MainTimer
                date={
                  resultData?.hotDealItems[hotDealIndex]?.salesEndDate ?? ''
                }
              />
            </div>
            <MainHotDealSlider
              data={resultData?.hotDealItems ?? []}
              setIndex={setHotDealIndex}
              refetch={() => {
                refetch();
                refetch2();
              }}
            />
          </div>
        </section>

        <Footer btmNav />
        <BottomNav active="HOME" />
        <MainImageModal />
      </div>
    </div>
  );
}
export default Page;
