import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAxios, Utils } from '@api/index';
import ConsignmentQueryKey from '@api/consignment/consignment.key';
import ConsignmentDto from '@api/consignment/consignment.dto';

namespace ConsignmentApi {
  /**
   * 위탁 내역 조회
   */
  export function useConsignmentHistoryQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [ConsignmentQueryKey.CONSIGNMENT_HISTORY],
      queryFn() {
        return axios.get<
          Utils.View.Response<ConsignmentDto.History.ListResponse>
        >('/api/users/consignment-history');
      },
    });
  }

  /**
   * 위탁 상세 조회
   * @param {number} consignmentId - 위탁 ID
   */
  export function useConsignmentDetailQuery(consignmentId?: number) {
    const axios = useAxios();

    return useQuery({
      queryKey: [ConsignmentQueryKey.CONSIGNMENT_DETAIL, consignmentId],
      queryFn() {
        return axios.get<
          Utils.View.Response<ConsignmentDto.History.DetailResponse>
        >(`/api/users/consignment/${consignmentId}`);
      },
      enabled: !!consignmentId,
    });
  }

  /**
   * 위탁 신청
   */

  /**
   * 송장번호 입력
   * @param {ConsignmentDto.Tracking.Request} body - Body
   */
  export function useTrackingNumberMutation() {
    const queryClient = useQueryClient();
    const axios = useAxios();

    return useMutation<
      AxiosResponse<Utils.View.Response<null>>,
      AxiosError<{ message: string }>,
      ConsignmentDto.Tracking.Request
    >({
      mutationFn(body: ConsignmentDto.Tracking.Request) {
        return axios.put<
          Utils.View.Response<null>,
          AxiosResponse<Utils.View.Response<null>>,
          ConsignmentDto.Tracking.Request
        >(`/api/users/tracking-number/${body.consignmentId}`, body);
      },
      // onSuccess({ data: responseData }) {
      //   console.log('responseData', responseData);
      // },
      onSettled() {
        return queryClient.invalidateQueries({
          queryKey: [ConsignmentQueryKey.TRACKING_NUMBER],
        });
      },
    });
  }

  /**
   * 위탁 수수료 조회
   */

  /**
   * 위탁 수수료 조회
   */
  export function useSettlementQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [ConsignmentQueryKey.SETTLEMENT],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<ConsignmentDto.ConsignmentList[]>
        >('/api/users/settlements-history');
      },
    });
  }

  /**
   * 정산 내역 조회
   * @param {number} consignmentId - 위탁 ID
   */
  export function useSettlementDetailQuery(consignmentId?: number) {
    const axios = useAxios();

    return useQuery({
      queryKey: [ConsignmentQueryKey.SETTLEMENT_DETAIL, consignmentId],
      queryFn() {
        return axios.get<
          Utils.View.ResponseResult<ConsignmentDto.Settlement.DetailResponse[]>
        >(`/api/users/settlements-history/${consignmentId}`);
      },
      enabled: !!consignmentId,
    });
  }
}

export default ConsignmentApi;
