import React from 'react';
import icClose from '@assets/images/icon_modal_x.svg';
import Modal from '@components/modal/modal';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CancelRefundPolicyModal({ open, setOpen }: Props) {
  return open ? (
    <Modal>
      <div className="modal_top">
        <p className="md_tit">취소 환불 정책</p>
        <button type="button" className="x_btn" onClick={() => setOpen(false)}>
          <img src={icClose} alt="모달 닫기" />
        </button>
      </div>
      <div className="modal_mid p-4">
        <div className="table_area table_style01">
          <table className="border-t-black">
            <colgroup>
              <col style={{ width: '33%' }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>4일 전</th>
                <td>
                  취소 수수료 없음 <br />
                  결제 금액 전액 환불
                </td>
              </tr>
              <tr>
                <th>3일 전</th>
                <td>
                  취소 수수료 30% <br />
                  결제 금액의 30% 환불 수수료 발생
                </td>
              </tr>
              <tr>
                <th>2일 전</th>
                <td>
                  취소 수수료 50% <br />
                  결제 금액의 50% 환불 수수료 발생
                </td>
              </tr>
              <tr>
                <th>4일 전</th>
                <td>
                  <span className="text-red">취소 및 부분 환불 불가</span>
                  <br />
                  상품이 출고된 이후, <br />
                  무료 취소 기간에 해당하더라도 취소 불가
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="disc_text_area mt-2">
          <ul>
            <li className="text-2.5xs font-medium">
              사용 시작일 기준, 취소 요청 시점에 따라 수수료가 발생합니다.
            </li>
            <li className="text-2.5xs font-medium">
              예약일정변경은 불가하며, 취소 후 다시 예약 부탁드립니다.
            </li>
          </ul>
        </div>
      </div>
    </Modal>
  ) : null;
}
