import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import icArrow from '@assets/images/icon_arrow_right_gray.svg';
import icShare from '@assets/images/icon_share.svg';
import icCoupon from '@assets/images/icon_coupon.svg';
import GetCouponModal from '@components/modal/getCoupon';
import ShareModal from '@components/modal/share';
import { common, valids } from '@config/index';
import useStore from '@store/index';
import ProductDto from '@api/product/product.dto';
import AuthApi from '@api/auth/auth.query';

interface Props {
  data?: ProductDto.Products.Response;
  reviewAverage: number;
  onReviewClick: () => void;
}

export default function ProductTopInfo({
  data,
  reviewAverage,
  onReviewClick,
}: Props) {
  const { setToastContainerStyle } = useStore((state) => state);
  const [shareOpen, setShareOpen] = useState(false);
  const [couponOpen, setCouponOpen] = useState(false);

  // api
  const isLogin = AuthApi.useIsLogin();

  return (
    <>
      <div className="prod_detail_top_info">
        <div className="inner">
          <button
            type="button"
            className="share_btn"
            onClick={() => setShareOpen(true)}
          >
            <img src={icShare} alt="공유하기" />
          </button>
          <p className="brand">
            {data?.brand?.nameEnglish.toUpperCase() ?? ''}
          </p>
          <h2 className="product">{data?.productName ?? ''}</h2>
          <div className="flex items-center gap-1">
            <div className="star_area">
              <div
                className="star_on"
                style={{ width: `${(reviewAverage ?? 0) * 20}%` }}
              />
            </div>
            <button
              type="button"
              className="review_cnt"
              onClick={onReviewClick}
            >
              {data?.reviews?.length ?? 0}건
              <img src={icArrow} alt="arrow" />
            </button>
          </div>
          <div className="pt-4">
            {Number(data?.discountRate ?? 0) > 0 && (
              <p className="price_discount">
                일{Number(data?.supplyPrice ?? 0).toLocaleString()}원
              </p>
            )}
            <div className="flex items-center gap-1">
              {Number(data?.discountRate ?? 0) > 0 && (
                <p className="discount_text">
                  {`${Number(data?.discountRate ?? 0)}%`}
                </p>
              )}
              <p className="price_total">
                일{' '}
                <b>
                  {Number(data?.discountRate ?? 0) > 0
                    ? Number(data?.discountPrice ?? 0).toLocaleString()
                    : Number(data?.dailyRentalPrice ?? 0).toLocaleString()}
                </b>
                원
              </p>
            </div>
          </div>
          {data?.coupon && (
            <div className="coupon_area mt-4">
              <p>
                {data?.coupon?.name ?? ''}{' '}
                <b>
                  {Number(data?.coupon?.discountValue ?? 0)}
                  {data?.coupon?.discountType === 'percentage'
                    ? '%'
                    : data?.coupon?.discountType === 'amount'
                      ? '원'
                      : ''}{' '}
                  할인
                </b>
              </p>
              <button
                type="button"
                onClick={() => {
                  if (!isLogin) {
                    setToastContainerStyle({ bottom: 78 });
                    toast(valids.goLogin, { style: common.toastStyle });
                  } else setCouponOpen(true);
                }}
              >
                <img src={icCoupon} alt="coupon" />
                쿠폰받기
              </button>
            </div>
          )}
        </div>
      </div>

      <ShareModal open={shareOpen} setOpen={setShareOpen} data={data} />
      <GetCouponModal open={couponOpen} setOpen={setCouponOpen} id={data?.id} />
    </>
  );
}
