import { useQuery } from '@tanstack/react-query';
import { useAxios, Utils } from '@api/index';
import CategoryQueryKey from '@api/category/category.key';
import CategoryDto from '@api/category/category.dto';

namespace CategoryApi {
  /**
   * 1차 카테고리 조회
   */
  export function useCategoriesQuery() {
    const axios = useAxios();

    return useQuery({
      queryKey: [CategoryQueryKey.CATEGORY],
      queryFn() {
        return axios.get<Utils.View.ResponseResult<CategoryDto.Category[]>>(
          '/api/users/categories',
        );
      },
    });
  }

  /**
   * 2차 카테고리 조회
   * @param {number} parentId - 1차 카테고리 ID
   */
  export function useSecondaryCategoriesQuery(parentId?: number) {
    const axios = useAxios();

    return useQuery({
      queryKey: [CategoryQueryKey.CATEGORY_DEPTH2, parentId],
      queryFn() {
        return axios.get<Utils.View.ResponseResult<CategoryDto.Category[]>>(
          `/api/users/categories/${parentId}/secondary`,
        );
      },
      enabled: !!parentId,
    });
  }

  /**
   * 3차 카테고리 조회
   * @param {number} parentId - 2차 카테고리 ID
   */
  export function useTertiaryCategoriesQuery(parentId?: number) {
    const axios = useAxios();

    return useQuery({
      queryKey: [CategoryQueryKey.CATEGORY_DEPTH3, parentId],
      queryFn() {
        return axios.get<Utils.View.ResponseResult<CategoryDto.Category[]>>(
          `/api/users/categories/${parentId}/tertiary`,
        );
      },
      enabled: !!parentId,
    });
  }
}

export default CategoryApi;
