/* ===== COMMONS ===== */
export const SNS_DATA_KEY = 'sns';
export const SNS_KAKAO_KEY = 'kakao';
export const SNS_NAVER_KEY = 'naver';
export const SNS_APPLE_KEY = 'apple';
export const PASS_DATA_KEY = 'pass';
export const TOSS_DATA_KEY = 'toss';
export const VERIFICATION_DATA_KEY = 'verification';
export const CANCEL_DATA_KEY = 'cancel_item';

export type ConfigType = {
  id: string | number;
  text: string;
};
export type ConfigIdType<T> = {
  id: T;
  text: string;
};

export const toastStyle = {
  borderRadius: '8px',
  background: 'rgba(0,0,0,0.8)',
  fontSize: '12px',
  color: '#fff',
};

export const imageUrl = process.env.REACT_APP_IMAGE_URL;

export const passApiUrl =
  'https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb';
export const tossApiUrl =
  'https://api.tosspayments.com/v1/billing/authorizations/issue';

export const weeks = [
  { id: '2', text: '월' },
  { id: '3', text: '화' },
  { id: '4', text: '수' },
  { id: '5', text: '목' },
  { id: '6', text: '금' },
  { id: '7', text: '토' },
  { id: '1', text: '일' },
];
