import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Header from '@components/shared/header';
import BottomNav from '@components/shared/bottomNav';
import FilterWrap from '@components/filterWrap';
import CustomSelect from '@components/forms/customSelect';
import LikeBtn from '@components/likeBtn';
import { common, options, valids } from '@config/index';
import { sleep } from '@utils/common';
import useStore from '@store/index';
import BrandApi from '@api/brand/brand.query';
// import { productData } from '@temp/productData';

function Page() {
  const [searchParams] = useSearchParams();
  const spBrandIds = searchParams.getAll('brandId');
  const spDiscountIds = searchParams.getAll('discountId');
  const spCategpryId = searchParams.get('categoryId');
  const { setLoading } = useStore((state) => state);
  const [sorts, setSorts] = useState(options.productSorts[0].id);

  // api
  const {
    data: callData,
    isLoading,
    refetch,
    // error,
  } = BrandApi.useSearchBrandsProductsQuery({
    brandId:
      spBrandIds?.length > 0
        ? spBrandIds?.map((item) => Number(item))
        : undefined,
    orders: sorts,
    discountRate: spDiscountIds?.length > 0 ? spDiscountIds : undefined,
    categoryId:
      spCategpryId && spCategpryId !== '0' ? Number(spCategpryId) : undefined,
  });
  const resultData = callData?.data?.data;
  const listData = resultData?.result ?? [];

  useEffect(() => {
    // console.log('resultData', resultData, error?.response?.data?.message);
    if (spBrandIds?.length <= 0 || (resultData !== undefined && !isLoading)) {
      (async () => {
        await sleep(400);
        setLoading(false);
      })();
    } else setLoading(true);
  }, [resultData, isLoading]);

  return (
    <div id="root_layout">
      <div className="root_container">
        <div className="sticky_menu">
          <Header title="브랜드" back />
        </div>

        {/* 필터 */}
        <div className="inner pb-6">
          <FilterWrap isCategory />
        </div>

        {/* 리스트 */}
        <div className="pb-24">
          <div className="inner">
            <div className="flex items-center justify-between pb-4">
              <p className="text-xs font-medium text-gray-9">
                {resultData?.cntAll ?? 0}건
              </p>
              <CustomSelect
                type={1}
                optionClassName="w-24 right-0"
                options={options.productSorts}
                value={sorts}
                setValue={setSorts}
              />
            </div>
            <div className="list_area list_style01 grid02">
              <ul>
                {spBrandIds?.length > 0 ? (
                  listData?.length > 0 ? (
                    listData?.map((item) => {
                      return (
                        <li key={item.id}>
                          <LikeBtn
                            id={item?.id}
                            likeYn={item?.likeYn}
                            refetch={refetch}
                            toastBottom={78}
                          />
                          <Link to={`/product/detail/${item.id}`}>
                            <div className="img_wrap">
                              <div className="img_area rounded-lg">
                                {item?.product_images?.length > 0 ? (
                                  <img
                                    src={`${common.imageUrl}/${item?.product_images?.[0]?.file?.fileUrl}`}
                                    alt="상품 이미지"
                                  />
                                ) : (
                                  <i className="no_data_img" />
                                )}
                                <span className="tag">렌트</span>
                              </div>
                            </div>
                            <div className="text_area">
                              <span className="brand">
                                {item?.brand?.nameEnglish.toUpperCase() ?? ''}
                              </span>
                              <p className="product">
                                {item?.productName ?? ''}
                              </p>
                              <div className="flex items-center justify-start gap-1">
                                {Number(item?.discountRate ?? 0) > 0 && (
                                  <span className="price_discount">
                                    {`${Number(item?.discountRate ?? 0)}%`}
                                  </span>
                                )}
                                <em className="price_desc">일</em>
                                <p className="price_total">
                                  {Number(item?.discountRate ?? 0) > 0
                                    ? Number(
                                        item?.discountPrice ?? 0,
                                      ).toLocaleString()
                                    : Number(
                                        item?.dailyRentalPrice ?? 0,
                                      ).toLocaleString()}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </li>
                      );
                    })
                  ) : (
                    <li className="no_data_text">
                      <p>{valids.noData}</p>
                    </li>
                  )
                ) : (
                  <li className="no_data_text">
                    <p>{valids.noDataSearch}</p>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>

        <BottomNav />
      </div>
    </div>
  );
}
export default Page;
