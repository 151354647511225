import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { format } from 'date-fns';
import { Swiper, SwiperSlide } from 'swiper/react';
import icStar from '@assets/images/icon_star_on.svg';
import { common, valids } from '@config/index';
import useStore from '@store/index';
import ProductDto from '@api/product/product.dto';
import AuthApi from '@api/auth/auth.query';
// import { reviewData } from '@temp/reviewData';

interface Props {
  reviewAverage: number;
  data: ProductDto.Products.Reviews[];
}

export default function ProductItemsInfo({ reviewAverage, data }: Props) {
  const navigate = useNavigate();
  const { setToastContainerStyle } = useStore((state) => state);
  const [datas, setDatas] = useState<ProductDto.Products.Reviews[]>([]);
  const [isLimit, setIsLimit] = useState(true);

  // api
  const isLogin = AuthApi.useIsLogin();

  useEffect(() => {
    if (data?.length > 0) {
      if (!isLimit) setDatas(data);
      else setDatas(data.slice(0, 5));
    } else setDatas([]);
  }, [data, isLimit]);

  return (
    <div className="inner">
      <div className="text-center">
        <h3 className="text-sm font-medium text-black">사용자 총 평점</h3>
        <div className="my-4 flex items-center justify-center gap-1">
          <img src={icStar} alt="star" className="w-4" />
          <p className="text-xl font-extrabold text-gray-9">
            <b className="font-extrabold text-black">{reviewAverage ?? 0}</b> /
            5
          </p>
        </div>
        <button
          type="button"
          className="btn btn_style01 text-medium h-10 w-full rounded-lg text-sm"
          onClick={() => {
            if (!isLogin) {
              setToastContainerStyle({ bottom: 78 });
              toast(valids.goLogin, { style: common.toastStyle });
            } else navigate('/mypage/review');
          }}
        >
          리뷰 작성
        </button>
      </div>

      <div className="list_area list_style06 mt-4">
        <ul>
          {datas?.length > 0 ? (
            datas?.map((item, index) => {
              const scorePercent = Number(item.rating ?? 0) * 20;
              return (
                <li key={index}>
                  <div className="img_wrap">
                    <Swiper>
                      {!(
                        item?.image_file_id_1_file ||
                        item?.image_file_id_2_file ||
                        item?.image_file_id_3_file
                      ) && (
                        <SwiperSlide>
                          <div className="img_area">
                            <i className="no_data_img" />
                          </div>
                        </SwiperSlide>
                      )}
                      {item?.image_file_id_1_file && (
                        <SwiperSlide>
                          <div className="img_area">
                            <img
                              src={`${common.imageUrl}/${item?.image_file_id_1_file?.fileUrl}`}
                              alt="상품 이미지"
                            />
                          </div>
                        </SwiperSlide>
                      )}
                      {item?.image_file_id_2_file && (
                        <SwiperSlide>
                          <div className="img_area">
                            <img
                              src={`${common.imageUrl}/${item?.image_file_id_2_file?.fileUrl}`}
                              alt="상품 이미지"
                            />
                          </div>
                        </SwiperSlide>
                      )}
                      {item?.image_file_id_3_file && (
                        <SwiperSlide>
                          <div className="img_area">
                            <img
                              src={`${common.imageUrl}/${item?.image_file_id_3_file?.fileUrl}`}
                              alt="상품 이미지"
                            />
                          </div>
                        </SwiperSlide>
                      )}
                    </Swiper>
                  </div>
                  <div className="text_area">
                    <div className="star_area sm">
                      <div
                        className="star_on"
                        style={{ width: `${scorePercent}%` }}
                      />
                    </div>
                    <p className="text my-2">{item.content}</p>
                    <div className="flex items-center gap-2">
                      <p>{item?.user?.name ?? ''}</p>
                      <p>{format(item.createdAt ?? '', 'yyyy.MM.dd')}</p>
                    </div>
                  </div>
                </li>
              );
            })
          ) : (
            <li className="no_data_text">
              <p>{valids.noData}</p>
            </li>
          )}
        </ul>
      </div>
      {data?.length > 5 && isLimit && (
        <button
          type="button"
          className="btn btn_style01 text-medium mt-4 h-10 w-full rounded-lg text-sm"
          onClick={() => setIsLimit(false)}
        >
          리뷰 더보기 +
        </button>
      )}
    </div>
  );
}
