import React, { useEffect, useState } from 'react';
import PassAuthWrap, { AuthType } from '@components/passAuthWrap';
import JoinAgreeModal from '@components/modal/joinAgree';

interface Props {
  onClick?: () => void;
  setData?: React.Dispatch<React.SetStateAction<AuthType | null>>;
  setIsAgree?: React.Dispatch<React.SetStateAction<boolean>>;
}

const checkListData = [
  {
    id: 1,
    text: '[필수] 만 14세 이상입니다.',
  },
  {
    id: 2,
    text: '[필수] 이용약관 동의',
    title: '렌티크 서비스 이용 약관',
  },
  {
    id: 3,
    text: '[필수] 개인정보 수집 및 이용 동의',
    title: '렌티크 개인정보 처리방침',
  },
  {
    id: 0,
    text: '[선택] 선택정보 수집 및 마케팅 수신 동의',
    title: '선택정보 수집 및 마케팅 수신 동의',
  },
];

export default function JoinAgree({
  onClick = () => {},
  setData,
  setIsAgree,
}: Props) {
  const [checked, setChecked] = useState<number[]>([]);
  const [agreeIndex, setAgreeIndex] = useState(1);
  const [agreeTitle, setAgreeTitle] = useState('');
  const [agreeOpen, setAgreeOpen] = useState(false);

  const handleCheckAllClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!(checked && setChecked)) return;

    if (e.target.checked) {
      const newSelecteds = checkListData.map((n) => n.id);
      setChecked(newSelecteds);

      return;
    }
    setChecked([]);
  };
  const handleCheckClick = (id: number) => {
    if (!(checked && setChecked)) return;
    const newChecked = checked.includes(id)
      ? checked.filter((item) => item !== id)
      : [...checked, id];

    setChecked(newChecked);
  };

  useEffect(() => {
    if (!setIsAgree) return;
    if (checked?.includes(0)) setIsAgree(true);
    else setIsAgree(false);
  }, [checked]);

  return (
    <>
      <div className="auth_wrap type01">
        <div className="inner">
          <p className="desc_text">약관에 동의해 주세요.</p>

          <div className="input_list_area grid01 mt-8">
            <ul className="gap-y-4">
              {checkListData.map((item) => {
                return (
                  <li key={item.id}>
                    <div className="flex items-center justify-between">
                      <div className="chk_input">
                        <input
                          type="checkbox"
                          id={`agree_0${item.id}`}
                          onChange={() => handleCheckClick(item.id)}
                          checked={checked?.indexOf(item.id) !== -1}
                        />
                        <label htmlFor={`agree_0${item.id}`}>{item.text}</label>
                      </div>
                      {item.id !== 1 && (
                        <button
                          type="button"
                          className="py-1 text-2xs text-gray-3 underline"
                          onClick={() => {
                            setAgreeIndex(item.id);
                            if (item?.title) setAgreeTitle(item.title);
                            setAgreeOpen(true);
                          }}
                        >
                          상세보기
                        </button>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <hr className="my-4 border-gray-c" />
          <div className="chk_input">
            <input
              type="checkbox"
              id="agree_all"
              onChange={handleCheckAllClick}
              checked={checked?.length === checkListData?.length}
            />
            <label htmlFor="agree_all">
              <b>모두 동의합니다.</b>
            </label>
          </div>
        </div>

        <div className="fixed_btm_btn">
          <div className="fixed_btn bg_style01">
            {setData ? (
              <PassAuthWrap setData={setData}>
                <button
                  type="button"
                  className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                  disabled={![1, 2, 3].every((num) => checked?.includes(num))}
                >
                  다음
                </button>
              </PassAuthWrap>
            ) : (
              <button
                type="button"
                className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                disabled={![1, 2, 3].every((num) => checked?.includes(num))}
                onClick={onClick}
              >
                다음
              </button>
            )}
          </div>
        </div>
      </div>

      <JoinAgreeModal
        index={agreeIndex}
        title={agreeTitle}
        open={agreeOpen}
        setOpen={setAgreeOpen}
      />
    </>
  );
}
