import React, { useEffect, useState } from 'react';
import icTimer from '@assets/images/main/icon_timer.svg';

interface Props {
  date: string;
}

export default function MainTimer({ date }: Props) {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  useEffect(() => {
    const diffDateTime = date ? +new Date(`${date}T23:59:59`) - +new Date() : 0;

    const timer = setInterval(() => {
      const days = Math.floor(diffDateTime / (1000 * 60 * 60 * 24));
      const hours = String(
        Math.floor((diffDateTime / (1000 * 60 * 60)) % 24),
      ).padStart(2, '0');
      const minutes = String(
        Math.floor((diffDateTime / 1000 / 60) % 60),
      ).padStart(2, '0');
      const seconds = String(Math.floor((diffDateTime / 1000) % 60)).padStart(
        2,
        '0',
      );
      setTimeLeft({ days, hours, minutes, seconds });
    }, 1000);

    if (diffDateTime < 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [timeLeft]);

  return (
    <div className="main_timer_text">
      <img src={icTimer} alt="타이머" />
      <span>
        {timeLeft.days ? `${timeLeft.days}일 ` : null}
        {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
      </span>
      <p>남음</p>
    </div>
  );
}
