import React from 'react';
import Header from '@components/shared/header';

interface Props {
  index: number;
  title: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function JoinAgreeModal({ index, title, open, setOpen }: Props) {
  return open ? (
    <div className="modal_wrap full">
      <div className="modal_area">
        <Header back backFunc={() => setOpen(false)} />

        <section className="agree_page_wrap">
          <div className="inner pb-24">
            <div className="title_area">
              <h3 dangerouslySetInnerHTML={{ __html: title }} />
            </div>
            {index === 0 ? (
              <div className="table_area table_style01">
                <table className="border-t-gray-c">
                  <colgroup>
                    <col style={{ width: '15%' }} />
                    <col />
                    <col />
                    <col style={{ width: '18%' }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>분류</th>
                      <th>수집·이용목적</th>
                      <th>수집·이용항목</th>
                      <th>
                        보유 및 <br />
                        이용기간
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center align-top">선택정보</td>
                      <td className="text-left align-top">
                        본인인증을 통한 거래 안전, 연령, 성별에 따른 서비스
                        제공, 매출 분석 및 배송
                      </td>
                      <td className="text-left align-top">
                        본인인증을 통한 거래 안전, 연령, 성별에 따른 서비스
                        제공, 매출 분석 및 배송
                      </td>
                      <td className="text-left align-top">회원 탈퇴 시 삭제</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="agree_area">
                1장 총칙 제1조(목적) 이 약관은 주식회사 렌티크(이하 “RENTIQUE”
                또는 “회사”라 함)가 운영하는 인터넷 사이트(이하 “렌티크사이트”라
                함)를 통하여 제공하는 인터넷 전자상거래 관련 서비스(이하
                “렌티크서비스”라 함)와 관련하여 회사와 이용자의 권리, 의무,
                책임사항을 규정함을 목적으로 합니다. 또한 본 약관은 유무선
                PC통신, 스마트폰(아이폰, 안드로이드폰 등) 어플리케이션 및
                모바일웹 등을 이용하는 전자상거래 등에 대해서도 그 성질에 반하지
                않는 한 준용됩니다. 제2조(정의) ① “몰”이란 회사가 재화 또는
                용역(이하 “재화 등”이라 함)을 이용자에게 제공하기 위하여 컴퓨터
                등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한
                가상의 영업장을 말하며, 아울러 사이버몰을 운영하는 사업자의
                의미로도 사용합니다. ② “이용자”란 “몰”에 접속하여 이 약관에 따라
                “몰”이 제공하는 서비스를 받는 회원 및 비회원을 말합니다. ③
                ‘회원’이라 함은 “몰”에 회원등록을 한 자로서, 계속적으로 “몰”이
                제공하는 서비스를 이용할 수 있는 자를 말합니다. ④ ‘비회원’이라
                함은 회원에 가입하지 않고 “몰”이 제공하는 서비스를 이용하는 자를
                말합니다. 이 약관에서 정의되지 않은 용어는 관련법령이 정하는
                바에 따르며, 그 외에는 일반적인 상거래 관행에 의합니다. 제3조
                (약관 등의 명시와 설명 및 개정) 1. “몰”은 이 약관의 내용과 상호
                및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수
                있는 곳의 주소를 포함), 전화번호․모사전송번호․전자우편주소,
                사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자등을
                이용자가 쉽게 알 수 있도록 사이버몰의 초기 서비스화면(전면)에
                게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수
                있도록 할 수 있습니다. 2. “몰은 이용자가 약관에 동의하기에 앞서
                약관에 정하여져 있는 내용 중 반품,취소․배송책임․환불조건 등과
                같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면
                또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다. 3.
                “몰”은 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의
                규제에 관한 법률」, 「전자문서 및 전자거래기본법」,
                「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및
                정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」,
                「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을
                개정할 수 있습니다. 4. “몰”이 약관을 개정할 경우에는 적용일자 및
                개정사유를 명시하여 현행약관과 함께 몰의 초기화면에 그 적용일자
                7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게
                불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전
                유예기간을 두고 공지합니다. 이 경우 “몰”은 개정 전 내용과 개정
                후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다. 5.
                “몰”이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에
                체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는
                개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한
                이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한
                개정약관의 공지기간 내에 “몰”에 송신하여 “몰”의 동의를 받은
                경우에는 개정약관 조항이 적용됩니다. 6. 이 약관에서 정하지
                아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의
                소비자보호에 관한 법률, 약관의 규제 등에 관한 법률,
                공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및
                관계법령 또는 상관례에 따릅니다. 7. 3조4항에 따라 공지된
                적용일자 이후에 이용자가 렌티크서비스를 계속 이용하는 경우
                개정된 약관에 동의하는 것으로 간주합니다. 그러나 개정된 약관에
                동의하지 아니하는 경우 이용자는 언제든지 자유롭게 렌티크서비스
                이용계약을 해지할 수 있습니다. 제4조 (렌티크 서비스 종류) 1.
                단기 렌탈 서비스 :가방, 의류, 액세서리, 시계 등 명품을 명품별로
                책정된 일단위 렌트료를 지급받고 단기간(최대2주)예약 대여하는
                서비스 2. 위탁 렌탈 서비스 : 이용자의 가방, 의류, 액세서리, 시계
                등 명 품을 명품별로 위탁받아 일정 수수료와 이용료를 받고 렌트
                대여하는 서비스 제 5 조 회원의 의무 1. 회원은 관계법령, 이
                약관의 규정, 이용안내 등 회사가 통지하는 사항을 준수하여야 하며,
                기타 타인의 권익을 침해하거나, 회사 업무에 방해되는 행위를
                하여서는 안됩니다. 2. 회원은 서비스 이용과 관련하여 다음 각 호의
                행위를 하여서는 안됩니다. • 이용계약 신청 또는 이용정보 변경 시
                허위내용의 등록 • 회사의 서비스에 게시된 정보 또는 회원이
                서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 영리 또는
                비영리의 목적으로 복제, 출판, 방송 등에 사용하거나 제 3 자에게
                제공하는 행위 • 회사의 사전 승낙없이 회사의 서비스를 이용하여
                영업활동을 하는 행위 • 회사가 게시한 정보의 허가 받지 않은 변경
                • 회사가 제공하는 서비스 및 사이트를 이용하여 본인 또는 제 3
                자를 홍보하거나, 홍보할 기회를 제공하는 행위 • 회사가 제공하는
                서비스 및 사이트를 이용하여 본인을 홍보하거나 제 3 자의 홍보를
                대행하는 등의 방법으로 금전을 수수하는 행위 • 회사의 명시적
                동의가 없는 서비스의 이용권한, 기타 이용계약상의 지위를 타인에게
                양도, 증여, 담보하거나 금전수수를 수반하는 행위 • 회사가 정한
                정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시 •
                정보통신망법 등 관련 법령에 의하여 그 전송 또는 게시가 금지되는
                정보(컴퓨터 프로그램 등)을 전송하거나 게시 및 관련 사이트를
                링크하는 행위 • 회사로부터 특별한 권리를 부여 받지않고 회사의
                프로그램을 변경하거나, 서버를 해킹하거나, 사이트 또는 게시된
                정보의 일부분 또는 전체를 임의로 변경하는 행위 • 회사 및 기타 제
                3 자의 저작권 등 지적 재산권에 대한 침해 • 회사 및 기타 제 3
                자의 명예를 손상시키거나 업무를 방해하는 행위 • 다른 회원의
                아이디 및 비밀번호를 도용하여 서비스를 이용하는 행위 • 이용자
                아이디를 타인과 거래하는 행위 • 회사에서 물품이나 서비스를
                이용하는 대가로 타인의 계좌번호, 신용카드 등의 결제정보를 허락
                없이 이용하는 행위 • 정크메일, 스팸메일, 행운의 편지, 광고메일,
                외설 또는 폭력적인 메시지, 동영상, 음성 등이 담긴 메일을 보내는
                행위 • 청소년보호법에서 규정하는 청소년유해매체물을 게시(링크
                포함)하는 행위 • 외설 또는 폭력적인 메시지, 동영상, 음성 기타
                공공질서, 미풍양속에 반하는 정보, 문장, 도형, 동영상, 음성 등
                사이트에 공개, 게시, 전송 또는 기타의 방법으로 다른 이용자에게
                유포하는 행위 • 다른 이용자를 희롱 또는 위협하거나, 특정
                이용자에게 지속적으로 고통 또는 불편을 주는 행위 • 회사의
                직원이나 서비스의 관리자로 가장, 사칭하거나 타인의 명의를
                도용하여 글을 게시하거나 메일을 발송하는 행위 • 컴퓨터
                소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해,
                파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터
                코드, 파일, 프로그램을 포함하는 자료를 게시하거나 메일으로
                발송하는 행위 • 스토킹, 욕설, 채팅글 도배 등 다른 회원의 서비스
                이용을 방해하는 행위 • 다른 회원의 개인정보를 그 동의 없이 수집,
                저장, 공개, 유포하는 행위 • 불특정 다수의 회원을 대상으로 하여
                광고 또는 선전물을 게시하거나 관련 사이트를 링크하는 행위 • 특정
                재화 또는 서비스를 실제 구매 또는 사용하지 않았음에도 불구하고
                마치 구매 또는 사용한 듯한 외관을 보이면서 회사의 사이트나 기타
                매체를 통해 통상의 비판범위를 초과하여 관련 재화/서비스의 품질을
                폄하하거나 회사를 비난하는 행위 • 서비스를 반복 주문 후 취소하는
                행위 • 회사에서 제공하는 적립금, 할인쿠폰 등을 부정한 방법으로
                취득 및 사용하거나 타인에게 제공하는 행위 • 서비스에 방해가 될
                수 있는 행위임을 알았거나 고의로서 방해하는 행위 • 근거 없이
                회사의 명예나 신용을 훼손하는 행위 • 회원의 의무 불이행 • 현행
                법령, 회사가 제공하는 서비스에 대한 약관 기타 서비스 이용에 관한
                규정 및 회사 방침(사이트 공지사항 포함)에 위반하는 행위 • 범죄와
                결부된다고 객관적으로 판단되거나 본 약관을 포함하여 회사의 제반
                규정 또는 이용 조건을 위반하는 행위
              </div>
            )}
            <div className="fixed_btm_btn">
              <div className="fixed_btn p-4 pt-8">
                <button
                  type="button"
                  className="btn btn_style02 h-12 w-full rounded-sm text-sm font-bold"
                  onClick={() => setOpen(false)}
                >
                  닫기
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  ) : null;
}
