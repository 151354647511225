import React from 'react';
import {
  QueryClient,
  QueryClientProvider,
  useQueryClient,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Axios from 'axios';
// import Axios, { AxiosResponse } from 'axios';
import queryString from 'query-string';
import { jwtDecode } from 'jwt-decode';

const API_BASE_URL = process.env.REACT_APP_API_URL;

// ===== Token & Axios Settings =====
export const TOKEN_STORE_KEY = 'token';

export namespace JwtDto {
  export interface Token {
    name: string; // 이름
    accessToken: string; // 엑세스 토큰
    refreshToken: string; // 리프레시 토큰
  }
}
type ContextType = [JwtDto.Token | null, React.Dispatch<JwtDto.Token | null>];

const context = React.createContext<ContextType>([null, () => {}]);
const _queryClient = new QueryClient();
let _token: JwtDto.Token | Promise<JwtDto.Token> | null = null;

export async function getToken(
  callback?: (response: JwtDto.Token) => void,
): Promise<JwtDto.Token | null> {
  if (_token) {
    const token = await _token;
    const originName = token.name;
    const decodedAccessToken = jwtDecode<{ exp: number }>(token.accessToken);
    const decodedRefreshToken = jwtDecode<{ exp: number }>(token.refreshToken);
    const currentTime = Date.now() / 1000;
    // console.log('decodedAccessToken', decodedAccessToken?.exp);
    // console.log('decodedRefreshToken', decodedRefreshToken?.exp);
    // console.log('currentTime', currentTime);

    if (
      decodedAccessToken?.exp < currentTime &&
      decodedRefreshToken?.exp > currentTime
    ) {
      _token = Axios.post(
        '/api/auth/refresh',
        {},
        {
          baseURL: API_BASE_URL,
          headers: {
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': API_BASE_URL,
            // 'Access-Control-Allow-Headers': API_BASE_URL,
            'Access-Control-Allow-Credentials': true,
            Authorization: `Bearer ${token.accessToken}`,
            Refresh: token.refreshToken,
          },
        },
      ).then(({ data: { data: responseData } }) => {
        callback?.({ name: originName, ...responseData });
        return { name: originName, ...responseData };
      });

      return _token;
    }
  }

  return _token;
}

export function useContext(): ContextType {
  const [token, setToken] = React.useContext(context);

  return [
    token,
    (value) => {
      _token = value;
      setToken(value);

      if (value) {
        localStorage.setItem(TOKEN_STORE_KEY, JSON.stringify(value));
      } else {
        localStorage.removeItem(TOKEN_STORE_KEY);
      }
    },
  ];
}

export function useAxios() {
  const queryClient = useQueryClient();
  const axios = React.useRef(
    Axios.create({
      baseURL: API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': API_BASE_URL,
        // 'Access-Control-Allow-Headers': API_BASE_URL,
        'Access-Control-Allow-Credentials': true,
      },
      paramsSerializer(params) {
        return queryString.stringify(params, { arrayFormat: 'none' });
      },
    }),
  ).current;
  const [token, setToken] = useContext();

  if (token) {
    axios.interceptors.request.use(async (value) => {
      if (_token) {
        const t = await getToken(setToken);
        if (t) {
          value.headers.Authorization = `Bearer ${t.accessToken}`;
          value.headers.Refresh = t.refreshToken;
        } else {
          delete value.headers.Authorization;
          delete value.headers.Refresh;
        }
      }
      return value;
    });
    axios.interceptors.response.use(
      (value) => {
        return value;
      },
      async (error) => {
        if (error?.response?.status === 401) {
          setToken(null);
          await queryClient.resetQueries();
          window.location.reload();
        }
        if (
          error.code === 'ERR_NETWORK' ||
          error?.response?.data?.status === 502
        ) {
          error.data = {
            response: {
              data: {
                status: 502,
                message: '인터넷 연결 오류',
              },
            },
          };
        }
        throw error;
      },
    );
  }
  return axios;
}

// ===== Provider Component =====
export function ReactQueryProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  let tokenStoreObject: JwtDto.Token | null = null;
  if (typeof window !== 'undefined') {
    const tokenStore = localStorage.getItem(TOKEN_STORE_KEY) ?? null;
    if (tokenStore) {
      tokenStoreObject = JSON.parse(tokenStore) as JwtDto.Token;
    }
  }
  // console.log('tokenStoreObject:', tokenStoreObject);
  const state = React.useState<JwtDto.Token | null>(tokenStoreObject);
  const [token] = state;
  _token = token;

  return (
    <QueryClientProvider client={_queryClient}>
      <context.Provider value={state}>{children}</context.Provider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

// ===== Common Utils =====
export namespace Utils {
  export namespace View {
    export interface Response<T> {
      status: number;
      data: T;
    }
    export interface ResponseResult<T> {
      status: number;
      data: {
        cntAll?: number;
        result: T;
      };
    }
  }
}
