import React, { useEffect } from 'react';
import icUrl from '@assets/images/icon_share_url.svg';
import icKakao from '@assets/images/icon_share_kakao.svg';
import Modal from '@components/modal/modal';
import { common } from '@config/index';
import { copyUrl } from '@utils/common';
import ProductDto from '@api/product/product.dto';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data?: ProductDto.Products.Response;
}

export default function ShareModal({ open, setOpen, data }: Props) {
  const { Kakao } = window;

  const handleKakaoShare = () => {
    Kakao.Share.sendDefault({
      objectType: 'feed',
      content: {
        title: data?.productName
          ? `${data?.productName} - 렌티크(RENTIQUE)`
          : '렌티크(RENTIQUE)',
        description: `${
          data?.brand?.nameEnglish
            ? `[${data?.brand?.nameEnglish.toUpperCase()}] `
            : ''
        }${data?.productName ?? ''} 상품을 렌티크에서 확인하세요!`,
        imageUrl:
          (data?.product_images ?? [])?.filter((item) => item.type !== 'info')
            ?.length > 0
            ? `${common.imageUrl}/${data?.product_images?.filter(
                (item) => item.type !== 'info',
              )?.[0]?.file?.fileUrl}`
            : '',
        link: {
          mobileWebUrl: window.location.href,
          webUrl: window.location.href,
        },
      },
      buttons: [
        {
          title: 'RENTIQUE에서 확인',
          link: {
            mobileWebUrl: window.location.href,
            webUrl: window.location.href,
          },
        },
      ],
    });
    setOpen(false);
  };

  useEffect(() => {
    Kakao.cleanup();
    Kakao.init(process.env.REACT_APP_KAKAO_API_KEY);
    // console.log(Kakao.isInitialized()); // 적용확인 (true)
  }, []);

  return open ? (
    <Modal type="BOTTOM" setOpen={setOpen}>
      <div className="modal_mid text-center">
        <p className="text-sm font-bold text-black">상품 공유</p>
        <div className="mt-4 flex items-center justify-center gap-8">
          <button
            type="button"
            className="text-xs font-medium text-black"
            onClick={() => {
              copyUrl();
              setOpen(false);
            }}
          >
            <img src={icUrl} alt="url copy" />
            <span className="mt-2 block">URL 복사</span>
          </button>
          <button
            type="button"
            className="text-xs font-medium text-black"
            onClick={handleKakaoShare}
          >
            <img src={icKakao} alt="kakao" />
            <span className="mt-2 block">카카오톡</span>
          </button>
        </div>
      </div>
    </Modal>
  ) : null;
}
